import React from 'react';

import Modal from '@mui/material/Modal';

import { BackButton } from '../buttons';
import ItemMaster from './itemMaster';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: '85%',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',
  overflow: 'hidden',
};

const ModItemMasterHelp = ({ status, setStatus, onItemMasterRowClick }) => {
  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={' overflow-hidden '}>
          <div className="w-full flex flex-col  h-full ">
            <div
              style={{
                fontSize: '14px',
                border: '1px solid black',
                // borderBottom: '0px',
                overflow: 'hidden',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                borderBottom: '1px solid black',
              }}
              className="font-semibold bg-[#f5f0e8] font-arial p-3"
            >
              Item Master | Help
            </div>

            <div className="flex flex-col grow h-full ">
              <ItemMaster onRowClick={onItemMasterRowClick} />
              <div
                style={{
                  borderTop: '1px dotted black',
                  borderLeft: '1px dotted black',
                  borderRight: '1px dotted black',
                  borderBottom: '1px dotted black',
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px',
                }}
                className="w-full  flex justify-end p-2  "
              >
                <BackButton onClick={handleClose} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModItemMasterHelp;
