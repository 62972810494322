import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import './QRCodeScanner.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectDepartmentName,
  selectPrefix,
  selectTableMasterPrefix,
  selectUserEmail,
  selectUserType,
  selectWidth,
  setDepartmentName,
} from '../utility/Redux/profile';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setModWorkerMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { useLocation, useNavigate } from 'react-router-dom';
import FullWidthModal from './orderDetailsModal';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
import { getMessageWallet, low_Bal_alert } from '../utility/func/common';
import GeniustextInput from '../utility/component/mastertext';
import Departmenthelp from '../help/departmenthelp';
import EmployeeMasterHelp from '../help/employeeMasterHelp';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import { setCustomerPrefix } from '../utility/Redux/booking';
import UpdateProductionFirebase from '../utility/component/updateProductionFirebase';
const QRCodeScanner = ({
  setQrBillList,
  setMrcNumber,
  setBookingPrefix,
  setEmployee,
  setBillSettlementDetails,
  mrc,
  bookingPref,
}) => {
  const [OrderNumber, setOrderNumber] = useState('');
  const [devices, setDevices] = useState('');
  const [alert, setAlert] = useState('');
  const scannerRef = useRef(null);
  const [orderDetails, setOrderDetails] = useState('');
  const lineRef = useRef(null);
  const [departmemnthelpStatus, setDepartmentHelpStatus] = useState(false);
  const [refrshScan, setRefreshScan] = useState(false);
  const [QrId, setQrId] = useState('');
  const [employeeDetails, setEmployeeDetails] = useState();
  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const prefix = useSelector(selectPrefix);
  const [fullWidthModalStatus, setFullWidthModalStatus] = useState(false);
  const token = useSelector(selectToken);
  const [saveQnty, setSaveQnty] = useState(1);
  const company = useSelector(selectCompanyAddress);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const dep = useSelector(selectDepartmentName);
  const [departmentName, setProDepartment] = useState();
  const [rows, setRows] = useState([]);
  const [errAlert, setErrAlert] = useState('');
  const [cameraList, setCameraList] = useState([]);
  const defaultCamera = localStorage.getItem('defaultCamera');

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const userType = useSelector(selectUserType);
  const navigate = useNavigate();
  // h

  const checkIsValidStageUpdate = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/booking/checkOrderStage',
          {
            table_prefix: prefix,
            QR_CODE: id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          if (p.data.response[0]) {
            if (p.data.response[0].STAGE_NAME === 'Delivery') {
              reject(`Order is already Delivered`);
            } else {
              if (p.data.response[0].STAGE_NAME === 'Ready') {
                if (departmentName === 'Delivery') {
                  resolve(p.data.response);
                } else {
                  reject(
                    `Order is already in the Ready stage, cannot be updated to a lower stage.`
                  );
                }
              } else {
                if (departmentName !== 'Delivery') {
                  resolve(p.data.response);
                } else {
                  reject(
                    'The order must be in the Ready stage before it can be updated to Delivery'
                  );
                }
              }
            }
          } else {
            if (departmentName !== 'Delivery') {
              resolve(p.data.response);
            } else {
              reject(
                'The order must be in the Ready stage before it can be updated to Delivery'
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.response.data.name);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          reject(error.response);
        });
    });
  };
  const getReadyOrders = (mrc, bookingPrefix) => {
    dispatch(setSpinnerLoading('Loading ready orders'));
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/production/CustomerOrderStatus',
          {
            table_prefix: prefix,
            MRC_NO: mrc,
            PREFIX: bookingPrefix,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          if (!p.data.error) {
            const filterRedy = p.data.response.filter(
              (o) => o.STAGE_NAME === 'Ready'
            );
            resolve(filterRedy);
          } else {
            reject([]);
          }
        })
        .catch((error) => {
          reject([]);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        })
        .finally(() => {
          dispatch(setSessionExpired(false));
        });
    });
  };

  const checkOrder = (o, orderType, id) => {
    dispatch(setSpinnerLoading('Verifying order'));
    if (!isNaN(o)) {
      axios
        .post(
          `/api/booking/loadOrder`,
          {
            table_prefix: prefix,
            BOOKING_ORDER_NO: o,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          if (!response.data.error) {
            if (response.data.response[0]) {
              checkIsValidStageUpdate(id)
                .then(() => {
                  openModal(
                    o,
                    response.data.response[0].ITEM_NAME,
                    response,
                    orderType,
                    id
                  );
                })
                .catch((msg) => {
                  dispatch(setSpinnerLoading(false));
                  setRefreshScan((o) => !o);

                  setAlertTwoButtonModal({
                    title: `Alert`,
                    msg: msg,
                    horizontal: true,
                    status: true,
                    setStatus: '',
                    button1: 'Ok',
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        title: '',
                        msg: '',
                        status: false,
                        setStatus: '',
                        button1: '',
                        button1Click: '',
                        button2: '',
                        button2Click: '',
                      });
                      if (userType === 'SUPERVISOR') {
                        setEmployeeDetails('');
                      } else {
                        navigate('/');
                      }
                    },
                  });
                });
            } else {
              setAlertTwoButtonModal({
                title: `Not Found`,
                msg: `Order Not Found. May be its delivered or not in record.`,
                status: true,
                setStatus: '',
                button1: 'Ok',
                button1Click: () => {
                  setAlertTwoButtonModal({
                    title: '',
                    msg: '',
                    status: false,
                    setStatus: '',
                    button1: '',
                    button1Click: '',
                    button2: '',
                    button2Click: '',
                  });
                  if (userType === 'SUPERVISOR') {
                    setEmployeeDetails('');
                  } else {
                    navigate('/');
                  }
                },
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }

          console.log(error);
        });
    }
  };
  const companyDetails = useSelector(selectCompanyAddress);
  const sendDelMsgAPI = (
    name,
    mobile,
    delDate,
    time,
    companyname,
    compnyMobile,
    email
  ) => {
    const data = {
      CUSTOMER_NAME: name,
      MOBILE: mobile,
      DEL_DATE: moment(delDate).format('YYYY-MM-DD'),
      TIME: time,
      COMPANY_NAME: companyname,
      COMPANY_MOBILE: compnyMobile,
      COMPANY_EMAIL: email,
    };
    dispatch(setSpinnerLoading('Sending Message'));
    axios
      .post(`/api/sendDigitalBill/sendDelDateWhatsApp`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(
          setAlertWithTitle({
            tilte: 'success',
            msg: 'Whatsapp message sent successfully',
            lottie: 'success',
          })
        );
      })
      .catch(function (error) {
        console.log(error);

        dispatch(
          setAlertWithTitle({
            tilte: 'Error',
            msg: 'Whatsapp message not sent',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (errAlert) {
      const timeout = setTimeout(() => {
        setErrAlert('');
      }, 3500); // Adjust the time as needed
      return () => clearTimeout(timeout);
    }
  }, [errAlert]);

  const animateScannerLine = () => {
    if (lineRef.current) {
      lineRef.current.style.display = 'block';
      // Optionally, you can use a CSS animation or requestAnimationFrame for smoother effect
    }
  };
  const windowWidth = useSelector(selectWidth);

  const lisstEmployeeMaster = async () => {
    // try {
    await axios
      .post(
        '/api/masters/listEmployeeMaster',
        {
          table_prefix_master: masterPrefix,
          MOBILE: '',
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        checkUser(a);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };
  function setDeliveryStatusAPI(bookingNo, icode, status) {
    const data = {
      table_prefix: prefix,
      BOOKING_ORDER_NO: bookingNo,
      ICODE: icode,
      DELIVERED_YN: status,
      DELIVERED_DATE: moment().format('YYYY-MM-DD'),
    };
    axios
      .post(`/api/booking/deliverItem`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response.data.error) {
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 126',
              msg: response.data.error,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'Error 125',
            msg: 'Something went wrong while Updating Delivery status',
          })
        );
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  }
  const email = useSelector(selectUserEmail);
  const hmac = CryptoJS.HmacSHA256(email, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const checkUser = (list) => {
    dispatch(setSpinnerLoading('Loading user'));
    const data = {
      user_email: email,
    };
    axios
      .post(`/api/users/userInfo`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((res) => {
        const userDetail = res.data.response[0];
        if (userDetail) {
          const mobile = userDetail.user_mobile;
          const isEmployee = list.filter((user) => user.mobile === mobile);
          setEmployeeDetails(isEmployee[0]);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const checkPendingOrder = (p, mrc) => {
    dispatch(setSpinnerLoading('Check pending order'));
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/production/CheckOrderPendingQnty',
          {
            table_prefix: prefix,
            PREFIX: p,
            MRC_NO: mrc,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          if (!p.data.error) {
            const totalQnty = p.data.response[0].TOTAL_QNTY;
            const deliverd = p.data.response[0].DELIVERED_QNTY;
            const remaining = totalQnty - deliverd;
            if (remaining > 0) {
              resolve(remaining);
            } else {
              reject(0);
            }
          } else {
            reject(0);
          }
        })
        .catch((error) => {
          reject(0);
          console.log(error.response.data.name);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          reject(error.response);
        });
    });
  };
  const saveStage = (o, details, q) => {
    dispatch(setSpinnerLoading('Saving'));

    const list = [...Array(Number(saveQnty))].map(() => ({
      table_prefix: prefix,
      SERIAL_NO: '',
      DATE: moment().format('YYYY-MM-DD'),
      BOOKING_ORDER_NO: o,
      STAGE_NAME: departmentName,
      EMPLOYEE_CODE: employeeDetails.code,
      EMPLOYEE_MOBILE: employeeDetails.mobile,
      EMPLOYEE_NAME: employeeDetails.employee_name,
      CURRENT_EMPLOYEE: employeeDetails.employee_name,
      DEPARTMENT_NAME: departmentName,
      ICODE: details[0].ICODE,
      ITEM_NAME: details[0].ITEM_NAME,
      UPPER_LOWER: details[0].UPPER_LOWER,
      QR_CODE: QrId,
      CURRENT_EMP_MOBILE: employeeDetails.mobile,
      MRC_NO: details[0].MRC_NO,
      PREFIX: details[0].PREFIX,
    }));

    const data = {
      table_prefix: prefix,
      prod_cart: list,
    };

    axios
      .post(`/api/production/saveForProduction`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setOrderNumber('');
        if (!response.data.error) {
          UpdateProductionFirebase(masterPrefix);
          // if (departmentName === 'Delivery') {
          //   setDeliveryStatusAPI(o, details[0].ICODE, 'YES');
          // }

          setFullWidthModalStatus(false);
          setAlertTwoButtonModal({
            title: 'Success',
            msg: 'Data saved',
            status: true,
            setStatus: '',
            button1: 'Ok',
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });

              if (userType === 'SUPERVISOR') {
                setEmployeeDetails('');
                if (setEmployee) {
                  setEmployee('');
                }
              } else {
                navigate('/');
              }
            },
            button2Click:
              departmentName === 'Delivery'
                ? () => {
                    setAlertTwoButtonModal((o) => {
                      return {
                        ...o,
                        status: false,
                      };
                    });
                  }
                : null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(setSpinnerLoading(false));
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  useEffect(() => {
    if (userType !== 'SUPERVISOR' && path !== '/billing') {
      lisstEmployeeMaster();
    }
  }, []);

  const openModal = (o, item, res, orderType, qrValue) => {
    const upperData = res.data.response.filter(
      (i) => i.UPPER_LOWER === 'UPPER'
    );
    const data = res.data.response;
    const lowerData = res.data.response.filter(
      (i) => i.UPPER_LOWER === 'LOWER'
    );

    if (
      (orderType === 'UPPER' && upperData[0]) ||
      (orderType === 'LOWER' && lowerData[0])
    ) {
      const dep = departmentName.toLowerCase();
      const typeOfStage = dep.includes('wa')
        ? washing
        : dep.includes('cu')
        ? cutting
        : dep.includes('emb')
        ? embroidery
        : dep.includes('st')
        ? sttiching
        : dep.includes('bu')
        ? buttonstype
        : dep.includes('pr')
        ? press
        : dep.includes('re')
        ? ready
        : dep.includes('del')
        ? delivery
        : sttiching;
      const upperData = res.data.response.filter(
        (o) => o.UPPER_LOWER === 'UPPER'
      );
      const lowerData = res.data.response.filter(
        (o) => o.UPPER_LOWER === 'LOWER'
      );
      const d = orderType === 'UPPER' ? upperData : lowerData;
      setOrderDetails(d);
      if (departmentName === 'Delivery') {
        navigate('/billing', {
          state: {
            customer: {
              mobile: data[0].MOBILE,
              customer_name: data[0].CUSTOMER_NAME,
              mrc: data[0].MRC_NO,
              prefix: data[0].PREFIX,
            },
            saveStage: {
              table_prefix: prefix,
              SERIAL_NO: '',
              DATE: moment().format('YYYY-MM-DD'),
              BOOKING_ORDER_NO: o,
              STAGE_NAME: departmentName,
              EMPLOYEE_CODE: employeeDetails.code,
              EMPLOYEE_MOBILE: employeeDetails.mobile,
              EMPLOYEE_NAME: employeeDetails.employee_name,
              CURRENT_EMPLOYEE: employeeDetails.employee_name,
              DEPARTMENT_NAME: departmentName,
              ICODE: d[0].ICODE,
              ITEM_NAME: d[0].ITEM_NAME,
              UPPER_LOWER: d[0].UPPER_LOWER,
              QR_CODE: qrValue,
              CURRENT_EMP_MOBILE: employeeDetails.mobile,
            },
            orderID: data[0].BOOKING_ORDER_NO,
            employee: employeeDetails,
          },
        });
      } else {
        setFullWidthModalStatus(true);
      }
    } else {
      dispatch(
        setAlertWithTitle({
          tilte: 'Alert',
          msg: 'Order Not Found. May be Delivered or not in record',
        })
      );
    }
  };
  const config =
    windowWidth <= 1280
      ? {
          fps: 10,
          qrbox: { width: 200, height: 200 },
          // videoConstraints: {
          //   facingMode: { exact: 'environment' },
          // },
        }
      : {
          fps: 10,
          qrbox: { width: 200, height: 200 },
        };
  useEffect(() => {
    if (departmentName && employeeDetails && path !== '/billing') {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length > 0) {
            setCameraList(devices);
            if (defaultCamera) {
              const defautCameraAvailvl = devices.filter(
                (o) => o.id === defaultCamera
              );
              if (defautCameraAvailvl[0]) {
                setDevices(defautCameraAvailvl[0].id);
              } else {
                const backCameraAvailvaal = devices.filter((o) =>
                  o.label.toLowerCase().includes('back')
                );
                if (backCameraAvailvaal[0]) {
                  setDevices(backCameraAvailvaal[0].id);
                } else {
                  setDevices(devices[0].id);
                }
              }
            } else {
              const backCameraAvailvaal = devices.filter((o) =>
                o.label.toLowerCase().includes('back')
              );
              if (backCameraAvailvaal[0]) {
                setDevices(backCameraAvailvaal[0].id);
              } else {
                setDevices(devices[0].id);
              }
            }
          } else {
            console.error('No cameras found.');
          }
        })
        .catch((err) => {
          // const v = '478_UPPER_12_2';
          // // const v = '475_LOWER_5_1';
          // // const v = '477_UPPER_10_1';
          // const val = v.split('_');
          // checkOrder(val[0], val[1], v);
          // setOrderNumber(val[0]);
          // setQrId(v);

          console.error('Error fetching cameras:', err);
        });
    }
  }, [departmentName, employeeDetails]);
  useEffect(() => {
    if (devices && departmentName && employeeDetails && path !== '/billing') {
      const html5QrCode = new Html5Qrcode('reader');

      html5QrCode
        .start(
          devices,
          config,
          (decodedText, decodedResult) => {
            const v = decodedText;
            const val = v.split('_');
            checkOrder(val[0], val[1], v);
            setOrderNumber(val[0]);
            setQrId(decodedText);
            animateScannerLine();
            if (decodedResult) {
              html5QrCode
                .stop()
                .then(() => {
                  console.log('Camera stopped successfully.');
                })
                .catch((err) => {
                  console.error('Error stopping the camera:', err);
                });
            }
          },
          (errorMessage) => {
            console.warn(`QR Code no match: ${errorMessage}`);
          }
        )
        .catch((err) => {
          console.error('Error starting Html5Qrcode:', err);
        });

      scannerRef.current = html5QrCode;
    }
  }, [devices, departmentName, employeeDetails, refrshScan]);

  useEffect(() => {
    if (dep && path !== '/billing') {
      setProDepartment(dep);
    }
  }, [dep]);
  const getData = async () => {
    // dispatch(setSpinnerLoading('Loading'));
    dispatch(setSpinnerLoading('Loading employee'));
    const data = {
      table_prefix_master: masterPrefix,
      DEPARTMENT_NAME: departmentName,
    };
    await axios
      .post('/api/masters/listEmployeeInDepartment', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    console.log(path);
    if (path !== '/billing') {
      getData();
    }
  }, [departmentName]);
  const columns = [
    {
      field: 'EMPLOYEE_CODE',
      type: 'string',
      renderHeader: (params) => <div>Code</div>,
      width: 60,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },

    {
      field: 'EMPLOYEE_NAME',
      type: 'string',
      renderHeader: (params) => <div>Employee Name</div>,
      width: 160,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'MOBILE',
      type: 'string',
      renderHeader: (params) => <div>Mobile</div>,
      width: 80,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const selectedCameras = devices
    ? cameraList.filter((o) => o.id === devices)
    : [{ id: '' }];
  const selectedCamera = selectedCameras[0].id;

  useEffect(() => {
    if (bookingPref && mrc) {
      if (setEmployee) {
        setEmployee(employeeDetails);
      }
      checkPendingOrder(bookingPref, mrc)
        .then((remaining) => {
          getReadyOrders(mrc, bookingPref)
            .then((readyList) => {
              setBillSettlementDetails(
                readyList.map((o) => {
                  return {
                    ...o,
                    remainingOrders: remaining,
                  };
                })
              );
              setQrBillList(readyList.map((o) => o.QR_CODE));

              dispatch(setCustomerPrefix(bookingPref));
              setRefreshScan((o) => !o);
            })
            .finally(() => {
              dispatch(setSpinnerLoading(false));
            });
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'No pending order for deliver',
            })
          );
          navigate('/');
        });
    }
  }, [mrc, bookingPref]);
  return (
    <div className="p-4">
      {userType === 'SUPERVISOR' && (
        <div className="flex justify-center">
          {!employeeDetails && (
            <div className="">
              <div>
                <GeniustextInput
                  title={'Department Name'}
                  search={() => {
                    setDepartmentHelpStatus(true);
                  }}
                  width={'340px'}
                  type={'text'}
                  value={departmentName}
                  readOnly={true}
                />
              </div>

              <div className="h-[500px] w-[340px]">
                <GeniusDatagrid
                  rowID={(e) => e.CODE}
                  columns={columns}
                  list={rows}
                  onRowClick={(p) => {
                    setEmployeeDetails({
                      code: p.row.EMPLOYEE_CODE,
                      mobile: p.row.MOBILE,
                      employee_name: p.row.EMPLOYEE_NAME,
                    });
                    if (setEmployee) {
                      setEmployee({
                        code: p.row.EMPLOYEE_CODE,
                        mobile: p.row.MOBILE,
                        employee_name: p.row.EMPLOYEE_NAME,
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {departmentName && employeeDetails ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
          className="flex justify-start"
        >
          <div className="flex  w-[300px] justify-start ">
            <div className=" flex">
              <div className="font-semibold w-[100px]">Department </div>:
              <div className="ml-2 w-[200px]">{departmentName}</div>
            </div>
          </div>
          <div className="flex  w-[300px] justify-start">
            <div className="font-semibold w-[100px]">Employee </div>:
            <div className="ml-2  w-[200px] ">
              {employeeDetails.employee_name}
            </div>
          </div>
          <div className="flex  w-[300px] justify-start">
            <div className="font-semibold w-[100px]">Mobile </div>:
            <div className="ml-2 w-[200px]">{employeeDetails.mobile}</div>
          </div>
          <div className="flex  w-[300px] justify-start mb-3 mt-3">
            {userType === 'SUPERVISOR' && (
              <button
                onClick={() => {
                  setEmployeeDetails();
                  if (setEmployee) {
                    setEmployee();
                  }
                }}
                className="bg-orange px-4 w-[200px]  rounded-sm  py-1 "
              >
                Change Department
              </button>
            )}
          </div>
          {cameraList.length > 1 && (
            <div className="flex mb-2 ">
              <div className="w-[30px] ">
                <i className="bi bi-camera-fill"></i>
              </div>{' '}
              :
              <select
                onChange={(e) => {
                  if (e.target.value) {
                    localStorage.setItem('defaultCamera', e.target.value);
                    window.location.reload();
                  }
                }}
                className="w-[100px] outline-none overflow-hidden ml-2"
                value={selectedCamera}
              >
                <option></option>
                {cameraList.map((o) => {
                  return (
                    <option className="" value={o.id}>
                      {o.label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div>Scan Here</div>
          <div
            id="readers"
            style={{
              width: '200px',
              height: '200px',
              position: 'relative',
            }}
          >
            <div className="scanner-line" ref={lineRef}></div>

            <div id="reader"></div>
          </div>
          Qr Code : {QrId}
          <div className="mt-3 flex justify-center text-red">{errAlert}</div>
        </div>
      ) : (
        ''
      )}
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <Departmenthelp
        status={departmemnthelpStatus}
        handleClose={() => {
          setDepartmentHelpStatus(false);
        }}
        onRowClick={(p) => {
          setDepartmentHelpStatus(false);
          setProDepartment(p.row.DEPARTMENT_NAME);
        }}
      />

      {orderDetails[0] && (
        <FullWidthModal
          departmentName={departmentName}
          orderDetails={orderDetails}
          status={fullWidthModalStatus}
          onSave={saveStage}
          handleClose={() => {
            setFullWidthModalStatus(false);
            if (userType === 'SUPERVISOR') {
              setEmployeeDetails('');
              if (setEmployee) {
                setEmployee();
              }
            } else {
              navigate('/');
            }
          }}
          saveQnty={saveQnty}
          setSaveQnty={setSaveQnty}
        />
      )}
    </div>
  );
};

export default QRCodeScanner;
