import React from 'react';

import Modal from '@mui/material/Modal';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import MeasurmentSlip from '../../../masters/measurmentSlip';
import '../../styles/print.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: '85%',

  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '95%',
  boxShadow: 24,
};

export default function ModSlipMeasuremnt({ paymode }, { data }) {
  //   const loading = useSelector(selectStatus);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <= 1024 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col h-full ">
            <div
              style={{
                borderBottom: '1px solid #b5b3b3 ',
              }}
              className="flex w-full bg-white items-center"
            >
              <div className="flex grow text-center  justify-center">
                {' '}
                Measurement Slip
              </div>
              <div style={{ width: '1px', height: 50 }} />
              <div className="flex  justify-between items-center text-white  px-3 font-medium ">
                <CancelPresentationIcon
                  sx={{
                    color: 'orangered',
                  }}
                  fontSize="medium"
                />
              </div>
            </div>

            <div className="flex-grow h-full bg-white    border-brown">
              <MeasurmentSlip />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
