import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import { setSessionExpired } from '../utility/Redux/modal';
import {
  selectCompanyAddress,
  selectTableMasterPrefix,
  selectUserEmail,
  selectWidth,
} from '../utility/Redux/profile';
import axios from 'axios';
import MasterHelp from '../utility/component/modals/masterHelp';
import CryptoJS from 'crypto-js';

const UserHelp = ({ onRowClick, status, handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const [rows, setRows] = useState([]);
  const width = useSelector(selectWidth);

  const columns = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        type: 'string',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'User Name'}</div>,
      },
      {
        field: 'USER_EMAIL',
        type: 'number',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Email'}</div>,
      },
      {
        field: 'USER_TYPE',
        type: 'number',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Type'}</div>,
      },
    ],
    []
  );
  const companyDetail = useSelector(selectCompanyAddress);
  const userEmail = useSelector(selectUserEmail);
  const companyEmail = companyDetail.email;
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const getData = async () => {
    const email = userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    await axios
      .post(
        '/api/users/userHelp',
        {
          company_email: companyEmail ? companyEmail : companyDetail.email,
          user_email: userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        setRows(a);
        setFullList(a);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.USER_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            handleClose();
            setRows([]);
          }}
          title={'User | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.CODE}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default UserHelp;
