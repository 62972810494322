import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setHsnMasterStatus,
  setModalMeasurementMasterStatus,
  setModalStyleMasterStatus,
} from '../utility/Redux/modal';
import { selectIsProductionModuleAllwed } from '../utility/Redux/profile';

const Masters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isProductionAllowed = useSelector(selectIsProductionModuleAllwed);
  const [masters, setMasters] = useState([
    {
      name: 'Item Master',
      path: '',
      onclick: () => {
        navigate('/item_master');
      },
    },
    {
      name: 'Section Master',
      path: '',
      onclick: () => {
        navigate('/section_master');
      },
    },
    {
      name: 'HSN Master',
      path: '',
      onclick: () => {
        dispatch(setHsnMasterStatus(true));
      },
    },
    {
      name: 'Prefix Master',
      path: '',
      onclick: () => {
        navigate('/prefix_master');
      },
    },

    {
      name: 'Style Master',
      path: '',
      onclick: () => {
        dispatch(setModalStyleMasterStatus(true));
      },
    },

    {
      name: 'Measurement Master',
      path: '',
      onclick: () => {
        dispatch(setModalMeasurementMasterStatus(true));
      },
    },
    // {
    //   name: 'Message Master',
    //   path: '',
    //   onclick: () => {
    //     navigate('/message_setup_master');
    //   },
    // },
  ]);

  useEffect(() => {
    if (isProductionAllowed) {
      setMasters((e) => {
        return [
          ...e,
          {
            name: 'Department Master',
            path: '',
            onclick: () => {
              navigate('/department_master');
            },
          },

          {
            name: 'Employee Department Master',
            path: '',
            onclick: () => {
              navigate('/employee_department_master');
            },
          },
          {
            name: 'Employee Master',
            path: '',
            onclick: () => {
              navigate('/employee_master');
            },
          },
        ];
      });
    }
  }, [isProductionAllowed]);
  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-5 ll:grid-cols-6 gap-3 ">
        {masters.map((e, i) => {
          return (
            <Button
              key={i}
              onClick={e.onclick}
              variant={'contained'}
              style={{
                textTransform: 'none',
                backgroundColor: 'transparent',
                padding: '0px',
                borderRadius: '7px',
                overflow: 'hidden',
              }}
            >
              <div className="bg-[#E8E2DB] py-4 w-full text-brown">
                {e.name}
              </div>
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Masters;
