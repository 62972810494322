import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import { useState } from 'react';

import axios from 'axios';
import { useEffect } from 'react';
import { useFocus } from '../utility/hooks/hooks';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import GeniustextInput from '../utility/component/mastertext';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import { toProperCase } from '../utility/component/format';

const DepartmentMaster = () => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);
  const [id, setId] = useState();
  const [secName, setSecName] = useState();
  const [rows, setRows] = useState([]);
  const [secError, setSecError] = useFocus();

  const columns = [
    {
      field: 'DEPARTMENT_NAME',
      type: 'string',
      renderHeader: (params) => <div>Department Name</div>,
      width: windowWidth > 764 ? 400 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];
  const masterPrefix = useSelector(selectTableMasterPrefix);

  const getData = async () => {
    // dispatch(setSpinnerLoading('Loading'));
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      table_prefix_master: masterPrefix,
      DEPARTMENT_NAME: '',
    };
    await axios
      .post('/api/masters/listDepartmentMaster', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        setSecError();
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const saveSectionMasterApi = () => {
    if (secName) {
      const isDuplicateMobile = rows.some(
        (row) => row.DEPARTMENT_NAME === secName
      );

      if (isDuplicateMobile) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Department is already exists. Please choose a different Department.',
          })
        );
        return;
      }

      const data = {
        table_prefix_master: masterPrefix,
        CODE: id ? id : '',
        DEPARTMENT_NAME: secName,
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/masters/saveDepartmentMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setSecName('');
          setId('');
          getData();
        });
    } else {
      if (!secName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Department Name.',
            lottie: 'reject',
          })
        );
        setSecError();
      }
    }
  };
  const navigate = useNavigate();
  return (
    <div className="flex justify-center pt-4">
      <div className=" bg-white  rounded-sm shadow-sm  border-brown ">
        <ModalHeaderStyle
          header={`Department Master`}
          onClose={() => {
            navigate('/masters');
          }}
        />

        <div id="input" className="flex mt-4 px-5 ">
          <div>
            <GeniustextInput
              title={'Department Name'}
              width={windowWidth > 764 ? '400px' : '300px'}
              type={'text'}
              //   search={handleOpenDeptHelp}
              value={secName}
              onChange={(e) => {
                setSecName(toProperCase(e.target.value));
              }}
              refrance={secError}
            />
          </div>
        </div>
        <div className="px-5 ">
          <div
            style={{
              width: columns[0].width + 0,
              height: window.innerHeight - 420,
            }}
            className="  mt-1 flex "
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setSecName(p.row.DEPARTMENT_NAME);
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div
            style={{
              width: columns[0].width + 0,
            }}
            className="flex  justify-end items-center py-3"
          >
            {id ? (
              <ModifyButton onClick={saveSectionMasterApi} />
            ) : (
              <SaveButton onClick={saveSectionMasterApi} />
            )}

            <UndoButton
              onClick={() => {
                setSecName('');
                setId('');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentMaster;
