import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlertWithTitle } from '../../../utility/Redux/modal';
import { Button } from '@mui/material';
import {
  selectBillingCartList,
  selectModifyBillingCartlist,
  setBillingCartList,
  setModifyBillcartList,
} from '../../Redux/billing';
import { useFocus } from '../../hooks/hooks';
import CreateBillingCartWithTax from '../functions/createBillingCartWithTax';
import {
  selectCustomerPrefix,
  selectHistoryOrderDetails,
} from '../../Redux/booking';

const QntyEdit = ({ editID, setStatus, mrcNumber }) => {
  const modifyBillList = useSelector(selectModifyBillingCartlist);
  const billCart = useSelector(selectBillingCartList);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  // if modifylist is availval then use modifylist else bill cart list
  const billCartList = modifyBillList[0] ? modifyBillList : billCart;
  const customerPrefix = useSelector(selectCustomerPrefix);
  const editItemDetails = billCartList.filter((b) => b.ID === editID);
  const [qnty, setQnty] = useState(editItemDetails[0]?.QNTY);
  const dispatch = useDispatch();

  const [qntyInputRef, setQntyInputFocus] = useFocus();

  const defaultQnty = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'Clear'];

  const checkUnder3Decimal = /^\d{0,3}(\.\d{0,3})?$/;

  const onOkClick = async () => {
    if (qnty) {
      const customerDetails = {
        address: editItemDetails[0].ADDRESS,
        city: editItemDetails[0].CITY,
        code: editItemDetails[0].CODE,
        familyMemberName: editItemDetails[0].FAMILY_MEM_NAME,
        company_name: editItemDetails[0].COMPANY_NAME,
        customer_name: editItemDetails[0].CUSTOMER_NAME,
        country: editItemDetails[0].COUNTRY,
        mobile: editItemDetails[0].MOBILE,
        phone: editItemDetails[0].PHONE,
        state: editItemDetails[0].STATE,
      };

      const itemDetails = {
        cuting_tax1_rate: editItemDetails[0].CUTING_TAX1_RATE,
        cuting_tax2_rate: editItemDetails[0].CUTING_TAX2_RATE,
        cuting_tax3_rate: editItemDetails[0].CUTING_TAX3_RATE,
        cutting_hsn_code: editItemDetails[0].CUTTING_HSN_CODE,
        icode: editItemDetails[0].ICODE,
        // item_description: item.FAMILY_MEM_NAME,
        item_name: editItemDetails[0].ITEM_NAME,
        rate: editItemDetails[0].RATE,
        regional_iname: editItemDetails[0].REGIONAL_INAME,
        section_name: editItemDetails[0].SECTION_NAME,
        sewing_hsn_code: editItemDetails[0].SEWING_HSN_CODE,
        sewing_tax1_rate: editItemDetails[0].SEWING_TAX1_RATE,
        sewing_tax2_rate: editItemDetails[0].SEWING_TAX2_RATE,
        sewing_tax3_rate: editItemDetails[0].SEWING_TAX3_RATE,
        // stop_yn: editItemDetails[0].FAMILY_MEM_NAME,
        upper_lower: editItemDetails[0].UPPER_LOWER,
      };

      const data = CreateBillingCartWithTax(
        itemDetails,
        customerDetails,
        qnty,
        editItemDetails[0].BOOKING_ORDER_NO,
        editItemDetails[0].REMARKS,
        mrcNumber,
        customerPrefix
      );

      const updatedList = await billCartList.map((item) => {
        if (item.ID === editID) {
          return data;
        } else {
          return item;
        }
      });

      dispatch(setBillingCartList(updatedList));
      setStatus(false);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Opps!',
          msg: 'Can not save empty Quantity.',
        })
      );
    }
  };

  const onModifyOkClick = async () => {
    if (qnty) {
      const customerDetails = {
        address: editItemDetails[0].ADDRESS,
        city: editItemDetails[0].CITY,
        code: editItemDetails[0].CODE,
        familyMemberName: editItemDetails[0].FAMILY_MEM_NAME,
        company_name: editItemDetails[0].COMPANY_NAME,
        customer_name: editItemDetails[0].CUSTOMER_NAME,
        country: editItemDetails[0].COUNTRY,
        mobile: editItemDetails[0].MOBILE,
        phone: editItemDetails[0].PHONE,
        state: editItemDetails[0].STATE,
      };

      const itemDetails = {
        cuting_tax1_rate: editItemDetails[0].CUTING_TAX1_RATE,
        cuting_tax2_rate: editItemDetails[0].CUTING_TAX2_RATE,
        cuting_tax3_rate: editItemDetails[0].CUTING_TAX3_RATE,
        cutting_hsn_code: editItemDetails[0].CUTTING_HSN_CODE,
        icode: editItemDetails[0].ICODE,
        // item_description: item.FAMILY_MEM_NAME,
        item_name: editItemDetails[0].ITEM_NAME,
        rate: editItemDetails[0].RATE,
        regional_iname: editItemDetails[0].REGIONAL_INAME,
        section_name: editItemDetails[0].SECTION_NAME,
        sewing_hsn_code: editItemDetails[0].SEWING_HSN_CODE,
        sewing_tax1_rate: editItemDetails[0].SEWING_TAX1_RATE,
        sewing_tax2_rate: editItemDetails[0].SEWING_TAX2_RATE,
        sewing_tax3_rate: editItemDetails[0].SEWING_TAX3_RATE,
        // stop_yn: editItemDetails[0].FAMILY_MEM_NAME,
        upper_lower: editItemDetails[0].UPPER_LOWER,
      };

      const res = CreateBillingCartWithTax(
        itemDetails,
        customerDetails,
        qnty,
        editItemDetails[0].BOOKING_ORDER_NO,
        editItemDetails[0].REMARKS,
        mrcNumber,
        customerPrefix
      );
      let data = {
        ...res,
        INVNO: historyOrderDetails[0].INVNO,
      };

      const updatedList = await modifyBillList.map((item) => {
        if (item.ID === editID) {
          return data;
        } else {
          return item;
        }
      });

      dispatch(setModifyBillcartList(updatedList));
      setStatus(false);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Opps!',
          msg: 'Can not save empty Quantity.',
        })
      );
    }
  };

  const handleClose = () => {
    setStatus(false);
  };
  return (
    <div>
      <div className="w-full flex  bg-gray-100 py-3 ">
        <div className="flex-1 flex  justify-center text-center ">
          <div className="flex grow pl-4 font-[500] justify-start">
            Update Quantity
          </div>

          <div className="flex  pr-3  justify-end" onClick={handleClose}>
            <i className="bi bi-x-circle flex items-center text-[18px] "></i>
          </div>
        </div>
      </div>

      <div className="p-5 -mt-2 ">
        <div className="flex w-full justify-center pb-3">
          {editItemDetails[0]?.ITEM_NAME}
        </div>

        <div className=" flex w-full justify-center">
          <div className="flex items-center  ">
            <div
              onClick={() => {
                if (qnty > 1.9999) {
                  if (qnty.toString().includes('.')) {
                    const inputString = qnty;
                    const integerValue = parseInt(inputString, 10);
                    const q = Number(integerValue) - 1;
                    const parts = inputString.split('.');
                    const decimalValue = parts[1] || ''; // If there is no decimal part, it will be an empty string
                    const value = q.toString() + '.' + decimalValue;

                    setQnty(value);
                  } else {
                    setQnty((p) => Number(p) - 1);
                  }
                }
              }}
              style={{
                border: '1px solid #7e8794',
              }}
              className="  items-center px-2  h-full  flex  p-1 rounded-sm   hover:cursor-pointer font-semibold"
            >
              <i className="bi bi-dash text-[20px] flex items-center"></i>
            </div>

            <div
              style={{
                border: '1px solid #7e8794',
              }}
              className="w-20 rounded-sm overflow-hidden text-center mx-2 bottom-2 border-slate-400 bg-slate-200 outline-1 outline-slate-500"
            >
              <input
                type="text"
                autoFocus={window.innerWidth <= 1280 ? false : true}
                ref={qntyInputRef}
                value={qnty}
                onChange={(t) => {
                  setQnty(t.target.value);
                }}
                className="w-full px-1 py-1 rounded-sm text-[16px] text-center   bottom-2 border-slate-300"

                //   onChange={(t) => {
                //     setIqnty(t.target.value);
                //   }}
              />
            </div>

            <div
              onClick={() => {
                if (qnty < 1 && qnty.toString() !== '.') {
                  setQnty(Number(qnty) + 1);
                } else {
                  if (
                    qnty.toString().includes('.') &&
                    qnty.toString() !== '.'
                  ) {
                    const inputString = qnty;
                    const integerValue = parseInt(inputString, 10);
                    const q = Number(integerValue) + 1;

                    const parts = inputString.toString().split('.');
                    const decimalValue = parts[1] || ''; // If there is no decimal part, it will be an empty string
                    const value = q.toString() + '.' + decimalValue;

                    setQnty(value);
                  } else {
                    if (qnty.toString() !== '.') {
                      setQnty((p) => Number(p) + 1);
                    }
                  }
                }
              }}
              style={{
                border: '1px solid #7e8794',
              }}
              className=" border-slate-200 flex items-center px-2 h-full rounded-sm p-1 hover:cursor-pointer"
            >
              <i className="bi bi-plus text-[20px] flex items-center "></i>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 pt-5   ">
          {defaultQnty.map((item, index) => {
            return (
              <Button
                onClick={() => {
                  if (item === 'Clear') {
                    setQnty('');
                  } else {
                    if (item === '.') {
                      if (!qnty.toString().includes('.')) {
                        if (checkUnder3Decimal.test(qnty.toString() + item)) {
                          setQnty((p) => {
                            const q = p.toString() + item;
                            return q;
                          });
                        }
                      }
                    } else {
                      if (checkUnder3Decimal.test(qnty.toString() + item)) {
                        setQnty((p) => p.toString() + item);
                      }
                    }
                  }
                }}
                variant={'contained'}
                style={{
                  backgroundColor: 'white',
                  color: '#67666e',
                  fontSize: '16px',
                  height:
                    window.innerWidth <= 1024
                      ? (window.innerWidth / 100) * 6
                      : (window.innerWidth / 100) * 3,
                  width: (window.innerWidth / 100) * 3,
                  boxShadow: '1px 1px 1px  rgba(0, 0, 0, 0.1)',
                  fontWeight: 500,
                  border: '0.5px solid #c1c1c7',
                  textTransform: 'none',
                }}
                key={index}
              >
                <div className="   text-[16px]  items-center justify-center flex w-full text-center">
                  <div className="text-center  "> {item} </div>
                </div>
              </Button>
            );
          })}
        </div>

        <Button
          // ref={updateRef}
          onClick={modifyBillList[0] ? onModifyOkClick : onOkClick}
          variant="contained"
          style={{
            textTransform: 'none',
            letterSpacing: '1px',
            backgroundColor: '#383535',
            boxShadow: '1px 1px 1px  rgba(0, 0, 0, 0.1)',
            width: '100%',
            marginTop: '12px',
          }}
        >
          <div className="text-white text-[16px] font-sans  px-3 ">Ok </div>
        </Button>
      </div>
    </div>
  );
};

export default QntyEdit;
