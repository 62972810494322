import React from 'react';
import '../component/styles/IntersectingDiagonals.css';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import Webcam from 'react-webcam';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { BookingCancelButton, BookingSaveImage } from './buttons';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingCustomerDetails } from '../Redux/booking';
import { deleteFile } from './functions/firebase';

const width = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;

const ProfilePicFrame = ({
  selectedFile,
  profilePicWidth,
  firabseUrl,
  setSelectedFile,
  webcamStatus,
  setWebcamStatus,
  capturedImage,
  setCapturedImage,
  webcamRef,
  path,
  setFirebaseUrl,
}) => {
  const customerDetails = useSelector(selectBookingCustomerDetails);
  const dispatch = useDispatch();

  const handleDrop = (acceptedFiles) => {
    const editedFiles = acceptedFiles.map((file) => {
      const editedName = ` ${uniqid()}-${file.name}`;
      return new File([file], editedName, { type: file.type });
    });
    setCapturedImage('');

    setSelectedFile(editedFiles[0]);

    // dispatch(setSelectedPhoto(acceptedFiles[0]));
  };
  const shootImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setSelectedFile('');
    setCapturedImage(imageSrc);
    setWebcamStatus(false);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className="diagonalp-container   rounded-sm"
    >
      {webcamStatus ? (
        <div className="h-full w-full flex flex-col justify-between">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#fa8572',
              zIndex: '50',
              fontSize: '19px',

              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />

          <Webcam
            audio={false}
            ref={webcamRef}
            style={{
              width: '100%', // Set your desired width
              height: 'auto',
            }}
            // height={profilePicWidth - 50}
            mirrored={true} // Depending on your preference
          />
          <BookingSaveImage
            onClick={() => {
              shootImage();
            }}
          />
        </div>
      ) : capturedImage ? (
        <div className="h-full w-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          >
            <Close
              onClick={() => {
                setCapturedImage(null);
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#fa8572',
                zIndex: '50',
                fontSize: '19px',

                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
              }}
            />
          </Button>
          <img
            src={capturedImage}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : selectedFile ? (
        <div className="h-full w-full ">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              fontSize: '19px',

              color: '#fa8572',
              zIndex: '50',
              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <img
                {...getRootProps()}
                src={URL.createObjectURL(selectedFile)}
                alt={`Selected`}
                style={{
                  width: 'auto',
                  height: '100%',
                }}
              />
            )}
          </Dropzone>
        </div>
      ) : firabseUrl ? (
        <div className="h-full w-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          >
            <Close
              onClick={() => {
                if (path) {
                  deleteFile(path);
                  setFirebaseUrl('');
                }
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#fa8572',
                zIndex: '50',
                fontSize: '19px',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
              }}
            />
          </Button>
          <img
            src={firabseUrl}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : (
        <>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  style={{
                    right: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                  className={
                    'flex justify-center items-center absolute h-full  '
                  }
                >
                  <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
                </div>
                <div className="diagonalp-1 w-full h-full "></div>

                <div className="diagonalp-2 "></div>
              </>
            )}
          </Dropzone>
        </>
      )}
    </div>
  );
};

export default ProfilePicFrame;
