import { Button, Grow } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserType, selectWidth } from '../utility/Redux/profile';

const Reports = () => {
  const navigation = useNavigate();
  const list = [
    {
      name: 'Bill Summary',
      path: '',
      minWidth: 1024,
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/bill_summary');
      },
    },
    {
      name: 'Voucher Summary',
      path: '',
      minWidth: 1024,

      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/voucher_summary');
      },
    },
    {
      name: 'Order Summary',
      path: '',
      minWidth: 1024,

      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/order_summary');
      },
    },
    {
      name: 'Booking History',
      path: '',
      minWidth: 1024,

      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/booking_history');
      },
    },
    {
      name: 'Item Wise Booking',
      path: '',
      minWidth: 300,

      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/item_wise_booking');
      },
    },
    {
      name: 'Running orders',
      path: '/running_orders',
      minWidth: 1024,

      userType: 'SUPERVISOR',

      onclick: () => {
        navigation('/running_orders');
      },
    },
  ];
  const width = useSelector(selectWidth);

  const userType = useSelector(selectUserType);
  const filterList = list.filter((o) => {
    console.log(o.minWidth <= width);
    console.log(o.minWidth);
    console.log(width);
    return o.userType.includes(userType) && o.minWidth <= width;
  });

  return (
    <div className="h-full p-4">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {filterList[0] &&
          filterList.map((e, i) => {
            return (
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
              >
                <Button
                  key={i}
                  onClick={() => {
                    e.onclick();
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e8e2db] py-4 w-full text-brown">
                    {e.name}
                  </div>
                </Button>
              </Grow>
            );
          })}
      </div>
    </div>
  );
};

export default Reports;
