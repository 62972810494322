import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import QntyEdit from './qntyEdit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',

  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',

  boxShadow: 24,
};

export default function ModalQntyEdit({
  status,
  setStatus,
  editID,
  mrcNumber,
}) {
  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <= 760 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex-grow h-full bg-white    border-brown">
              <QntyEdit
                editID={editID}
                setStatus={setStatus}
                mrcNumber={mrcNumber}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
