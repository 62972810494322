import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { setCustomerMasterHelpModStatus } from '../Redux/modal';
import { OkButton } from './buttons';

const width = window.innerWidth / 100;

export const CollectionFromToDate = ({
  firstDate,
  setFirstDate,
  SecondDate,
  printButton,
  setSecondDate,
  reset,
  bookingCustomerDetails,
  onOkClick,
  hideCustomer,
}) => {
  const dispatch = useDispatch();

  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState('');
  const userType = 'USER';
  return (
    <div style={{ borderRadius: '7px' }} className=" flex h-full w-full  ">
      <div className="flex rounded-l-sm  border border-r-0 border-[#b5b3b3] ">
        <div className=" flex items-center   border-[#b5b3b3] border-r p-1  px-4 ">
          <div
            style={{ fontSize: 13 }}
            className=" whitespace-nowrap text-[#4B4545]"
          >
            From
          </div>
          <div
            style={{ borderRadius: '7px' }}
            className="flex border border-[#b5b3b3] ml-2 text-[#4B4545]  "
          >
            <div
              style={{
                padding: '2px',
              }}
            >
              <DatePicker
                onFocus={() => setfirstDateFocus(true)}
                onClickOutside={() => setfirstDateFocus(false)}
                onSelect={() => setfirstDateFocus(false)}
                open={firstdateFocus}
                className=" bg-transparent text-sm w-32 outline-0  "
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(firstDate)}
                onChange={(date) =>
                  setFirstDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className=" items-center flex justify-start px-2  ">
              <i
                onClick={() => {
                  setfirstDateFocus(true);
                }}
                className="bi bi-calendar2-minus"
              ></i>
            </div>
          </div>

          <div
            style={{ fontSize: 13, borderRadius: '7px' }}
            className="  text-[#4B4545] px-4 p-3"
          >
            To
          </div>
          <div
            style={{ borderRadius: '7px' }}
            className="flex border border-[#b5b3b3]  "
          >
            <div
              style={{
                padding: '2px',
              }}
              className=" text-[#4B4545]  "
            >
              <DatePicker
                onFocus={() => SetSecondDateFocus(true)}
                onClickOutside={() => SetSecondDateFocus(false)}
                onSelect={() => SetSecondDateFocus(false)}
                open={secondDateFocus}
                style={{ fontSize: 13 }}
                className=" bg-transparent w-32 text-sm outline-0   "
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(SecondDate)}
                onChange={(date) =>
                  setSecondDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className=" items-center text-[#4B4545]  flex justify-start px-2 ">
              <i
                onClick={() => {
                  SetSecondDateFocus(true);
                }}
                className="bi bi-calendar2-minus"
              ></i>
            </div>
          </div>
        </div>

        {/* okButton */}

        <div
          style={
            userType === 'MANAGER'
              ? {
                  fontSize: 12,
                  width: '45%',
                  color: '#a6a2a2',
                  borderLeft: '1px solid #4B4545 ',
                }
              : {
                  fontSize: 12,
                  width: '45%',
                  color: '#a6a2a2',
                  borderRight: !hideCustomer
                    ? '0.5px solid #b5b3b3'
                    : '0px solid #b5b3b3',
                }
          }
          className=" flex whitespace-normal     justify-start items-center  "
        >
          {/* party name 1st part */}
          <div className="flex  h-full items-center px-4 ">
            <OkButton onClick={onOkClick} />
            {printButton && printButton}
          </div>
        </div>

        {/* party name full */}
        {!hideCustomer && (
          <div
            style={
              userType === 'MANAGER'
                ? {
                    fontSize: 12,
                    width: '45%',
                    color: '#a6a2a2',
                    borderLeft: '1px solid #4B4545 ',
                    // borderRight: '0.5px solid #4B4545',
                  }
                : {
                    fontSize: 12,
                    width: '45%',
                    color: '#a6a2a2',
                  }
            }
            className=" flex whitespace-normal     justify-start items-center  "
          >
            {/* party name 1st part */}
            <div className="flex  h-full items-center px-4 ">
              <div
                onClick={() => dispatch(setCustomerMasterHelpModStatus(true))}
                className="flex hover:cursor-pointer h-full items-center"
              >
                <div>
                  <i
                    className="bi bi-search"
                    style={{ color: '#f44336', fontSize: 15 }}
                  ></i>
                </div>

                <span
                  style={{ fontSize: 12 }}
                  className=" text-[#5c5b5b]  pl-2 flex font-semibold items-center"
                >
                  {' '}
                  Customer
                  <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                    {' '}
                    Mobile
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* other */}
      <div
        style={{
          border: '1px dotted #a6a2a2 ',
        }}
        className="flex grow rounded-r-sm "
      >
        {/* Party details 2nd part*/}

        <div
          style={{
            borderRight: '1px dotted #a6a2a2',
          }}
          className="flex w-full h-full "
        >
          <div className="flex grow h-full ">
            <div className="w-full h-full">
              <div
                className="w-full h-1/2 print:h-full flex items-center"
                style={{
                  fontSize: 12,
                  color: 'red',
                }}
              >
                <span className="pl-4 font-semibold ">
                  {!hideCustomer && bookingCustomerDetails.customer_name}
                </span>
              </div>
              <div
                style={{
                  borderTop: '1px dotted #a6a2a2',
                  color: '#f2666f',
                  fontSize: 12,
                }}
                className="h-1/2 flex items-center print:hidden"
              >
                <span className="pl-4 font-semibold  ">
                  {!hideCustomer && bookingCustomerDetails.mobile}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Party details 3rd part*/}
        <div className="px-2 flex justify-center items-center print:hidden">
          <i
            className="bi bi-backspace-fill"
            style={{ color: '#f2666f', fontSize: 18 }}
            onClick={reset}
          ></i>
        </div>
      </div>
    </div>
  );
};
