import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  selectSpinnerLoading,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import {
  Chip,
  Collapse,
  Divider,
  Skeleton,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import { toProperCase } from '../utility/component/format';
import { selectToken } from '../utility/Redux/security';
import formatMobileNumber from '../utility/func/formatNumber';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
import database from '../utility/component/functions/firebase';
import { child, get, onValue, ref } from 'firebase/database';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
const SupervisorDashboard2 = () => {
  const [list, setList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [openList, setOpenList] = useState([]);
  const [orderNumbewr, setorderNumber] = useState('');
  const [searchPlaceHolders, setSearchPlaceHolders] = useState('Mobile');
  const loadData = () => {
    axios
      .post(
        '/api/production/productionStatus',
        {
          table_prefix: prefix,
          STAGE_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (p.data.response[0]) {
          const data = p.data.response;
          setList(data);
          setOpenList(data.map((o) => o.STAGE_NAME));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    loadData();
  }, [prefix, token, dispatch]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      dispatch(setSpinnerLoading('Loading'));
      const details = {};
      for (const stage of list) {
        const response = await axios
          .post(
            '/api/production/OrdersInProduction',
            {
              table_prefix: prefix,
              STAGE_NAME: stage.STAGE_NAME,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((p) => p.data.response)
          .catch((error) => {
            console.log(error);
            return [];
          });
        details[stage.STAGE_NAME] = response;
      }
      dispatch(setSpinnerLoading(false));
      setOrderDetails(details);
    };
    if (list.length > 0) {
      fetchOrderDetails();
    }
  }, [list, prefix, token, dispatch]);
  const reduxLoading = useSelector(selectSpinnerLoading);

  const chartData = {
    labels: list.map((stage) => stage.STAGE_NAME),
    datasets: [
      {
        label: '# of Orders',
        data: list.map((stage) => stage.NO_OF_ORDERS),
        backgroundColor: [
          '#FFC1CC', // Powder Pink
          '#C1E1C1', // Powder Green
          '#C1D4E1', // Powder Blue
          '#E1C1B3', // Powder Beige
          '#E1E1C1', // Powder Yellow
        ],
        hoverBackgroundColor: [
          '#FFB3C1', // Bold Powder Pink
          '#A3D1A3', // Bold Powder Green
          '#A3C4D1', // Bold Powder Blue
          '#D4A3A3', // Bold Powder Beige
          '#D4D4A3', // Bold Powder Yellow
        ],
      },
    ],
  };
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const chartOptions = {
    plugins: {
      datalabels: {
        color: 'black',
        formatter: (value, context) => {
          return `${list[context.dataIndex].STAGE_NAME} : ${value}`;
        },
      },
    },
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setSearchPlaceHolders((prevPlaceholder) => {
        return prevPlaceholder === 'Mobile' ? '#Order_ID' : 'Mobile';
      });
    }, 5000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    const connectedRef = ref(database, `${masterPrefix}`);
    const connectedRef2 = ref(database, `${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          loadData();
        }
      });
    });
  }, []);
  return (
    <div
      style={{
        height: window.innerHeight - 180,
      }}
      className="w-full  p-6 text-sm  "
    >
      <div className="md:flex md:justify-between h-full">
        {loading || reduxLoading ? (
          <SkeletonLoader />
        ) : (
          <div
            style={{
              height: window.innerWidth < 768 ? `calc(100% - 100px)` : '100%',
            }}
            className="overflow-y-auto  w-fit t:w-[600px]"
          >
            {list.map((i, n) => {
              const isOpen = openList.includes(i.STAGE_NAME);
              const dep = i.STAGE_NAME.toLowerCase();
              const typeOfStage = dep.includes('wa')
                ? washing
                : dep.includes('cu')
                ? cutting
                : dep.includes('emb')
                ? embroidery
                : dep.includes('st')
                ? sttiching
                : dep.includes('bu')
                ? buttonstype
                : dep.includes('pr')
                ? press
                : dep.includes('re')
                ? ready
                : dep.includes('del')
                ? delivery
                : sttiching;
              return (
                <div
                  key={i.STAGE_NAME}

                  // className={`${!isOpen ? 'mb-2' : ''} `}
                >
                  <div
                    onClick={() => {
                      if (!isOpen) {
                        setOpenList((prevOpenList) => [
                          ...prevOpenList,
                          i.STAGE_NAME,
                        ]);
                      } else {
                        setOpenList((prevOpenList) =>
                          prevOpenList.filter(
                            (stageName) => stageName !== i.STAGE_NAME
                          )
                        );
                      }
                    }}
                    className="flex items-center hover:cursor-pointer"
                  >
                    <div className="bg-[#e5f2fd] justify-center flex items-center w-[200px] h-fit pt-1 pr-1 pb-1 pl-3  text-center rounded-[11px]">
                      <div className="flex-1 flex items-center text-start font-semibold text-[15px]">
                        <img src={typeOfStage} className="w-[20px] mt-1" />{' '}
                        <div className="ml-2">{`${i.STAGE_NAME} - ${i.NO_OF_ORDERS}`}</div>
                      </div>

                      <button className="flex text-center">
                        <i
                          className={`bi px-2 text-[13px] text-black flex items-center ${
                            isOpen ? 'bi-chevron-up' : 'bi-chevron-down'
                          }`}
                        ></i>
                      </button>
                    </div>
                    {n === 0 && (
                      <div className="ml-3">
                        <button
                          onClick={() => {
                            loadData();
                          }}
                        >
                          <i className="bi text-[19px] text-red bi-arrow-clockwise"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  {!isOpen && (
                    <div className="h-full w-[100px] flex justify-center">
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          borderRightWidth: 1, // Change width
                          borderColor: '#0d0d0d', // Change color,
                          height: '10px',
                        }}
                      />
                    </div>
                  )}
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <div className="flex flex-col">
                      {orderDetails[i.STAGE_NAME]?.map((e, index) => {
                        if (orderNumbewr) {
                          const isOrder = orderNumbewr.includes('#');
                          const compareto = isOrder
                            ? e.BOOKING_ORDER_NO
                            : e.MOBILE;
                          if (
                            compareto
                              .toString()
                              .includes(
                                orderNumbewr.replace('#', '').toString()
                              )
                          ) {
                            return (
                              <div key={index} className="flex h-[100px]">
                                <div className="h-full w-[100px] flex justify-center">
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                      borderRightWidth: 3, // Change width
                                      borderColor: '#e5f2fd', // Change color,
                                      height: '100%',
                                    }}
                                  >
                                    {' '}
                                    <Chip
                                      label={`#${e.BOOKING_ORDER_NO}`}
                                      size="small"
                                      sx={{
                                        width: '60px',
                                        fontSize: '14px',
                                        borderRadius: '11px',
                                      }}
                                    />
                                  </Divider>
                                </div>
                                <div className="p-2 text-[12px] flex items-center">
                                  <div>
                                    <div className="font-semibold text-[15px]">
                                      {toProperCase(e.ITEM_NAME.toLowerCase())}
                                      {' - '} {e.QNTY}
                                    </div>
                                    <div className=" text-[14px] flex">
                                      <div>{e.CUSTOMER_NAME}</div>
                                      <div className="ml-1">
                                        {' - '} {formatMobileNumber(e.MOBILE)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div
                              key={index}
                              className="flex h-[150px] t:h-[100px]"
                            >
                              <div className="h-full w-[100px] flex justify-center">
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{
                                    borderRightWidth: 3, // Change width
                                    borderColor: '#e5f2fd', // Change color,
                                    height: '100%',
                                  }}
                                >
                                  {' '}
                                  <Chip
                                    label={`#${e.BOOKING_ORDER_NO}`}
                                    size="small"
                                    sx={{
                                      width: '60px',
                                      fontSize: '14px',
                                      borderRadius: '11px',
                                    }}
                                  />
                                </Divider>
                              </div>
                              <div className="p-2 text-[12px]   flex-1  flex items-center">
                                <div className="w-full">
                                  <div className="font-semibold text-[15px] w-full flex items-center">
                                    {toProperCase(e.ITEM_NAME.toLowerCase())}
                                    {' - '} {e.QNTY}{' '}
                                    <div className=" ml-1 -mt-1">
                                      {e.UPPER_LOWER === 'UPPER' ? '👕' : '👖'}
                                    </div>
                                  </div>
                                  <div className=" text-[14px] flex flex-1 flex-col t:flex-row">
                                    <div>{e.CUSTOMER_NAME}</div>
                                    <div className="ml-1">
                                      {' - '} {formatMobileNumber(e.MOBILE)}
                                    </div>
                                  </div>
                                  <div className=" text-[14px] flex flex-1">
                                    {`( Emp. Name : ${
                                      e.CURRENT_EMPLOYEE
                                    } - ${formatMobileNumber(
                                      e.CURRENT_EMP_MOBILE
                                    )} )`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        )}

        <div className="w-[500px] hidden t:flex">
          <div className="w-full  ">
            <div className="w-full flex justify-center mb-2 ">
              <TextField
                size="small"
                value={orderNumbewr}
                InputProps={{
                  sx: {
                    fontSize: '14px',
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '0.5px solid black',
                    },
                    padding: '0px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#03a9f4',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '0.1px solid #03a9f4',
                    },
                  },
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        padding: '0px',
                      }}
                      position="end"
                    >
                      <button className="h-[37px] rounded-r-sm px-[12px] bg-red  ">
                        <i className="bi text-white bi-search flex items-center"></i>
                      </button>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '14px',
                  },
                }}
                type="text"
                name="text"
                id="email"
                label={`Search by ${searchPlaceHolders}`}
                onChange={(e) => {
                  setorderNumber(e.target.value);
                }}
              />
            </div>

            <div className="flex w-full flex-col items-center">
              <div className=" mb-5">
                <Pie
                  style={{
                    width: window.innerWidth > 768 ? '500px' : '150px',
                    height: window.innerWidth > 768 ? '500px' : '150px',
                  }}
                  data={chartData}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="h-full overflow-y-auto">
      {[...Array(7)].map((_, index) => (
        <div key={index} mb={2}>
          <Skeleton variant="rectangular" height={30} width="140px" />
          {[...Array(Math.floor(Math.random() * 3) + 1)].map(() => {
            return (
              <div className="flex">
                <div className="w-[100px] flex justify-center text-center ">
                  <Skeleton variant="rectangular" height={80} width="3px" />
                </div>
                <div>
                  <div className="mt-2">
                    <Skeleton variant="text" height={20} width="200px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="150px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="100px" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default SupervisorDashboard2;
