import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { AddTocartHeader } from '../utility/component/datagrid.js/header';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useEffect } from 'react';
import { selectToken } from '../utility/Redux/security';
import { useState } from 'react';
import moment from 'moment';
import { AddToCartButton } from '../utility/component/buttons';
import uniqueId from 'uniqid';

import CreateBillingCartWithTax from '../utility/component/functions/createBillingCartWithTax';
import {
  setAlertWithTitle,
  setloading,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectModifyBillingCartlist,
  setAdvanceVoucherDetails,
  setBillingAdvanceAmount,
  setBillSettlement,
} from '../utility/Redux/billing';
import {
  selectCustomerPrefix,
  setCustomerPrefix,
} from '../utility/Redux/booking';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { useNavigate } from 'react-router-dom';

const AddToCart = ({
  bookingCustomerDetails,
  mrcNumber,
  orderID,
  isDelivery,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  qrBillList,
  setBookingCustomerDetails,
  setBill_delevery_cart,
  bill_delevery_cart,
  customerPrefixState,
  billSettelmentDetails,
}) => {
  const prifix = useSelector(selectPrefix);
  const masterprefix = useSelector(selectTableMasterPrefix);
  const [list, setlist] = useState([]);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const customerPrefix = useSelector(selectCustomerPrefix);
  const StyleColums = [
    {
      field: 'BOOKING_ORDER_NO',
      type: 'string',
      align: 'center',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 5,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Order'} </div>,
    },

    {
      field: 'DATE',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div>{moment(params.value).format('DD-MM-YYYY')}</div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Date'} </div>,
    },

    {
      field: 'select',
      type: 'string',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 8,
      align: 'right',
      editable: false,
      renderCell: (param) => {
        return (
          <div
            className="flex justify-center w-full"
            onClick={() => {
              if (param.value === 'NO') {
                let index = list.findIndex((item) => item.id === param.row.id);
                const updatedList = list.map((item) => {
                  const data = item;
                  if (item.id === param.row.id) {
                    return { ...item, select: 'YES' };
                  } else {
                    return data;
                  }
                });
                setlist(updatedList);
              } else {
                let index = list.findIndex((item) => item.id === param.row.id);
                const updatedList = list.map((item) => {
                  const data = item;
                  if (item.id === param.row.id) {
                    return { ...item, select: 'NO' };
                  } else {
                    return data;
                  }
                });
                setlist(updatedList);
              }
            }}
          >
            {param.value === 'NO' ? (
              <i className="bi bi-square"></i>
            ) : (
              <i className="bi bi-check-square"></i>
            )}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Select'} </div>,
    },
  ];

  const getCustomermasterListPromise = (mobile, name) => {
    dispatch(setSpinnerLoading('Customers Loading'));
    const data = {
      table_prefix: prifix,
      MOBILE: mobile ? mobile : '',
      CODE: '',
      CUSTOMER_NAME: name ? name : '',
      ADDRESS: '',
      CITY: '',
      STATE: '',
      COUNTRY: '',
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/masters/listCustomerMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (!res.data.error) {
            const data = res.data.response;
            resolve(data);
          } else {
            reject(new Error('Error in response data'));
          }
        })
        .catch((e) => {
          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(e);
          reject(e);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    });
  };
  const loadOrdersDetails = (t, order) => {
    const filterdList = list.filter((item) => item.select === 'YES');
    if (filterdList[0] || order) {
      const bookinList = filterdList.map((item) => item.BOOKING_ORDER_NO);
      dispatch(setSpinnerLoading('loading order details'));
      const data = {
        table_prefix: prifix,
        table_prefix_master: masterprefix,
        BOOKING_ORDER_NO: `(${order ? order : bookinList})`,
        MRC_NO: mrcNumber,
        PREFIX: customerPrefixState ? customerPrefixState : customerPrefix,
      };
      console.warn(bookinList);
      console.warn(order);
      if (bookinList[0] || order) {
        axios
          .post(`/api/booking/loadOrdersDetails`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            if (!response?.data?.error) {
              const data = response.data.response;

              let billingList = [];
              if (!bookingCustomerDetails.mobile) {
                getCustomermasterListPromise(data[0].MOBILE).then((d) => {
                  const customerDetails = d.filter(
                    (o) =>
                      o.mrc_no === mrcNumber &&
                      o.mobile === data[0].MOBILE &&
                      o.customer_name === data[0].CUSTOMER_NAME
                  );
                  if (customerDetails[0]) {
                    setBookingCustomerDetails(customerDetails[0]);
                    dispatch(setCustomerPrefix(customerDetails[0].PREFIX));
                  }
                });
              }
              let QrBasedBillList = [];
              const upperQrList = qrBillList.filter((o) => o.includes('UPPER'));
              const lowerQrList = qrBillList.filter((o) => o.includes('LOWER'));
              const upperBillList = data.filter(
                (o) => o.UPPER_LOWER === 'UPPER'
              );
              const lowerBillList = data.filter(
                (o) => o.UPPER_LOWER === 'LOWER'
              );

              if (upperQrList[0] && lowerQrList[0]) {
                QrBasedBillList = data;
              } else {
                if (upperQrList[0]) {
                  QrBasedBillList = upperBillList;
                } else {
                  if (lowerQrList[0]) {
                    QrBasedBillList = lowerBillList;
                  }
                }
              }
              for (let i = 0; i < QrBasedBillList.length; i++) {
                const itemDetails = {
                  icode: QrBasedBillList[i].ICODE,
                  item_name: QrBasedBillList[i].ITEM_NAME,
                  regional_iname: '',
                  section_name: QrBasedBillList[i].SECTION_NAME,
                  upper_lower: QrBasedBillList[i].UPPER_LOWER,
                  rate: QrBasedBillList[i].RATE,
                  sewing_hsn_code: QrBasedBillList[i].SEWING_HSN_CODE,
                  sewing_tax1_rate: QrBasedBillList[i].SEWING_TAX1_RATE,
                  sewing_tax2_rate: QrBasedBillList[i].SEWING_TAX2_RATE,
                  sewing_tax3_rate: QrBasedBillList[i].SEWING_TAX3_RATE,
                  cutting_hsn_code: QrBasedBillList[i].CUTTING_HSN_CODE,
                  cuting_tax1_rate: QrBasedBillList[i].CUTING_TAX1_RATE,
                  cuting_tax2_rate: QrBasedBillList[i].CUTING_TAX2_RATE,
                  cuting_tax3_rate: QrBasedBillList[i].CUTING_TAX3_RATE,
                };
                const customerDetails = {
                  code: QrBasedBillList[i].CODE,
                  customer_name: QrBasedBillList[i].CUSTOMER_NAME,
                  familyMemberName: QrBasedBillList[i].FAMILY_MEM_NAME,
                  mobile: QrBasedBillList[i].MOBILE,
                  phone: QrBasedBillList[i].PHONE,
                  address: QrBasedBillList[i].ADDRESS,
                  city: QrBasedBillList[i].CITY,
                  state: QrBasedBillList[i].STATE,
                  country: QrBasedBillList[i].COUNTRY,
                  company_name: bookingCustomerDetails.company_name,
                };
                const pref = customerPrefixState
                  ? customerPrefixState
                  : customerPrefix;
                const l = CreateBillingCartWithTax(
                  itemDetails,
                  customerDetails,
                  1,
                  QrBasedBillList[i].BOOKING_ORDER_NO,
                  QrBasedBillList[i].REMARKS,
                  mrcNumber,
                  pref
                );
                if (QrBasedBillList[i].UPPER_LOWER === 'UPPER') {
                  for (let ind = 0; ind < upperQrList.length; ind++) {
                    const qrBookingSplit = upperQrList[ind].split('_');
                    const isAllreadyAdded = bill_delevery_cart.filter(
                      (o) => o.ID === upperQrList[ind]
                    );
                    if (
                      !isAllreadyAdded[0] &&
                      Number(qrBookingSplit[0]) ===
                        Number(QrBasedBillList[i].BOOKING_ORDER_NO)
                    ) {
                      billingList.push({
                        ...l,
                        ID: upperQrList[ind],
                        delivery: false,
                        bill: false,
                      });
                    }
                  }
                } else {
                  for (let ind = 0; ind < lowerQrList.length; ind++) {
                    const qrBookingSplit = lowerQrList[ind].split('_');
                    const isAllreadyAdded = bill_delevery_cart.filter(
                      (o) => o.ID === lowerQrList[ind]
                    );
                    if (
                      !isAllreadyAdded[0] &&
                      Number(qrBookingSplit[0]) ===
                        Number(QrBasedBillList[i].BOOKING_ORDER_NO)
                    ) {
                      billingList.push({
                        ...l,
                        ID: lowerQrList[ind],
                        delivery: false,
                        bill: false,
                      });
                    }
                  }
                }
              }

              setBill_delevery_cart([...bill_delevery_cart, ...billingList]);
            }
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      } else {
        setBill_delevery_cart([]);
        dispatch(setSpinnerLoading(false));
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Please select any order to add bill.',
        })
      );
    }
  };
  useEffect(() => {
    if (qrBillList[0]) {
      const qrList = [
        ...new Set(
          qrBillList.map((o) => {
            const d = o.split('_');
            return d[0];
          })
        ),
      ];
      const orderValue = `${qrList.map((value) => `'${value}'`).join(', ')}`;
      const value = qrList[1] ? orderValue : qrList[0];
      loadOrdersDetails('', value);
    }
  }, [bookingCustomerDetails, qrBillList, orderID]);
  if (isDelivery) {
    return;
  }
  return (
    <div className="h-full w-full   flex flex-col justify-between">
      {' '}
      <div
        style={{
          height: window.innerWidth <= 1424 ? '100%' : '100%',
          border: '1px solid #e8e2db',
        }}
        className="rounded-sm overflow-hidden"
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            height: window.innerWidth <= 1424 ? '100%' : '100%',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
          }}
          rowHeight={33}
          headerHeight={0}
          columns={StyleColums}
          rows={list}
          getRowId={(item) => item.id}
          hideFooter
          scrollbarSize={0}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          // onCellEditCommit={editCell}
          disableColumnMenu={true}
          components={{
            Header: () => (
              <AddTocartHeader columns={StyleColums} setlist={setlist} />
            ),

            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                No Order
              </div>
            ),
          }}
        />
      </div>
      <div className="w-full my-2 h-8 ">
        <AddToCartButton onClick={loadOrdersDetails} />
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default AddToCart;
