import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';

import axios from 'axios';
import { FormatNumber2 } from '../utility/component/tools';
import { CollectionFromToDate } from '../utility/component/collectionFromToDate';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  selectCustomerMasterHelpModStatus,
  setAlertWithTitle,
  setCustomerMasterHelpModStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectBookingCustomerDetails,
  setBookingAttachImage,
  setBookingCustomerDetails,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../utility/Redux/booking';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import { setViewBillInvoiceId, setViewBillMod } from '../utility/Redux/billing';
import AddIcon from '@mui/icons-material/Add';
import { CollectionFromToDateMobile } from '../utility/component/collectionFromToDateMobile';
import { PrintButton } from '../utility/component/SNAA_buttons';
import { useNavigate } from 'react-router-dom';
import PrintBillSummary from './printBillSummary';
import { useReactToPrint } from 'react-to-print';
import CustomerMasterhelp from '../help/customermasterHelp';
import { getEmptyMeasurementList } from '../utility/func/common';
import MeasurmentSlip from '../masters/measurmentSlip';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import QrCodeSlip from '../masters/qrSlip';
import FilterData from './filterData';
import OrderWiseQrCode from '../masters/orderQrSlip';
const width = window.innerWidth / 100;

const OrderSummary = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const [lowerList, setLowerList] = useState([]);
  const [upperList, setUpperList] = useState([]);
  const [selectedQrList, setSelectedQrList] = useState([]);
  const [lowerListQR, setLowerListQR] = useState([]);
  const [upperListQR, setUpperListQR] = useState([]);
  const [list, setList] = useState([]);
  const [FullList, setFullList] = useState([]);
  const [filterQrSearchTExt, setFlterQrSearchText] = useState('');
  const [bookingOrderNumber, setBookingOrderNumber] = useState('');
  const bookingCustomerDetails = useSelector(selectBookingCustomerDetails);
  const slipRef = useRef();
  const [selectedQrValue, setSelectedQrValue] = useState('');
  const QrprintRef = useRef();
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [isUpper, setIsUpper] = useState([]);
  const [isLower, setIsLower] = useState([]);
  const [qrType, setIsQrType] = useState('');
  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );
  const [bookingDetails, setBookingDetails] = useState({
    bookingOrderNumber: '',
    upperTryDate: '',
    upperDeliveryDate: '',
    lowerTryDate: '',
    lowerDeleveryDate: '',
    remarks: {
      lower: '',
      upper: '',
    },
    selectedUpperItemDetails: '',
    selectedLowerItemDetails: '',
    mobile: '',
    customer_name: '',
    date: moment().format('YYYY-MM-DD'),
  });

  const customerDetails = (params) => {
    dispatch(setBookingCustomerDetails(''));
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    dispatch(setBookingCustomerDetails(params.row));
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  const columns = [
    {
      field: 'DATE',
      type: 'number',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      accessor: 'DATE',
      renderCell: (param) => {
        return <div>{moment(param.value).format('YYYY-MM-DD')}</div>;
      },
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Date'}
        </div>
      ),
    },
    {
      field: 'PREFIX',
      accessor: 'PREFIX',
      type: 'number',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Prefix'}
        </div>
      ),
    },
    {
      field: 'MRC_NO',
      accessor: 'MRC_NO',
      type: 'number',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'MRC No.'}
        </div>
      ),
    },
    {
      field: 'BOOKING_ORDER_NO',
      accessor: 'BOOKING_ORDER_NO',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Booking Order'}
        </div>
      ),
    },
    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      width: 100,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Mobile'}
        </div>
      ),
    },
    {
      accessor: 'CUSTOMER_NAME',
      field: 'CUSTOMER_NAME',
      type: 'string',
      width: 400,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Customer Name'}
        </div>
      ),
    },
    {
      accessor: 'COUNTRY',
      field: 'COUNTRY',
      type: 'string',
      width: 200,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Country'}
        </div>
      ),
    },

    {
      field: 'CITY',
      accessor: 'CITY',
      type: 'string',
      width: 200,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'City'}
        </div>
      ),
    },
  ];

  const loadVocherAPI = (invNo) => {
    const data = {
      table_prefix: prefix,
      FDATE: firstDate,
      TDATE: SecondDate,
      //   MOBILE: bookingCustomerDetails.mobile,
    };

    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(`/api/reportRoute/orderSummary`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;
          const list = data.map((o, i) => {
            return {
              ...o,
              id: i,
            };
          });
          setList(list);
          setFullList(list);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const getOrderDetails = (id, isQrCode, isDatagrid, isMeasSlip) => {
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/booking/loadCustomerOrder`,
        {
          table_prefix: prefix,
          BOOKING_ORDER_NO: id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          const orderData = response.data.response;
          if (isMeasSlip) {
            ['UPPER', 'LOWER'].map(async (o) => {
              if (o === 'UPPER') {
                let upperList = [];
                await getEmptyMeasurementList(
                  'UPPER',
                  masterPrefix,
                  token
                ).then((e) => {
                  e.map((o) => {
                    const upperData = orderData.filter(
                      (data) =>
                        data.UPPER_LOWER === 'UPPER' &&
                        data.PARAMETER === o.PARAMETER
                    );
                    if (upperData[0]) {
                      setBookingDetails((e) => {
                        return {
                          ...e,
                          bookingOrderNumber: id,
                          upperTryDate: upperData[0].TRY_DATE,
                          upperDeliveryDate: upperData[0].DEL_DATE,
                          remarks: {
                            ...e.remarks,
                            upper: upperData[0].REMARKS,
                          },
                          date: moment(upperData[0].DATE).format('YYYY-MM-DD'),
                          selectedUpperItemDetails: {
                            item_name: upperData[0].ITEM_NAME,
                            regional_iname: upperData[0].REGIONAL_INAME,
                          },
                          mobile: upperData[0].MOBILE,
                          customer_name: upperData[0].CUSTOMER_NAME,
                        };
                      });
                      upperList.push({
                        ...upperData[0],
                        size: upperData[0].SIZE,
                      });
                    } else {
                      upperList.push(o);
                    }
                  });
                });
                const isNotEmpty = upperList.filter((o) => o.size !== '');
                if (isNotEmpty[0]) {
                  setUpperList(upperList);
                } else {
                  setUpperList([]);
                }
              } else {
                let lowerList = [];
                await getEmptyMeasurementList(
                  'LOWER',
                  masterPrefix,
                  token
                ).then((e) => {
                  e.map((o) => {
                    const lowerData = orderData.filter(
                      (data) =>
                        data.UPPER_LOWER === 'LOWER' &&
                        data.PARAMETER === o.PARAMETER
                    );
                    if (lowerData[0]) {
                      setBookingDetails((e) => {
                        return {
                          ...e,
                          bookingOrderNumber: id,
                          upperTryDate: lowerData[0].TRY_DATE,
                          upperDeliveryDate: lowerData[0].DEL_DATE,
                          remarks: {
                            ...e.remarks,
                            upper: lowerData[0].REMARKS,
                          },
                          date: moment(lowerData[0].DATE).format('YYYY-MM-DD'),
                          selectedLowerItemDetails: {
                            item_name: lowerData[0].ITEM_NAME,
                            regional_iname: lowerData[0].REGIONAL_INAME,
                          },
                          mobile: lowerData[0].MOBILE,
                          customer_name: lowerData[0].CUSTOMER_NAME,
                        };
                      });
                      lowerList.push({
                        ...lowerData[0],
                        size: lowerData[0].SIZE,
                      });
                    } else {
                      lowerList.push(o);
                    }
                  });
                });
                const isNotEmpty = lowerList.filter((o) => o.size !== '');
                if (isNotEmpty[0]) {
                  setLowerList(lowerList);
                } else {
                  setLowerList([]);
                }
                dispatch(setSpinnerLoading('Creating..'));
                handleSlipPRint();
              }
            });
          } else {
            if (isQrCode && !isDatagrid) {
              const upperQr = orderData.filter(
                (o) => o.UPPER_LOWER === 'UPPER'
              );
              const lowerQr = orderData.filter(
                (o) => o.UPPER_LOWER === 'LOWER'
              );
              setLowerListQR(lowerQr);
              setUpperListQR(upperQr);
            } else {
              if (isDatagrid) {
                const upperQr = orderData.filter(
                  (o) => o.UPPER_LOWER === 'UPPER'
                );
                const lowerQr = orderData.filter(
                  (o) => o.UPPER_LOWER === 'LOWER'
                );
                if (upperQr[0]) {
                  setIsQrType('UPPER');
                } else {
                  if (lowerQr[0]) {
                    setIsQrType('LOWER');
                  }
                }
                setIsUpper(upperQr);
                setIsLower(lowerQr);
                setAlertTwoButtonModal({
                  title: 'Print',
                  msg: `Do you want to print Measurements/QR Code`,
                  status: true,
                  setStatus: '',
                  button1: 'Measurements',
                  cancelAble: true,
                  button1Click: () => {
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        status: false,
                      };
                    });
                    getOrderDetails(id, false, false, true);
                  },
                  button2: 'Qr-code',
                  button2Click: () => {
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        status: false,
                      };
                    });
                    getOrderDetails(id, true);
                  },
                });
              }
            }
          }

          // now map the order details because may be one history have two type records like upper and lowwer
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 109',
              msg: 'Something went wrong while loading customer order details',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(setSpinnerLoading(false));

        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const navigate = useNavigate();

  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
  });
  const handleSlipPrint = useReactToPrint({
    content: () => slipRef.current,
    onBeforeGetContent: () => {
      const style = document.createElement('style');
      style.innerHTML = `
        @page {
          size: portrait;
          margin: 0;
        }
      
      `;
      document.head.appendChild(style);

      return () => document.head.removeChild(style);
    },
  });
  const handleSlipPRint = () => {
    setTimeout(() => {
      dispatch(setSpinnerLoading(false));
      handleSlipPrint();
    }, 1000); // 1000 milliseconds = 1 second
  };

  const handleBarCodeScanner = useReactToPrint({
    content: () => QrprintRef.current,
    documentTitle: 'QR Code Print',
    onBeforeGetContent: () => {
      const style = document.createElement('style');
      style.innerHTML = `
        @page {
       size: 138px 460px;
              margin: 0
        }
        #print-qr * {
          visibility: visible;
          width: 132px;
          font-size: 15px;
        }
      `;
      document.head.appendChild(style);

      return () => document.head.removeChild(style);
    },
  });
  useEffect(() => {
    if (upperListQR[0] || lowerListQR[0]) {
      handleBarCodeScanner();
    }
  }, [upperListQR, lowerListQR]);
  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-y-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            hideCustomer={true}
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            hideCustomer={true}
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            printButton={
              <button
                onClick={handlePrint}
                variant="contained"
                style={{
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#bad4f9',
                  marginLeft: '5px',
                }}
                className="py-[6px] rounded-sm shadow-sm"
              >
                <div className="text-black px-4 py-0.5 text-[14px] ">
                  Print{' '}
                </div>
              </button>
            }
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadVocherAPI();
            }}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
            // setBookingCustomerDetails={setBookingCustomerDetails}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}
      <div className="flex flex-col grow mt-2 max-sm:mt-2 ">
        <div className="h-[360px]  l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 13,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },

              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            rowHeight={33}
            headerHeight={0}
            columns={columns}
            rows={list}
            pagination
            onRowClick={(p) => {
              setSelectedQrList([]);
              setFlterQrSearchText('');
              setSelectedQrValue('');
              setBookingOrderNumber(p.row.BOOKING_ORDER_NO);
              getOrderDetails(p.row.BOOKING_ORDER_NO, false, true);
            }}
            scrollbarSize={0}
            getRowId={(e) => e.id}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={columns} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />
        </div>
        <FilterData
          placeHolder={'By Order Number'}
          placeHolder2={'By MRC Number'}
          onFilter={(filterText) => {
            setList(
              FullList.filter((o) =>
                o.BOOKING_ORDER_NO.toString().includes(filterText)
              )
            );
          }}
          onFilter2={(filterText) => {
            setList(
              FullList.filter((o) => o.MRC_NO.toString().includes(filterText))
            );
          }}
        />
      </div>
      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setCustomerMasterHelpModStatus(false));
        }}
      />
      <div className="hidden print:flex">
        <MeasurmentSlip
          ref={slipRef}
          upperData={upperList}
          date={bookingDetails.date}
          lowerData={lowerList}
          bookingOrderNumber={bookingDetails.bookingOrderNumber}
          upperTryDate={bookingDetails.upperTryDate}
          upperDeliveryDate={bookingDetails.upperDeliveryDate}
          lowerTryDate={bookingDetails.lowerTryDate}
          lowerDeleveryDate={bookingDetails.lowerDeleveryDate}
          lowerRemarks={bookingDetails.remarks.lower}
          upperRemarks={bookingDetails.remarks.upper}
          bookingCustomerDetails={bookingCustomerDetails}
          selectedUpperItemDetails={bookingDetails.selectedUpperItemDetails}
          selectedLowerItemDetails={bookingDetails.selectedLowerItemDetails}
          mobile={bookingDetails.mobile}
          customer_name={bookingDetails.customer_name}
        />
      </div>
      <div>
        <div className="hidden">
          {selectedPrintOption === 'print' && windowSize ? (
            <div
              ref={printRef}
              style={{ display: 'block', visibility: 'hidden' }}
            >
              <PrintBillSummary
                data={list}
                columns={columns}
                firstDate={firstDate}
                SecondDate={SecondDate}
                bookingCustomerDetails={bookingCustomerDetails}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className=" hidden print:flex">
        <OrderWiseQrCode ref={QrprintRef} list={selectedQrList} />
      </div>
      <AlertModalTwoButton
        ExtraFeature={
          <div className="text-sm flex justify-center mt-1 w-full">
            <div className="w-fit mt-3">
              <div className="flex justify-center mb-2">
                <div className="bg-gray-100 flex px-1 items-center rounded-sm">
                  <div className="bg-gray-100 pl-1">
                    <i className="bi bi-search text-sm flex items-center"></i>
                  </div>
                  <input
                    value={filterQrSearchTExt}
                    type="text"
                    autoFocus={true}
                    onChange={(e) => {
                      setFlterQrSearchText(e.target.value);
                    }}
                    placeholder="Search QR Code"
                    className="bg-transparent ml-2 py-1 w-[110px] outline-none"
                  />
                </div>

                <button
                  onClick={() => {
                    setFlterQrSearchText('upper');
                  }}
                  className="bg-gray-100 py-1 px-3 ml-2 rounded-sm"
                >
                  Upper
                </button>
                <button
                  onClick={() => {
                    setFlterQrSearchText('lower');
                  }}
                  className="bg-gray-100 py-1 px-3 ml-2 rounded-sm"
                >
                  Lower
                </button>
              </div>
              {isUpper[0] && (
                <div className=" mt-1 grid grid-cols-2 t:grid-cols-3 gap-2 min-w-[353px] t:min-w-[517px]">
                  {[...Array(isUpper[0].QNTY)].map((o, i) => {
                    const index = i + 1;
                    const value = `${isUpper[0].BOOKING_ORDER_NO}_UPPER_${isUpper[0].ICODE}_${index}`;
                    if (
                      filterQrSearchTExt &&
                      value
                        .toLowerCase()
                        .includes(filterQrSearchTExt.toLowerCase())
                    ) {
                      return qrOption(value, setSelectedQrList, isUpper[0]);
                    } else {
                      if (!filterQrSearchTExt) {
                        return qrOption(value, setSelectedQrList, isUpper[0]);
                      }
                    }
                  })}
                </div>
              )}

              {isLower[0] && (
                <div className=" mt-1 grid grid-cols-2 t:grid-cols-3 gap-2">
                  {[...Array(isLower[0].QNTY)].map((o, i) => {
                    const index = i + 1;
                    const value = `${isLower[0].BOOKING_ORDER_NO}_LOWER_${isLower[0].ICODE}_${index}`;
                    if (
                      filterQrSearchTExt &&
                      value
                        .toLowerCase()
                        .includes(filterQrSearchTExt.toLowerCase())
                    ) {
                      return lowerQrOption(
                        value,
                        setSelectedQrList,
                        isLower[0]
                      );
                    } else {
                      if (!filterQrSearchTExt) {
                        return lowerQrOption(
                          value,
                          setSelectedQrList,
                          isLower[0]
                        );
                      }
                    }
                  })}
                </div>
              )}
              <div className="mt-2 px-2">
                <div className="flex pb-1 font-semibold ">Selected QR Code</div>
                <div className="h-[150px] overflow-y-auto">
                  {selectedQrList.map((qr) => {
                    return (
                      <div className="flex justify-between my-1">
                        <div>{qr.qrCode}</div>
                        <div className="flex">
                          <div className="w-[40px]">Qnty</div>:
                          <div className="ml-2 flex items-center border  justify-center">
                            <button
                              onClick={() => {
                                setSelectedQrList((q) =>
                                  q.map((o) => {
                                    if (o.qrCode === qr.qrCode) {
                                      const newQnty = o.qnty - 1;
                                      return {
                                        ...o,
                                        qnty: newQnty > 0 ? newQnty : 1,
                                      };
                                    } else {
                                      return o;
                                    }
                                  })
                                );
                              }}
                              className="flex items-center h-full px-1 border-r bg-gray-50"
                            >
                              <RemoveIcon
                                sx={{
                                  fontSize: '16px',
                                }}
                              />
                            </button>
                            <input
                              type="number"
                              value={qr.qnty}
                              className=" text-center rounded-sm outline-none text-red bg-transparent  w-[20px] "
                            />
                            <button
                              onClick={() => {
                                setSelectedQrList((q) =>
                                  q.map((o) => {
                                    if (o.qrCode === qr.qrCode) {
                                      return {
                                        ...o,
                                        qnty: o.qnty + 1,
                                      };
                                    } else {
                                      return o;
                                    }
                                  })
                                );
                              }}
                              className="flex items-center h-full px-1 border-l bg-gray-50"
                            >
                              <AddIcon
                                sx={{
                                  fontSize: '16px',
                                }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        }
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default OrderSummary;
function lowerQrOption(value, setSelectedQrList, o) {
  return (
    <div
      className=" bg-slate-100 items-center flex p-2 rounded-sm"
      value={value}
    >
      {' '}
      <input
        type="checkbox"
        className=" mr-2"
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedQrList((u) => {
              return [
                ...u,
                {
                  ...o,
                  qrCode: value,
                  qnty: 1,
                },
              ];
            });
          } else {
            setSelectedQrList((u) => u.filter((q) => q.qrCode !== value));
          }
        }}
      />
      👖
      {value}
    </div>
  );
}

function qrOption(value, setSelectedQrList, o) {
  return (
    <div
      className=" bg-slate-100 items-center flex p-2 rounded-sm"
      value={value}
    >
      {' '}
      <input
        type="checkbox"
        className=" mr-2"
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedQrList((q) => {
              return [
                ...q,
                {
                  ...o,
                  qrCode: value,
                  qnty: 1,
                },
              ];
            });
          } else {
            setSelectedQrList((q) => q.filter((q) => q.qrCode !== value));
          }
        }}
      />
      👕
      {value}
    </div>
  );
}
