import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocalMall } from '@mui/icons-material';
import { selectDepartmentName } from '../utility/Redux/profile';
import { HomeDashboard } from '../dashboard/HomeDashboard';

const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const departMentname = useSelector(selectDepartmentName);
  const masters = [
    {
      name: 'Scan Qr-code',
      onclick: () => {
        navigate('/Scan_Qr_Code');
      },
      icon: (
        <i className="bi bi-qr-code-scan flex items-center text-[15px]"></i>
      ),
    },
    {
      name: 'Pending Orders',
      icon: (
        <i className="bi  bi-exclamation-octagon flex items-center text-[15px] "></i>
      ),
      onclick: () => {
        navigate('/pending_orders');
      },
    },
  ];
  return (
    <div className="h-full w-full ">
      {departMentname === 'Delivery' ? (
        <HomeDashboard />
      ) : departMentname ? (
        <div className="p-4 ">
          <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-5 ll:grid-cols-6 gap-3 ">
            {masters.map((e, i) => {
              return (
                <Button
                  key={i}
                  onClick={e.onclick}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#E8E2DB] py-4 items-center w-full justify-center flex text-brown">
                    {e.icon && <div className="mr-3">{e.icon}</div>}

                    {e.name}
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EmployeeDashboard;
