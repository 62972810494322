import React, { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectIsProductionModuleAllwed,
  selectUserEmail,
  selectUserType,
} from '../utility/Redux/profile';
import CryptoJS from 'crypto-js';
import axios from 'axios';

import { useEffect } from 'react';
import {
  setAlertWithTitle,
  setModCompanyHelpStatus,
} from '../utility/Redux/modal';
import ModUserInfo from '../help/modUserInfo';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import GeniustextInput from '../utility/component/mastertext';
import { SaveButton, UndoButton } from '../utility/component/SNAA_buttons';
import ModCompanyHelp from '../help/companyMasterHelp';
//tes
const UserMaster2 = () => {
  const [selectedUserType, setSelectedUserType] = useState('USER');
  const [companyInfo, setCompanyInfo] = useState('');
  const [userInfoStatus, setUserInfoStatus] = useState(false);
  const [selectedUserStatus, setSelectedUserStatus] = useState('ACTIVE');
  const [userCode, setUserCode] = useState('');
  const [oldPaswrd, setoldPaswrd] = useState('');
  const [userDetails, setUserDetails] = useState({
    user_email: '',
    user_password: '',
    user_name: '',
    user_mobile: '',
    user_type: 'USER',
    user_status: selectedUserStatus,
    company_email: '',
    company_name: '',
    company_mobile: '',
    company_website: '',
    company_logo: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    current_name: '',
    currency_symbol: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    disputes: '',
  });

  const [confirmPaswrd, setConfirmPaswrd] = useState('');
  const userEmail = useSelector(selectUserEmail);
  const companyDetail = useSelector(selectCompanyAddress);
  const headerRef = useRef();

  const dispatch = useDispatch();
  const CompanyUserInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const email = companyDetail.email;
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    axios
      .post(
        `/api/users/userCompanyInfo`,
        {
          company_email: companyDetail.email,
          user_email: userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        setCompanyInfo(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  const VerifyUser = async (email, userName, code) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    axios
      .post(
        '/api/users/emailVerified',
        {
          user_email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((res) => {
        axios
          .post(
            '/api/users/sendEmailToAdmin',
            {
              user_email: email,
              user_name: userName,
              activate_code: code,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${hmacString}`,
              },
            }
          )
          .then((res) => {
            console.log('activate email send');
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((e) => console.log(e));
  };
  const saveUser = () => {
    if (userDetails.user_mobile) {
      if (userDetails.user_email && userDetails.user_name) {
        if (userDetails.user_password === confirmPaswrd) {
          if (
            userDetails.user_password
              ? userDetails.user_password.length > 5
              : true
          ) {
            const email = userDetails.user_email;
            // const hmac = CryptoJS.HmacSHA256(message, secret);
            const hmac = CryptoJS.HmacSHA256(email, secretPass);
            // Convert the HMAC to a string and encode it using Base64
            const hmacString = hmac.toString(CryptoJS.enc.Base64);
            const companyName = companyInfo.company_name;
            const words = companyName.split(' ');
            const p =
              words.reduce((acc, word) => {
                if (word) {
                  acc += word[0];
                }
                return acc;
              }, '') + '_';

            const data = {
              ...userDetails,
              user_type: userDetails.user_type ? userDetails.user_type : 'USER',
              table_prefix: p.toLowerCase(),
              user_status: selectedUserStatus,
              company_email: companyInfo.company_email,
              company_name: companyInfo.company_name,
              company_mobile: companyInfo.company_mobile,
              company_website: companyInfo.company_website,
              company_logo: '',
              user_password: userDetails.user_password,
              address: companyInfo.address,
              city: companyInfo.city,
              state: companyInfo.state,
              country: companyInfo.country,
              activate_code: companyInfo.activate_code,
              pin_code: companyInfo.pin_code,
              current_name: companyInfo.currency_name,
              currency_symbol: companyInfo.currency_symbol,
              regd_no1: companyInfo.regd_no1,
              regd_no2: companyInfo.regd_no2,
              regd_no3: companyInfo.regd_no3,
              disputes: companyInfo.disputes,
            };
            axios
              .post(
                `/api/users/createNewUser`,

                data,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hmacString}`,
                  },
                }
              )
              .then(async function async(response) {
                if (response.status === 200) {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Success',
                      msg: response.data.message,
                    })
                  );
                  if (!userDetails?.code) {
                    VerifyUser(
                      userDetails.user_email,
                      userDetails.user_name,
                      companyInfo.activate_code
                    );
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              })
              .finally(() => {
                undo();
              });
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Password Error',
                msg: 'Password length must be more then 5 digits.',
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error ',
              msg: 'Password and Confirm Password must be same.',
            })
          );
        }
      } else {
        if (!userDetails.user_email) {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Please enter a valid email address',
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Mobile number required',
        })
      );
    }
  };
  const undo = () => {
    setUserDetails({
      user_email: '',
      user_password: '',
      user_name: '',
      user_mobile: '',
      user_type: 'USER',
      user_status: '',
      company_email: '',
      company_name: '',
      company_mobile: '',
      company_website: '',
      company_logo: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pin_code: '',
      currency_name: '',
      currency_symbol: '',
      regd_no1: '',
      regd_no2: '',
      regd_no3: '',
      disputes: '',
    });
    setConfirmPaswrd('');
    setUserCode('');
    setSelectedUserType('USER');
    setSelectedUserStatus('ACTIVE');
  };

  useEffect(() => {
    CompanyUserInfo();
  }, []);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();

  const userType = useSelector(selectUserType);
  const isPrdoctionAllowed = useSelector(selectIsProductionModuleAllwed);
  const UserTypeOption =
    userType === 'ADMIN'
      ? [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
          {
            name: 'ADMIN',
            value: 'ADMIN',
          },
          {
            name: 'PRODUCTION',
            value: 'PRODUCTION',
          },
          {
            name: 'SUPERVISOR',
            value: 'SUPERVISOR',
          },
        ]
      : isPrdoctionAllowed
      ? [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
          {
            name: 'PRODUCTION',
            value: 'PRODUCTION',
          },
          {
            name: 'SUPERVISOR',
            value: 'SUPERVISOR',
          },
        ]
      : [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
        ];

  return (
    <div className="w-full flex justify-center  bg-gray-50 h-full  p-3 overflow-y-scroll ">
      <div className="text-sm  rounded-sm  h-full">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={'User Master'}
            onClose={() => navigate('/settings')}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="      flex flex-col "
        >
          <div className=" bg-white p-3">
            {userType === 'ADMIN' && (
              <div className="flex ">
                <GeniustextInput
                  type={'text'}
                  titleBold={true}
                  titleColor={'#383535'}
                  title={'Comapny Name'}
                  width={'270px'}
                  value={userDetails.company_name}
                  error={false}
                  search={() => {
                    dispatch(setModCompanyHelpStatus(true));
                  }}
                  readonly={true}
                />
              </div>
            )}
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              <div className="flex  ">
                <GeniustextInput
                  search={() => {
                    setUserInfoStatus(true);
                  }}
                  value={userDetails.user_name}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_name: t.target.value,
                    });
                  }}
                  type={'text'}
                  title={'User Name'}
                  width={'270px'}
                  error={false}
                />
              </div>

              <div className="flex  ">
                <GeniustextInput
                  width={'270px'}
                  type={'text'}
                  title={'Email'}
                  value={userDetails.user_email}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_email: t.target.value,
                    });
                  }}
                  error={
                    userDetails.user_email
                      ? emailRegex.test(userDetails.user_email)
                        ? false
                        : 'Invalid email'
                      : false
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              <div className="flex">
                <GeniustextInput
                  type={'number'}
                  width={'270px'}
                  title={'Mobile'}
                  value={userDetails.user_mobile}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_mobile: t.target.value,
                    });
                  }}
                  error={
                    userDetails.user_mobile
                      ? true
                        ? false
                        : 'Invalid mobile'
                      : false
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              {!userCode ? (
                <>
                  <div className="flex">
                    <GeniustextInput
                      type={'password'}
                      width={'270px'}
                      title={'Password'}
                      value={userDetails.user_password}
                      error={
                        userDetails.user_password
                          ? userDetails.user_password.length > 5
                            ? false
                            : 'Passwords must be at least 6 characters.'
                          : false
                      }
                      onChange={(t) => {
                        setUserDetails({
                          ...userDetails,
                          user_password: t.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex">
                    <GeniustextInput
                      type={'password'}
                      width={'270px'}
                      title={'Password'}
                      onFocus={(e) => {
                        e.currentTarget.select();
                      }}
                      value={oldPaswrd ? oldPaswrd : userDetails.user_password}
                      onChange={(t) => {
                        setUserDetails({
                          ...userDetails,
                          user_password: t.target.value,
                        });
                        setoldPaswrd('');
                      }}
                      error={false}
                    />
                  </div>
                </>
              )}
              {!userCode ? (
                <>
                  <div className="flex">
                    <GeniustextInput
                      width={'270px'}
                      type={'password'}
                      value={confirmPaswrd}
                      onChange={(t) => {
                        setConfirmPaswrd(t.target.value);
                      }}
                      title={'Confirm Password'}
                      error={
                        confirmPaswrd
                          ? userDetails.user_password === confirmPaswrd
                            ? false
                            : 'Passwords must be same.'
                          : false
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className=" flex ">
                    <GeniustextInput
                      width={'270px'}
                      type={'password'}
                      onFocus={(e) => {
                        e.currentTarget.select();
                      }}
                      value={confirmPaswrd ? confirmPaswrd : oldPaswrd}
                      onChange={(t) => {
                        setConfirmPaswrd(t.target.value);
                        setoldPaswrd('');
                      }}
                      title={'Confirm Password'}
                      error={
                        confirmPaswrd
                          ? confirmPaswrd !== userDetails.user_password
                            ? 'Password must be same.'
                            : false
                          : false
                      }
                    />
                  </div>
                </>
              )}
            </div>

            <div className="mt-2 flex justify-between ">
              {' '}
              <div className="flex">
                <GeniustextInput
                  option={UserTypeOption}
                  width={'130px'}
                  value={selectedUserType}
                  onChange={(e) => {
                    setSelectedUserType(e.target.value);
                    setUserDetails({
                      ...userDetails,
                      user_type: e.target.value,
                    });
                  }}
                  title={'User Type'}
                />
                <div className="ml-2">
                  <GeniustextInput
                    option={[
                      {
                        name: 'Select Type',
                        value: '',
                      },
                      {
                        name: 'ACTIVE',
                        value: 'ACTIVE',
                      },
                      {
                        name: 'PENDING',
                        value: 'PENDING',
                      },
                    ]}
                    onChange={(e) => {
                      setSelectedUserStatus(e.target.value);
                      setUserDetails({
                        ...userDetails,
                        user_status: e.target.value,
                      });
                    }}
                    value={selectedUserStatus}
                    title={'User Status'}
                    width={'130px'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end bg-white py-3 rounded-b-sm  px-3"
          >
            <SaveButton onClick={saveUser} />

            <UndoButton onClick={undo} />
          </div>
        </div>

        <ModCompanyHelp
          onRowClick={(p) => {
            setCompanyInfo(p.row);
            setUserDetails({
              ...setUserDetails,
              company_name: p.row.company_email,
            });
            dispatch(setModCompanyHelpStatus(false));
          }}
        />

        <ModUserInfo
          setSelectedUserType={setSelectedUserType}
          setSelectedUserStatus={setSelectedUserStatus}
          setUserDetails={setUserDetails}
          status={userInfoStatus}
          setUserCode={setUserCode}
          setoldPaswrd={setoldPaswrd}
          setStatus={setUserInfoStatus}
          companyEmail={companyInfo.company_email}
        />
      </div>
    </div>
  );
};

export default UserMaster2;
