import React from 'react';
import nlp from 'compromise';

const CapitalizeProperNouns = (inputText) => {
  const doc = nlp(inputText);
  const formattedText = doc.sentences().toTitleCase().out('text');

  return formattedText;
};

export default CapitalizeProperNouns;
