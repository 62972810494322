import React, { useState } from 'react';

const FilterData = ({ onFilter, placeHolder, onFilter2, placeHolder2 }) => {
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  return (
    <div
      style={{
        border: '1px solid #5c5b5a',
      }}
      className="w-full flex text-sm py-2 rounded-sm mt-3 px-2"
    >
      <div className="w-[50px]">Filter</div> :
      <input
        value={value}
        className="outline-none w-[200px] border-r  ml-2"
        placeholder={placeHolder}
        onChange={(e) => {
          setValue2('');

          setValue(e.target.value);
          onFilter(e.target.value);
        }}
      ></input>
      <input
        value={value2}
        className="outline-none   w-[200px] ml-2 border-r "
        placeholder={placeHolder2}
        onChange={(e) => {
          setValue('');

          setValue2(e.target.value);
          onFilter2(e.target.value);
        }}
      ></input>
    </div>
  );
};

export default FilterData;
