import { Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBillingCartList, setBillingDicount } from '../../Redux/billing';
import { FormatNumber2 } from '../tools';

const ModBillDiscount = ({ status, setStatus }) => {
  const dispatch = useDispatch();

  const billingCartList = useSelector(selectBillingCartList);

  const totalAmount = FormatNumber2(
    billingCartList.reduce(
      (total, row) => Number(total) + Number(row.AMOUNT),
      0
    )
  );
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height: 'auto',
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',

    // boxShadow: 'none',
  };
  const styleTab = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (window.innerWidth / 100) * 45,
    height: 'auto',
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
  };
  const handleClose = () => setStatus(false);
  const discountButtonList = [
    5, 10, 15, 20, 25, 50, 55, 65, 70, 75, 85, 90, 100, 0,
  ];

  const [discount, setDiscount] = useState(0);
  return (
    <div>
      <Modal open={status}>
        <div style={window.innerWidth <= 1024 ? styleTab : style}>
          <div className="w-full items-center p-2 rounded-tl-sm rounded-tr-sm  bg-[#e8e2db] flex justify-between">
            <div>Select Discount</div>
            <button onClick={handleClose}>
              <i className="bi text-[20px] bi-x"></i>
            </button>
          </div>

          <div className="p-5">
            <div className="flex w-full">
              <div className="p-3 border flex border-gray-700 rounded-sm w-1/2 ">
                Bill Amount : <div className="pl-2">{totalAmount}</div>
              </div>

              <div className="p-3 border border-gray-400 ml-3  rounded-sm flex grow ">
                Discount : {(totalAmount * discount) / 100}
              </div>
            </div>

            <div className="w-full grid gap-4 grid-cols-5 mt-4">
              {discountButtonList.map((i, key) => {
                return (
                  <Button
                    key={key}
                    variant="contained"
                    style={{
                      backgroundColor: 'white',
                      color: '#67666e',
                      fontSize: '17px',
                      height:
                        window.innerWidth <= 1024
                          ? (window.innerWidth / 100) * 6
                          : (window.innerWidth / 100) * 3,
                      width: (window.innerWidth / 100) * 3,
                      boxShadow: '1px 1px 1px  rgba(0, 0, 0, 0.1)',
                      fontWeight: 500,
                      border: '0.5px solid #c1c1c7',
                    }}
                    onClick={() => {
                      setDiscount(i);
                    }}
                    className="rounded-sm p-3 "
                  >
                    {i}%
                  </Button>
                );
              })}
            </div>

            <div className="w-full flex justify-between mt-4">
              <div className="p-3 border  text-[#1302cc]  border-gray-400 rounded-sm w-1/2 ">
                Bill Net Amount :{' '}
                {Math.round(totalAmount - (totalAmount * discount) / 100)}
              </div>
              <Button
                // ref={updateRef}
                onClick={() => {
                  dispatch(setBillingDicount(discount));
                  handleClose();
                }}
                variant="contained"
                style={{
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#f5874f',
                  marginLeft: 20,
                  flex: 1,
                  direction: 'flex',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className="  flex items-center text-black h-full  px-3 ">
                  Ok{' '}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModBillDiscount;
