import React, { useEffect, useRef, useState } from 'react';
import { useFocus } from '../utility/hooks/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import {
  setAlertWithTitle,
  setHsnMasterStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  ExitButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';
import GeniustextInput from '../utility/component/mastertext';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';

const GeniusHsnMaster = () => {
  const [hsnCode, setHsnCode] = useState('');
  const [hsnCodeError, setHsnCodeRef] = useFocus();
  const [tax, setTax] = useState('');

  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();
  const windowWidth = useSelector(selectWidth);

  const columns = [
    {
      width: windowWidth <= 768 ? 80 : 140,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'HSN_CODE',
      renderHeader: (params) => <div className="text-md">HSN Code</div>,
      width: windowWidth <= 768 ? 120 : 300,
      headerAlign: 'center',
      align: 'left',
    },
    {
      width: windowWidth <= 768 ? 80 : 130,
      headerAlign: 'center',
      align: 'left',
      field: 'GST',
      renderHeader: (params) => <div className="text-md">Tax Rate</div>,
    },
  ];

  const masterprefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);

  const getData = async () => {
    // try {
    await axios
      .post(
        '/api/masters/listHSNCode',
        {
          table_prefix_master: masterprefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  const [id, setId] = useState('');

  const saveHsnCodeMaster = () => {
    if (hsnCode) {
      const isDuplicateHSN = rows.some((row) => row.HSN_CODE === hsnCode);

      if (!id && isDuplicateHSN) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This HSN Code is already exists. Please choose a different HSN Code.',
          })
        );
        setHsnCodeRef();
        return;
      }

      const data = {
        table_prefix_master: masterprefix,
        CODE: id ? id : '',
        HSN_CODE: hsnCode,
        GST: tax ? tax : 0,
      };
      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/masters/saveHSNCodeMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            {
              id
                ? dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Updated successfully.',
                      lottie: 'success',
                    })
                  )
                : dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Saved successfully.',
                      lottie: 'success',
                    })
                  );
            }
            clearState();
            getData();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'ERROR',
          msg: 'Please Fill Required Feild.',
          lottie: 'reject',
        })
      );
      setHsnCodeRef();
    }
  };

  const handleClose = () => {
    dispatch(setHsnMasterStatus(false));
    clearState();
  };

  const clearState = () => {
    setHsnCode('');
    setTax('');
    setId('');
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 15) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            inputFields[nextIndex].focus(); // Set focus on the next field
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.altKey) && e.key === 's') {
        e.preventDefault();
        saveHsnCodeMaster();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hsnCode, tax, id]);

  return (
    <div className="flex-grow  bg-white border-brown  px-3" ref={formRef}>
      <div id="input" className="flex mt-3">
        <div>
          <GeniustextInput
            title={'HSN Code'}
            width={windowWidth > 764 ? '600px' : '295px'}
            type={'text'}
            value={hsnCode}
            // onKeyDown={(e) => {
            //   if ((e.ctrlKey || e.altKey) && e.key === 's') {
            //     e.preventDefault();
            //     saveHsnCodeMaster();
            //   }
            // }}
            onChange={(e) => {
              setHsnCode(e.target.value);
            }}
            refrance={hsnCodeError}
            autofocus={true}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Tax Rate'}
          width={windowWidth > 764 ? '600px' : '295px'}
          type={'number'}
          value={tax}
          onChange={(e) => {
            setTax(e.target.value);
          }}
          // onKeyDown={(e) => {
          //   if ((e.ctrlKey || e.altKey) && e.key === 's') {
          //     e.preventDefault();
          //     saveHsnCodeMaster();
          //   }
          // }}
        />
      </div>

      <div className="w-full flex t:h-[300px] d:h-[400px] sm:h-[300px] h-[300px] mt-1 ">
        <GeniusDatagrid
          rowID={(t) => t.CODE}
          onRowClick={(p) => {
            setId(p.row.CODE);
            setHsnCode(p.row.HSN_CODE);
            setTax(p.row.GST);
          }}
          columns={columns}
          list={rows}
        />
      </div>

      <div className="flex w-full justify-end items-center py-3">
        {id ? (
          <ModifyButton onClick={saveHsnCodeMaster} />
        ) : (
          <SaveButton onClick={saveHsnCodeMaster} />
        )}
        <UndoButton onClick={clearState} />
        <ExitButton onClick={handleClose} />
      </div>
    </div>
  );
};

export default GeniusHsnMaster;
