import React, { useRef } from 'react';
import { Country, State, City } from 'country-state-city';
import { useState } from 'react';
import { useEffect } from 'react';
import { toProperCase } from '../format';
import GeniustextInput from '../mastertext';
import { number } from 'yup';
import { useSelector } from 'react-redux';
import { selectWidth } from '../../Redux/profile';

const CompanyInfo = ({
  rowStyle,
  rowValueStyle,
  rowWidth,
  companyInfo,
  setCompanyInfo,
  SaveData,
}) => {
  const mobileStyle = {
    fontSize: '13px',
    width: '40%',
    height: '38px',
    border: '1px solid #b5b3b3',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    color: '#4a4a4a',
  };
  const countries = Country.getAllCountries();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);

  const windowWidth = useSelector(selectWidth);

  useEffect(() => {
    if (companyInfo.Country) {
      const selectedCountry = countries.filter(
        (country) => country.name === toProperCase(companyInfo.Country)
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [companyInfo.Country]);

  useEffect(() => {
    if (companyInfo.State && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === toProperCase(companyInfo.State)
      );
      setCitylist(
        City.getCitiesOfState(
          selectedstate[0].countryCode,
          selectedstate[0].isoCode
        )
      );
    }
  }, [companyInfo.State, stateList]);

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 9) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  return (
    <div
      style={
        window.innerWidth <= 500
          ? { display: 'block', marginLeft: '0px', overflow: 'scroll' }
          : window.innerWidth <= 800
          ? { display: 'block', marginLeft: '20px', overflow: 'scroll' }
          : {
              border: '1px solid #b5b3b3',
              borderTop: '0px solid #b5b3b3',
            }
      }
      className="max-sm:p-2 t:p-5 h-full max-sm:overflow-y-scroll t:overflow-y-scroll d:overflow-y-auto"
      ref={formRef}
    >
      <div className="flex">
        <GeniustextInput
          title={'Company Name'}
          width={windowWidth <= 500 ? '155px' : '620px'}
          autofocus={true}
          type={'text'}
          search={'search'}
          value={companyInfo?.compName}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              compName: t.target.value,
            });
          }}
        />
      </div>
      <div className="flex">
        <GeniustextInput
          title={'Address'}
          width={windowWidth <= 500 ? '155px' : '620px'}
          type={'text'}
          value={companyInfo?.Address}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              Address: t.target.value,
            });
          }}
        />
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Country'}
          country={true}
          option={countries}
          width={windowWidth < 768 ? '160px' : '300px'}
          value={toProperCase(companyInfo.Country)}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              Country: t.target.value,
            });
          }}
        />

        <div className="ml-2 tab:ml-5">
          <GeniustextInput
            title={'State'}
            country={true}
            option={stateList}
            width={windowWidth < 768 ? '160px' : '300px'}
            value={toProperCase(companyInfo.State)}
            onChange={(t) => {
              setCompanyInfo({
                ...companyInfo,
                State: t.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="flex">
        <GeniustextInput
          title={'City'}
          country={true}
          width={windowWidth < 768 ? '160px' : '300px'}
          option={cityList}
          value={toProperCase(companyInfo.City)}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              City: t.target.value,
            });
          }}
        />
        <div className="ml-2 tab:ml-5">
          <GeniustextInput
            title={'Pin Code'}
            type={'number'}
            width={windowWidth < 768 ? '160px' : '300px'}
            value={companyInfo?.Pincode}
            onChange={(t) => {
              setCompanyInfo({
                ...companyInfo,
                Pincode: t.target.value,
              });
            }}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'PAN No.'}
          type={'text'}
          width={windowWidth < 768 ? '160px' : '300px'}
          value={companyInfo?.PanNo}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              PanNo: t.target.value,
            });
          }}
        />
        <div className="ml-2 tab:ml-5">
          <GeniustextInput
            title={'Mobile'}
            type={'mobile'}
            error={'mobile'}
            width={windowWidth < 768 ? '160px' : '300px'}
            value={companyInfo?.mobile}
            onChange={(t) => {
              if (t.target.value.length < 16) {
                setCompanyInfo({
                  ...companyInfo,
                  mobile: t.target.value,
                });
              }
            }}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'GST No.'}
          type={'number'}
          width={windowWidth < 768 ? '160px' : '300px'}
          value={companyInfo?.Gst}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              Gst: t.target.value,
            });
          }}
        />
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Regd No.'}
          type={'number'}
          width={windowWidth < 768 ? '160px' : '300px'}
          value={companyInfo?.Regd}
          onChange={(t) => {
            setCompanyInfo({
              ...companyInfo,
              Regd: t.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
