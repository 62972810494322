import React, { useEffect, useRef, useState } from 'react';
import {
  SaveButton,
  UndoButton,
  PrintButton,
  ModifyButton,
} from '../utility/component/SNAA_buttons';
import ProfilePicFrame from '../utility/component/styles/profilepicFrame';
import ImageUpload from '../utility/component/CMcustomerPic';
import {
  setAlertWithTitle,
  setHsnCodeHelpStatus,
  setItemMasterHelpStatus,
  setSectionMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import axios from 'axios';
import { useFocus } from '../utility/hooks/hooks';
import GeniustextInput from '../utility/component/mastertext';
import {
  DownloadFile,
  UploadFile,
} from '../utility/component/functions/firebase';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import ItemMasterHelp from '../help/itemMasterHelp';
import HsnCodeHelp from '../help/hsnMasterHelp';
import SectionMasterHelp from '../help/sectionMasterHelp';
import { Fade, Skeleton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ItemBox2 from './itemBox';
import { setItemDetails } from '../utility/Redux/booking';

const ResponsiveMaster = () => {
  const [id, setId] = useState('');
  const [itemName, setItemName] = useState('');
  const [stop, setStop] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [regional, setRegional] = useState('');
  const [description, setDescription] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [upperLower, setUpperLower] = useState('');
  const [Category, setCategory] = useState('');
  const [rate, setRate] = useState('');
  const [sewinghsnCode, setSewingHsnCode] = useState('');
  const [cuttinghsnCode, setCuttingHsnCode] = useState('');
  const [sewingtax1, setSewingTax1] = useState('');
  const [cuttingtax1, setCuttingTax1] = useState('');
  const [sewingtax2, setSewingTax2] = useState('');
  const [cuttingtax2, setCuttingTax2] = useState('');
  const [sewingtax3, setSewingTax3] = useState('');
  const [cuttingtax3, setCuttingTax3] = useState('');
  const [firebaseImg, setFirebaseImg] = useState('');
  const [path, setpath] = useState('');
  const [rowData, setRowData] = useState([]);
  const [tabScreen, setTabScreen] = useState(1);
  const [sectionList, setSectionList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [ListsectionName, setListSecname] = useState('');
  const webcamRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [linkCode, setLinkCode] = useState('');
  const [itemError, setItemError] = useFocus();
  const [rateError, setRateError] = useFocus();

  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const transliterateText = async (text) => {
    const response = await fetch(
      `https://inputtools.google.com/request?text=${text}&itc=${
        selectedLanguage === 'en'
          ? 'en-t-i0-und'
          : selectedLanguage === 'hi'
          ? 'hi-t-i0-und'
          : selectedLanguage === 'pa'
          ? 'pa-t-i0-und'
          : selectedLanguage === 'es'
          ? 'es-t-i0-und'
          : selectedLanguage === 'ru'
          ? 'ru-t-i0-und'
          : selectedLanguage === 'fr'
          ? 'fr-t-i0-und'
          : selectedLanguage === 'de'
          ? 'de-t-i0-und'
          : selectedLanguage === 'pt'
          ? 'pt-t-i0-und'
          : 'roboto-font'
      }&num=1&cp=0&cs=1&ie=utf-8&oe=utf-8&app=jsapi&uv`
    );
    const data = await response.json();
    if (data && data[1] && data[1][0] && data[1][0][1] && data[1][0][1][0]) {
      const t = data[1][0][1][0];
      setRegional(t);
    }
  };
  const masterPrefix = useSelector(selectTableMasterPrefix);

  const getData = (sec) => {
    const data = {
      table_prefix_master: masterPrefix,
      SECTION_NAME: sec,
      ICODE: '',
      ITEM_NAME: '',
    };

    dispatch(setloading(true));
    axios
      .post(`/api/masters/listItemMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response?.data?.error) {
          setItemList(response.data.response);
          return response.data.response;
        }
      })
      .catch(function (error) {
        return null;
      })
      .finally(() => {
        dispatch(setloading(false));
      });
    return <div>error</div>;
  };

  const detectLanguage = (regional) => {
    const en = /^[A-Za-z]+$/;
    const hi = /^[\u0900-\u097F]+$/;
    const pa = /^[\u0A00-\u0A7F]+$/;
    const de = /^[A-Za-zäÄöÖüÜß]+$/;
    const es = /^[\u00C0-\u02AF\u1E00-\u1EFF]+$/;
    const fr = /^[A-Za-zÀàÂâÇçÉéÈèÊêËëÎîÏïÔôÙùÛûŸÿ]+$/;
    const ru = /^[А-Яа-яЁё]+$/;
    const pt = /^[A-Za-zÀàÁáÂâÃãÉéÊêÍíÓóÔôÚúÇç]+$/;

    if (en.test(regional)) {
      return 'en';
    } else if (hi.test(regional)) {
      return 'hi';
    } else if (pa.test(regional)) {
      return 'pa';
    } else if (de.test(regional)) {
      return 'de';
    } else if (es.test(regional)) {
      return 'es';
    } else if (fr.test(regional)) {
      return 'fr';
    } else if (ru.test(regional)) {
      return 'ru';
    } else if (pt.test(regional)) {
      return 'pt';
    } else {
      return 'Unknown';
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleHsnInputChange = (e) => {
    const newValueDept = e.target.value;

    if (newValueDept) {
      dispatch(setHsnCodeHelpStatus(true));
    } else {
      dispatch(setHsnCodeHelpStatus(false));
    }
  };

  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);

  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);

  const token = useSelector(selectToken);

  const masterprefix = useSelector(selectTableMasterPrefix);

  const handleOpen = () => {
    dispatch(setSectionMasterHelpStatus(true));
  };

  const handleOpenItemMaster = () => {
    dispatch(setItemMasterHelpStatus(true));
  };

  const handleOpenHsnCodeHelp = () => {
    dispatch(setHsnCodeHelpStatus(true));
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 17) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            inputFields[nextIndex].focus(); // Set focus on the next field
          }
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  const prefix = useSelector(selectPrefix);
  const saveItemMasterAPI = () => {
    if (itemName && rate && upperLower) {
      const isDuplicateItemName = rowData.some(
        (row) => row.item_name === itemName
      );

      if (isDuplicateItemName && !id) {
        // Handle the case where the item name already exists for new items
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Item Name already exists. Please choose a different Item name.',
          })
        );
        return; // Exit the function without saving
      }

      const data = {
        table_prefix_master: masterprefix,
        ICODE: id ? id : '',
        ITEM_NAME: itemName,
        REGIONAL_INAME: regional,
        ITEM_DESCRIPTION: description,
        RATE: rate,
        SECTION_NAME: sectionName,
        UPPER_LOWER: upperLower,
        SEWING_HSN_CODE: sewinghsnCode,
        SEWING_TAX1_RATE: sewingtax1 ? sewingtax1 : 0,
        SEWING_TAX2_RATE: sewingtax2 ? sewingtax2 : 0,
        SEWING_TAX3_RATE: sewingtax3 ? sewingtax3 : 0,
        CUTTING_HSN_CODE: cuttinghsnCode,
        CUTING_TAX1_RATE: cuttingtax1 ? cuttingtax1 : 0,
        CUTING_TAX2_RATE: cuttingtax2 ? cuttingtax2 : 0,
        CUTING_TAX3_RATE: cuttingtax3 ? cuttingtax3 : 0,
        STOP_YN: stop ? stop : 'NO',
        CATEGORY: Category,
        LOWER_ICODE: linkCode ? linkCode : 0,
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));

      axios
        .post(`/api/masters/saveItemMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            dispatch(setItemDetails([]));
            const icode = id ? id : response.data.result.insertId;
            if (capturedImage || selectedFile) {
              const underscoreIndex = prefix.indexOf('_');
              const result = prefix.slice(0, underscoreIndex) + '_';

              const name = `${result + icode}`;
              const path = 'seam/items/';
              const camera = capturedImage
                ? await fetch(capturedImage).then((response) => response.blob())
                : '';
              const file = selectedFile ? selectedFile : camera;
              UploadFile(file, path, name);
            }
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Modified successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
            clearData();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!sewinghsnCode) {
        setRateError();
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Fill HSN Code!',
          })
        );
      }
      if (!rate) {
        setRateError();
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Fill Rate!',
          })
        );
      }
      if (!itemName) {
        setItemError();
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Fill Item Name!',
          })
        );
      }
      if (!upperLower) {
        setItemError();
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Select Upper Lower!',
          })
        );
      }
    }
  };

  const clearData = () => {
    setId('');
    setItemName('');
    setDescription('');
    setStop('');
    setSectionName('');
    setRate('');
    setSewingHsnCode('');
    setCuttingHsnCode('');
    setSewingTax1('');
    setCuttingTax1('');
    setSewingTax2('');
    setCuttingTax2('');
    setSewingTax3('');
    setCuttingTax3('');
    setRegional('');
    setSelectedFile(null);
    setCapturedImage(null);
    setFirebaseImg('');
    setpath('');
    setLinkCode('');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.altKey) && e.key === 's') {
        e.preventDefault();
        saveItemMasterAPI();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    itemName,
    rate,
    stop,
    description,
    regional,
    upperLower,
    Category,
    sectionName,
    sewinghsnCode,
    cuttinghsnCode,
    sewingtax1,
    sewingtax2,
    sewingtax3,
    cuttingtax1,
    cuttingtax2,
    cuttingtax3,
  ]);
  const headerRef = useRef(null);

  const windowWidth = useSelector(selectWidth);

  useEffect(() => {
    getData('');
    getSectionList();
  }, []);
  const getSectionList = () => {
    axios
      .post(
        '/api/masters/listSectionMaster',
        {
          table_prefix_master: masterPrefix,
          SECTION_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSectionList(res.data.response);
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  // const storeLocalData = () => {};

  // useEffect(() => {
  //   storeLocalData();
  // }, [upperLower,Category,selectedLanguage]);
  return (
    <div
      ref={formRef}
      className="w-full h-full flex justify-center bg-[#f5f5f7]  overflow-y-scroll  max-sm:p-2"
    >
      <div className="text-sm  rounded-sm  h-full max-sm:w-full">
        <div ref={headerRef} className="bg-white">
          <ModalHeaderStyle
            header={'Item Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>

        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="flex flex-col"
        >
          <div
            style={{
              width:
                windowWidth <= 765
                  ? '400px'
                  : windowWidth < 1024
                  ? '700px'
                  : '920px',
            }}
            className="  bg-white p-2 rounded-sm flex "
          >
            <div
              onClick={() => {
                setTabScreen(1);
              }}
              style={{
                color: tabScreen === 1 ? 'white' : 'gray',
              }}
              className={`flex-1 p-2 ${
                tabScreen === 1
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-l-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 1 ? 600 : 400,
                }}
                className=" h-full flex items-center"
              >
                Master
              </div>
            </div>
            <div
              style={{
                color: tabScreen === 2 ? 'white' : 'gray',
              }}
              onClick={() => {
                setTabScreen(2);
              }}
              className={`flex-1 p-2 ${
                tabScreen === 2
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-r-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 2 ? 600 : 400,
                }}
                className="  flex items-center"
              >
                Item List
              </div>
            </div>
          </div>
          {tabScreen === 1 && (
            <div className="px-4 py-2 justify-center   bg-white  flex  ">
              <div>
                <div className="  flex mt-2">
                  <div>
                    <div>
                      <GeniustextInput
                        title={'Item Name'}
                        search={() => {
                          handleOpenItemMaster();
                          setFilterBy('');
                        }}
                        type={'text'}
                        onChange={(e) => {
                          setItemName(e.target.value);
                        }}
                        autofocus={true}
                        refrance={itemError}
                        value={itemName}
                        width={windowWidth >= 764 ? '400px' : '150px'}
                      />
                    </div>
                    {upperLower === 'UPPER' && (
                      <div>
                        <GeniustextInput
                          title={'Link To'}
                          search={() => {
                            handleOpenItemMaster();
                            setFilterBy('LOWER');
                          }}
                          type={'text'}
                          value={linkCode}
                          onClear={
                            linkCode > 0
                              ? () => {
                                  setLinkCode(0);
                                }
                              : false
                          }
                          width={windowWidth >= 764 ? '400px' : '150px'}
                        />
                      </div>
                    )}

                    <GeniustextInput
                      title={'Description'}
                      type={'text'}
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      width={windowWidth >= 764 ? '400px' : '150px'}
                    />
                    <GeniustextInput
                      title={'Section Name'}
                      refrance={firstInputRef}
                      type={'text'}
                      search={handleOpen}
                      value={sectionName}
                      onClick={handleOpen}
                      width={windowWidth >= 764 ? '400px' : '150px'}
                      onChange={(e) => {
                        dispatch(setSectionMasterHelpStatus(true));
                      }}
                    />
                  </div>

                  <div className="w-1/2 ml-3 flex justify-end ">
                    <div className="w-full">
                      <div
                        style={{
                          width: windowWidth >= 768 ? '200px' : '150px',
                          height: windowWidth >= 768 ? '200px' : '150px',
                        }}
                      >
                        <ProfilePicFrame
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          webcamRef={webcamRef}
                          customerFirebasePic={firebaseImg}
                          setpath={setpath}
                          setFirebaseImg={setFirebaseImg}
                          path={path}
                          webcamStatus={webcamStatus}
                          capturedImage={capturedImage}
                          setCapturedImage={setCapturedImage}
                          setWebcamStatus={setWebcamStatus}
                        />
                      </div>
                      <div
                        className="flex mt-2"
                        style={{
                          width: windowWidth >= 768 ? '200px' : '150px',
                        }}
                      >
                        <div className="flex w-[100%] items-center mr-1">
                          <ImageUpload
                            setCapturedImage={setCapturedImage}
                            setSelectedFile={setSelectedFile}
                            webcamRef={webcamRef}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex">
                  <GeniustextInput
                    title={'Regional Language'}
                    refrance={secondInputRef}
                    option={[
                      {
                        name: 'Select Language',
                        value: '',
                      },
                      {
                        name: 'English',
                        value: 'en',
                      },
                      {
                        name: 'ਪੰਜਾਬੀ',
                        value: 'pa',
                      },
                      {
                        name: 'हिंदी',
                        value: 'hi',
                      },
                      {
                        name: 'Spanish',
                        value: 'es',
                      },
                      {
                        name: 'Russian',
                        value: 'ru',
                      },
                      {
                        name: 'French',
                        value: 'fr',
                      },
                      {
                        name: 'German',
                        value: 'de',
                      },
                      {
                        name: 'Portuguese',
                        value: 'pt',
                      },
                    ]}
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    width={windowWidth >= 764 ? '400px' : '150px'}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Regional Item Name'}
                      type={'text'}
                      width={windowWidth >= 764 ? '200px' : '150px'}
                      onChange={(e) => {
                        transliterateText(e.target.value);
                        setRegional(e.target.value);
                      }}
                      value={regional}
                    />
                  </div>
                </div>

                <div className=" flex">
                  <GeniustextInput
                    title={'Upper/Lower'}
                    option={[
                      {
                        name: 'Select Upper/Lower',
                        value: '',
                      },
                      {
                        name: 'Upper',
                        value: 'UPPER',
                      },
                      {
                        name: 'Lower',
                        value: 'LOWER',
                      },
                      {
                        name: 'Fabric',
                        value: 'FABRIC',
                      },
                    ]}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                    value={upperLower}
                    onChange={(e) => {
                      setLinkCode('');
                      setUpperLower(e.target.value);
                    }}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Category'}
                      option={[
                        {
                          name: 'Select Category',
                          value: '',
                        },
                        {
                          name: 'Male',
                          value: 'MALE',
                        },
                        {
                          name: 'Female',
                          value: 'FEMALE',
                        },
                      ]}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                      value={Category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className=" flex">
                  <GeniustextInput
                    title={'Rate'}
                    value={rate}
                    type={'number'}
                    refrance={rateError}
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Stop Item'}
                      option={[
                        {
                          name: 'No',
                          value: 'NO',
                        },
                        {
                          name: 'Yes',
                          value: 'YES',
                        },
                      ]}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                      value={stop}
                      onChange={(e) => {
                        setStop(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className=" flex">
                  <GeniustextInput
                    title={' Sewing HSN'}
                    type={'text'}
                    search={handleOpenHsnCodeHelp}
                    refrance={thirdInputRef}
                    onFocus={() => {
                      dispatch(setHsnCodeHelpStatus(true));
                      fourthInputRef.current.focus();
                    }}
                    value={sewinghsnCode}
                    onChange={handleHsnInputChange}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Cutting HSN'}
                      type={'text'}
                      value={cuttinghsnCode}
                      onChange={handleHsnInputChange}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                    />
                  </div>
                </div>

                <div className=" flex">
                  <GeniustextInput
                    title={'Sewing CGST'}
                    type={'text'}
                    refrance={fourthInputRef}
                    value={sewingtax1}
                    onChange={(e) => {
                      setSewingTax1(e.target.value);
                    }}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Sewing SGST'}
                      type={'text'}
                      value={sewingtax2}
                      onChange={(e) => {
                        setSewingTax2(e.target.value);
                      }}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                    />
                  </div>
                  {windowWidth >= 768 && (
                    <div className="ml-2">
                      <GeniustextInput
                        title={'Sewing Tax-3'}
                        type={'text'}
                        value={sewingtax3}
                        onChange={(e) => {
                          setSewingTax3(e.target.value);
                        }}
                        width={windowWidth >= 768 ? '200px' : '150px'}
                      />
                    </div>
                  )}
                </div>
                {windowWidth < 768 && (
                  <div>
                    <GeniustextInput
                      title={'Sewing Tax-3'}
                      type={'text'}
                      value={sewingtax3}
                      onChange={(e) => {
                        setSewingTax3(e.target.value);
                      }}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                    />
                  </div>
                )}

                <div className=" flex ">
                  <GeniustextInput
                    title={'Cutting CGST'}
                    type={'text'}
                    value={cuttingtax1}
                    onChange={(e) => {
                      setCuttingTax1(e.target.value);
                    }}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Cutting SGST'}
                      type={'text'}
                      value={cuttingtax2}
                      onChange={(e) => {
                        setCuttingTax2(e.target.value);
                      }}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                    />
                  </div>
                  {windowWidth >= 768 && (
                    <div className="ml-2">
                      <GeniustextInput
                        title={'Cutting Tax-3'}
                        type={'text'}
                        value={cuttingtax3}
                        onChange={(e) => {
                          setCuttingTax3(e.target.value);
                        }}
                        width={windowWidth >= 768 ? '200px' : '150px'}
                      />
                    </div>
                  )}
                </div>
                {windowWidth < 768 && (
                  <div className="ml-0">
                    <GeniustextInput
                      title={'Cutting Tax-3'}
                      type={'text'}
                      value={cuttingtax3}
                      onChange={(e) => {
                        setCuttingTax3(e.target.value);
                      }}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {tabScreen === 1 && (
            <div
              style={{
                borderTop: '1px solid #D2D2D7',
              }}
              className="flex justify-end max-sm:justify-between bg-white py-3 w-full px-4"
            >
              {id ? (
                <ModifyButton
                  onClick={() => {
                    saveItemMasterAPI();
                  }}
                />
              ) : (
                <SaveButton
                  onClick={() => {
                    saveItemMasterAPI();
                  }}
                />
              )}
              <PrintButton />
              <UndoButton onClick={clearData} />
            </div>
          )}

          {tabScreen === 2 && (
            <div
              style={{
                width:
                  windowWidth < 764
                    ? '380px'
                    : windowWidth < 1024
                    ? '680px'
                    : '900px',
              }}
              className="   bg-white  flex px-3 py-3 pt-3 "
            >
              <div
                style={{
                  height: `700px`,
                  width: '240px',
                }}
                className=" overflow-y-scroll"
              >
                <div className="grid  gap-1 grid-cols-1">
                  {sectionList
                    ? sectionList.map((item, key) => (
                        <>
                          <div
                            key={key}
                            className="flex overflow-hidden mx-2"
                            style={{
                              border:
                                item.SECTION_NAME === ListsectionName
                                  ? '2px solid #A8CF45'
                                  : '0px solid #A8CF45',
                              height: '45px',
                              boxShadow: '1px 1px 3px 0px #9F9C9C',
                              borderRadius: '7px',
                              textTransform: 'none',
                              backgroundColor: '#f5eb82',
                              fontSize: '15px',
                              color: '#4a4a4a',
                            }}
                          >
                            <button
                              onClick={() => {
                                getData(item.SECTION_NAME);
                                setListSecname(item.SECTION_NAME);
                              }}
                              style={{
                                width: `calc(100% - 20px)`,
                              }}
                              className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                            >
                              {item.SECTION_NAME}
                            </button>
                            <Fade
                              orientation="horizontal"
                              in={item.SECTION_NAME === ListsectionName}
                            >
                              <button
                                style={{
                                  paddingRight: '5px',
                                  paddingLeft: '5px',
                                }}
                                className="bg-green h-full"
                                onClick={() => {
                                  getData('');
                                  setListSecname('');
                                }}
                              >
                                <Close
                                  color="white"
                                  fontSize={'small'}
                                  sx={{
                                    fontSize: '15px',
                                    color: 'white',
                                  }}
                                />
                              </button>
                            </Fade>
                          </div>
                        </>
                      ))
                    : [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                      ].map(() => {
                        return (
                          <Skeleton
                            variant="rectangular"
                            width={136}
                            animation="wave"
                            height={70}
                            sx={{ fontSize: '1rem', borderRadius: '7px' }}
                          />
                        );
                      })}
                </div>
              </div>
              <div
                style={{
                  height: `700px`,

                  width:
                    windowWidth < 764
                      ? '380px'
                      : windowWidth < 1024
                      ? '680px'
                      : '800px',
                }}
                className="   bg-white overflow-y-scroll  "
              >
                <div className="grid px-2  gap-2 grid-cols-2 t:grid-cols-3 l:grid-cols-4 ">
                  {itemList[0]
                    ? itemList.map((i, index) => {
                        return (
                          <ItemBox2
                            key={index}
                            item={i}
                            index={index}
                            itemNameStyle={{
                              width: '100%',

                              height: '70px',
                              textTransform: 'none',
                              backgroundColor: '#fadb3f',
                              borderRadius: '7px',
                              color: '#4a4a4a',
                              padding: '0px',
                              fontSize: '12px',
                            }}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ItemMasterHelp
        filterBy={filterBy}
        onRowClick={(p) => {
          if (!filterBy) {
            const icode = p.row.icode;
            setId(p.row.icode);
            setItemName(p.row.item_name);
            setDescription(p.row.item_description);
            setSectionName(p.row.section_name);
            setRate(p.row.rate);
            setStop(p.row.stop_yn);
            setUpperLower(p.row.upper_lower);
            setSewingHsnCode(p.row.sewing_hsn_code);
            setCuttingHsnCode(p.row.cutting_hsn_code);
            setSewingTax1(p.row.sewing_tax1_rate);
            setSewingTax2(p.row.sewing_tax2_rate);
            setSewingTax3(p.row.sewing_tax3_rate);
            setCuttingTax1(p.row.cuting_tax1_rate);
            setCuttingTax2(p.row.cuting_tax2_rate);
            setCuttingTax3(p.row.cuting_tax3_rate);
            setCategory(p.row.category);
            setRegional(p.row.regional_iname);
            setLinkCode(p.row.lower_icode);
            const lang = detectLanguage(p.row.regional_iname);
            setSelectedLanguage(lang);
            const underscoreIndex = prefix.indexOf('_');
            const result = prefix.slice(0, underscoreIndex) + '_';

            const d = `seam/items/${result + icode}`;
            DownloadFile(d)
              .then((downloadFile) => {
                if (!downloadFile.includes('FirebaseError')) {
                  setFirebaseImg(downloadFile);
                  setpath(d);
                } else {
                  setpath('');

                  setFirebaseImg('');
                }
              })
              .catch((error) => {
                setFirebaseImg('');
                console.log(error);
              });
          } else {
            const icode = p.row.icode;
            setLinkCode(icode);
          }

          dispatch(setItemMasterHelpStatus(false));
        }}
      />
      <SectionMasterHelp
        onRowClick={(p) => {
          setSectionName(p.row.SECTION_NAME);
          dispatch(setSectionMasterHelpStatus(false));
        }}
      />
      <HsnCodeHelp
        onRowClick={(p) => {
          setSewingHsnCode(p.row.HSN_CODE);
          setCuttingHsnCode(p.row.HSN_CODE);
          setSewingTax3(p.row.GST);
          setCuttingTax3(p.row.GST);
          setSewingTax1(p.row.GST / 2);
          setCuttingTax1(p.row.GST / 2);
          setSewingTax2(p.row.GST / 2);
          setCuttingTax2(p.row.GST / 2);
          dispatch(setHsnCodeHelpStatus(false));
        }}
      />
    </div>
  );
};

export default ResponsiveMaster;
