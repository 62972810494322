import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toProperCase } from '../utility/component/format';
import MasterHelp from '../utility/component/modals/masterHelp';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import {
  SelectModWorkerMasterHelpStatus,
  setModWorkerMasterHelpStatus,
  setSessionExpired,
} from '../utility/Redux/modal';
import axios from 'axios';

const EmployeeMasterHelp = ({ onRowClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const dispatch = useDispatch();
  const width = useSelector(selectWidth);
  const token = useSelector(selectToken);

  const status = useSelector(SelectModWorkerMasterHelpStatus);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'code',
      renderHeader: (params) => <div className="text-[15px]">Code</div>,
    },
    {
      field: 'employee_name',
      renderHeader: (params) => <div className="text-[15px]">Worker Name</div>,
      width:
        width <= 768 ? 90 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'address',
      renderHeader: (params) => <div className="text-[15px]">Address</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 280,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'mobile',
      renderHeader: (params) => <div className="text-[15px]">Mobile</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [loading, setloading] = useState(true);

  const masterprefix = useSelector(selectTableMasterPrefix);
  // const prefix = useSelector(selectPrefix);
  const getData = async () => {
    // try {
    await axios
      .post(
        '/api/masters/listEmployeeMaster',
        {
          table_prefix_master: masterprefix,
          MOBILE: '',
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        setRows(a);
        setFullList(a);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.code.toString().includes(searchQuery);
        } else {
          return item.employee_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const handleClose = () => {
    dispatch(setModWorkerMasterHelpStatus(false));
    setSearchQuery('');
  };

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            handleClose();
            setRows([]);
          }}
          title={'Employee Master | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.code}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default EmployeeMasterHelp;
