import React, { useEffect } from 'react';
import { useState } from 'react';

import { useRef } from 'react';

import uniqid from 'uniqid';

import CompanyInfo from './compyINFO';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import {
  selectCompanyAddress,
  selectPrefix,
  selectUserEmail,
} from '../utility/Redux/profile';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import {
  DownloadFile,
  UploadFile,
} from '../utility/component/functions/firebase';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;

const rowHeaderWidths = window.innerWidth <= 1280 ? widths * 250 : widths * 80;
const profilePicWidths = window.innerWidth <= 1024 ? widths * 80 : widths * 10;

const CompanyMaster = () => {
  const [rowHeaderWidth, setRowHeaderWidth] = useState(rowHeaderWidths);
  const [selectedFile, setSelectedFile] = useState('');
  const [webcamStatus, setWebcamStatus] = useState('');
  const [profilePicWidth, setProfilePicWidth] = useState(profilePicWidths);
  const [firebaseUrl, setFirebaseUrl] = useState('');
  const webcamRef = useRef();
  const userEmail = useSelector(selectUserEmail);
  const [capturedImage, setCapturedImage] = useState();
  const prefix = useSelector(selectPrefix);
  const [companyInfo, setCompanyInfo] = useState({
    user_email: '',
    user_password: '',
    user_name: '',
    user_type: '',
    user_status: '',
    company_email: '',
    company_name: '',
    company_mobile: '',
    company_website: '',
    company_logo: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    currency_name: '',
    currency_symbol: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    disputes: '',
  });
  const dispatch = useDispatch();
  const minProfileicWidth =
    window.innerWidth < 768
      ? '120px'
      : window.innerWidth <= 1024
      ? '120px'
      : '250px';

  const handleDrop = (acceptedFiles) => {
    const editedFiles = acceptedFiles.map((file) => {
      const editedName = ` ${uniqid()}-${file.name}`;
      return new File([file], editedName, { type: file.type });
    });

    setSelectedFile(editedFiles[0]);
  };

  const companyDetails = useSelector(selectCompanyAddress);
  const CompanyUserInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const email = companyDetails.email;
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: companyDetails.email,
          // user_email: userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        setCompanyInfo(response.data[0]);
        const b = `seam/companyProfile/${
          prefix + response.data[0].company_email
        }`;
        DownloadFile(b)
          .then((e) => {
            if (!e.includes('FirebaseError')) {
              setSelectedFile('');
              setCapturedImage('');
              setFirebaseUrl(e);
            } else {
              setFirebaseUrl('');
              setSelectedFile('');
              setCapturedImage('');
            }
          })
          .catch(() => {
            setFirebaseUrl('');
            if (typeof setSelectedFile === 'function') {
              setSelectedFile('');
              setCapturedImage('');
            }
          });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    CompanyUserInfo();
  }, []);

  const UpdateCompanyDetailsAPI = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = companyInfo.user_email;
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    const data = {
      user_email: userEmail,
      user_password: companyInfo.user_password,
      user_name: companyInfo.user_name,
      user_type: 'MANAGER',
      user_status: 'ACTIVE',
      company_email: companyInfo.company_email,
      company_mobile: companyInfo.company_mobile,
      company_website: companyInfo.company_website,
      company_logo: '',
      address: companyInfo.address,
      // table_prefix: 'go_',
      table_prefix: companyInfo.table_prefix,
      city: companyInfo.city,
      state: companyInfo.state,
      country: companyInfo.country,
      pin_code: companyInfo.pin_code,
      currency_name: companyInfo.currency_name,
      currency_symbol: companyInfo.currency_symbol,
      regd_no1: companyInfo.regd_no1,
      regd_no2: companyInfo.regd_no2,
      regd_no3: companyInfo.regd_no3,
      disputes: companyInfo.disputes,
    };

    axios
      .post(
        `/api/users/updateCompanyInfo`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function async(response) {
        if (response.status === 200) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: response.data.message,
            })
          );
          if (capturedImage || selectedFile) {
            const name = `${prefix + userEmail}`;
            const path = 'seam/companyProfile/';
            const camera = capturedImage
              ? await fetch(capturedImage).then((response) => response.blob())
              : '';
            const file = selectedFile ? selectedFile : camera;
            UploadFile(file, path, name);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        undo();
      });
  };

  const undo = () => {
    setCompanyInfo({
      user_email: '',
      user_password: '',
      user_name: '',
      user_type: '',
      user_status: '',
      company_email: '',
      company_name: '',
      company_mobile: '',
      company_website: '',
      company_logo: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pin_code: '',
      currency_name: '',
      currency_symbol: '',
      regd_no1: '',
      regd_no2: '',
      regd_no3: '',
      disputes: '',
    });
    setFirebaseUrl('');
    setCapturedImage('');
    setSelectedFile('');
  };

  const handleResize = () => {
    setRowHeaderWidth(window.innerWidth <= 1280 ? widths * 250 : widths * 100);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once

  return (
    <div className="w-full h-full flex flex-col text-md  overflow-y-scroll">
      <div className="h-full  overflow-y-scroll">
        <CompanyInfo
          rowHeaderWidth={rowHeaderWidth}
          minProfileicWidth={minProfileicWidth}
          customerFirebasePic={firebaseUrl}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          webcamStatus={webcamStatus}
          setWebcamStatus={setWebcamStatus}
          profilePicWidth={profilePicWidth}
          webcamRef={webcamRef}
          capturedImage={capturedImage}
          setCapturedImage={setCapturedImage}
          handleDrop={handleDrop}
          companyInfo={companyInfo}
          firebaseUrl={firebaseUrl}
          setCompanyInfo={setCompanyInfo}
          UpdateCompanyDetailsAPI={UpdateCompanyDetailsAPI}
        />
      </div>
    </div>
  );
};

export default CompanyMaster;
