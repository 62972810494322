import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BillCart from './billCart';
import BillSettelment from './billSettelment';
import { selectModifyBillingCartlist } from '../utility/Redux/billing';
import BillhistoryModal from '../utility/component/modals/printMod';
import ModSaveVoucher from '../utility/component/modals/voucherSaveMod';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;
const rowHeaderWidths = window.innerWidth <= 1280 ? widths * 250 : widths * 200;
const Billing = ({
  SetTabScreen,
  setMrcNumber,
  setBookingDate,
  bookingCustomerDetails,
  setBookingCustomerDetails,
  setCustomerHistory,
  mrcNumber,
  orderID,
  ICODE,
  state,
  bookingOrderNumber,
  setBookingOrderNumber,
}) => {
  // ...........................Use State...................................................
  const [rowHeaderWidth, setRowHeaderWidth] = useState(rowHeaderWidths);
  const [voucherModStatus, setVoucherModStatus] = useState(false);
  console.log(bookingOrderNumber);
  // .......................................Redux.............................................................

  const modifyBillingList = useSelector(selectModifyBillingCartlist);

  // .......................................Use Effect...........................................................
  const dispatch = useDispatch();
  const prifix = useSelector(selectPrefix);
  const masterprefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // .................................... function ...........................................................

  const handleResize = () => {
    setRowHeaderWidth(
      window.innerWidth <= 1280
        ? ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 250
        : ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 200
    );
  };

  const { mobile, customer_name, company_name, regd_no1, email } =
    bookingCustomerDetails;

  return (
    <div className="w-full h-full flex flex-col bg-white text-md text-gray-800">
      <div className="h-fit flex  p-1 ">
        {/* ...............................................customer info.................................................................................. */}
        <div className="w-[60%]  h-full ">
          <div className="flex  ">
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderBottom: '0px solid #b5b3b3',

                width: rowHeaderWidth,
              }}
              className=" flex rounded-tl-sm text-orange  justify-Start p-1 l:p-1 d:p-2 pl-3 items-center"
            >
              Customer Name
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderBottom: '0px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-tr-sm pl-3 items-center text-red font-semibold grow p-1"
            >
              {customer_name}
            </div>
          </div>

          <div className="flex  ">
            <div
              //   onClick={() => {
              //     dispatch(setCustomerMasterHelpModStatus(true));
              //   }}
              style={{
                border: '1px solid #b5b3b3',
                borderTop: '1px dotted #b5b3b3',
                width: rowHeaderWidth,
              }}
              className=" flex text-orange rounded-bl-sm  justify-Start p-1 l:p-1 d:p-2 pl-3 items-center"
            >
              Customer Mobile
              <i
                style={{
                  fontSize: '11px',
                }}
                className="bi text-red font-semibold ml-2  mr-1 items-center bi-search flex "
              ></i>
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex  pl-3 rounded-br-sm justify-between text-red font-semibold  grow items-center "
            >
              <div
                style={{
                  borderRight: '1px dotted #b5b3b3',
                }}
                className={' h-full flex items-center w-1/3 '}
              >
                {mobile}
              </div>

              <div className=" flex grow h-full items-center">
                <div
                  style={{
                    borderRight: '1px dotted #b5b3b3',
                  }}
                  className=" justify-start  flex text-red px-3 font-normal items-center   h-full pl-3"
                >
                  Prev. Balance
                </div>

                <div className="flex p-1 w-24 grow justify-end font-semibold pr-3">
                  {' '}
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full mt-2">
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="w-2/3 items-center  rounded-sm  flex"
            >
              <div className="w-1/2 p-1 l:p-1 d:p-2 pl-3 flex">
                <div className="text-gray-700">Bill No. :</div>
                <div className="pl-2 ">
                  {' '}
                  {modifyBillingList[0] ? modifyBillingList[0].INVNO : null}
                </div>
              </div>
            </div>

            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex pl-3 grow p-1 l:p-1 d:p-2  ml-2 rounded-sm  justify-start"
            >
              <div>
                <label className="  text-gray-700">Date :</label>
                <label className="text-gray-900 pl-2">
                  {moment().format('YYYY-MM-DD')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex grow ml-2  ">
          <div className="w-full ">
            <div className="flex  rounded-sm mb-2 ">
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderRight: '1px solid #b5b3b3',

                  overflow: 'hidden',
                }}
                className=" pl-3 p-1 l:p-1 d:p-2  w-28 l:w-24 rounded-tl-sm rounded-bl-sm   "
              >
                Company
              </div>
              <div
                style={{
                  border: '1px dotted #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                }}
                className="flex pl-2 rounded-tr-sm rounded-br-sm grow items-center "
              >
                {company_name}
              </div>
            </div>

            <div className="flex mt-2 rounded-sm ">
              <div className="w-full ">
                {' '}
                <div className="flex  rounded-sm ">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',
                      borderBottom: '0px solid #b5b3b3',
                      overflow: 'hidden',
                    }}
                    className=" pl-3 p-1 l:p-1 d:p-2 w-28  l:w-24 rounded-tl-sm    "
                  >
                    GST No.
                  </div>
                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px solid #b5b3b3',
                      borderBottom: '0px solid #b5b3b3',
                    }}
                    className="flex pl-2  grow items-center rounded-tr-sm "
                  >
                    {regd_no1}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full ">
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderTop: '1px dotted #b5b3b3',
                  borderRight: '1px solid #b5b3b3',

                  overflow: 'hidden',
                }}
                className=" pl-3 p-1 l:p-1 d:p-2 w-28  l:w-24  rounded-bl-sm   "
              >
                Email
              </div>
              <div
                style={{
                  border: '1px dotted #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                }}
                className="flex pl-2  rounded-br-sm grow items-center "
              >
                {email}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col grow  mt-2  ">
        <div className="flex h-full ">
          <div className="flex flex-col grow b">
            <BillCart
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
              setVoucherModStatus={setVoucherModStatus}
              mrcNumber={mrcNumber}
            />
          </div>

          <div className="flex grow ml-2 ">
            <BillSettelment
              orderID={orderID}
              setCustomerHistory={setCustomerHistory}
              mrcNumber={mrcNumber}
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
              SetTabScreen={SetTabScreen}
              setMrcNumber={setMrcNumber}
              setBookingDate={setBookingDate}
              setBookingOrderNumber={setBookingOrderNumber}
              bookingOrderNumber={bookingOrderNumber}
            />
          </div>
        </div>
      </div>

      <ModSaveVoucher
        bookingCustomerDetails={bookingCustomerDetails}
        status={voucherModStatus}
        setStatus={setVoucherModStatus}
        type={'advance'}
      />
    </div>
  );
};

export default Billing;
