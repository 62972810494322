import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import { BarLoader } from 'react-spinners';
import {
  selectCustomerMasterModStatus,
  selectStatus,
  setCustomerMasterModStatus,
} from '../../Redux/modal';
import CustomerMaster from '../../../masters/customerMaster';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  height: '85%',

  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '95%',
  boxShadow: 24,
};

export default function CustomerMasterModal({ paymode }, { data }) {
  const dispatch = useDispatch();

  const ModalStatus = useSelector(selectCustomerMasterModStatus);
  const loading = useSelector(selectStatus);

  const handleClose = () => {
    dispatch(setCustomerMasterModStatus(false));
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <= 1280 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-[#383535] h-full ">
            <div className="flex w-full bg-brown items-center">
              <img
                src={require('../../../utility/images/logo3.png')}
                className="w-36 md:w-48 h-auto"
              />
              <div
                style={{ width: '1px', height: 50 }}
                className=" bg-orange"
              />
              <div className="flex  grow justify-between items-center text-white bg-brown px-3 font-medium ">
                Customer Master
                <CancelPresentationIcon
                  onClick={handleClose}
                  sx={{
                    color: 'orangered',
                  }}
                  fontSize="medium"
                />
              </div>
            </div>

            <div className="flex-grow h-full bg-white    border-brown">
              <div className="h-0.5 ">
                <BarLoader
                  color={'#F5874F'}
                  loading={loading}
                  cssOverride={{
                    top: 0,
                    width: 'auto',
                  }}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
              </div>
              <CustomerMaster />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
