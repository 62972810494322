import React from 'react';
import './diognalCss.css';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import Webcam from 'react-webcam';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAlertWithTitle } from '../../Redux/modal';

const ProfulePic2 = ({
  selectedFile,
  profilePicWidth,
  setSelectedFile,
  webcamStatus,
  setWebcamStatus,
  capturedImage,
  setCapturedImage,
  webcamRef,
  bookingCustomerDetails,
  firebaseUrl,
}) => {
  const dispatch = useDispatch();
  const handleDrop = (acceptedFiles) => {
    if (bookingCustomerDetails?.mobile) {
      const editedFiles = acceptedFiles.map((file) => {
        const editedName = ` ${uniqid()}-${file.name}`;
        return new File([file], editedName, { type: file.type });
      });

      setSelectedFile(editedFiles[0]);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Customer Mobile!',
          msg: 'Please select Customer Mobile to continue.',
        })
      );
    }

    // dispatch(setSelectedPhoto(acceptedFiles[0]));
  };
  const shootImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage(imageSrc);
    setWebcamStatus(false);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className="diagonal2-container   rounded-sm"
    >
      {webcamStatus ? (
        <div className="h-full w-full flex flex-col justify-between">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#fa8572',
              zIndex: '50',
              fontSize: '19px',
              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />

          <Webcam
            audio={false}
            ref={webcamRef}
            style={{
              width: '100%', // Set your desired width
              height: 'auto',
            }}
            // height={profilePicWidth - 50}
            mirrored={true} // Depending on your preference
          />

          <Button
            // ref={updateRef}
            onClick={() => {
              shootImage();
            }}
            variant="contained"
            size="small"
            style={{
              textTransform: 'none',
              letterSpacing: '1px',
              backgroundColor: '#ebd9dd',
              width: '100%',

              fontWeight: '500',

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="text-black  flex flex-col  ">Save Image </div>
          </Button>
        </div>
      ) : capturedImage ? (
        <div className="h-full w-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          >
            <Close
              onClick={() => {
                setCapturedImage(null);
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#fa8572',
                zIndex: '50',
                fontSize: '19px',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
              }}
            />
          </Button>

          <img
            src={capturedImage}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : selectedFile ? (
        <div className="h-full w-full">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#fa8572',
              zIndex: '50',
              fontSize: '19px',
              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <img
                {...getRootProps()}
                src={URL.createObjectURL(selectedFile)}
                alt={`Selected`}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            )}
          </Dropzone>
        </div>
      ) : bookingCustomerDetails?.mobile ? (
        firebaseUrl ? (
          <div className="h-full w-full  ">
            <Button
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
              }}
            >
              <Close
                onClick={() => {
                  setCapturedImage(null);
                }}
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  color: '#fa8572',
                  zIndex: '50',
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '7px',
                  border: '1px dotted gray',
                  fontSize: '19px',
                }}
              />
            </Button>

            <img
              src={firebaseUrl}
              alt={`Selected`}
              style={{
                width: 'auto',
                height: '100%',
              }}
            />
          </div>
        ) : (
          <>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <div
                    {...getRootProps()}
                    style={{
                      right: 0,
                      left: 0,
                      zIndex: 100,
                    }}
                    className={
                      'flex justify-center items-center absolute h-full  '
                    }
                  >
                    <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
                  </div>
                  <div className="diagonal2-1 w-full h-full "></div>

                  <div className="diagonal2-2 "></div>
                </>
              )}
            </Dropzone>
          </>
        )
      ) : (
        <>
          <div
            style={{
              right: 0,
              left: 0,
              zIndex: 100,
            }}
            onClick={() => {
              dispatch(
                setAlertWithTitle({
                  title: 'Customer Mobile!',
                  msg: 'Please select Customer Mobile to continue.',
                })
              );
            }}
            className={'flex justify-center items-center absolute h-full  '}
          >
            <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
          </div>
          <div className="diagonal2-1 w-full h-full "></div>

          <div className="diagonal2-2 "></div>
        </>
      )}
    </div>
  );
};

export default ProfulePic2;
