import React from 'react';
import upper from '../utility/images/1.png';
import lower from '../utility/images/3.png';
import TableGenius from '../utility/component/reactTable';
import { useSelector } from 'react-redux';
import {
  selectBookingNumber,
  selectHistoryOrderDetails,
  selectLowerDate,
  selectModyingBookingID,
  selectUpperDate,
} from '../utility/Redux/booking';
import moment from 'moment';
import './measurmentPrint.css';

const MeasurmentSlip = React.forwardRef((props, ref) => {
  const {
    upperData,
    lowerData,
    bookingCustomerDetails,
    lowerRemarks,
    selectedLowerItemDetails,
    selectedUpperItemDetails,
    upperRemarks,
    bookingOrderNumber,
    mobile,
    customer_name,
    date,
  } = props;

  const upperDate = useSelector(selectUpperDate);
  const lowerDate = useSelector(selectLowerDate);
  const StyleColums = [
    {
      field: 'PARAMETER',
      type: 'string',
      accessor: 'PARAMETER',
      align: 'left',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="text-white"> {'Parameters'} </div>
      ),
    },
    {
      field: 'size',
      type: 'string',
      flex: 1,
      accessor: 'size',
      align: 'center',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Size'} </div>,
    },
  ];

  const isAllEmptyUpper = upperData.filter((u) => u.size !== '');
  const isAllEmptyLower = lowerData.filter((u) => u.size !== '');

  return (
    <div
      id="print-measurementSlip"
      ref={ref}
      className="h-full w-full flex p-5 text-md "
      style={{ fontSize: '15px' }}
    >
      {isAllEmptyUpper[0] ? (
        <div className="w-1/2 h-full flex flex-col bg-white ">
          <div
            style={{
              border: '1px solid black',
            }}
            className="rounded-t-md bg-white"
          >
            <div
              style={{
                borderBottom: '1px solid black',
              }}
              className="font-semibold text-center p-1"
            >
              Measurement Slip Upper
            </div>

            <div className="flex">
              <div
                style={{
                  borderRight: '1px solid black',
                }}
                className="w-1/2 p-1 flex"
              >
                Order No. : <div className="pl-1 "> {bookingOrderNumber}</div>
              </div>
              <div className="w-1/2 p-1 flex">
                Date :
                <div className="pl-1"> {moment(date).format('DD-MM-YYYY')}</div>{' '}
              </div>
            </div>

            <div
              style={{
                borderTop: '1px solid black',
              }}
              className="text-center p-1 "
            >
              {customer_name} -{`[${mobile}]`}
            </div>
          </div>
          <div
            className="mt-1 w-full flex p-1 bg-white "
            style={{
              border: '1px dotted black ',
            }}
          >
            <div className="flex grow justify-center  font-semibold">
              {/* {upperData[0] ? (
                <div>
                  {selectedUpperItemDetails.item_name}-{' '}
                  {selectedUpperItemDetails.regional_iname}
                </div>
              ) : (
                'UPPER'
              )} */}

              {upperData[0] ? (
                <div>
                  {selectedUpperItemDetails.item_name &&
                  selectedUpperItemDetails.regional_iname
                    ? `${selectedUpperItemDetails.item_name} - (${selectedUpperItemDetails.regional_iname})`
                    : selectedUpperItemDetails.item_name}
                </div>
              ) : (
                'UPPER'
              )}
            </div>
            <div>
              <img
                src={upper}
                style={{
                  height: 25,
                  width: 25,
                }}
              />
            </div>
          </div>

          <div
            style={{
              border: '1px solid #e8e2db',
              borderBottom: '1px solid #e8e2db',
            }}
            className="flex-col  grow mt-1   overflow-hidden bg-white"
          >
            <TableGenius columns={StyleColums} data={isAllEmptyUpper} />

            {/* <div className="w-full"></div> */}
          </div>
          <div className="flex mt-2">
            <div
              style={{
                border: '1px solid black',
              }}
              className=" bg-white  w-1/2 p-1 flex"
            >
              Try Date :{' '}
              <div className="pl-1">
                {upperDate.try && moment(upperDate.try).format('DD-MM-YYYY')}
              </div>
            </div>
            <div
              style={{
                border: '1px solid black',
              }}
              className=" bg-white  w-1/2 p-1 ml-1 flex"
            >
              Del Date :
              <div className="pl-1">
                {' '}
                {moment(upperDate.del).format('DD-MM-YYYY')}
              </div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid black',
            }}
            className=" bg-white rounded-b-md w-full mt-1 p-1 flex"
          >
            Remarks :<div className="pl-1">{upperRemarks}</div>
          </div>
        </div>
      ) : null}

      {isAllEmptyLower.length !== 0 ? (
        <div className="w-1/2 h-full flex flex-col ml-5 bg-white">
          <div
            style={{
              border: '1px solid black',
            }}
            className="rounded-t-md"
          >
            <div
              style={{
                borderBottom: '1px solid black',
              }}
              className="font-semibold text-center p-1"
            >
              Measurement Slip Lower
            </div>

            <div className="flex ">
              <div
                style={{
                  borderRight: '1px solid black',
                }}
                className="w-1/2 p-1 flex "
              >
                Order No. : <div className="pl-1"> {bookingOrderNumber}</div>
              </div>
              <div className="w-1/2 p-1 flex">
                Date : <div className="pl-1"> {date}</div>
              </div>
            </div>

            <div
              style={{
                borderTop: '1px solid black',
              }}
              className="text-center p-1 "
            >
              {customer_name} -{`[${mobile}]`}
            </div>
          </div>
          <div
            className="mt-1 w-full flex p-1  "
            style={{
              border: '1px dotted black',
            }}
          >
            <div className="flex grow  justify-center font-semibold">
              {/* {lowerData[0] ? <div> {selectedLowerItemDetails.item_name} - {selectedLowerItemDetails.regional_iname}</div> : 'LOWER'} */}

              {lowerData[0] ? (
                <div>
                  {selectedLowerItemDetails.item_name &&
                  selectedLowerItemDetails.regional_iname
                    ? `${selectedLowerItemDetails.item_name} - (${selectedLowerItemDetails.regional_iname})`
                    : selectedLowerItemDetails.item_name}
                </div>
              ) : (
                'LOWER'
              )}
            </div>
            <div>
              <img
                src={lower}
                style={{
                  height: 25,
                  width: 25,
                }}
              />
            </div>
          </div>

          <div
            style={{
              border: '1px solid #e8e2db',
              borderBottom: '1px solid #e8e2db',
            }}
            className="flex-col  grow  mt-1  overflow-hidden"
          >
            <TableGenius columns={StyleColums} data={isAllEmptyLower} />

            {/* <div className="w-full"></div> */}
          </div>
          <div className="flex mt-2">
            <div
              style={{
                border: '1px solid black',
              }}
              className=" w-1/2 p-1 flex"
            >
              Try Date :{' '}
              <div className="pl-1">
                {' '}
                {lowerDate.try && moment(lowerDate.try).format('DD-MM-YYYY')}
              </div>
            </div>
            <div
              style={{
                border: '1px solid black',
              }}
              className=" w-1/2 p-1 ml-1 flex"
            >
              Del Date :
              <div className="pl-1">
                {' '}
                {moment(lowerDate.del).format('DD-MM-YYYY')}
              </div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid black',
            }}
            className="rounded-b-md w-full mt-1 p-1 flex"
          >
            Remarks :<div className="pl-1"> {lowerRemarks}</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
});

export default MeasurmentSlip;
