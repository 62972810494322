import React from 'react';
import Modal from '@mui/material/Modal';

import logo from '../../images/ico.png';
import { Alert1Button, Alert2Button } from '../buttons';
import { Button } from '@mui/material';

export default function AlertModalTwoButton({
  details,
  setAlertTwoButtonModal,
  ExtraFeature,
}) {
  const handleClose = () =>
    setAlertTwoButtonModal({
      title: '',
      msg: '',
      status: false,
      setStatus: '',
      button1: '',
      button1Click: '',
      button2: '',
      button2Click: '',
    });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    minWidth: '280px',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  return (
    <div>
      <Modal open={details?.status}>
        <div style={style} className=" w-full ">
          {details?.horizontal ? (
            <div className="min-w-[250px] max-w-[410px]">
              <div className="bg-white  p-3 rounded-t-sm">
                <div className="flex">
                  <div className="h-8 rounded-[64px] w-8  flex items-center justify-center">
                    {details?.leftIcon ? (
                      <img
                        src={details.leftIcon}
                        style={{
                          height: '100%',
                        }}
                      />
                    ) : (
                      <i className="bi  text-[#f87f7f] text-[28px] justify-center itlgems-center flex bi-exclamation-circle-fill"></i>
                    )}
                    {/* <ReportProblemOutlined  /> */}
                  </div>

                  <div className="ml-3">
                    <div className="font-semibold text-[16px]">
                      {details.title}
                    </div>
                    <div className=" text-[14px]  text-[#645f5f] mt-1 ">
                      {details.msg}
                    </div>
                    <div>{ExtraFeature}</div>
                  </div>
                  {details?.cancelAble && (
                    <div className="flex-1 justify-end -mt-7 rounded-tr-sm overflow-hidden  -mr-4 flex ">
                      <button
                        onClick={
                          details?.onCancel
                            ? details?.onCancel
                            : () => {
                                setAlertTwoButtonModal((e) => {
                                  return {
                                    ...e,
                                    status: false,
                                  };
                                });
                              }
                        }
                        className="rounded-tr-sm"
                      >
                        <div className=" p-2 h-fit rounded-tr-sm">
                          <i className="bi font-semibold  text-black text-[12px]  items-center  bi-x-lg flex "></i>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end p-2 bg-[#f2f4f7] rounded-b-sm">
                <div className="w-full flex justify-end mt-1">
                  <Button
                    // ref={updateRef}
                    onClick={details?.button1Click}
                    variant="contained"
                    style={
                      details?.button1Style
                        ? details?.button1Style
                        : {
                            textTransform: 'none',
                            letterSpacing: '1px',
                            backgroundColor: '#383535',
                            marginLeft: 4,
                            color: 'white',
                          }
                    }
                  >
                    <div className=" px-1   ">{details?.button1} </div>
                  </Button>

                  {details?.button2 ? (
                    <Button
                      // ref={updateRef}
                      onClick={
                        details?.button2Click
                          ? details?.button2Click
                          : handleClose
                      }
                      variant="contained"
                      style={
                        details?.button2Style
                          ? details?.button2Style
                          : {
                              textTransform: 'none',
                              letterSpacing: '1px',
                              backgroundColor: '#383535',
                              marginLeft: 4,
                              paddingLeft: 0,
                              paddingRight: 0,
                              color: 'white',
                            }
                      }
                    >
                      <div className=" px-4   ">
                        {details?.button2 ? details?.button2 : 'cancel'}{' '}
                      </div>
                    </Button>
                  ) : null}
                  {details?.button3 ? (
                    <Button
                      // ref={updateRef}
                      onClick={details?.button3Click}
                      variant="contained"
                      style={
                        details?.button3Style
                          ? details?.button3Style
                          : {
                              textTransform: 'none',
                              letterSpacing: '1px',
                              backgroundColor: '#383535',
                              marginLeft: 4,
                              paddingLeft: 0,
                              paddingRight: 0,
                              color: 'white',
                            }
                      }
                    >
                      <div className=" px-4   ">{details?.button3} </div>
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : details?.horizontal2 ? (
            <div className="min-w-[250px] max-w-[410px]">
              <div className="bg-white  p-3 rounded-t-sm">
                <div className=" flex w-1/2 justify-end">
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginTop: -30,
                      marginRight: -22,

                      borderRadius: 100,
                    }}
                    className="bg-white p-[2px]"
                  >
                    <img
                      src={logo}
                      alt=""
                      style={{
                        width: '50px',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>

                {details?.cancelAble && (
                  <div className="flex-1 justify-end -mt-[35px] rounded-tr-sm overflow-hidden  -mr-4 flex ">
                    <button
                      onClick={
                        details?.onCancel
                          ? details?.onCancel
                          : () => {
                              setAlertTwoButtonModal((e) => {
                                return {
                                  ...e,
                                  status: false,
                                };
                              });
                            }
                      }
                      className="rounded-tr-sm"
                    >
                      <div className=" p-2 h-fit rounded-tr-sm">
                        <i className="bi font-semibold  text-black text-[12px]  items-center  bi-x-lg flex "></i>
                      </div>
                    </button>
                  </div>
                )}
                <div className="flex mt-3 flex-1">
                  <div className="flex-1">
                    <div className="font-semibold text-[16px] flex justify-center">
                      {details.title}
                    </div>
                    <div className=" text-[14px]  text-[#645f5f] mt-1 flex justify-center">
                      {details.msg}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end p-2 bg-[#f2f4f7] rounded-b-sm">
                <div className="w-full flex justify-end mt-1">
                  <Button
                    // ref={updateRef}
                    onClick={details?.button1Click}
                    variant="contained"
                    style={
                      details?.button1Style
                        ? details?.button1Style
                        : {
                            textTransform: 'none',
                            letterSpacing: '1px',
                            backgroundColor: '#383535',
                            marginLeft: 4,
                            color: 'white',
                          }
                    }
                  >
                    <div className=" px-1   ">{details?.button1} </div>
                  </Button>

                  {details?.button2 ? (
                    <Button
                      // ref={updateRef}
                      onClick={
                        details?.button2Click
                          ? details?.button2Click
                          : handleClose
                      }
                      variant="contained"
                      style={
                        details?.button2Style
                          ? details?.button2Style
                          : {
                              textTransform: 'none',
                              letterSpacing: '1px',
                              backgroundColor: '#383535',
                              marginLeft: 4,
                              paddingLeft: 0,
                              paddingRight: 0,
                              color: 'white',
                            }
                      }
                    >
                      <div className=" px-4   ">
                        {details?.button2 ? details?.button2 : 'cancel'}{' '}
                      </div>
                    </Button>
                  ) : null}
                  {details?.button3 ? (
                    <Button
                      // ref={updateRef}
                      onClick={details?.button3Click}
                      variant="contained"
                      style={
                        details?.button3Style
                          ? details?.button3Style
                          : {
                              textTransform: 'none',
                              letterSpacing: '1px',
                              backgroundColor: '#383535',
                              marginLeft: 4,
                              paddingLeft: 0,
                              paddingRight: 0,
                              color: 'white',
                            }
                      }
                    >
                      <div className=" px-4   ">{details?.button3} </div>
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className={'h-full w-full bg-white  rounded-sm   p-3'}>
              <div className=" flex  w-full rounded-sm ">
                <div className=" flex w-1/2 justify-end">
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginTop: -30,
                      marginRight: -22,

                      borderRadius: 100,
                    }}
                    className="bg-white p-[2px]"
                  >
                    <img
                      src={logo}
                      alt=""
                      style={{
                        width: '50px',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>

                {details?.cancelAble && (
                  <div className="flex-1 justify-end -mt-7 rounded-tr-sm overflow-hidden  -mr-4 flex ">
                    <button
                      onClick={
                        details?.onCancel
                          ? details?.onCancel
                          : () => {
                              setAlertTwoButtonModal((e) => {
                                return {
                                  ...e,
                                  status: false,
                                };
                              });
                            }
                      }
                      className="rounded-tr-sm"
                    >
                      <div className=" p-2 h-fit rounded-tr-sm">
                        <i className="bi font-semibold  text-black text-[12px]  items-center  bi-x-lg flex "></i>
                      </div>
                    </button>
                  </div>
                )}
              </div>

              <div className="w-full justify-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
                {details?.title}
              </div>
              <div
                style={{
                  fontSize: '13px',
                }}
                className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 "
              >
                {details?.msg}
              </div>
              <div className="w-full">{ExtraFeature}</div>

              <div className="w-full flex justify-center mt-4">
                <Alert1Button
                  text={details?.button1}
                  onClick={details?.button1Click}
                />
                {details?.button2 ? (
                  <Alert2Button
                    text={details?.button2 ? details?.button2 : 'cancel'}
                    onClick={
                      details?.button2Click
                        ? details?.button2Click
                        : handleClose
                    }
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
