import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { selectPrefix, selectUserType } from '../../Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../Redux/security';

import axios from 'axios';
import { FormatNumber2 } from '../tools';
import { CollectionFromToDate } from '../collectionFromToDate';
import { GeniusHeader3 } from '../datagrid.js/header';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setModCustomerMasterModifyStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../../Redux/modal';
import {
  selectBookingCustomerDetails,
  setBookingAttachImage,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../../Redux/booking';
import { VoucherSummaryFooter } from '../datagrid.js/footer';
import { CollectionFromToDateMobile } from '../collectionFromToDateMobile';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from '../SNAA_buttons';
import PrintBillSummary from '../../../summary/printBillSummary';
import CustomerMasterhelp from '../../../help/customermasterHelp';

const width = window.innerWidth / 100;

const VoucherSummary = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const dispatch = useDispatch();
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const [list, setList] = useState([]);
  const [bookingCustomerDetails, setBookingCustomerDetails] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 100000,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: 'Good',
    remarks: '',
  });

  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    setBookingCustomerDetails(params.row);
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  const columns = [
    {
      field: 'VOUCHER_NO',
      accessor: 'VOUCHER_NO',
      type: 'number',
      width: width * 5,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'V No.'}
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return '';
        }
      },

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Date'}
        </div>
      ),
    },
    {
      field: 'CUSTOMER_NAME',
      accessor: 'CUSTOMER_NAME',
      type: 'string',
      width: width * 10,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Customer Name'}
        </div>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Mobile'}
        </div>
      ),
    },

    {
      field: 'AMOUNT_DESC',
      accessor: 'AMOUNT_DESC',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Description'}
        </div>
      ),
    },

    {
      field: 'DEBIT',
      accessor: 'DEBIT',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Debit'}
        </div>
      ),
    },
    {
      field: 'CREDIT',
      accessor: 'CREDIT',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Credit'}
        </div>
      ),
    },

    {
      field: 'PAY_MODE',
      accessor: 'PAY_MODE',
      type: 'string',
      width: width * 8,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Pay Mode'}
        </div>
      ),
    },
    {
      field: 'CARD_NO',
      accessor: 'CARD_NO',
      type: 'string',
      width: width * 8,

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Card No.'}
        </div>
      ),
    },
    {
      field: 'EPAYMENT_BANK',
      accessor: 'EPAYMENT_BANK',
      type: 'string',
      width: width * 10,

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'ePayment Bank'}
        </div>
      ),
    },
  ];

  const columnsTab = [
    {
      field: 'VOUCHER_NO',
      accessor: 'VOUCHER_NO',
      type: 'number',
      width: width * 10,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'V No.'}
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      width: width * 12,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return '';
        }
      },

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Date'}
        </div>
      ),
    },
    {
      field: 'CUSTOMER_NAME',
      accessor: 'CUSTOMER_NAME',
      type: 'string',
      width: width * 20,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Customer Name'}
        </div>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      width: width * 20,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Mobile'}
        </div>
      ),
    },

    {
      field: 'DEBIT',
      accessor: 'DEBIT',
      type: 'string',
      width: width * 10,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Debit'}
        </div>
      ),
    },
    {
      field: 'CREDIT',
      accessor: 'CREDIT',
      type: 'string',
      width: width * 10,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Credit'}
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: 'DATE',
      type: 'string',
      width: width * 21,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('YYYY-MM-DD');
        } else {
          return '';
        }
      },

      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Date'}</div>,
    },
    {
      field: 'CUSTOMER_NAME',
      type: 'string',
      width: width * 30,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Customer Name'}
        </div>
      ),
    },

    {
      field: 'CREDIT',
      type: 'string',
      width: width * 20,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Credit'}
        </div>
      ),
    },

    {
      field: 'PAY_MODE',
      type: 'string',
      width: width * 20,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Pay Mode'}</div>
      ),
    },
  ];
  const loadVocherAPI = (invNo) => {
    const data = {
      table_prefix: prefix,
      FDATE: firstDate,
      TDATE: SecondDate,
      MOBILE: bookingCustomerDetails.mobile,
    };

    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(`/api/vouchers/voucherSummary`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          setList(response.data.response);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const printRef = useRef();
  const navigate = useNavigate();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate('/voucher_summary');
      setSelectedPrintOption('print');
    },
  });

  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadVocherAPI();
            }}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            onOkClick={() => {
              loadVocherAPI();
            }}
            setSecondDate={setSecondDate}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}
      <div className="w-full flex flex-col grow mt-2 max-sm:mt-2">
        <div className="h-[360px] l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 12,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            rowHeight={33}
            headerHeight={0}
            columns={
              window.innerWidth <= 500
                ? columnsMobile
                : window.innerWidth <= 800
                ? columnsTab
                : columns
            }
            rows={list}
            hideFooter
            getRowId={(e) => e.VOUCHER_NO}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => (
                <GeniusHeader3
                  columns={
                    window.innerWidth <= 500
                      ? columnsMobile
                      : window.innerWidth <= 800
                      ? columnsTab
                      : columns
                  }
                />
              ),

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />
        </div>
        <VoucherSummaryFooter
          rows={list}
          columns={window.innerWidth <= 500 ? columnsMobile : columns}
        />
      </div>

      <div className="flex justify-end mt-4">
        <PrintButton onClick={handlePrint} />
      </div>

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setModCustomerMasterModifyStatus(false));
        }}
      />

      <div className="hidden">
        {selectedPrintOption === 'print' && windowSize ? (
          <div
            ref={printRef}
            style={{ display: 'block', visibility: 'hidden' }}
          >
            <PrintBillSummary
              data={list}
              columns={columns}
              firstDate={firstDate}
              SecondDate={SecondDate}
              bookingCustomerDetails={bookingCustomerDetails}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VoucherSummary;
