import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';

import MasterHelp from '../utility/component/modals/masterHelp';
import { getCustomerMasterAPI } from '../booking/list';

const CustomerMasterhelp = ({
  onRowClick,
  rows,
  onSearchMobile,
  onSearchName,
  onSearchAddress,
  onSearchCity,
  onSearchState,
  onSearchCountry,
}) => {
  const enterSearch = true;
  const ignoreOtherFilters = true;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };
  const dispatch = useDispatch();
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const modStatus = useSelector(selectCustomerMasterHelpModStatus);
  const handleClose = () => dispatch(setCustomerMasterHelpModStatus(false));
  //   const [searchText, SetSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterdList, setFilteredList] = useState(rows ? rows : []);
  const [list, setList] = useState(rows ? rows : []);
  const [searchType, setSearchType] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [countrySearch, setCountrySearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [addressSearch, setaddressSearch] = useState('');
  const width = useSelector(selectWidth);

  const columns = [
    {
      field: 'mobile',
      type: 'string',
      width:
        width <= 768
          ? 100
          : width <= 1024
          ? 100
          : width <= 1280
          ? 150
          : width <= 1440
          ? 140
          : 150,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Mobile'}</div>,
    },
    {
      field: 'mobile_2',
      type: 'string',
      width:
        width <= 768
          ? 100
          : width <= 1024
          ? 100
          : width <= 1280
          ? 150
          : width <= 1440
          ? 140
          : 150,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Mobile 2'}</div>,
    },
    {
      field: 'mobile_3',
      type: 'string',
      width:
        width <= 768
          ? 100
          : width <= 1024
          ? 100
          : width <= 1280
          ? 150
          : width <= 1440
          ? 140
          : 150,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Mobile 3'}</div>,
    },
    {
      field: 'prefix',
      type: 'number',
      width: width <= 768 ? 50 : width <= 1024 ? 70 : width <= 1440 ? 70 : 70,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Prefix'}</div>,
    },
    {
      field: 'mrc_no',
      type: 'number',
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 120,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'MRC'}</div>,
    },
    {
      field: 'customer_name',
      type: 'number',
      width:
        width <= 768 ? 90 : width <= 1024 ? 120 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Customer Name'}</div>,
    },
    {
      field: 'address',
      type: 'number',
      width:
        width <= 768 ? 100 : width <= 1024 ? 120 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Address'}</div>,
    },
    {
      field: 'city',
      type: 'number',
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 120,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'City'}</div>,
    },
    {
      field: 'state',
      type: 'number',
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 120,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'State'}</div>,
    },
    {
      field: 'country',
      type: 'number',
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 120,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Country'}</div>,
    },
  ];

  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getCustomermasterList = () => {
    dispatch(setSpinnerLoading('Loading'));

    axios
      .post(
        `/api/masters/listCustomerMaster`,
        {
          table_prefix: prefix,
          MOBILE: '',
          CODE: '',
          CUSTOMER_NAME: '',
          ADDRESS: '',
          CITY: '',
          STATE: '',
          COUNTRY: '',
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          const l = data.map((o, i) => {
            return {
              ...o,
              id: i,
            };
          });
          setFilteredList(l);
          setList(l);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (modStatus && !rows) {
      getCustomermasterList();
    }
  }, [modStatus]);

  const filterByCity = (city, placeHolder) => {
    setCitySearch(city);
    if (onSearchCity) {
      if (placeHolder === 'City') {
        setSearchType('');
        onSearchCity(city);
      }
    } else {
      if (placeHolder === 'City') {
        setSearchType('');
        onSearchCityMaster(city);
      }
    }
  };
  const filterByState = (city, placeHolder) => {
    setStateSearch(city);
    if (onSearchState) {
      if (placeHolder === 'State') {
        setSearchType('');
        onSearchState(city);
      }
    } else {
      if (placeHolder === 'State') {
        setSearchType('');
        onSearchStateMaster(city);
      }
    }
  };
  const filterByCountry = (city, placeHolder) => {
    setCountrySearch(city);
    if (onSearchCountry) {
      if (placeHolder === 'Country') {
        setSearchType('');
        onSearchCountry(city);
      }
    } else {
      if (placeHolder === 'Country') {
        setSearchType('');
        onSearchCountryMaster(city);
      }
    }
  };

  const filterByAddress = (city, placeHolder) => {
    setaddressSearch(city);
    if (onSearchAddress) {
      if (placeHolder === 'Address') {
        setSearchType('');
        onSearchAddress(city);
      }
    } else {
      if (placeHolder === 'Address') {
        setSearchType('');
        onSearchAddressMaster(city);
      }
    }
  };

  const filterByName = (city, placeHolder) => {
    setNameSearch(city);
    if (onSearchName) {
      if (placeHolder === 'Name') {
        setSearchType('');
        onSearchName(city);
      }
    } else {
      if (placeHolder === 'Name') {
        setSearchType('');

        onSearchNameMaster(city);
      }
    }
  };

  const onSearchMobileMaster = async (text) => {
    const data = await getCustomerMasterAPI(prefix, token, dispatch, text, '');
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, text, '');
      setList(res.data.response);
    }
  };

  const onSearchNameMaster = async (text) => {
    const data = await getCustomerMasterAPI(prefix, token, dispatch, '', text);
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, text, '');
      setList(res.data.response);
    }
  };

  const onSearchAddressMaster = async (text) => {
    const data = await getCustomerMasterAPI(
      prefix,
      token,
      dispatch,
      '',
      '',
      text
    );
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, '', '');
      setList(res.data.response);
    }
  };
  const onSearchCityMaster = async (text) => {
    const data = await getCustomerMasterAPI(
      prefix,
      token,
      dispatch,
      '',
      '',
      '',
      text
    );
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, '', '');
      setList(res.data.response);
    }
  };

  const onSearchStateMaster = async (text) => {
    const data = await getCustomerMasterAPI(
      prefix,
      token,
      dispatch,
      '',
      '',
      '',
      '',
      text
    );
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, '', '');
      setList(res.data.response);
    }
  };

  const onSearchCountryMaster = async (text) => {
    const data = await getCustomerMasterAPI(
      prefix,
      token,
      dispatch,
      '',
      '',
      '',
      '',
      '',
      text
    );
    if (text) {
      setList(data.data.response);
    } else {
      const res = await getCustomerMasterAPI(prefix, token, dispatch, '', '');
      setList(res.data.response);
    }
  };
  useEffect(() => {
    const regex = /\d+/;
    if (modStatus) {
      if (onSearchMobile) {
        if (searchType === 'Search') {
          setSearchType('');

          if (regex.test(searchQuery.substring(0, 1))) {
            onSearchMobile(searchQuery);
          } else {
            if (searchType === 'Search') {
              setSearchType('');
              onSearchName(searchQuery);
            }
          }
        }
      } else {
        if (searchQuery) {
          if (regex.test(searchQuery.substring(0, 1))) {
            if (searchType === 'Search') {
              setSearchType('');
              onSearchMobileMaster(searchQuery);
            }
          }
        } else {
          if (searchType === 'Search') {
            setSearchType('');
            onSearchNameMaster(searchQuery);
          }
        }
      }
    }
  }, [searchQuery, searchType]);

  useEffect(() => {
    if (rows) {
      setList(rows);
    }
  }, [rows]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={modStatus}
          style={style}
          onClose={() => {
            handleClose();
            setSearchQuery('');
            setFilteredList([]);
            setSearchType('');
            setCitySearch('');
            setStateSearch('');
            setCountrySearch('');
            setNameSearch('');
            setaddressSearch('');
          }}
          title={'Customer Master | Help'}
          columns={columns}
          list={list}
          rowId={(p) => p.mrc_no + p.prefix + p.mobile + p.customer_name}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          enterSearch={enterSearch}
          extraFilter={
            ignoreOtherFilters
              ? [
                  {
                    placeHolder: 'Name',
                    onChange: filterByName,
                    value: nameSearch,
                    width: columns[5].width,
                    firstWidth:
                      columns[0].width +
                      columns[1].width +
                      columns[2].width +
                      columns[4].width +
                      columns[3].width,
                  },
                  {
                    placeHolder: 'Address',
                    onChange: filterByAddress,
                    value: addressSearch,
                    width: columns[6].width,
                  },
                  {
                    placeHolder: 'City',
                    onChange: filterByCity,
                    value: citySearch,
                    width: columns[7].width,
                  },
                  {
                    placeHolder: 'State',
                    value: stateSearch,
                    onChange: filterByState,
                    width: columns[8].width,
                  },
                  {
                    placeHolder: 'Country',
                    value: countrySearch,
                    width: columns[9].width,
                    onChange: filterByCountry,
                  },
                ]
              : [
                  {
                    placeHolder: 'Name',
                    onChange: filterByName,
                    value: nameSearch,
                    width: columns[5].width,
                    firstWidth:
                      columns[0].width +
                      columns[1].width +
                      columns[2].width +
                      columns[4].width +
                      columns[3].width,
                  },
                  {
                    placeHolder: 'Address',
                    onChange: filterByAddress,
                    value: addressSearch,
                    width: columns[6].width,
                  },
                  {
                    placeHolder: 'City',
                    onChange: filterByCity,
                    value: citySearch,
                    width: columns[7].width,
                  },
                  {
                    placeHolder: 'State',
                    value: stateSearch,
                    onChange: filterByState,
                    width: columns[8].width,
                  },
                  {
                    placeHolder: 'Country',
                    value: countrySearch,
                    width: columns[9].width,
                    onChange: filterByCountry,
                  },
                ]
          }
          tips={'Press enter for search'}
          setSearchType={setSearchType}
          onRowClick={(p) => {
            onRowClick(p);
            setFilteredList([]);
            setSearchType('');
            setCitySearch('');
            setStateSearch('');
            setCountrySearch('');
            setNameSearch('');
            setaddressSearch('');
          }}
        />
      </div>
    </>
  );
};

export default CustomerMasterhelp;
