import React from 'react';
import tailringLogo from '../../images/tailiringLogo.svg';
import tailringX_2 from '../../images/Tailoring_Text.svg';
const TalringLogo = () => {
  return (
    <div className={`${'flex items-center w-100  '} `}>
      <img
        src={tailringLogo}
        alt=""
        style={{
          height: 'auto',
          width: '35px',
        }}
      />
      <div className={`  justify-between h-full  items-center `}>
        <div
          style={{ fontSize: 16 }}
          className={'  flex   items-center h-full   mt-1'}
        >
          <img
            src={tailringX_2}
            style={{
              width: 'auto',
              height: '30px',
            }}
            className="pl-1"
          />
        </div>
      </div>
    </div>
  );
};

export default TalringLogo;
