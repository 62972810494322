import React, { useEffect, useRef } from 'react';
import {
  PrintButton,
  Save_button,
  Undo_button,
} from '../utility/component/buttons';

import { useState } from 'react';

import CustomerInfo from '../utility/component/functions/customerInfo';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { UploadFile } from '../utility/component/functions/firebase';
import { toProperCase } from '../utility/component/format';

import CompanyInfo from '../utility/component/functions/companyInfo';
import { ModifyButton } from '../utility/component/SNAA_buttons';
import { CallAPI } from '../utility/func/func';
import UpdateCustomer from '../utility/component/functions/updateCustomer';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
const rowWidth =
  window.innerWidth <= 1280
    ? ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 210
    : ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 120;

const CustomerMaster = () => {
  const masterprefix = useSelector(selectTableMasterPrefix);
  const [selectedFile, setSelectedFile] = useState('');
  const [prefixList, setCustomerPrrefixList] = useState([]);
  const [mobileAlert, setMobielAlert] = useState('');
  const [path, setPath] = useState('');
  const prefix = useSelector(selectPrefix);
  const [customerMasterListByCode, setCustomerMasterListByCode] = useState([]);

  const rowStyle = {
    border: '0.5px solid #b5b3b3',
    width: rowWidth,

    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
  };

  const rowValueStyle = {
    border: '1px dotted gray',
    borderLeft: '0px dotted gray',
    width: rowWidth,
    padding: '7px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  };
  // const countryState = JSON.parse(localStorage.getItem('country-city-state'));
  const [modifyCustomerDetails, setModifyCustomerDetails] = useState({
    table_prefix_master: masterprefix,
    table_prefix: prefix,
    OLD_MOBILE: '',
    OLD_MOBILE_2: '',
    OLD_MOBILE_3: '',
    MOBILE: '',
    MOBILE_2: '',
    MOBILE_3: '',
    ADDRESS: '',
    NAME: '',
    CITY: '',
    STATE: '',
    COUNTRY: '',
  });
  const [customerInfo, setCustomerInfo] = useState({
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    CusName: '',
    CusStatus: 'NO',
    Email: '',
    Phone: '',
    Address: '',
    City: '',
    State: '',
    Country: '',
    PinCode: '',
    CredLimit: '',
    Disq: '',
    prefix: prefixList[0],
  });

  const [webcamStatus, setWebcamStatus] = useState(false);
  const [modifyMobileAlert, setModifyMobileAlert] = useState('');
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [capturedImage, setCapturedImage] = useState('');

  const [firabseUrl, setFirebaseUrl] = useState('');
  const [customerlist, setCustomerList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({
    compName: '',
    Address: '',
    City: '',
    State: '',
    Country: '',
    Pincode: '',
    PanNo: '',
    Gst: '',
    Regd: '',
    mobile: '',
    code: '',
  });

  const [id, setId] = useState('');

  const webcamRef = useRef();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [tabScreen, setTabScreen] = useState(1);
  // const [modifyCus]
  const profilePicWidth =
    window.innerWidth <= 1024
      ? ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 50
      : ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 80;
  const minProfileicWidth =
    window.innerWidth < 768
      ? '150px'
      : window.innerWidth < 1024
      ? '150px'
      : '190px';

  const saveCustomerInfoAPI = () => {
    const data = {
      table_prefix_master: masterprefix,
      CODE: customerInfo.code,
      CUSTOMER_NAME: toProperCase(customerInfo.CusName),
      MOBILE: customerInfo.mobile.replace(/[ -]/g, ''),
      MOBILE_2: customerInfo.mobile_2.replace(/[ -]/g, ''),
      MOBILE_3: customerInfo.mobile_3.replace(/[ -]/g, ''),
      PHONE: customerInfo.Phone,
      EMAIL: customerInfo.Email,
      ADDRESS: customerInfo.Address,
      CITY: customerInfo.City,
      STATE: customerInfo.State,
      COUNTRY: customerInfo.Country,
      PIN_CODE: customerInfo.PinCode,
      CREDIT_LIMIT: customerInfo.CredLimit ? customerInfo.CredLimit : 0,
      DISCOUNT: customerInfo.Disq ? customerInfo.Disq : 0,
      COMPANY_NAME: companyInfo.compName,
      COMPANY_PHONE: companyInfo.mobile,
      COMPANY_EMAIL: '',
      COMPANY_ADDRESS: companyInfo.Address,
      COMPANY_CITY: companyInfo.City,
      COMPANY_STATE: companyInfo.State,
      COMPANY_COUNTRY: companyInfo.Country,
      COMPANY_PINCODE: companyInfo.Pincode,
      REGD_NO1: companyInfo.PanNo,
      REGD_NO2: companyInfo.Gst,
      REGD_NO3: companyInfo.Regd,
      STOP_YN: customerInfo.CusStatus,
      STATUS: 'Good',
      REMARKS: '',
      MRC_NO: '',
      PREFIX: customerInfo.prefix,
      OLD_MOBILE: customerInfo.mobile,
    };

    axios
      .post(`/api/masters/saveCustomerMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          if (capturedImage || selectedFile) {
            const camera = capturedImage
              ? await fetch(capturedImage).then((response) => response.blob())
              : '';
            const file = selectedFile ? selectedFile : camera;
            const name = `${prefix + customerInfo.mobile}`;
            const path = 'seam/images/';
            UploadFile(file, path, name);
          }
          dispatch(
            setAlertWithTitle({
              title: 'Success.',
              msg: id
                ? 'Data updated successfully.'
                : 'Data saved successfully.',
              lottie: 'success',
            })
          );
          undoData();
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 112',
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      });
  };

  const ModifyMobileAPI = () => {
    dispatch(setSpinnerLoading('updating'));
    const data = {
      table_prefix_master: modifyCustomerDetails.table_prefix_master,
      table_prefix: modifyCustomerDetails.table_prefix,
      OLD_MOBILE: modifyCustomerDetails.OLD_MOBILE,
      OLD_MOBILE_2: modifyCustomerDetails.MOBILE_2,
      OLD_MOBILE_3: modifyCustomerDetails.MOBILE_3,
      MOBILE: modifyCustomerDetails.MOBILE,
      MOBILE_2: modifyCustomerDetails.MOBILE_2,
      MOBILE_3: modifyCustomerDetails.MOBILE_3,
    };
    axios
      .post(`/api/masters/updateCustomerMobile`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        setModifyMobileAlert('');
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: response?.data?.message,
          })
        );
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setModifyCustomerDetails({
          table_prefix_master: masterprefix,
          table_prefix: prefix,
          OLD_MOBILE: '',
          OLD_MOBILE_2: '',
          OLD_MOBILE_3: '',
          MOBILE: '',
          MOBILE_2: '',
          MOBILE_3: '',
          ADDRESS: '',
          NAME: '',
          CITY: '',
          STATE: '',
          COUNTRY: '',
        });
      });
  };

  const SaveData = () => {
    if (tabScreen === 3) {
      if (modifyCustomerDetails.OLD_MOBILE && modifyCustomerDetails.MOBILE) {
        if (modifyMobileAlert) {
          if (modifyMobileAlert !== 'Please enter a valid mobile') {
            setAlertTwoButtonModal({
              title: 'Alert',
              msg: 'Number is already registerd do you want to merge customer mobile',
              status: true,
              setStatus: '',
              button1: 'Yes',
              button1Click: () => {
                setAlertTwoButtonModal((o) => {
                  return {
                    ...o,
                    status: false,
                  };
                });
                ModifyMobileAPI();
              },
              button2: 'Cancel',
              button2Click: () => {
                setAlertTwoButtonModal((o) => {
                  return {
                    ...o,
                    status: false,
                  };
                });
              },
            });
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: modifyMobileAlert,
              })
            );
          }
        } else {
          ModifyMobileAPI();
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Mobile are required to update the customer',
          })
        );
      }
    } else {
      if (!mobileAlert) {
        if (customerInfo.CusName && customerInfo.mobile) {
          if (customerInfo.mobile) {
            let re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (customerInfo.Email && !re.test(customerInfo.Email)) {
              dispatch(
                setAlertWithTitle({
                  title: 'Email Error',
                  msg: 'Wrong email address',
                })
              );
            } else {
              if (customerInfo.prefix) {
                saveCustomerInfoAPI();
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Please select Prefix',
                  })
                );
              }
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Invalid mobile format',
              })
            );
          }
        } else {
          if (tabScreen === 2) {
            dispatch(
              setAlertWithTitle({
                title: 'Customer Information!',
                msg: 'Mobile number and Customer Name are required',
              })
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Customer Information!',
                msg: 'Please fill all details to continue.',
              })
            );
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: mobileAlert,
          })
        );
      }
    }
  };

  const undoData = () => {
    setCompanyInfo({
      compName: '',
      Address: '',
      City: '',
      State: '',
      Country: '',
      Pincode: '',
      PanNo: '',
      Gst: '',
      Regd: '',
      mobile: '',
    });
    setModifyCustomerDetails({
      table_prefix_master: masterprefix,
      table_prefix: prefix,
      OLD_MOBILE: '',
      OLD_MOBILE_2: '',
      OLD_MOBILE_3: '',
      MOBILE: '',
      MOBILE_2: '',
      MOBILE_3: '',
      ADDRESS: '',
      NAME: '',
      CITY: '',
      STATE: '',
      COUNTRY: '',
    });
    setMobielAlert('');
    setModifyMobileAlert('');
    setSelectedFile('');
    setCapturedImage('');
    setFirebaseUrl('');
    setId('');
    setCustomerInfo({
      mobile: '',
      CusName: '',
      CusStatus: 'NO',
      Email: '',
      Phone: '',
      Address: '',
      City: '',
      State: '',
      Country: '',
      PinCode: '',
      CredLimit: '',
      Disq: '',
      code: '',
      prefix: prefixList[0],
      mobile_2: '',
      mobile_3: '',
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the event key is 's' and if the 'Alt' key is pressed
      if (event.key === 's' && event.altKey) {
        // Your save logic here
        SaveData();
        // Prevent the default browser behavior for the key combination
        event.preventDefault();
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [customerInfo]);

  useEffect(() => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPI(
      '/api/masters/listPrefixMaster',
      {
        table_prefix_master: masterprefix,
      },
      token,
      (e) => {
        if (!e.data.error) {
          setCustomerPrrefixList(
            e.data.response.map((e) => {
              return e.PREFIX_NAME;
            })
          );
          setCustomerInfo((p) => {
            return {
              ...p,
              prefix: e.data.response[1],
            };
          });
        }
      }
    );
    dispatch(setSpinnerLoading(false));
  }, []);
  const getCustomerList = () => {
    dispatch(setSpinnerLoading('Loading'));

    axios
      .post(
        `/api/masters/listCustomerMaster`,
        {
          table_prefix: prefix,

          MOBILE: '',
          CODE: '',
          CUSTOMER_NAME: '',
          ADDRESS: '',
          CITY: '',
          STATE: '',
          COUNTRY: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setCustomerList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  // useEffect(() => {
  //   getCustomerList();
  // }, []);
  return (
    <div className="text-md  h-full flex flex-col max-sm:p-2  bg-[#f2f2f2] ">
      <div className="h-full  overflow-y-auto t:p-5 ">
        <div className=" w-full  flex bg-[#f2f2f2]">
          <div
            onClick={() => {
              setTabScreen(1);
            }}
            style={{
              borderLeft:
                tabScreen === 1 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              borderTop:
                tabScreen === 1 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              borderBottom:
                tabScreen === 1 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
              backgroundColor: tabScreen === 1 ? 'white' : '#faf4ed',
              marginTop: tabScreen === 1 ? '0px' : '4px',
              fontWeight: tabScreen === 1 ? 500 : 400,
              borderRight:
                tabScreen === 1 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
            }}
            className="flex-1 p-2   flex justify-center items-center rounded-t-sm "
          >
            <div className="flex items-start ">
              <i
                style={{
                  color: tabScreen === 1 ? '#03a9f4' : 'gray',
                }}
                className={`bi ${
                  tabScreen === 1 ? 'bi bi-person-fill' : 'bi bi-person'
                } text-[16px] pr-3`}
              ></i>
            </div>

            <div
              style={{
                color: tabScreen === 1 ? 'black' : 'gray',
                fontSize: '16px',
              }}
              className=" h-full flex items-center"
            >
              Customer Info
            </div>
          </div>

          <div
            style={{
              borderRight:
                tabScreen === 2 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              borderLeft:
                tabScreen === 2 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
              borderTop:
                tabScreen === 2 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              backgroundColor: tabScreen === 2 ? 'white' : '#faf4ed',
              borderBottom:
                tabScreen === 2 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
              marginTop: tabScreen === 2 ? '0px' : '4px',
              fontWeight: tabScreen === 2 ? 500 : 400,
            }}
            onClick={() => {
              setTabScreen(2);
            }}
            className="flex-1 p-2 flex justify-center rounded-t-sm"
          >
            <div className="flex items-start ">
              <i
                style={{
                  color: tabScreen === 2 ? '#03a9f4' : 'gray',
                }}
                className={`bi ${
                  tabScreen === 2 ? 'bi-diagram-3-fill' : 'bi-diagram-3'
                } text-[16px] pr-3`}
              ></i>
            </div>

            <div
              style={{
                color: tabScreen === 2 ? 'black' : 'gray',
                fontSize: '16px',
              }}
              className=" h-full flex items-center"
            >
              Company Info
            </div>
          </div>

          <div
            style={{
              borderRight:
                tabScreen === 3 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              borderLeft:
                tabScreen === 3 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
              borderTop:
                tabScreen === 3 ? '1px solid #b5b3b3' : '1px solid #dee3e0',
              backgroundColor: tabScreen === 3 ? 'white' : '#faf4ed',
              borderBottom:
                tabScreen === 3 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
              marginTop: tabScreen === 3 ? '0px' : '4px',
              fontWeight: tabScreen === 3 ? 500 : 400,
            }}
            onClick={() => {
              setTabScreen(3);
            }}
            className="flex-1 p-2 flex justify-center rounded-t-sm"
          >
            <div className="flex items-start ">
              <i
                style={{
                  color: tabScreen === 3 ? '#03a9f4' : 'gray',
                }}
                className={`bi ${
                  tabScreen === 3 ? 'bi-pencil-fill' : 'bi-pencil'
                } text-[16px] pr-3`}
              ></i>
            </div>

            <div
              style={{
                color: tabScreen === 3 ? 'black' : 'gray',
                fontSize: '16px',
              }}
              className=" h-full flex items-center"
            >
              Modify Customer Mobile
            </div>
          </div>
        </div>

        <div
          className="w-full flex flex-col justify-between  bg-white"
          style={
            tabScreen === 1 && window.innerWidth <= 500
              ? { height: '85%' }
              : tabScreen === 2 && window.innerWidth <= 550
              ? { height: '85%' }
              : tabScreen === 1 && window.innerWidth <= 1024
              ? { height: '70%' }
              : tabScreen === 1 && window.innerWidth <= 1280
              ? { height: '80%' }
              : tabScreen === 2 && window.innerWidth <= 1280
              ? { height: '80%' }
              : tabScreen === 2 && window.innerWidth <= 1025
              ? { height: '70%' }
              : { height: '90%' }
          }
        >
          <div className="h-full w-full">
            {tabScreen === 1 && (
              <CustomerInfo
                firabseUrl={firabseUrl}
                setCustomerInfo={setCustomerInfo}
                customerInfo={customerInfo}
                rowWidth={rowWidth}
                mobileAlert={mobileAlert}
                setMobielAlert={setMobielAlert}
                rowStyle={rowStyle}
                customerMasterListByCode={customerMasterListByCode}
                setCustomerMasterListByCode={setCustomerMasterListByCode}
                rowValueStyle={rowValueStyle}
                profilePicWidth={profilePicWidth}
                webcamRef={webcamRef}
                webcamStatus={webcamStatus}
                capturedImage={capturedImage}
                setCapturedImage={setCapturedImage}
                setWebcamStatus={setWebcamStatus}
                selectedFile={selectedFile}
                path={path}
                customerlist={customerlist}
                setFirebaseUrl={setFirebaseUrl}
                setSelectedFile={setSelectedFile}
                minProfileicWidth={minProfileicWidth}
                prefixList={prefixList}
                setCompanyInfo={setCompanyInfo}
                setId={setId}
                prefix={prefix}
                setPath={setPath}
              />
            )}

            {tabScreen === 2 && (
              <CompanyInfo
                companyInfo={companyInfo}
                setCompanyInfo={setCompanyInfo}
                rowStyle={rowStyle}
                rowValueStyle={rowValueStyle}
                rowWidth={rowWidth}
                saveData={SaveData}
              />
            )}

            {tabScreen === 3 && (
              <UpdateCustomer
                modifyCustomerDetails={modifyCustomerDetails}
                customerlist={customerlist}
                modifyMobileAlert={modifyMobileAlert}
                setModifyMobileAlert={setModifyMobileAlert}
                setModifyCustomerDetails={setModifyCustomerDetails}
              />
            )}
          </div>

          <div className="w-full flex justify-end max-sm:justify-between pt-3  bg-[#f2f2f2] ">
            {id ? (
              <ModifyButton onClick={SaveData} />
            ) : (
              <Save_button onClick={SaveData} />
            )}
            <PrintButton />
            <Undo_button onClick={undoData} />
          </div>
        </div>
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default CustomerMaster;
