import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';
import {
  selectAdvanceVoucherDetails,
  selectBillingCartList,
  selectModifyBillingCartlist,
  setAdvanceVoucherDetails,
  setBillingAdvanceAmount,
} from '../../Redux/billing';

import moment from 'moment';
import { useState } from 'react';
import { selectToken } from '../../Redux/security';
import { selectPrefix, selectWidth } from '../../Redux/profile';
import axios from 'axios';
import {
  selectHistoryOrderDetails,
  setBookingAttachImage,
  setBookingCustomerDetails,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../../Redux/booking';
import {
  selectCustomerMasterHelpModStatus,
  setAlertWithTitle,
  setCustomerMasterHelpModStatus,
  setModCustomerMasterModifyStatus,
  setSessionExpired,
} from '../../Redux/modal';
import { useEffect } from 'react';
import GeniustextInput from '../mastertext';
import { SaveButton, UndoButton } from '../SNAA_buttons';
import CustomerMasterhelp from '../../../help/customermasterHelp';
import ModVoucherHelp from '../../../help/modVoucher';
const VoucherSave = ({ setStatus, type, bookingCustomerDetails }) => {
  const dispatch = useDispatch();
  const billCartList = useSelector(selectBillingCartList);
  const modifyBillCartList = useSelector(selectModifyBillingCartlist);

  const [voucherNo, setVoucherNo] = useState('');

  const [desc, setDesc] = useState(
    type === 'advance' ? 'Advance againt Booking' : ''
  );
  const [voucherMobile, setVoucherMobile] = useState('');
  const [voucherCusName, setVoucherCusName] = useState('');
  const [voucherAddress, setVoucherAddress] = useState('');
  const [voucherCusCode, setVoucherCusCode] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [amountReceived, setAmountReceived] = useState('');
  const [paymentMode, setPaymentMode] = useState('Cash');
  const [cardNo, setCardNo] = useState('');
  const [ePaymentBank, setEPaymentBank] = useState('');
  const [modVoucherHelp, setModVoucherHelp] = useState(false);
  const [customerPrefix, setCustomerPrefix] = useState(''); //
  const [mrc_no, setMrcNumber] = useState(''); //
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  const advanceVoucherDetails = useSelector(selectAdvanceVoucherDetails);
  const billinglist = modifyBillCartList[0] ? modifyBillCartList : billCartList;
  const totalBillAmount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );
  const TotalCuttingTax1Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX1_AMOUNT),
    0
  );
  const TotalCuttingTax2Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX2_AMOUNT),
    0
  );
  const TotalCuttingTax3Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX3_AMOUNT),
    0
  );
  const TotalSewingTax1Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX1_AMOUNT),
    0
  );
  const TotalSewingTax2Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX2_AMOUNT),
    0
  );
  const TotalSewingTax3Amount = billinglist.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX3_AMOUNT),
    0
  );

  const netAmount = Math.round(
    totalBillAmount +
      TotalCuttingTax1Amount +
      TotalCuttingTax2Amount +
      TotalCuttingTax3Amount +
      TotalSewingTax1Amount +
      TotalSewingTax2Amount +
      TotalSewingTax3Amount
  );

  const saveVoucherAPI = (data) => {
    setAmountPaid('');
    axios
      .post(`/api/vouchers/saveVoucher`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (response.data.result.affectedRows === 1) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Data is saved successfully',
              lottie: 'success',
            })
          );
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(
          setAlertWithTitle({
            title: 'Failed',
            msg: 'something went wrong ',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        setVoucherNo('');
        setVoucherAddress('');
        setVoucherCusName('');
        setVoucherMobile('');
        setAmountReceived('');
        setAmountPaid('');
        setDesc('');
      });
  };
  const saveVoucher = () => {
    if (
      (bookingCustomerDetails?.customer_name &&
        amountReceived <= netAmount &&
        amountReceived &&
        billCartList[0]) ||
      modifyBillCartList[0] ||
      bookingCustomerDetails?.code ||
      (voucherCusName && amountPaid && type === 'menu') ||
      bookingCustomerDetails?.code ||
      (voucherCusName && amountReceived && type === 'menu')
    ) {
      const data = {
        table_prefix: prefix,
        VOUCHER_NO: voucherNo,
        DATE: moment(date).format('YYYY-MM-DD'),
        INVNO: historyOrderDetails[0]?.INVNO ? historyOrderDetails[0].INVNO : 0,
        PAY_MODE: paymentMode,
        CARD_NO: cardNo,
        EPAYMENT_BANK: ePaymentBank,
        CUSTOMER_NAME:
          type === 'menu'
            ? voucherCusName
            : bookingCustomerDetails.customer_name,
        ADDRESS:
          type === 'menu' ? voucherAddress : bookingCustomerDetails.address,
        MOBILE: type === 'menu' ? voucherMobile : bookingCustomerDetails.mobile,
        DEBIT: amountPaid ? Number(amountPaid) : 0,
        CREDIT: amountReceived ? Number(amountReceived) : 0,
        AMOUNT_DESC: desc,
        LATITUDE: '',
        LONGITUDE: '',
        APPROVED: 'NO',
        MRC_NO: mrc_no,
        PREFIX: customerPrefix,
      };

      if (type === 'menu') {
        saveVoucherAPI(data);
      } else {
        dispatch(setBillingAdvanceAmount(amountReceived));
        dispatch(setAdvanceVoucherDetails(data));
        dispatch(
          setAlertWithTitle({
            title: 'Success',
            msg: 'Voucher saved successfully',
            lottie: 'success',
          })
        );
        setStatus(false);
      }
    }
  };

  useEffect(() => {
    if (advanceVoucherDetails) {
      setDesc(advanceVoucherDetails.AMOUNT_DESC);
      setEPaymentBank(advanceVoucherDetails.EPAYMENT_BANK);
      setPaymentMode(advanceVoucherDetails.PAY_MODE);
      setAmountPaid(advanceVoucherDetails.DEBIT);
      setAmountReceived(advanceVoucherDetails.CREDIT);
      setCardNo(advanceVoucherDetails.CARD_NO);
    }
  }, []);

  useEffect(() => {
    if (type === 'menu') {
      if (amountPaid) {
        setDesc('Amount Debit');
      } else {
        if (amountReceived) {
          setDesc('Amount Credit');
        }
      }
    }
  }, [amountPaid, amountReceived]);

  const windowWidth = useSelector(selectWidth);

  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    dispatch(setBookingCustomerDetails(''));
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    dispatch(setBookingCustomerDetails(params.row));
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  return (
    <div className="h-full w-full bg-white p-4 max-sm:p-2 text-md">
      <div className="w-full flex mb-2 max-sm:block">
        <div className="flex  rounded-sm  max-sm:mt-2 max-sm:w-full">
          <GeniustextInput
            title={'Voucher No.'}
            type={'text'}
            search={() => {
              setModVoucherHelp(true);
            }}
            value={voucherNo}
            onChange={(t) => {
              setVoucherNo(t.target.value);
            }}
            width={
              windowWidth <= 768
                ? '150px'
                : windowWidth <= 1024
                ? '304px'
                : '400px'
            }
          />
        </div>
        <div className="flex justify-end grow max-sm:hidden">
          <GeniustextInput
            title={'Date'}
            type={'date'}
            value={moment().format('YYYY-MM-DD')}
            width={
              windowWidth <= 768
                ? '150px'
                : windowWidth <= 1024
                ? '200px'
                : '200px'
            }
            readOnly
          />
        </div>
      </div>

      <div className="w-full flex mb-2 max-sm:block">
        <GeniustextInput
          title={'Mobile'}
          error={'mobile'}
          // search={dispatch(setCustomerMasterHelpModStatus(true))}
          // search={() => {
          //   dispatch(setCustomerMasterHelpModStatus(true));
          // }}
          type={'number'}
          value={
            type === 'menu' ? voucherMobile : bookingCustomerDetails?.mobile
          }
          onChange={(t) => {
            if (t.target.value.length < 16) {
              setVoucherMobile(t.target.value);
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Mobile Number',
                  msg: 'Please enter a valid  mobile number.',
                })
              );
            }
          }}
          width={
            windowWidth <= 768
              ? '150px'
              : windowWidth <= 1024
              ? '304px'
              : '400px'
          }
        />

        {/* <div className=" flex grow ml-5 max-sm:ml-0 max-sm:mt-2">
          <div className="flex  rounded-sm w-full"> */}
        <div className="flex ml-5">
          <GeniustextInput
            title={'Amount Paid'}
            type={'number'}
            value={amountPaid}
            readOnly={type === 'advance' ? true : false}
            onChange={(t) => {
              if (!amountReceived) {
                setAmountPaid(t.target.value);
              }
            }}
            width={
              windowWidth <= 800
                ? '150px'
                : windowWidth <= 1024
                ? '200px'
                : '190px'
            }
          />
        </div>
        {/* //   </div>
        // </div> */}

        {/* <div className=" flex grow max-sm:ml-0 max-sm:mt-2">
          <div className="flex  rounded-sm w-full"> */}
        <div className="flex ml-5">
          <GeniustextInput
            title={'Amount Recieved'}
            type={'number'}
            value={amountReceived}
            onChange={(t) => {
              if (!amountPaid) {
                setAmountReceived(t.target.value);
              }
            }}
            autofocus={window.innerWidth <= 1280 ? false : true}
            width={
              windowWidth <= 800
                ? '150px'
                : windowWidth <= 1024
                ? '200px'
                : '190px'
            }
          />
        </div>
        {/* </div>
        </div> */}
      </div>

      <div className="w-full flex mb-2 max-sm:block">
        <GeniustextInput
          title={'Customer Name'}
          type={'text'}
          value={
            type === 'menu'
              ? voucherCusName
              : bookingCustomerDetails?.customer_name
          }
          readOnly={type === 'advance' ? true : false}
          width={
            windowWidth <= 768
              ? '150px'
              : windowWidth <= 1024
              ? '304px'
              : '400px'
          }
        />

        <div className=" flex grow ml-5 max-sm:ml-0 max-sm:mt-2">
          <div className="flex  rounded-sm w-full">
            <GeniustextInput
              title={'Description'}
              type={'text'}
              value={desc}
              onChange={(t) => {
                setDesc(t.target.value);
              }}
              width={
                windowWidth <= 768
                  ? '150px'
                  : windowWidth <= 1024
                  ? '304px'
                  : '400px'
              }
            />
          </div>
        </div>
      </div>

      <div className="w-full flex mb-2 max-sm:block">
        <GeniustextInput
          title={'ePayment Bank'}
          type={'number'}
          value={ePaymentBank}
          onChange={(t) => {
            setEPaymentBank(t.target.value);
          }}
          width={
            windowWidth <= 768
              ? '150px'
              : windowWidth <= 1024
              ? '304px'
              : '400px'
          }
        />

        <div className=" flex grow ml-5 max-sm:ml-0 max-sm:mt-2">
          <div className="flex  rounded-sm w-full">
            <GeniustextInput
              title={'Card No.'}
              type={'number'}
              value={cardNo}
              onChange={(t) => {
                setCardNo(t.target.value);
              }}
              width={
                windowWidth <= 768
                  ? '150px'
                  : windowWidth <= 1024
                  ? '200px'
                  : '200px'
              }
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full mt-4 max-sm:block">
        <div
          className="flex"
          // style={
          //   window.innerWidth <= 500 ? { width: '100%' } : { width: '40%' }
          // }
        >
          <Button
            variant="contained"
            onClick={() => {
              setPaymentMode('Cash');
            }}
            style={{
              backgroundColor: 'transparent',
              textTransform: 'none',
              color: `${paymentMode === 'Cash' ? 'red' : '#383535'}`,
              textAlign: 'left',
              boxShadow: '1px 1px 1px  #b5b3b3',
              border: '1px solid #b5b3b3',
            }}
            className="text-lg items-center   w-full bg-red justify-between flex px-5 "
          >
            <div>
              <i
                className={`bi text-[22px] flex items-center text-ggray ${
                  paymentMode === 'Cash' ? 'bi-record2-fill ' : 'bi-record2'
                }`}
              ></i>
            </div>
            <div className="w-full ">Cash</div>
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setPaymentMode('Card');
            }}
            style={{
              backgroundColor: 'transparent',
              boxShadow: '1px 1px 1px  #b5b3b3',
              textTransform: 'none',
              marginLeft: window.innerWidth <= 500 ? '8px' : '8px',
              color: `${paymentMode === 'Card' ? 'red' : '#383535'}`,
              textAlign: 'left',

              border: '1px solid #b5b3b3',
            }}
            className="text-lg items-center   w-full bg-red justify-between flex px-5"
          >
            <div>
              <i
                className={`bi text-[22px] flex items-center text-ggray ${
                  paymentMode === 'Card' ? 'bi-record2-fill ' : 'bi-record2'
                }`}
              ></i>
            </div>
            <div className="w-full">Card</div>
          </Button>
        </div>
        <div
          className="flex max-sm:mt-2"
          // style={
          //   window.innerWidth <= 500 ? { width: '100%' } : { width: '40%' }
          // }
        >
          <Button
            variant="contained"
            onClick={() => {
              setPaymentMode('ePayment');
            }}
            style={{
              backgroundColor: 'transparent',
              boxShadow: '1px 1px 1px  #b5b3b3',
              textTransform: 'none',
              color: `${paymentMode === 'ePayment' ? 'red' : '#383535'}`,
              textAlign: 'left',
              marginLeft: window.innerWidth <= 500 ? '0px' : '8px',
              border: '1px solid #b5b3b3',
            }}
            className="text-lg items-center   w-full bg-red justify-between flex  px-5 "
          >
            <div>
              <i
                className={`bi text-[22px] flex items-center text-ggray ${
                  paymentMode === 'ePayment' ? 'bi-record2-fill ' : 'bi-record2'
                }`}
              ></i>
            </div>
            <div className="w-full ">ePayment</div>
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setPaymentMode('Void');
            }}
            style={{
              backgroundColor: 'transparent',
              boxShadow: '1px 1px 1px  #b5b3b3',
              textTransform: 'none',
              color: `${paymentMode === 'Void' ? 'red' : '#383535'}`,
              textAlign: 'left',
              marginLeft: window.innerWidth <= 500 ? '8px' : '8px',
              border: '1px solid #b5b3b3',
            }}
            className="text-lg items-center   w-full bg-red justify-between flex px-5 "
          >
            <div>
              <i
                className={`bi text-[22px] flex items-center text-ggray ${
                  paymentMode === 'Void' ? 'bi-record2-fill ' : 'bi-record2'
                }`}
              ></i>
            </div>
            <div className="w-full ">Void</div>
          </Button>
        </div>
      </div>

      <div
        className="flex justify-end mt-3 max-sm:mt-2 max-sm:ml-0"
        style={{ width: '100%' }}
      >
        <SaveButton onClick={saveVoucher} />
        <div className="ml-1 ">
          <UndoButton
            onClick={() => {
              setVoucherNo('');
              setVoucherAddress('');
              setVoucherCusName('');
              setVoucherMobile('');
              setAmountReceived('');
              setAmountPaid('');
              setDesc('');
            }}
          />
        </div>
      </div>

      <ModVoucherHelp
        onRowClick={(params) => {
          if (params.row.INVNO === 0) {
            setCustomerPrefix(params.row.PREFIX);
            setMrcNumber(params.row.MRC_NO);
            setVoucherNo(params.row.VOUCHER_NO);
            setDesc(params.row.AMOUNT_DESC);
            setEPaymentBank(params.row.EPAYMENT_BANK);
            setAmountReceived(params.row.CREDIT);
            setAmountPaid(params.row.DEBIT);
            setCardNo(params.row.CARD_NO);
            setPaymentMode(params.row.PAY_MODE);
            setDate(params.row.DATE);
            setVoucherCusName(params.row.CUSTOMER_NAME);
            setVoucherMobile(params.row.MOBILE);
            setVoucherCusCode(params.row.CODE);
            setStatus(false);
            setVoucherAddress(params.row.ADDRESS);
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Against Bill',
              })
            );
          }
        }}
        status={modVoucherHelp}
        setStatus={setModVoucherHelp}
      />

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setModCustomerMasterModifyStatus(false));
        }}
      />
    </div>
  );
};

export default VoucherSave;
