import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import {
  selectVoucherSummaryModStatus,
  setVoucherSummaryModStatus,
} from '../../Redux/modal';
import VoucherMod from './voucherMod';
import TalringLogo from '../styles/talringLogo';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '85%',

  boxShadow: 24,
};

const styleTab = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '95%',
  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  height: '95%',
  boxShadow: 24,
};

export default function VoucherMasterMod({ paymode, data }) {
  const dispatch = useDispatch();

  const ModalStatus = useSelector(selectVoucherSummaryModStatus);

  const handleClose = () => {
    dispatch(setVoucherSummaryModStatus(false));
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={
            window.innerWidth <= 500
              ? styleMobile
              : window.innerWidth <= 1280
              ? styleTab
              : style
          }
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-[#383535] h-full ">
            <div className="flex w-full bg-brown items-center">
              <div className="px-2">
                <TalringLogo />
              </div>
              <div
                style={{ width: '1px', height: 50 }}
                className=" bg-orange"
              />
              <div className="flex  grow justify-between items-center text-white bg-brown px-3 font-medium ">
                Voucher Summary
                <CancelPresentationIcon
                  onClick={handleClose}
                  sx={{
                    color: 'orangered',
                  }}
                  fontSize="medium"
                />
              </div>
            </div>

            <div className="flex-grow h-full bg-white    border-brown">
              <VoucherMod />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
