import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';

import { setAlertwithTitleModalStatus } from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { selectBillSettlement, setBillSettlement } from '../../Redux/billing';

export default function ModEPayment({
  status,
  setStatus,
  setEPaymentType,
  ePaymentType,
}) {
  const dispatch = useDispatch();

  const billSettelmentFields = useSelector(selectBillSettlement);
  const handleClose = () => setStatus(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        event.preventDefault();

        // 👇️ your logic here
        dispatch(setAlertwithTitleModalStatus(false));
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div>
      <Modal open={status}>
        <div
          style={style}
          className=" rounded-sm   text-brown overflow-hidden w-full bg-white  min-w-[200px] max-w-[250px]"
        >
          <div className="w-full  flex items-center p-1 px-3 py-2 bg-gray-100  ">
            <div className="text-lg  flex grow justify-left  font-[500] text-brown">
              Select ePayment Type
            </div>
            <button
              onClick={() => {
                setStatus(false);
              }}
            >
              <i className="bi bi-x-circle flex items-center text-[18px] "></i>
            </button>
          </div>

          {/* {ePaymentType} */}
          <div className="h-full w-full bg-white p-4">
            <div className="w-full flex justify-center"></div>
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      epaymentType: 'Gpay',
                    })
                  );
                  setEPaymentType('Gpay');
                  setStatus(false);
                }}
                style={{
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  color: `${ePaymentType === 'Gpay' ? 'orange' : '#383535'}`,
                  textAlign: 'left',
                  boxShadow: '1px 1px 1px  #cfccca',
                  border: '1px solid #7e8794',
                }}
                className="text-lg items-center  text-black w-full bg-red justify-between flex p-1 pl-3 mt-1"
              >
                <div className="w-full ">Gpay</div>
                <div>
                  <i
                    className={`bi text-[22px] text-ggray ${
                      ePaymentType === 'Gpay'
                        ? 'bi-record2-fill '
                        : 'bi-record2'
                    }`}
                  ></i>
                </div>
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      epaymentType: 'Phone Pe',
                    })
                  );
                  setEPaymentType('Phone Pe');
                  setStatus(false);
                }}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: '1px 1px 1px  #cfccca',
                  textTransform: 'none',
                  color: `${
                    ePaymentType === 'Phone Pe' ? 'orange' : '#383535'
                  }`,
                  textAlign: 'left',
                  marginTop: '8px',
                  border: '1px solid #7e8794',
                }}
                className="text-lg items-center  text-black w-full bg-red justify-between flex p-1 pl-3 mt-1"
              >
                <div className="w-full">Phone Pe</div>
                <div>
                  <i
                    className={`bi text-[22px] text-ggray ${
                      ePaymentType === 'Phone Pe'
                        ? 'bi-record2-fill '
                        : 'bi-record2'
                    }`}
                  ></i>
                </div>
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      epaymentType: 'Paytm',
                    })
                  );
                  setEPaymentType('Paytm');
                  setStatus(false);
                }}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: '1px 1px 1px  #cfccca',
                  textTransform: 'none',
                  color: `${ePaymentType === 'Paytm' ? 'orange' : '#383535'}`,
                  textAlign: 'left',
                  marginTop: '8px',
                  border: '1px solid #7e8794',
                }}
                className="text-lg items-center  text-black w-full bg-red justify-between flex p-1 pl-3 mt-1"
              >
                <div className="w-full">Paytm</div>
                <div>
                  <i
                    className={`bi text-[22px] text-ggray ${
                      ePaymentType === 'Paytm'
                        ? 'bi-record2-fill '
                        : 'bi-record2'
                    }`}
                  ></i>
                </div>
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      epaymentType: 'Other',
                    })
                  );
                  setEPaymentType('other');
                  setStatus(false);
                }}
                style={{
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  boxShadow: '1px 1px 1px  #cfccca',
                  color: `${ePaymentType === 'other' ? 'orange' : '#383535'}`,
                  textAlign: 'left',
                  marginTop: '8px',
                  border: '1px solid #7e8794',
                }}
                className="text-lg items-center  text-black w-full bg-red justify-between flex p-1 pl-3 mt-1"
              >
                <div className="w-full">Other</div>
                <div>
                  <i
                    className={`bi text-[22px] text-ggray ${
                      ePaymentType === 'other'
                        ? 'bi-record2-fill '
                        : 'bi-record2'
                    }`}
                  ></i>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
