import axios from 'axios';
export const low_Bal_alert = 'Insufficient Credits';
export const getEmptyMeasurementList = async (
  uppr_lower,
  masterPrefix,
  token
) => {
  let list = [];
  await axios
    .post(
      `/api/masters/listMeasurementMaster`,
      {
        table_prefix_master: masterPrefix,
        UPPER_LOWER: uppr_lower,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (!res.data.error) {
        if (res.data.response[0]) {
          list = res.data.response.map((res) => {
            return {
              ...res,
              size: '',
            };
          });
        }
        return list;
      } else {
        return list;
      }
    })
    .catch((err) => {
      console.log(err);
      return list;
    });

  return list;
};

export const getMessageWallet = (companyEmail, token, sms) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/userSettings/loadMessageSetup`,
        {
          company_email: companyEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            if (sms) {
              resolve(
                Number(res.data.response[0].sms_balance),
                res.data.response[0]
              );
            } else {
              console.log('hii');
              resolve(
                Number(res.data.response[0].whatsapp_balance),
                res.data.response[0]
              );
            }
          } else {
            resolve(0);
          }
        } else {
          resolve(0);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(0);
      });
  });
};
