import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from './dashboard/home';
import CustomerMaster from './masters/customerMaster';
import BillSummary from './summary/billSummery';
import UserMaster from './masters/snaUserMaster';
import EmployeeMaster from './masters/employeeMaster';
import CompanyMaster from './masters/companyMaster';
import Dashboard from './booking/dashobard';
import CustomerLedger from './reports/customerLedger';
import DefaultSetting from './settings/defultSetting';
import AdminDashboard from './admin/dashboard';
import UserList from './admin/userList';
import Masters from './masters/dashboard';
import ItemMaster from './masters/ItemMaster';
import MessageSetupMaster from './masters/messageSetupMaster';
import VoucherSummary from './utility/component/modals/voucherMod';
import NotFound from './auth/notFound';
import { Login } from './auth/Login';
import { FinancialYear } from './auth/financialYear';
import { CompnySignup } from './auth/signup';
import { ResetEmail } from './auth/resetUserEmail';
import Auth from './auth/auth';
import { Reports } from './reports';
import CustomerWiseSummary from './reports/customerWiseSummary';
import CustomerBalanceReport from './reports/customerBalanceReport';
import Updation from './updation/dashboard';
import ProtectedRoute from './auth/util/protectedRoute';
import { useEffect } from 'react';
import ActivationAccount from './auth/activation';
import { ForgotPassword } from './auth/changePassword';
import VerifyEmail from './auth/verifyEmail';
import OrderSummary from './summary/orderSummery';
import HistoryReport from './summary/historyReport';
import GeniusSectionMaster from './masters/sectionMaster';
import Prefixmaster from './masters/prefixMaster';
import { useDispatch } from 'react-redux';
import DepartmentMaster from './masters/departmentMaster';
import EmpDepartMaster from './masters/empDepmaster';
import QRCodeScanner from './employeee/scanCode';
import PendingOrder from './employeee/pendingOrder';
import RunningOrders from './supervisor/runningOrders';
import BillingDashboard from './billing/dashboard';
import OrderProductionHistory from './supervisor/orderStatus';
import FundDashboard from './dashboard/fundDashboard';
import ChnageDwefaultSetting from './settings/chnageDefaultSetting';
import UserProfile from './profile';
import ItemWiseBookings from './summary/itemWiseBooking';
import DelBilling from './del_billing/billing';

const AppRoutes = ({ userType }) => {
  const authRoutes = [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'financial_year',
      element: <FinancialYear />,
    },
    {
      path: 'signup',
      element: <CompnySignup />,
    },
    {
      path: 'reset_password_email',
      element: <ResetEmail />,
    },
  ];
  const privateRoutes = [
    {
      path: '/admin_dashboard',
      userType: 'ADMIN',
      component: AdminDashboard,
    },
    {
      path: '/bill_summary',
      userType: 'USER ADMIN MANAGER',
      component: BillSummary,
    },
    {
      path: '/order_summary',
      userType: 'USER ADMIN MANAGER',
      component: OrderSummary,
    },
    {
      path: '/order_status',
      userType: 'USER ADMIN MANAGER',
      component: OrderProductionHistory,
    },
    {
      path: '/user_profile',
      userType: 'USER ADMIN MANAGER PRODUCTION SUPERVISOR',
      component: UserProfile,
    },
    {
      path: '/del_billing',
      userType: 'USER ADMIN MANAGER PRODUCTION SUPERVISOR',
      component: DelBilling,
    },
    {
      path: '/booking',
      userType: 'USER ADMIN MANAGER',
      exclude: 'MANAGER',
      component: Dashboard,
    },
    {
      path: '/company_master',
      userType: 'USER ADMIN MANAGER',
      component: CompanyMaster,
    },
    {
      path: '/billing',
      userType: 'USER STORE MANAGER PRODUCTION SUPERVISOR',
      component: BillingDashboard,
    },
    {
      path: '/customer_ledger',
      userType: 'USER ADMIN MANAGER',
      component: CustomerLedger,
    },
    {
      path: '/fund_dashboard',
      userType: 'USER ADMIN MANAGER',
      component: FundDashboard,
    },
    {
      path: '/customer_master',
      userType: 'USER ADMIN MANAGER',
      component: CustomerMaster,
    },
    {
      path: '/change_default_setting',
      userType: 'USER ADMIN MANAGER',
      component: ChnageDwefaultSetting,
    },
    {
      path: '/employee_master',
      userType: 'USER ADMIN MANAGER',
      component: EmployeeMaster,
    },
    {
      path: '/item_master',
      userType: 'USER ADMIN MANAGER',
      component: ItemMaster,
    },
    {
      path: '/running_orders',
      userType: 'SUPERVISOR',
      component: RunningOrders,
    },
    {
      path: '/booking_history',
      userType: 'USER ADMIN MANAGER',
      component: HistoryReport,
    },
    {
      path: '/item_wise_booking',
      userType: 'USER ADMIN MANAGER',
      component: ItemWiseBookings,
    },

    {
      path: '/masters',
      userType: 'USER ADMIN MANAGER',
      component: Masters,
    },
    {
      path: '/message_setup_master',
      userType: 'ADMIN',
      component: MessageSetupMaster,
    },
    {
      path: '/reports',
      userType: 'USER ADMIN MANAGER SUPERVISOR',
      component: Reports,
    },
    {
      path: '/section_master',
      userType: 'USER ADMIN MANAGER',
      component: GeniusSectionMaster,
    },
    {
      path: '/prefix_master',
      userType: 'USER ADMIN MANAGER',
      component: Prefixmaster,
    },
    {
      path: '/updation',
      userType: 'USER ADMIN MANAGER',
      component: Updation,
    },
    {
      path: '/customer_wise_summary',
      userType: 'USER ADMIN MANAGER',
      component: CustomerWiseSummary,
    },
    {
      path: '/customer_balance_report',
      userType: 'USER ADMIN MANAGER',
      component: CustomerBalanceReport,
    },
    {
      path: '/settings',
      userType: 'MANAGER ADMIN',
      component: DefaultSetting,
    },
    {
      path: '/user_list',
      userType: 'ADMIN',
      component: UserList,
    },
    {
      path: '/user_master',
      userType: 'MANAGER ADMIN',
      component: UserMaster,
    },
    {
      path: '/department_master',
      userType: 'USER ADMIN MANAGER',
      component: DepartmentMaster,
    },
    {
      path: '/Scan_Qr_Code',
      userType: 'PRODUCTION SUPERVISOR',
      component: QRCodeScanner,
    },
    {
      path: '/pending_orders',
      userType: 'PRODUCTION',
      component: PendingOrder,
    },
    {
      path: '/employee_department_master',
      userType: 'USER ADMIN MANAGER',
      component: EmpDepartMaster,
    },

    {
      path: '/voucher_summary',
      userType: 'USER ADMIN MANAGER',
      component: VoucherSummary,
    },
  ];

  const otherRouter = [
    '/changeUserPassword',
    '/activateUser',
    '/emailVerified',
    '/auth/mobile_signup',
  ];
  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const UserTime = () => {
    if (expires) {
      if (expires < date) {
        navigate('/auth/login');
      }
    } else {
      navigate('/auth/login');
    }
  };
  useEffect(() => {
    const isOtherRoute = otherRouter.filter((r) => r === location.pathname);
    if (!isOtherRoute[0]) {
      UserTime();
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />}>
          {authRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.userType.includes(userType) ? (
                    <ProtectedRoute>
                      {' '}
                      <route.component />
                    </ProtectedRoute>
                  ) : (
                    <NotFound />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
