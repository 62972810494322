import moment from 'moment';

const width = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;

export const columns = [
  {
    field: 'Date',
    type: 'string',
    width: width * 70,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Date'}{' '}
      </div>
    ),
  },

  {
    field: 'ACCOUNT_NO',
    type: 'string',
    width: width * 120,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Item Name'}{' '}
      </div>
    ),
  },

  {
    field: 'NAME',
    type: 'string',
    width: width * 80,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        Delivery Date{' '}
      </div>
    ),
  },

  {
    field: 'MOBILE',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    headerAlign: 'center',
    align: 'left',

    renderHeader: (params) => (
      <div
        className="text-ggray  whitespace-normal"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        Delivered
      </div>
    ),
  },
];

export const TabOrderHistorycolumns = [
  {
    field: 'Date',
    type: 'string',
    width: width * 100,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Date'}{' '}
      </div>
    ),
  },

  {
    field: 'ACCOUNT_NO',
    type: 'string',
    width: width * 160,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Item Name'}{' '}
      </div>
    ),
  },

  {
    field: 'NAME',
    type: 'string',
    width: width * 120,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <div
        className="text-ggray"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        Delivery{' '}
      </div>
    ),
  },

  {
    field: 'MOBILE',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    headerAlign: 'center',
    align: 'left',

    renderHeader: (params) => (
      <div
        className="text-ggray  whitespace-normal"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        Delivered
      </div>
    ),
  },
];

export const StyleColums = [
  {
    field: 'PARAMETER',
    type: 'string',
    width:
      window.innerWidth <= 1024 ? 140 : window.innerWidth <= 1400 ? 160 : 180,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div className="text-white"> {'Parameters'} </div>
    ),
  },

  {
    field: 'size',
    type: 'string',
    flex: 1,
    editable: true,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div className="text-white"> {'Size'} </div>,
  },
];

export const ItemsColums = [
  {
    field: 'icode',
    type: 'string',
    width: width * 50,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Code'}{' '}
      </div>
    ),
  },

  {
    field: 'item_name',
    type: 'string',
    width: width * 110,
    headerClassName: 'super-app-theme--header',

    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Item Name'}{' '}
      </div>
    ),
  },
  {
    field: 'QNTY',
    type: 'string',
    width: width * 80,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Qnty'}{' '}
      </div>
    ),
  },
  {
    field: 'try_date',
    type: 'string',
    width: width * 70,

    headerClassName: 'super-app-theme--header',
    valueFormatter: (params) => {
      if (params.value) {
        return moment(params.value).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Try Date'}{' '}
      </div>
    ),
  },
  {
    field: 'del_date',
    type: 'string',
    flex: 1,
    valueFormatter: (params) => {
      if (params.value) {
        return moment(params.value).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Delivery Date'}{' '}
      </div>
    ),
  },
];

export const TabItemColums = [
  {
    field: 'Date',
    type: 'string',
    width: width * 80,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Code'}{' '}
      </div>
    ),
  },

  {
    field: 'ITEM',
    type: 'string',
    width: width * 120,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Item Name'}{' '}
      </div>
    ),
  },
  {
    field: 'QNTY',
    type: 'string',
    width: width * 80,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Qnty'}{' '}
      </div>
    ),
  },
  {
    field: 'TRY_DATE',
    type: 'string',
    width: width * 160,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Try Date'}{' '}
      </div>
    ),
  },
  {
    field: 'DEL_DATE',
    type: 'string',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <div
        className="text-white"
        style={{ fontSize: window.innerWidth <= 1024 ? '10px' : '13px' }}
      >
        {' '}
        {'Delivery date'}{' '}
      </div>
    ),
  },
];
