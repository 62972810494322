import { useDispatch, useSelector } from 'react-redux';
import { toProperCase } from '../utility/component/format';
import { useEffect, useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import {
  SelectSectionMasterHelpStatus,
  setSectionMasterHelpStatus,
  setSessionExpired,
} from '../utility/Redux/modal';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import axios from 'axios';
import MasterHelp from '../utility/component/modals/masterHelp';

const SectionMasterHelp = ({ onRowClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const status = useSelector(SelectSectionMasterHelpStatus);
  const [rows, setRows] = useState([]);
  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'SECTION_NAME',
      renderHeader: (params) => <div className="text-md">Section Name</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const masterPrefix = useSelector(selectTableMasterPrefix);
  const getData = async () => {
    await axios
      .post(
        '/api/masters/listSectionMaster',
        {
          table_prefix_master: masterPrefix,
          SECTION_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        setRows(a);
        setFullList(a);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.SECTION_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const handleClose = () => {
    dispatch(setSectionMasterHelpStatus(false));
    setSearchQuery('');
  };

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            handleClose();
            setRows([]);
          }}
          title={'Section Master | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.CODE}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default SectionMasterHelp;
