import React from 'react';

import CancelPresentation from '@mui/icons-material/CancelPresentation';

const ModalHeaderStyle = ({ header, onClose }) => {
  return (
    <div
      style={{
        borderBottom: '1px solid #D2D2D7',
      }}
      className={
        ' bg-white rounded-t-sm overflow-hidden  w-full justify-between  flex items-center  px-3 py-2'
      }
    >
      <div className="w-full    ">
        <div className="w-full flex font-bold text-[#4a4a4a] items-center  ">
          <h3
            style={
              window.innerWidth <= 700
                ? {
                    fontSize: '18px',
                    width: '100%',
                  }
                : {
                    fontSize: '25px',
                    width: '100%',
                  }
            }
            className="  max-sm:px-1"
          >
            {header}
          </h3>
        </div>
      </div>
      <button fontSize={'medium'} onClick={onClose}>
        {' '}
        <i className="bi bi-x-square-fill  text-[22px] text-red font-bold p-1"></i>
      </button>
    </div>
  );
};

export default ModalHeaderStyle;
