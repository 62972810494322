import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';

import { setAlertwithTitleModalStatus } from '../../Redux/modal';

import VoucherSave from './voucherSave';
import { useDispatch } from 'react-redux';
import { Fade } from '@mui/material';
import ModalHeaderStyle from '../styles/modalHeaderStyle';
import ModalBody from '../styles/modalBody';
export default function ModSaveVoucher({
  status,
  setStatus,
  type,
  bookingCustomerDetails,
}) {
  const handleClose = () => setStatus(false);
  const dispatch = useDispatch();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth <= 800 ? '90%' : 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '2px solid black',
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        event.preventDefault();

        // 👇️ your logic here
        dispatch(setAlertwithTitleModalStatus(false));
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div>
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={status}>
          <div style={style} className={'rounded-lg overflow-hidden '}>
            <div className="w-full flex flex-col bg-white h-full ">
              <ModalHeaderStyle
                header={'Advance Voucher'}
                onClose={handleClose}
              />

              <ModalBody>
                <VoucherSave
                  setStatus={setStatus}
                  type={type}
                  bookingCustomerDetails={bookingCustomerDetails}
                />
              </ModalBody>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
