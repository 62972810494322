import { parseISO } from 'date-fns';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import AttachImage from '../utility/component/functions/AttachImage';
import {
  BillingSave_button,
  BillingUpdate_button,
  PrintButton,
} from '../utility/component/buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setSessionExpired,
  setloading,
} from '../utility/Redux/modal';
import {
  selectEditType,
  selectHistoryOrderDetails,
  selectLowerBookingItemDetails,
  selectLowerDate,
  selectLowerMeasuremnt,
  selectModyingBookingID,
  selectRemarks,
  selectUpperDate,
  selectUpperMeasuremnt,
  setEditType,
  setLowerDate,
  setRemarks,
  setUpperDate,
} from '../utility/Redux/booking';
import { selectPrefix } from '../utility/Redux/profile';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import { Button } from '@mui/material';
import {
  setBillSettlement,
  setBillingCartList,
  setModifyBillcartList,
} from '../utility/Redux/billing';

import CapitalizeProperNouns from '../utility/component/functions/sentenceFormater';

const Remarks = ({
  saveLoading,
  bookingAttachImage,
  clearBookingFields,
  selectedUpperItemDetails,
  parametrInput,
  setParameterInput,
  upperTryDateFocus,
  setUpperTryDateFocus,
  upperDeliveryDateFocus,
  setUpperDeliveryDateFocus,
  lowerTryDateFocus,
  setLowerTryDateFocus,
  lowerDeliveryDateFocus,
  setLowerDeliveryDateFocus,
  saveBooking,
  attachImage,
  attachImageModStatus,
  setAttachImageModStatus,
  setAttachImage,
  SetTabScreen,
  setAttachImageFolder,
  setBookingOrderNumber,
  attachImageFolder,
  bookingDate,
  upperCuttingDateFocus,
  setUpperCuttingDateFocus,
  lowerCuttingDateFocus,
  setLowerCuttingDateFocus,
  bookingCustomerDetails,
  setBookingCustomerDetails,
  setCustomerHistory,
  setAlertTwoButtonModal,
  setBookingDate,
  setBookingNumber,
  alertTwoButtonModal,
  setCustomerFirebasePic,
  setHistoryOrderDetails,
  setMrcNumber,
}) => {
  const remarks = useSelector(selectRemarks);
  const dispatch = useDispatch();
  const lowerDate = useSelector(selectLowerDate);
  const selectedLowerItemDetails = useSelector(selectLowerBookingItemDetails);
  const upperDate = useSelector(selectUpperDate);
  const upperTryDate1 = upperDate?.try;
  const upperTryDate = upperTryDate1
    ? moment(upperTryDate1).format('YYYY-MM-DD')
    : '';
  const upperDeliveryDate = moment(upperDate.del).format('YYYY-MM-DD');
  const upperCuttingDate = upperDate.cutting
    ? moment(upperDate.cutting).format('YYYY-MM-DD')
    : '';
  const prefix = useSelector(selectPrefix);
  const lowerTryDate2 = lowerDate?.try;
  const upperMeasrment = useSelector(selectUpperMeasuremnt);
  const lowerMeasrment = useSelector(selectLowerMeasuremnt);
  const lowerTryDate = lowerTryDate2
    ? moment(lowerTryDate2).format('YYYY-MM-DD')
    : '';
  const lowerDeliveryDate = moment(lowerDate.del).format('YYYY-MM-DD');
  const lowerCuttingDate = lowerDate.cutting
    ? moment(lowerDate.cutting).format('YYYY-MM-DD')
    : '';
  const modifiedBookingID = useSelector(selectModyingBookingID);
  const historyDetails = useSelector(selectHistoryOrderDetails);
  const token = useSelector(selectToken);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  const deleteItems = () => {
    if (historyOrderDetails[0].INVNO) {
      if (upperMeasrment[0] || lowerMeasrment[0]) {
        dispatch(setloading(true));

        axios
          .post(
            `/api/booking/voidOrderAndBill`,
            {
              table_prefix: prefix,
              BOOKING_ORDER_NO: modifiedBookingID,
              INVNO: historyDetails[0].INVNO,
              ORDER_STATUS: 'VOID',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            const data = res.data.response;

            if (!res.data.error) {
              dispatch(
                setAlertWithTitle({
                  title: 'Success',
                  msg: 'The order was successfully voided.',
                  lottie: 'success',
                })
              );

              clearBookingFields();
              setAlertTwoButtonModal({
                title: 'Successfull',
                msg: 'Do you want to save another measuremnets ?',
                status: true,
                button1: 'YES',
                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  setBookingNumber('');
                  setBookingOrderNumber('');
                },
                button2: 'NO',
                button2Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  setAlertTwoButtonModal({
                    title: 'Create Bill',
                    msg: 'Do you want to Create Bill ?',
                    status: true,
                    button1: 'YES',
                    button1Click: () => {
                      clearBookingFields();
                      SetTabScreen(3);
                    },
                    button2: 'NO',
                    button2Click: () => {
                      dispatch(setloading(false));
                      setBookingCustomerDetails({
                        code: '',
                        customer_name: '',
                        mobile: '',
                        mobile_2: '',
                        mobile_3: '',
                        phone: '',
                        email: '',
                        address: '',
                        city: '',
                        state: '',
                        country: '',
                        pin_code: '',
                        credit_limit: 0,
                        company_name: '',
                        company_phone: '',
                        company_email: '',
                        company_address: '',
                        company_city: '',
                        company_state: '',
                        company_country: '',
                        company_pincode: '',
                        regd_no1: '',
                        regd_no2: '',
                        regd_no3: '',
                        status: '',
                        remarks: '',
                        prefix: '',
                        mrc_no: '',
                        old_mobile: '',
                      });
                      dispatch(setCustomerFirebasePic(''));
                      dispatch(setHistoryOrderDetails(''));
                      setCustomerHistory([]);
                      clearBookingFields();
                      SetTabScreen(1);
                      setMrcNumber('');
                      setBookingDate(
                        moment(localStorage.getItem('bookingDate')).format(
                          'YYYY-MM-DD'
                        )
                      );
                    },
                  });
                },
              });
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'There was an issue while voiding the order',
                lottie: 'reject',
              })
            );
          });
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Not found bill No. for Void',
        })
      );
    }
  };
  const editType = useSelector(selectEditType);

  useEffect(() => {
    if (editType === 'U-REMARKS') {
      dispatch(
        setRemarks({
          ...remarks,
          upper: parametrInput,
        })
      );
    } else {
      if (editType === 'L-REMARKS') {
        dispatch(
          setRemarks({
            ...remarks,
            lower: parametrInput,
          })
        );
      }
    }
  }, [parametrInput]);
  //date
  // useEffect(() => {
  //   dispatch(
  //     setUpperDate({
  //       ...upperDate,
  //       del: moment(upperTryDate).add(5, 'days').format('YYYY-MM-DD'),
  //     })
  //   );
  // }, [upperTryDate]);

  // useEffect(() => {
  //   dispatch(
  //     setLowerDate({
  //       ...lowerDate,
  //       del: moment(lowerTryDate).add(5, 'days').format('YYYY-MM-DD'),
  //     })
  //   );
  // }, [lowerTryDate]);

  return (
    <div className="h-full l:text-sm d:text-md w-full flex   mt-2">
      {' '}
      <div className=" flex flex-col w-full h-full ">
        {/* .............................. remarks textarea.............................................................................................. */}

        {/* .................................. date area...................................................................................................... */}

        <div className="flex w-full  mt-3 ">
          <div className="w-1/2 ">
            <div className=" flex  ">
              <div
                style={{
                  border: '1px solid #e8e2db',
                  borderLeft: '1px solid #e8e2db',
                  borderRight: '1px solid #e8e2db',
                }}
                className="  rounded-t-sm   flex grow"
              >
                <div
                  style={{
                    borderRight: '1px solid #e8e2db',
                  }}
                  onClick={() => {
                    if (!upperTryDate) {
                      if (selectedUpperItemDetails) {
                        setUpperTryDateFocus(true);
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'First select any item',
                          })
                        );
                      }
                    }
                  }}
                  className="w-1/2 text-ggray  flex items-center "
                >
                  <div className="-mt-10  bg-white px-2 absolute">Try -</div>
                  <div className="flex    py-1  justify-center items-center">
                    {upperTryDate || upperTryDateFocus ? (
                      <ReactDatePicker
                        readOnly={true}
                        onFocus={() => setUpperTryDateFocus(true)}
                        onClickOutside={() => setUpperTryDateFocus(false)}
                        onSelect={() => setUpperTryDateFocus(false)}
                        open={upperTryDateFocus}
                        className=" bg-transparent text-sm text-center outline-0 w-[100px]  "
                        dateFormat=" d MMM , yyyy "
                        selected={
                          upperTryDate
                            ? parseISO(upperTryDate)
                            : parseISO(moment().format('YYYY-MM-DD'))
                        }
                        onChange={(date) => {
                          if (moment(date).isAfter(moment(bookingDate))) {
                            dispatch(
                              setUpperDate({
                                ...upperDate,
                                try: moment(date).format('YYYY-MM-DD'),
                                // del: moment(date)
                                //   .add(3, 'days')
                                //   .format('YYYY-MM-DD'),
                              })
                            );
                            if (selectedUpperItemDetails?.lower_icode > 0) {
                              dispatch(
                                setLowerDate({
                                  ...lowerDate,
                                  try: moment(date).format('YYYY-MM-DD'),
                                  // del: moment(date)
                                  //   .add(3, 'days')
                                  //   .format('YYYY-MM-DD'),
                                })
                              );
                            }
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert !',
                                msg: 'Only future dates are allowed',
                              })
                            );
                          }
                        }}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                  {/* <div className=" p-2 flex justify-center items-center">
                    <i
                      onClick={() => {
                        setUpperTryDateFocus(true);
                      }}
                      className="bi text-ggray text-[18px]  flex items-center bi-calendar2-event"
                    ></i>
                  </div> */}
                </div>
                <div className="w-1/2 flex items-center text-ggray ">
                  <div className="-mt-10 bg-white px-2 absolute">Del. -</div>
                  <div className=" flex  justify-center items-center  py-1">
                    <ReactDatePicker
                      readOnly={true}
                      onFocus={(e) => {
                        if (selectedUpperItemDetails) {
                          setUpperDeliveryDateFocus(true);
                        } else {
                          e.currentTarget.blur();
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'First select any item',
                            })
                          );
                        }
                      }}
                      onClickOutside={() => setUpperDeliveryDateFocus(false)}
                      onSelect={() => setUpperDeliveryDateFocus(false)}
                      open={upperDeliveryDateFocus}
                      className=" bg-transparent text-sm  text-center outline-0 w-[100px]  "
                      dateFormat=" d MMM , yyyy "
                      selected={parseISO(upperDeliveryDate)}
                      onChange={(date) => {
                        if (moment(date).isAfter(moment(bookingDate))) {
                          dispatch(
                            setUpperDate({
                              ...upperDate,
                              del: moment(date).format('YYYY-MM-DD'),
                            })
                          );
                          if (selectedUpperItemDetails?.lower_icode > 0) {
                            dispatch(
                              setLowerDate({
                                ...lowerDate,
                                del: moment(date).format('YYYY-MM-DD'),
                                // del: moment(date)
                                //   .add(3, 'days')
                                //   .format('YYYY-MM-DD'),
                              })
                            );
                          }
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert !',
                              msg: 'Only future dates are allowed',
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  {/* <div className=" p-2 flex justify-center items-center">
                    <i
                      onClick={() => {
                        setUpperCuttingDateFocus(true);
                      }}
                      className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                    ></i>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex ">
              <div
                style={{
                  border: '1px solid #e8e2db',
                  borderTop: '0px solid #e8e2db',
                }}
                onClick={() => {
                  if (!upperCuttingDate) {
                    if (selectedUpperItemDetails) {
                      setUpperCuttingDateFocus(true);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'First select any item',
                        })
                      );
                    }
                  }
                }}
                className=" rounded-b-sm   flex grow pl-1 text-red  items-center "
              >
                Cutting -
                <div className=" flex p-1 justify-center items-center">
                  {upperCuttingDate || upperCuttingDateFocus ? (
                    <ReactDatePicker
                      readOnly={true}
                      onFocus={(e) => {
                        if (selectedUpperItemDetails) {
                          setUpperCuttingDateFocus(true);
                        } else {
                          e.currentTarget.blur();
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'First select any item',
                            })
                          );
                        }
                      }}
                      onClickOutside={() => setUpperCuttingDateFocus(false)}
                      onSelect={() => setUpperCuttingDateFocus(false)}
                      open={upperCuttingDateFocus}
                      className=" bg-transparent text-sm    text-center outline-0 w-[100px]  "
                      dateFormat=" d MMM , yyyy "
                      selected={
                        upperCuttingDate
                          ? parseISO(upperCuttingDate)
                          : parseISO(moment().format('YYYY-MM-DD'))
                      }
                      onChange={(date) => {
                        dispatch(
                          setUpperDate({
                            ...upperDate,
                            cutting: moment(date).format('YYYY-MM-DD'),
                          })
                        );
                        if (selectedUpperItemDetails?.lower_icode > 0) {
                          dispatch(
                            setLowerDate({
                              ...lowerDate,
                              cutting: moment(date).format('YYYY-MM-DD'),
                              // del: moment(date)
                              //   .add(3, 'days')
                              //   .format('YYYY-MM-DD'),
                            })
                          );
                        }
                      }}
                    />
                  ) : (
                    <div className="h-[20px]" />
                  )}
                </div>
                {/* <div className=" p-2 flex justify-center items-center">
                  <i
                    onClick={() => {
                      setUpperCuttingDateFocus(true);
                    }}
                    className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                  ></i>
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex grow  ">
            <div className="flex flex-col w-full ml-2  ">
              <div className="w-full  flex flex-col  grow ">
                <div className="flex  ">
                  <div
                    style={{
                      border: '1px solid #fad9cd',
                    }}
                    className=" rounded-t-sm   flex grow"
                  >
                    <div
                      style={{
                        borderRight: '1px solid #fad9cd',
                      }}
                      onClick={() => {
                        if (!lowerTryDate) {
                          if (selectedLowerItemDetails) {
                            setLowerTryDateFocus(true);
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'First select any item',
                              })
                            );
                          }
                        }
                      }}
                      className="w-1/2 text-ggray  flex items-center "
                    >
                      <div className="-mt-10  bg-white px-2 absolute">
                        Try -
                      </div>
                      <div className=" flex   py-1 justify-center items-center">
                        {lowerTryDate || lowerTryDateFocus ? (
                          <ReactDatePicker
                            readOnly={true}
                            onFocus={() => setLowerTryDateFocus(true)}
                            onClickOutside={() => setLowerTryDateFocus(false)}
                            onSelect={() => setLowerTryDateFocus(false)}
                            open={lowerTryDateFocus}
                            className=" bg-transparent text-sm w-[100px]  text-center outline-0   "
                            dateFormat="d MMM , yyyy"
                            selected={
                              lowerTryDate
                                ? parseISO(lowerTryDate)
                                : parseISO(moment().format('YYYY-MM-DD'))
                            }
                            onChange={(date) => {
                              if (moment(date).isAfter(moment(bookingDate))) {
                                dispatch(
                                  setLowerDate({
                                    ...lowerDate,
                                    try: moment(date).format('YYYY-MM-DD'),
                                    // del: moment(date)
                                    //   .add(3, 'days')
                                    //   .format('YYYY-MM-DD'),
                                  })
                                );

                                if (selectedUpperItemDetails?.lower_icode > 0) {
                                  dispatch(
                                    setUpperDate({
                                      ...upperDate,
                                      try: moment(date).format('YYYY-MM-DD'),
                                      // del: moment(date)
                                      //   .add(3, 'days')
                                      //   .format('YYYY-MM-DD'),
                                    })
                                  );
                                }
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert !',
                                    msg: 'Only future dates are allowed',
                                  })
                                );
                              }
                              setLowerTryDateFocus(false);
                            }}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                      {/* <div className=" flex grow p-2 justify-center items-center">
                        <i
                          onClick={() => {
                            setLowerTryDateFocus(true);
                          }}
                          className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                        ></i>
                      </div> */}
                    </div>
                    <div className="grow flex">
                      <div className="  rounded-br-sm  text-ggray items-center pl-1  flex grow">
                        <div className="-mt-10  bg-white px-2 absolute">
                          Del. -
                        </div>
                        <div className=" flex  py-1 justify-center  items-center">
                          <ReactDatePicker
                            readOnly={true}
                            onFocus={(e) => {
                              if (selectedLowerItemDetails) {
                                setLowerDeliveryDateFocus(true);
                              } else {
                                e.currentTarget.blur();
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: 'First select any item',
                                  })
                                );
                              }
                            }}
                            onClickOutside={() =>
                              setLowerDeliveryDateFocus(false)
                            }
                            onSelect={() => setLowerDeliveryDateFocus(false)}
                            open={lowerDeliveryDateFocus}
                            className=" bg-transparent text-sm w-[100px]  text-center outline-0"
                            dateFormat="d MMM , yyyy"
                            selected={parseISO(lowerDeliveryDate)}
                            onChange={(date) => {
                              if (moment(date).isAfter(moment(bookingDate))) {
                                dispatch(
                                  setLowerDate({
                                    ...lowerDate,
                                    del: moment(date).format('YYYY-MM-DD'),
                                  })
                                );
                                if (selectedUpperItemDetails?.lower_icode > 0) {
                                  dispatch(
                                    setUpperDate({
                                      ...upperDate,
                                      del: moment(date).format('YYYY-MM-DD'),
                                      // del: moment(date)
                                      //   .add(3, 'days')
                                      //   .format('YYYY-MM-DD'),
                                    })
                                  );
                                }
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert !',
                                    msg: 'Only future dates are allowed',
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                        {/* <div className=" grow p-2 flex justify-center items-center">
                          <i
                            onClick={() => {
                              setLowerDeliveryDateFocus(true);
                            }}
                            className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                          ></i>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex text-red ">
                  <div
                    onClick={() => {
                      if (!lowerCuttingDate) {
                        if (selectedLowerItemDetails) {
                          setLowerCuttingDateFocus(true);
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'First select any item',
                            })
                          );
                        }
                      }
                    }}
                    style={{
                      border: '1px solid #fad9cd',
                      borderTop: '0px solid #fad9cd',
                      borderRight: '0px solid #fad9cd',
                    }}
                    className="p-1 pl-3 flex items-center text-sm rounded-bl-sm "
                  >
                    Cutting -
                  </div>
                  <div
                    style={{
                      border: '1px solid #fad9cd',
                      borderTop: '0px solid #fad9cd',
                      borderLeft: '0px solid #fad9cd',
                    }}
                    className="  rounded-br-sm   flex grow"
                  >
                    <div className=" flex  p-1 justify-center items-center">
                      {lowerCuttingDate || lowerCuttingDateFocus ? (
                        <ReactDatePicker
                          readOnly={true}
                          onFocus={() => setLowerCuttingDateFocus(true)}
                          onClickOutside={() => setLowerCuttingDateFocus(false)}
                          onSelect={() => setLowerCuttingDateFocus(false)}
                          open={lowerCuttingDateFocus}
                          className=" bg-transparent text-sm w-[100px] text-center outline-0"
                          dateFormat="d MMM , yyyy"
                          selected={
                            lowerCuttingDate
                              ? parseISO(lowerCuttingDate)
                              : parseISO(moment().format('YYYY-MM-DD'))
                          }
                          onChange={(date) => {
                            dispatch(
                              setLowerDate({
                                ...lowerDate,
                                cutting: moment(date).format('YYYY-MM-DD'),
                              })
                            );

                            if (selectedUpperItemDetails?.lower_icode > 0) {
                              dispatch(
                                setUpperDate({
                                  ...upperDate,
                                  cutting: moment(date).format('YYYY-MM-DD'),
                                  // del: moment(date)
                                  //   .add(3, 'days')
                                  //   .format('YYYY-MM-DD'),
                                })
                              );
                            }
                          }}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                    {/* <div className="  p-2 flex justify-center items-center">
                      <i
                        onClick={() => {
                          setLowerCuttingDateFocus(true);
                        }}
                        className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                      ></i>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ......................................button area................................................................................................. */}
        <div className="flex w-full mt-2 ">
          <div className="w-1/2 flex">
            <AttachImage
              attachImage={attachImage}
              bookingAttachImage={bookingAttachImage}
              attachImageModStatus={attachImageModStatus}
              setAttachImageModStatus={setAttachImageModStatus}
              setAttachImage={setAttachImage}
              setAttachImageFolder={setAttachImageFolder}
              attachImageFolder={attachImageFolder}
              bookingCustomerDetails={bookingCustomerDetails}
            />
            {modifiedBookingID && (
              <Button
                // ref={updateRef}
                onClick={() => {
                  clearBookingFields();
                }}
                variant="contained"
                style={{
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#616161',
                  height: '100%',
                  fontWeight: 500,
                  marginLeft: 7,
                }}
              >
                <div className="text-white  px-3  ">Undo </div>
              </Button>
            )}
          </div>
          <div className="   flex grow ml-2 h-full ">
            {modifiedBookingID ? (
              <div className="h-full w-full flex">
                <BillingUpdate_button
                  onClick={saveBooking}
                  saveLoading={saveLoading}
                />{' '}
                <div className="ml-1">
                  <PrintButton
                    onClick={() => {
                      setBookingOrderNumber(
                        historyOrderDetails[0].BOOKING_ORDER_NO
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <BillingSave_button
                onClick={saveBooking}
                saveLoading={saveLoading}
              />
            )}

            <div className="ml-1">
              {modifiedBookingID ? (
                <div className="h-full w-full">
                  <Button
                    // ref={updateRef}
                    onClick={() => {
                      setAlertTwoButtonModal({
                        title: 'Void Bill',
                        msg: 'Are you sure to void this bill',
                        status: true,
                        setStatus: '',
                        button1: 'Void',
                        button1Click: () => {
                          setAlertTwoButtonModal({
                            title: '',
                            msg: '',
                            status: false,
                            setStatus: '',
                            button1: '',
                            button1Click: '',
                            button2: '',
                            button2Click: '',
                          });
                          deleteItems();
                        },

                        button2: 'Cancel',
                        button2Click: () => {
                          setAlertTwoButtonModal({
                            title: '',
                            msg: '',
                            status: false,
                            setStatus: '',
                            button1: '',
                            button1Click: '',
                            button2: '',
                            button2Click: '',
                          });
                        },
                      });
                    }}
                    variant="contained"
                    style={{
                      textTransform: 'none',
                      letterSpacing: '1px',
                      backgroundColor: 'red',
                    }}
                  >
                    <div className="text-white font-sans px-3  "> Void </div>
                  </Button>
                </div>
              ) : (
                <Button
                  // ref={updateRef}
                  onClick={() => {
                    clearBookingFields();
                  }}
                  variant="contained"
                  style={{
                    textTransform: 'none',
                    letterSpacing: '1px',
                    backgroundColor: '#616161',
                    height: '100%',
                    fontWeight: 500,
                  }}
                >
                  <div className="text-white  px-3  ">Undo </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
