import { Button, Radio } from '@mui/material';
import Lottie from 'lottie-react';
import spinner from '../../utility/json/Spinner.json';
import { TiArrowBack } from 'react-icons/ti';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
const Modify_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#ebaf81',
        }}
      >
        <div className="text-black  px-3">Modify </div>
      </Button>
    </>
  );
};

const Save_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#c1ea9f',
        }}
      >
        <div className="text-black px-5 ">Save </div>
      </Button>
    </>
  );
};
const Whatsapp_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          width: '100%',

          backgroundColor: '#60d26d',
          height: '100%',
        }}
      >
        <div
          style={{
            fontWeight: '400',
          }}
          className="text-white text-[15px] px-5 flex items-center "
        >
          WhatsApp{' '}
        </div>
      </Button>
    </>
  );
};

const Add_Item = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#f3f4f6',
        width: '100%',
        borderRadius: '0px',
        border: '1px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        color: '#383535',
        height: '100% ',
        boxShadow: '1px 1px 1px  #cfccca',
      }}
    >
      <div className="  "> + Add Item </div>
    </Button>
  );
};

const Signup_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#373435',
          color: 'white',
          width: '100%',
        }}
      >
        <div className=" px-4 ">Sign Up </div>
      </Button>
    </>
  );
};

const ArrowBack_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#373435',
          color: 'white',
          marginRight: '8px',
          width: '100%',
        }}
      >
        <div className="  "> {'<'} Back </div>
      </Button>
    </>
  );
};

const Next_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#373435',
          color: 'white',
          width: '100%',
        }}
      >
        <div className=" px-4 ">Next </div>
      </Button>
    </>
  );
};

const BookingItemDetailsButton = ({
  onClick,
  bg,
  title,
  type,
  icode,
  isSelected,
}) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: bg,
          border: isSelected ? '1px solid gray' : '0px solid gray',
          padding: '8px',
          flex: 1,
        }}
      >
        <div className="text-gray-700  py-1 w-full   flex justify-between items-center ">
          {' '}
          <label className=" flex items-center  ">{title}</label>
          <img
            src={require(`../images/${type === 'UPPER' ? '1' : '3'}.png`)}
            style={{
              height: window.innerWidth <= 1024 ? '20px' : '30px',
              width: window.innerWidth <= 1024 ? '20px' : '30px',
            }}
          />
        </div>
      </Button>
    </>
  );
};

const BillingSave_button = ({ onClick, saveLoading }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#ffe76a',
        fontWeight: 500,
        height: '100%',
        width: '100%',
        flex: 1,
        marginRight: 3,
      }}
    >
      <div className="text-black px-2 justify-center items-center h-full flex whitespace-nowrap grow  hover:cursor-pointer">
        {' '}
        <label className="whitspace-nowrap flex ">
          Save <label className="hidden ml-1  d:flex">Measurements</label>{' '}
        </label>
      </div>
    </Button>
  );
};

const BillingUpdate_button = ({ onClick, saveLoading }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#ffe76a',
        fontWeight: 500,
        height: '100%',
        width: '100%',
        flex: 1,
      }}
    >
      <div className="text-black px-2 justify-center items-center h-full flex whitespace-nowrap grow  hover:cursor-pointer">
        {' '}
        <label className="whitspace-nowrap flex ">
          Update <label className="hidden ml-1  d:flex">Measurements</label>{' '}
        </label>
      </div>
      {saveLoading ? (
        <div className="w-6 h-6">
          <Lottie
            animationData={spinner}
            loop={true}
            style={{ fontSize: 15 }}
            color="red"
          />
        </div>
      ) : null}
    </Button>
  );
};

const BookingCancelButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fa8572',
          // width: '55%',
          fontWeight: '500',
        }}
      >
        <div className="text-black d:px-8  ">Clear </div>
      </Button>
    </>
  );
};

const BookingSaveImage = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#ebd9dd',
          width: '100%',
          fontWeight: '500',

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="text-black border border-red  ">Save Image </div>
      </Button>
    </>
  );
};

const PrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        letterSpacing: '1px',
        backgroundColor: '#bad4f9',
        marginLeft: 4,
        marginRight: 4,
        textTransform: 'none',
        color: 'white',
        display: 'flex',
      }}
    >
      <div className="text-black px-4    flex items-center text-center   ">
        <div>Print </div>
      </div>
    </Button>
  );
};
const PrintButtonInvoice = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        width: '100%',
        letterSpacing: '1px',
        backgroundColor: '#3595f0',
      }}
    >
      <div
        style={{
          fontWeight: '400',
        }}
        className="text-white text-[15px] px-5 flex items-center "
      >
        Print{' '}
      </div>
    </Button>
  );
};

const DownoaddButtonInvoice = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        width: '100%',
        letterSpacing: '1px',
        backgroundColor: '#3595f0',
      }}
    >
      <div
        style={{
          fontWeight: '400',
        }}
        className="text-white text-[15px] px-5 flex items-center "
      >
        Download{' '}
      </div>
    </Button>
  );
};

const SavePrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#f5874f',
        marginRight: 3,
      }}
    >
      <div className="text-black px-5  "> Save & Print </div>
    </Button>
  );
};

const OkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
          marginLeft: 4,
        }}
      >
        <div className="text-black   px-3 ">Ok </div>
      </Button>
    </>
  );
};

const Alert1Button = ({ onClick, text }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          width: '100%',
          marginLeft: 4,
        }}
      >
        <div className="text-white   px-3 ">{text} </div>
      </Button>
    </>
  );
};

const Alert2Button = ({ onClick, text }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#6b6969',
        width: '100%',
        marginLeft: 4,
      }}
    >
      <div className="text-white   px-3 ">{text}</div>
    </Button>
  );
};

const AlertOkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#1c1a1a',
          width: '100%',
          marginLeft: 4,
        }}
      >
        <div className="text-white   px-3 ">Ok </div>
      </Button>
    </>
  );
};

const OkButtonAttachButtton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#403e3e',
          marginLeft: 4,
        }}
      >
        <div className="text-[#cfcfcf]   px-3 ">Ok </div>
      </Button>
    </>
  );
};

const Undo_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#616161',
          height: '100%',
          fontWeight: 500,
        }}
      >
        <div className="text-white  px-3  ">Undo </div>
      </Button>
    </>
  );
};

const AddToCartButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fdd7c5',
          width: '100%',
          height: '100%',
          padding: '0px',
        }}
      >
        <div className="text-brown flex items-center h-full">Add to Bill</div>
      </Button>
    </>
  );
};

const Delete_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#616161',
        }}
      >
        <div className="text-white  px-3  "> Void </div>
      </Button>
    </>
  );
};
const ExitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#f1f5f9',
          marginLeft: 4,
        }}
      >
        <div className="text-black  px-2 ">Exit </div>
      </Button>
    </>
  );
};

const BackButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          border: '1px dotted gray',
          marginLeft: '3px',
        }}
      >
        <div className="text-black hover:text-white   flex justify-center w-full whitespace-nowrap px-2 ">
          <i
            style={{
              fontSize: '14px',
            }}
            className="bi bi-reply"
          ></i>
          <div className="flex  items-center pl-1">Back </div>
        </div>
      </Button>
    </>
  );
};

const BillingBackButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          textTransform: 'none',
          backgroundColor: '#fec24a',
          width:
            window.innerWidth <= 768 ? (window.innerWidth / 100) * 48 : '100%',
        }}
      >
        <div className="text-black  w-full  hover:text-white  h-full flex justify-center items-center whitespace-nowrap px-2 ">
          <div className="flex items-center w-fit ">
            <i
              style={{
                fontSize: '15px',
              }}
              className="bi bi-reply -mt-[2px] flex items-center "
            ></i>
            {/* <TiArrowBack /> */}
          </div>
          <div className=" pl-1  text-md flex items-center ">Back </div>
        </div>
      </Button>
    </>
  );
};

const BackToLogin = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          width: '100%',
          marginTop: '8px',
          height: '32px',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-black   whitespace-nowrap "
        >
          <ReplyOutlinedIcon
            sx={{
              fontSize: '22px',
            }}
          />
          Back{' '}
        </div>
      </Button>
    </>
  );
};

const RegisterNewUser = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          width: '100%',

          height: '36px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
          }}
          className="text-black   whitespace-nowrap "
        >
          SignUp
        </div>
      </Button>
    </>
  );
};

const UserLogin = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          width: '100%',

          height: '36px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
          }}
          className="text-white   whitespace-nowrap "
        >
          Login
        </div>
      </Button>
    </>
  );
};

const GetOtpButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}

        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-white   whitespace-nowrap px-16"
        >
          Send OTP
        </div>
      </Button>
    </>
  );
};

const SubmitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',

          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '15px',
          }}
          className="text-white   whitespace-nowrap w-full"
        >
          Submit
        </div>
      </Button>
    </>
  );
};

const GeniusOfficeButton = ({ onClick, title, color, checked }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          boxShadow: 'none',
        }}
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: color ? color : '#f1f5f9',
          marginLeft: 4,
          padding: 0,
        }}
      >
        <div
          style={{
            fontSize: 13,
          }}
          className="text-black   whitespace-nowrap flex items-center   pr-3 "
        >
          <Radio
            checked={checked}
            color="success"
            size="small"
            sx={{ backgroundColor: 'white', color: 'white' }}
          />
          {title}
        </div>
      </Button>
    </>
  );
};

export {
  Modify_button,
  PrintButton,
  Save_button,
  BookingCancelButton,
  Undo_button,
  OkButton,
  Add_Item,
  DownoaddButtonInvoice,
  ExitButton,
  OkButtonAttachButtton,
  GetOtpButton,
  BackButton,
  PrintButtonInvoice,
  SubmitButton,
  Alert1Button,
  BackToLogin,
  GeniusOfficeButton,
  SavePrintButton,
  RegisterNewUser,
  UserLogin,
  Delete_button,
  Alert2Button,
  AlertOkButton,
  BookingSaveImage,
  BillingSave_button,
  BillingBackButton,
  Signup_button,
  AddToCartButton,
  Next_button,
  Whatsapp_button,
  ArrowBack_button,
  BillingUpdate_button,
  BookingItemDetailsButton,
};
