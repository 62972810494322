import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyAddress } from '../utility/Redux/profile';
import {
  selectModUserListStatus,
  setModUserListStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import CryptoJS from 'crypto-js';
import { Button } from '@mui/material';
import axios from 'axios';
import ModUserList from '../help/modUserList';

const AdminDashboard = () => {
  const companyDetails = useSelector(selectCompanyAddress);
  const [companyList, setCompanyList] = useState([]);
  const dispatch = useDispatch();

  const getCompanyInfo = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(companyDetails.email, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    await axios
      .post(
        '/api/users/companyHelp',
        {
          company_email: companyDetails.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((p) => {
        setCompanyList(p.data.response);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const [selectedData, setSelectedData] = useState(null);

  const handleButtonClick = (e) => {
    const email = e.company_email;
    const userEmail = e.user_email;
    const companyName = e.company_name;
    const tUser = e.tusers;
    setSelectedData({ email, userEmail, companyName, tUser });
    dispatch(setModUserListStatus(true));
  };

  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-6 gap-3  ">
        {companyList.map((e, i) => {
          return (
            <Button
              key={i}
              variant={'contained'}
              style={{
                textTransform: 'none',
                backgroundColor: 'transparent',
                padding: '0px',
                borderRadius: '7px',
                overflow: 'hidden',
              }}
              onClick={() => handleButtonClick(e)}
            >
              <div className="bg-[#E8E2DB] py-3 w-full whitespace-nowrap text-[#302d2d] px-3 ">
                <div className="w-full flex justify-start font-semibold text-brown">
                  <i className="bi bi-briefcase-fill flex items-center mr-2 text-brown"></i>{' '}
                  {e.company_name}
                </div>
                <div className="w-full flex justify-end">
                  {' '}
                  <i className="bi bi-people-fill mr-2 text-[#b4b4b1] "></i>
                  {e.tusers}
                </div>
              </div>
            </Button>
          );
        })}
      </div>

      {selectModUserListStatus && (
        <ModUserList
          selectedData={selectedData}
          onClose={() => setModUserListStatus(false)}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
