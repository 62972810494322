import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectDate,
  selectDefultProfileSetting,
  selectDepartmentName,
  selectPrefix,
  selectUserType,
} from '../utility/Redux/profile';
import moment from 'moment';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { FormatNumber2 } from '../utility/component/tools';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import Lottie from 'lottie-react';
import spinner from '../utility/json/Spinner.json';
import { useNavigate } from 'react-router-dom';
import { getMessageWallet, low_Bal_alert } from '../utility/func/common';
import { not_permisson_forWhastapp } from '../utility/common';
export const HomeDashboard = () => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [todaySale, setTodaySale] = useState();
  const [weeklySale, setWeeklySale] = useState([]);
  const [monthlySale, setMonthlySale] = useState([]);
  const [todayBookings, setTodayBookings] = useState([]);
  const [todayExpDelivery, setTodayExpectedDelivery] = useState([]);
  const [todayAdvancePaymentReciept, setTodayAdvancePaymentReciept] = useState(
    []
  );

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const companyDetail = useSelector(selectCompanyAddress);
  const [weeklyExpDelivery, setWeeklyExpDelivery] = useState([]);
  const [monthlyExpDelivery, setMonthlyExpDelivery] = useState([]);
  const [monthlyDelCount, setMonthlyDelCount] = useState([]);
  const [weeklyDelCount, setWeeklyDelCount] = useState([]);
  const [todayCanceledBookings, setTodayCanceledBookings] = useState([]);
  const [todayTry, setTodayExpectedTry] = useState([]);
  const [todayDeliveredItem, setTodayDeliverdItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkCurrentDate = useSelector(selectDate);
  const [mobile, setMobile] = useState('');
  const [firstExpersion, setFirstExp] = useState(true);
  const defultProfile = useSelector(selectDefultProfileSetting);
  const userType = useSelector(selectUserType);
  const getTodaySale = () => {
    setLoading(true);
    axios
      .post(
        `/api/dashBoardRoute/todaySale`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodaySale(response.data.response[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayPaymentReciept();
      });
  };

  const getTodayPaymentReciept = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayPaymentReceipt`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayAdvancePaymentReciept(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getWeeklySale();
      });
  };

  const getWeeklySale = () => {
    axios
      .post(
        `/api/dashBoardRoute/weeklySale`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate)
            .subtract(7, 'days')
            .format('YYYY-MM-DD'),
          TDATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then(function (response) {
        if (!response.data.error) {
          setWeeklySale(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getMonthlySale();
      });
  };
  const departmentName = useSelector(selectDepartmentName);
  const sendtryMsgAPI = (
    name,
    mobile,
    tryDate,
    time,
    companyname,
    compnyMobile,
    email
  ) => {
    const data = {
      CUSTOMER_NAME: name,
      MOBILE: mobile,
      TRY_DATE: tryDate ? moment(tryDate).format('YYYY-MM-DD') : '',
      TIME: time,
      COMPANY_NAME: companyname,
      COMPANY_MOBILE: compnyMobile,
      COMPANY_EMAIL: email,
    };
    dispatch(setSpinnerLoading('Sending Message'));
    axios
      .post(`/api/sendDigitalBill/sendTryDateWhatsApp`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(
          setAlertWithTitle({
            tilte: 'success',
            msg: 'Whatsapp message sent successfully',
            lottie: 'success',
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(
          setAlertWithTitle({
            tilte: 'Error',
            msg: 'Whatsapp message not sent',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const sendDelMsgAPI = (
    name,
    mobile,
    delDate,
    time,
    companyname,
    compnyMobile,
    email
  ) => {
    const data = {
      CUSTOMER_NAME: name,
      MOBILE: mobile,
      DEL_DATE: delDate ? moment(delDate).format('YYYY-MM-DD') : '',
      TIME: time,
      COMPANY_NAME: companyname,
      COMPANY_MOBILE: compnyMobile,
      COMPANY_EMAIL: email,
    };
    dispatch(setSpinnerLoading('Sending Message'));
    axios
      .post(`/api/sendDigitalBill/sendDelDateWhatsApp`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(
          setAlertWithTitle({
            tilte: 'success',
            msg: 'Whatsapp message sent successfully',
            lottie: 'success',
          })
        );
      })
      .catch(function (error) {
        console.log(error);

        dispatch(
          setAlertWithTitle({
            tilte: 'Error',
            msg: 'Whatsapp message not sent',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const getMonthlySale = () => {
    axios
      .post(
        `/api/dashBoardRoute/monthlySale`,
        {
          table_prefix: prefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then(function (response) {
        if (!response.data.error) {
          setMonthlySale(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayBooking();
      });
  };
  const getTodayBooking = (m) => {
    axios
      .post(
        `/api/dashBoardRoute/todayBookings`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayBookings(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        setTodayBookings([]);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayExpectedDelivery();
      });
  };

  const getTodayExpectedDelivery = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayExpDelivery`,
        {
          table_prefix: prefix,
          DEL_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayExpectedDelivery(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getWeeklyDelCount();
      });
  };

  const getWeeklyDelCount = () => {
    axios
      .post(
        `/api/dashBoardRoute/weeklyDeliveryCount`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          TDATE: moment(checkCurrentDate).add(7, 'days').format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setWeeklyDelCount(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getMonthlyDelCount();
      });
  };

  const getMonthlyDelCount = () => {
    axios
      .post(
        `/api/dashBoardRoute/monthlyDeliveryCount`,
        {
          table_prefix: prefix,
          FDEL_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          TDEL_DATE: moment(checkCurrentDate)
            .add(30, 'days')
            .format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setMonthlyDelCount(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayCanceldBooking();
      });
  };

  const getTodayCanceldBooking = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayCanceledBookings`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayCanceledBookings(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayExpectedTry();
      });
  };

  const getTodayExpectedTry = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayExpTry`,
        {
          table_prefix: prefix,
          TRY_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayExpectedTry(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayDeliveredItems();
      });
  };

  const getTodayDeliveredItems = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayDelivered`,
        {
          table_prefix: prefix,
          F_DELIVERED_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          T_DELIVERED_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
          MOBILE: mobile,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayDeliverdItem(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function setDeliveryStatusAPI(bookingNo, icode, status) {
    axios
      .post(
        `/api/booking/deliverItem`,
        {
          table_prefix: prefix,
          BOOKING_ORDER_NO: bookingNo,
          ICODE: icode,
          DELIVERED_YN: status,
          DELIVERED_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,

            status: false,
          });

          axios
            .post(
              `/api/dashBoardRoute/todayExpDelivery`,
              {
                table_prefix: prefix,
                DEL_DATE: moment(checkCurrentDate).format('YYYY-MM-DD'),
                MOBILE: mobile,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
              if (!response.data.error) {
                setTodayExpectedDelivery(response.data.response);
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error.response.data.name === 'TokenExpiredError') {
                dispatch(setSessionExpired(true));
              }
            });
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 126',
              msg: response.data.error,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'Error 125',
            msg: 'Something went wrong while Updating Delivery status',
          })
        );
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  }

  useEffect(() => {
    getTodaySale();
  }, [checkCurrentDate]);

  useEffect(() => {
    if (!mobile && !firstExpersion) {
      getTodayBooking();
    }
  }, [mobile]);

  return (
    <div className="h-full w-full text-md  overflow-y-scroll p-3  ">
      {todaySale && departmentName !== 'Delivery' ? (
        <div className="w-full">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit flex px-3 py-1  text-white   rounded-br-3xl bg-[#634258]"
          >
            Today Sale :{' '}
            <div
              style={{
                fontWeight: '400',
              }}
              className="pl-2"
            >
              {FormatNumber2(
                todaySale.CASH_AMOUNT +
                  todaySale.CARD_AMOUNT +
                  todaySale.EPAYMENT +
                  todaySale.ADVANCE_AMT
              )}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todaySale ? (
              <>
                {todaySale.CASH_AMOUNT !== 0 ? (
                  <Button
                    variant={'contained'}
                    style={{
                      textTransform: 'none',

                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor: '#e8e2db',
                      }}
                      className="flex justify-between w-full p-[0px] h-full rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Cash</div>
                        <div className="text-[#615b5b]">
                          {todaySale.CASH_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CASH_AMOUNT)
                            : ' '}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.CARD_AMOUNT !== 0 ? (
                  <Button
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor: '#e8e2db',
                      }}
                      className="flex justify-between h-full w-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Card</div>
                        <div className="text-[#615b5b]">
                          {' '}
                          {todaySale.CARD_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CARD_AMOUNT)
                            : ' '}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.EPAYMENT !== 0 ? (
                  <Button
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor: '#e8e2db',
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">ePayment</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.EPAYMENT !== 0
                            ? FormatNumber2(todaySale.EPAYMENT)
                            : ' '}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.ADVANCE_AMT !== 0 ? (
                  <Button
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor: '#e8e2db',
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Advance</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.ADVANCE_AMT !== 0
                            ? FormatNumber2(todaySale.ADVANCE_AMT)
                            : null}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* Today Advance */}
      {todayAdvancePaymentReciept[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit whitespace-nowrap flex  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Today Advance & Receipts :{'  '}
            <div
              style={{
                fontWeight: '400',
              }}
              className="pl-2"
            >
              {FormatNumber2(todayAdvancePaymentReciept[0].CREDIT)}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayAdvancePaymentReciept[0]
              ? todayAdvancePaymentReciept.map((e, index) => {
                  return (
                    <Button
                      key={index}
                      variant={'contained'}
                      style={{
                        textTransform: 'none',
                        backgroundColor: 'transparent',
                        padding: '0px',
                        marginBottom: '8px',
                        boxShadow: 'none',
                      }}
                    >
                      <div
                        style={{
                          boxShadow: '1px 1px 1px gray',
                          backgroundColor: '#e8e2db',
                        }}
                        className="flex justify-between w-full p-[0px] rounded-sm"
                      >
                        <div className="bg-[#e8e2db] pb-1  rounded-sm">
                          <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                            {e.PAY_MODE}
                          </div>
                          <div className="px-2 text-[#615b5b]">
                            {FormatNumber2(e.CREDIT)}
                          </div>
                        </div>
                      </div>
                    </Button>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {weeklySale[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit flex px-3 py-1  text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Weekly Sale{' '}
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {weeklySale.map((w, index) => (
              <Button
                key={index}
                variant={'contained'}
                style={{
                  textTransform: 'none',

                  backgroundColor: 'transparent',
                  padding: '0px',
                  marginBottom: '8px',
                  boxShadow: 'none',
                }}
              >
                <div
                  style={{
                    boxShadow: '1px 1px 1px gray',
                    backgroundColor: '#e8e2db',
                  }}
                  className="flex justify-between w-full p-[0px] h-full rounded-sm"
                >
                  <div className="p-1 px-2 text-[#0d0c0c]">
                    <div className="text-start ">{w.DAY_NAME}</div>
                    <div className="text-[#615b5b]">
                      <div className="flex ">
                        Total :{'  '}
                        {FormatNumber2(
                          w.CASH_AMOUNT + w.CARD_AMOUNT + w.EPAYMENT
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      ) : null}

      {monthlySale[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit flex  px-3 py-1  text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Monthly Sale{' '}
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {monthlySale.map((w, index) => (
              <Button
                key={index}
                variant={'contained'}
                style={{
                  textTransform: 'none',

                  backgroundColor: 'transparent',
                  padding: '0px',
                  marginBottom: '8px',
                  boxShadow: 'none',
                }}
              >
                <div
                  style={{
                    boxShadow: '1px 1px 1px gray',
                    backgroundColor: '#e8e2db',
                  }}
                  className="flex justify-between w-full p-[0px] h-full rounded-sm"
                >
                  <div className="p-1 px-2 text-[#0d0c0c]">
                    <div className="text-start ">{w.MONTH_NAME}</div>
                    <div className="text-[#615b5b]">
                      <div className="flex ">
                        Total :{' '}
                        {FormatNumber2(
                          w.CASH_AMOUNT + w.CARD_AMOUNT + w.EPAYMENT
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      ) : null}
      <div className="mt-3 flex items-center">
        <TextField
          size="small"
          InputProps={{
            sx: {
              fontSize: '14px',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '0.5px solid black',
              },
              padding: '0px',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#03a9f4',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '0.1px solid #03a9f4',
              },
            },
            endAdornment: (
              <InputAdornment
                sx={{
                  padding: '0px',
                }}
                position="end"
              >
                <button
                  onClick={() => {
                    getTodayBooking();
                  }}
                  className="h-[37px] rounded-r-sm px-[12px] bg-red  "
                >
                  <i className="bi text-white bi-search flex items-center"></i>
                </button>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontSize: '14px',
            },
          }}
          type="mobile"
          name="email"
          id="email"
          label="Search By Mobile"
          onChange={(e) => {
            setFirstExp(false);
            setMobile(e.target.value);
          }}
          value={mobile}
        />

        <div className="h-full flex items-center ml-2">
          {/* <button
            onClick={() => {
              getTodayBooking();
            }}
            className="bg-green p-2 shadow-md rounded-sm px-3"
          >
            Search
          </button> */}

          {departmentName === 'Delivery' && (
            <button
              onClick={() => {
                navigate('/Scan_Qr_Code');
              }}
              className="bg-sky-300  p-2  shadow-md rounded-sm px-3"
            >
              <i className="bi bi-qr-code-scan"></i>
            </button>
          )}
        </div>
      </div>

      {/* Today Booking */}
      {todayBookings[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit px-3 py-1 text-black   rounded-br-3xl bg-[#f8c469]"
          >
            Today Bookings
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayBookings[0]
              ? todayBookings.map((e, index) => {
                  return (
                    <Button
                      key={index}
                      variant={'contained'}
                      style={{
                        textTransform: 'none',
                        backgroundColor: 'transparent',
                        padding: '0px',
                        marginBottom: '8px',
                        boxShadow: 'none',
                      }}
                    >
                      <div
                        style={{
                          boxShadow: '1px 1px 1px gray',
                          backgroundColor:
                            e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                        }}
                        className="flex justify-between w-full text-sm pl-1 pb-2 pt-2 rounded-sm"
                      >
                        <div className=" p-2 px-2 w-full  flex  justify-between rounded-sm">
                          <div className="text-[#0d0c0c] text-start">
                            {e.ITEM_NAME + '-' + ' ' + e.QNTY}
                          </div>
                          <div className="text-[16px]  ">
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                              }.png`)}
                              style={{
                                height: '25px',
                                width: '25px',
                              }}
                            />{' '}
                          </div>
                        </div>
                      </div>
                    </Button>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {/* Today Delieveries */}
      {todayExpDelivery[0] ? (
        <div className="w-full mt-4">
          <div className="flex items-center  w-fit">
            <div
              style={{
                border: '1px solid #b5b3b3',
                fontSize: '13px  ',
                fontWeight: '500',
              }}
              className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f1f4c6]"
            >
              Today Expected Deliveries
            </div>{' '}
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayExpDelivery.map((e, index) => {
              return (
                <Button
                  key={index}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    marginBottom: '8px',
                    boxShadow: 'none',
                  }}
                  onClick={() => {
                    if (userType === 'PRODUCTION') {
                      navigate('/billing', {
                        state: {
                          customer: {
                            mobile: e.MOBILE,
                            customer_name: e.CUSTOMER_NAME,
                            mrc: e.MRC_NO,
                            prefix: e.PREFIX,
                          },
                          orderID: e.BOOKING_ORDER_NO,
                        },
                      });
                    } else {
                      if (defultProfile?.SEND_WHATSAPP === 'Y') {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          title: 'Send Whatsapp Message ',
                          msg: 'Do you want to send whatsapp message ?',
                          status: true,
                          button1: 'YES',
                          button1Click: () => {
                            setAlertTwoButtonModal({
                              ...alertTwoButtonModal,

                              status: false,
                            });
                            getMessageWallet(companyDetail.email, token).then(
                              (bal) => {
                                if (bal > 0) {
                                  sendDelMsgAPI(
                                    e.CUSTOMER_NAME,
                                    e.MOBILE,
                                    moment().format('YYYY-MM-DD'),
                                    '03:00 pm',
                                    companyDetail.companyName,
                                    companyDetail.mobile,
                                    companyDetail.email
                                  );
                                } else {
                                  dispatch(
                                    setAlertWithTitle({
                                      title: 'Alert',
                                      msg: low_Bal_alert,
                                    })
                                  );
                                }
                              }
                            );
                          },
                          button2: 'No',
                          button2Click: () => {
                            setAlertTwoButtonModal({
                              ...alertTwoButtonModal,
                              status: false,
                            });
                          },
                        });
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: not_permisson_forWhastapp,
                          })
                        );
                      }
                    }
                  }}
                >
                  <div
                    style={{
                      boxShadow: '1px 1px 1px gray',
                      backgroundColor:
                        e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                    }}
                    className="flex h-full justify-between w-full p-[0px] rounded-sm"
                  >
                    <div className="rounded-sm  w-full p-1 px-2">
                      <div className="   flex  justify-between w-full ">
                        <div className="font-semibold text-[#0d0c0c] text-start">
                          {e.ITEM_NAME + '-' + ' ' + e.QNTY}
                        </div>
                        <div>
                          <img
                            src={require(`../utility/images/${
                              e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                            }.png`)}
                            style={{
                              height: '25px',
                              width: '25px',
                            }}
                          />
                        </div>
                      </div>

                      <div className="text-sm text-start text-[#615b5b]">
                        {e.CUSTOMER_NAME}
                      </div>
                      <div className="text-start text-[#615b5b]">
                        {e.MOBILE}
                      </div>
                      <div className="flex justify-between ">
                        <div className="text-[#615b5b]">{e.CITY}</div>
                        <div
                          className={`w-[18px] h-[18px] ${
                            e.DELIVERED_YN === 'YES' ? 'bg-green' : 'bg-red'
                          } flex justify-center items-center  rounded-sm`}
                        >
                          {e.DELIVERED_YN === 'YES' ? (
                            <i className="bi bi-check2 justify-center flex items-center text-white"></i>
                          ) : (
                            <i className="bi bi-x justify-center flex items-center text-white"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      ) : null}

      {/* Today Try */}
      {todayTry[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4">
          <div className="flex items-center bg-[#f1f4c6] w-fit">
            <div
              style={{
                borderRight: '1px solid #b5b3b3',
                zIndex: 100,
              }}
              className="flex pr-1  rounded-br-3xl bg-white"
            >
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  fontSize: '13px  ',
                  fontWeight: '500',
                }}
                className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f1f4c6]"
              >
                Today Try
              </div>{' '}
            </div>
            <button
              style={{
                borderRight: '1px solid #b5b3b3',
                borderBottom: '1px solid #b5b3b3',
                zIndex: 50,
                boxShadow: '0px 0px 1px #b5b3b3',
                borderTop: '1px solid #b5b3b3',
              }}
              onClick={() => {
                if (defultProfile.SEND_WHATSAPP === 'Y') {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    title: 'Send Whatsapp Message ',
                    msg: 'Do you want to send whatsapp message ?',
                    status: true,
                    button1: 'YES',
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,

                        status: false,
                      });
                      getMessageWallet(companyDetail.email, token).then(
                        (bal) => {
                          if (bal > todayTry.length - 1) {
                            todayTry.map((e) => {
                              if (e.MOBILE) {
                                sendtryMsgAPI(
                                  e.CUSTOMER_NAME,
                                  e.MOBILE,
                                  e.TRY_DATE,
                                  '03:00 pm',
                                  companyDetail.companyName,
                                  companyDetail.mobile,
                                  companyDetail.email
                                );
                              }
                            });
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: low_Bal_alert,
                              })
                            );
                          }
                        }
                      );
                    },
                    button2: 'No',
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: not_permisson_forWhastapp,
                    })
                  );
                }
              }}
              className=" text-[13px] border border-l-0 -ml-4 pl-4 "
            >
              <div
                style={{
                  fontSize: '13px  ',
                  fontWeight: '500',
                }}
                className="w-fit  px-3 py-1 text-black    bg-[#f1f4c6]"
              >
                {' '}
                Send Whatsapp to all
              </div>
            </button>
          </div>
          <div className="grid max-sm:grid-cols-1 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayTry.map((e, index) => {
              return (
                <Button
                  onClick={() => {
                    if (defultProfile.SEND_WHATSAPP === 'Y') {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        title: 'Send Whatsapp Message',
                        msg: 'Do you want to send whatsapp message ?',
                        status: true,
                        button1: 'YES',
                        button1Click: () => {
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,

                            status: false,
                          });
                          getMessageWallet(companyDetail.email, token).then(
                            (bal) => {
                              if (bal > 0) {
                                sendtryMsgAPI(
                                  e.CUSTOMER_NAME,
                                  e.MOBILE,
                                  e.TRY_DATE,
                                  '03:00 pm',
                                  companyDetail.companyName,
                                  companyDetail.mobile,
                                  companyDetail.email
                                );
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: low_Bal_alert,
                                  })
                                );
                              }
                            }
                          );
                        },
                        button2: 'No',
                        button2Click: () => {
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          });
                        },
                      });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: not_permisson_forWhastapp,
                        })
                      );
                    }
                  }}
                  key={index}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    marginBottom: '8px',
                    boxShadow: 'none',
                  }}
                >
                  <div
                    style={{
                      boxShadow: '1px 1px 1px gray',
                      backgroundColor:
                        e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                    }}
                    className="flex justify-between h-full w-full p-[0px] rounded-sm"
                  >
                    <div className="rounded-sm  w-full p-1 px-2">
                      <div className="   flex  justify-between w-full ">
                        <div className="font-semibold text-[#0d0c0c] text-start">
                          {e.ITEM_NAME + '-' + ' ' + e.QNTY}{' '}
                        </div>
                        <div>
                          <img
                            src={require(`../utility/images/${
                              e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                            }.png`)}
                            style={{
                              height: '25px',
                              width: '25px',
                            }}
                          />{' '}
                        </div>
                      </div>

                      <div className="text-sm text-start text-[#615b5b]">
                        {e.CUSTOMER_NAME}
                      </div>
                      <div className="text-start text-[#615b5b]">
                        {e.MOBILE}
                      </div>
                      <div className="flex justify-between ">
                        <div className="text-[#615b5b]"> {e.CITY}</div>
                        <div
                          className={`w-[18px] h-[18px] ${
                            e.DELIVERED_YN === 'YES' ? 'bg-green' : 'bg-red'
                          } flex justify-center items-center  rounded-sm`}
                        >
                          {e.DELIVERED_YN === 'YES' ? (
                            <i className="bi bi-check2 justify-center flex items-center text-white"></i>
                          ) : (
                            <i className="bi bi-x justify-center flex items-center text-white"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      ) : null}

      {todayCanceledBookings[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Today canceled Booking
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayCanceledBookings.map((e, index) => {
              return (
                <Button
                  key={index}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    marginBottom: '8px',
                    boxShadow: 'none',
                  }}
                >
                  <div
                    style={{
                      boxShadow: '1px 1px 1px gray',
                      backgroundColor:
                        e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                    }}
                    className="flex  h-full justify-between w-full p-[0px] rounded-sm"
                  >
                    <div className="rounded-sm  w-full p-1 px-2">
                      <div className="   flex  justify-between w-full ">
                        <div className="font-semibold text-[#0d0c0c ] text-start">
                          {e.ITEM_NAME + '-' + ' ' + e.QNTY}{' '}
                        </div>
                        <div>
                          <img
                            src={require(`../utility/images/${
                              e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                            }.png`)}
                            style={{
                              height: '25px',
                              width: '25px',
                            }}
                          />{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      ) : null}

      {weeklyDelCount[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Weekly Delivery Count
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
              {weeklyDelCount.map((e, index) => {
                return (
                  <Button
                    key={index}
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor:
                          e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                      }}
                      className="flex h-full justify-between w-full pl-1 pt-2 pr-2 pb-2 rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + '-' + ' ' + e.NOOF_DELIVERY}{' '}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                              }.png`)}
                              style={{
                                height: '25px',
                                width: '25px',
                              }}
                            />{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      {loading && !monthlyDelCount[0] ? (
        <div className="w-6 h-6">
          <Lottie
            animationData={spinner}
            loop={true}
            style={{ fontSize: 5 }}
            color="gray"
          />
        </div>
      ) : monthlyDelCount[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Monthly Delivery Count
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2 ">
              {monthlyDelCount.map((e, index) => {
                return (
                  <Button
                    key={index}
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor:
                          e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                      }}
                      className="flex justify-between h-full w-full pl-1 pr-2 pt-2 pb-2 rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + '-' + ' ' + e.NOOF_DELIVERY}{' '}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                              }.png`)}
                              style={{
                                height: '25px',
                                width: '25px',
                              }}
                            />{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {todayDeliveredItem[0] && departmentName !== 'Delivery' ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: '1px solid #b5b3b3',
              fontSize: '13px  ',
              fontWeight: '500',
            }}
            className="w-fit px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Today Delivered Item
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
              {todayDeliveredItem.map((e, index) => {
                return (
                  <Button
                    key={index}
                    onDoubleClick={() => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        title: 'Delivery Status',
                        msg: 'Do you want to cancel Delivery ?',
                        status: true,
                        button1: 'YES',
                        button1Click: () => {
                          setDeliveryStatusAPI(
                            e.BOOKING_ORDER_NO,
                            e.ICODE,
                            'NO'
                          );
                        },
                        button2: 'NO',
                        button2Click: '',
                      });
                    }}
                    variant={'contained'}
                    style={{
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      padding: '0px',
                      marginBottom: '8px',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{
                        boxShadow: '1px 1px 1px gray',
                        backgroundColor:
                          e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                      }}
                      className="flex h-full justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + '-' + ' ' + e.QNTY}{' '}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === 'UPPER' ? '2' : '3'
                              }.png`)}
                              style={{
                                height: '25px',
                                width: '25px',
                              }}
                            />{' '}
                          </div>
                        </div>

                        <div className="text-sm text-start text-[#615b5b]">
                          {e.CUSTOMER_NAME}
                        </div>
                        <div className="text-start text-[#615b5b]">
                          {e.MOBILE}
                        </div>
                        <div className="flex justify-between ">
                          <div className="text-[#615b5b]"> {e.CITY}</div>
                          <div
                            className={`w-[18px] h-[18px] ${
                              e.DELIVERED_YN === 'YES' ? 'bg-green' : 'bg-red'
                            } flex justify-center items-center  rounded-sm`}
                          >
                            {e.DELIVERED_YN === 'YES' ? (
                              <i className="bi bi-check2 justify-center flex items-center text-white"></i>
                            ) : (
                              <i className="bi bi-x justify-center flex items-center text-white"></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};
