import React from 'react';

const MeasuremtTags = ({ onSelect }) => {
  const tags = [
    { value: '¼', color: '#f2e4d3' },

    { value: '½', color: '#faf4ed' },
    { value: '¾', color: '#88e0d0' },
  ];
  return (
    <div className="flex p-1  w-full justify-end">
      {tags.map((o) => {
        return (
          <button
            style={{
              backgroundColor: o.color,
            }}
            onClick={() => {
              onSelect(o.value);
            }}
            className="py-[2px]  font-semibold mr-2 px-3 rounded-sm"
          >
            {o.value}
          </button>
        );
      })}
    </div>
  );
};

export default MeasuremtTags;
