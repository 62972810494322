import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';

import moment from 'moment';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix } from '../utility/Redux/profile';
import { selectRemarks } from '../utility/Redux/booking';
import {
  setAlertWithTitle,
  setSessionExpired,
  setloading,
} from '../utility/Redux/modal';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';

const VoucherHelp = ({ onRowClick }) => {
  const dispatch = useDispatch();
  const [searchText, SetSearchText] = useState('');
  const [filterdList, setFilteredList] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        field: 'VOUCHER_NO',
        type: 'string',
        width: 90,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Voucher'}</div>,
      },
      {
        field: 'DATE',
        type: 'number',
        width: 120,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
          <div>{moment(params.value).format('DD-MM-YYYY')}</div>
        ),
        renderHeader: (params) => <div>{'Date'}</div>,
      },

      {
        field: 'PAY_MODE',
        type: 'string',
        width: 120,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Pay Mode'}</div>,
      },

      {
        field: 'CUSTOMER_NAME',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Customer'}</div>,
      },
      {
        field: 'MOBILE',
        type: 'string',
        width: 120,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Mobile'}</div>,
      },
    ],
    []
  );
  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'CODE',
        type: 'string',
        width: 150,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'code'}</div>,
      },
      {
        field: 'STYLE_NAME',
        type: 'number',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'style'}</div>,
      },
    ],
    []
  );
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getVoucherHelpDetails = () => {
    setloading(true);
    axios
      .post(
        `/api/vouchers/voucherHelp`,
        {
          table_prefix: prefix,
          VOUCHER_TYPE: 'CREDIT',
          MOBILE: '',
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  const remarks = useSelector(selectRemarks);

  useEffect(() => {
    getVoucherHelpDetails();
  }, []);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = list.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.VOUCHER_NO.toString().includes(searchText);
        } else {
          return item.CUSTOMER_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setFilteredList(searchItems);
    } else {
      setFilteredList('');
    }
  }, [searchText]);
  return (
    <div className="h-full w-full flex flex-col ">
      <div className="flex flex-col grow">
        {loading ? (
          <div className="w-full   flex justify-center items-center  ">
            <div className="w-6 h-6">
              <Lottie
                animationData={SpinnerLoading}
                loop={true}
                style={{ fontSize: 15 }}
                color="white"
              />
            </div>
          </div>
        ) : (
          <DataGrid
            hideFooterPagination
            hideFooter
            headerHeight={0}
            getRowId={(param) => param.VOUCHER_NO}
            onRowClick={onRowClick}
            components={{
              Header: () => {
                const headerDetails =
                  window.innerWidth < 770 ? columnsMobile : columns;
                return (
                  <div className="  bg-[#f5f0e8] flex ">
                    {headerDetails?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="  text-black flex justify-center items-center py-2  "
                          style={
                            item.flex
                              ? index < headerDetails.length - 1
                                ? {
                                    flex: item.flex,
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',

                                    // borderTop: '1px solid #4a4948',
                                  }
                                : {
                                    flex: item.flex,

                                    borderBottom: '1px solid black',
                                  }
                              : index < headerDetails.length - 1
                              ? {
                                  width: item.width,

                                  borderRight: '1px solid black',
                                  borderBottom: '1px solid black',
                                  // border: '1px solid black',
                                  // borderTop: '1px solid black',
                                }
                              : {
                                  width: item.width,
                                  borderBottom: '1px solid black',
                                }
                          }
                        >
                          {item.renderHeader()}
                        </div>
                      );
                    })}
                  </div>
                );
              },
            }}
            sx={
              window.innerWidth < 770
                ? {
                    backgroundColor: 'white',

                    fontSize: 9,
                    '& .super-app-theme--header': {
                      backgroundColor: '#f5f0e8',
                      borderRight: `1px solid #4a4948`,
                      display: 'none',
                    },
                    borderLeft: '1px solid black',
                    borderRight: '1px solid black',
                    borderRadius: '0px',
                  }
                : {
                    backgroundColor: 'white',

                    fontSize: 13,
                    borderLeft: '1px solid black',
                    borderRight: '1px solid black',

                    borderRadius: '0px',

                    '& .MuiDataGrid-cell': {
                      borderRight: `1px solid #918f8d`,
                      borderBottom: `1px solid #918f8d`, // Customize border color here
                    },

                    '& .super-app-theme--header': {
                      backgroundColor: '#f5f0e8',
                      borderRight: `1px solid #4a4948`,
                      display: 'none',
                    },
                  }
            }
            rowHeight={33}
            columns={window.innerWidth < 770 ? columnsMobile : columns}
            rows={filterdList[0] ? filterdList : list}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            scrollbarSize={0}
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
      </div>

      <div
        style={{
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          borderBottom: '0px solid #4a4948',
          borderTop: '1px solid #4a4948',
          fontSize: '14px',
          backgroundColor: '#f5f0e8',
        }}
        className="flex p-2 "
      >
        <i className="bi bi-search pr-2"></i>
        <input
          type={'text'}
          placeholder="Search"
          value={searchText}
          onChange={(t) => {
            SetSearchText(t.target.value);
          }}
          className="w-full outline-0 bg-[#f5f0e8]"
        />
      </div>
    </div>
  );
};

export default VoucherHelp;
