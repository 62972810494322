import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  SelectHsnCodeHelpStatus,
  setHsnCodeHelpStatus,
  setSessionExpired,
} from '../utility/Redux/modal';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import axios from 'axios';
import MasterHelp from '../utility/component/modals/masterHelp';
import { useFocus } from '../utility/hooks/hooks';

const MrcHelp = ({ onRowClick, status, onCancel }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [searchType, setSearchType] = useState('');

  const token = useSelector(selectToken);
  const width = useSelector(selectWidth);
  const [mobileSearch, setMobileSearch] = useState('');
  const [searchRef, setSearchFocus] = useFocus();
  const columns = [
    {
      width: width <= 768 ? 50 : width <= 1024 ? 70 : width <= 1440 ? 70 : 70,

      headerAlign: 'center',
      align: 'left',
      field: 'PREFIX',
      headerName: 'prefix',
      renderHeader: (params) => <div className="text-md">Prefix</div>,
    },
    {
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'left',
      field: 'MRC_NO',
      headerName: 'MRC',
      renderHeader: (params) => <div className="text-md">MRC</div>,
    },
    {
      field: 'INVNO',
      renderHeader: (params) => <div className="text-md">Invoice</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 80,
      headerAlign: 'center',
      renderCell: (params) => (
        <div className="text-md">{params.value > 0 ? params.value : ''}</div>
      ),
      align: 'left',
    },
    {
      field: 'BOOKING_ORDER_NO',
      renderHeader: (params) => <div className="text-md">Order ID</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'CUSTOMER_NAME',
      renderHeader: (params) => <div className="text-md">Customer</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MOBILE',
      renderHeader: (params) => <div className="text-md">Mobile</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MOBILE_2',
      renderHeader: (params) => <div className="text-md">Mobile 2</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MOBILE_3',
      renderHeader: (params) => <div className="text-md">Mobile 3</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const prefix = useSelector(selectPrefix);

  const getData = async () => {
    // try {
    await axios
      .post(
        '/api/booking/listMRCNo',
        {
          table_prefix: prefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
        setFullList(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.MRC_NO.toString().includes(searchQuery);
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const searchByMobile = (mobile, placeHolder) => {
    const regex = /\d+/;
    setMobileSearch(mobile);
    setSearchType('');
    const searchItems = fullList.filter((item) => {
      return item.MOBILE.toString().includes(mobile);
    });

    setRows(searchItems);
  };
  useEffect(() => {
    setSearchFocus();
  }, []);
  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onCancel();
            setRows([]);
            setSearchQuery('');
            setMobileSearch('');
          }}
          title={'MRC | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.BOOKING_ORDER_NO}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchRef={searchRef}
          setSearchType={setSearchType}
          searchPlaceholder={'Search MRC'}
          extraFilter={[
            {
              placeHolder: 'Mobile',
              onChange: searchByMobile,
              value: mobileSearch,
              width: columns[5].width + columns[6].width + columns[7].width,
              firstWidth:
                columns[0].width +
                columns[1].width +
                columns[2].width +
                columns[3].width +
                columns[4].width,
            },
          ]}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setSearchQuery('');
            setMobileSearch('');
          }}
        />
      </div>
    </>
  );
};

export default MrcHelp;
