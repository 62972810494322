import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import ProfilePicFrame from '../CMprofilepic';
import ImageUpload from '../CMcustomerPic';
import { setAlertWithTitle } from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { toProperCase } from '../format';
import { Country, State, City } from 'country-state-city';
import GeniustextInput from '../mastertext';
import { selectTableMasterPrefix, selectWidth } from '../../Redux/profile';
import { setCustomerMasterHelpModStatus } from '../../Redux/modal';
import { customerPrefixGlobal } from '../../common';
import CustomerMasterhelp from '../../../help/customermasterHelp';
import { DownloadFile } from './firebase';
import { getCustomerMasterAPI } from '../../../booking/list';
import { selectToken } from '../../Redux/security';
const CustomerInfo = ({
  setCustomerInfo,
  customerInfo,
  rowStyle,
  firabseUrl,
  rowWidth,
  rowValueStyle,
  profilePicWidth,
  minProfileicWidth,
  webcamStatus,
  setWebcamStatus,
  webcamRef,
  capturedImage,
  setCapturedImage,
  selectedFile,
  setSelectedFile,
  path,
  setFirebaseUrl,
  prefixList,
  customerlist,
  mobileAlert,
  setMobielAlert,
  setCompanyInfo,
  setId,
  prefix,
  setPath,
  customerMasterListByCode,
  setCustomerMasterListByCode,
}) => {
  const [Status, setStatus] = useState('ACTIVE');
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const dispatch = useDispatch();
  const countries = Country.getAllCountries();
  const token = useSelector(selectToken);
  const masterPrefix = useSelector(selectTableMasterPrefix);
  useEffect(() => {
    if (customerInfo.Country) {
      const selectedCountry = countries.filter(
        (country) => country.name === toProperCase(customerInfo.Country)
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [customerInfo.Country]);

  useEffect(() => {
    if (customerInfo.State && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === toProperCase(customerInfo.State)
      );

      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [customerInfo.State, stateList]);

  const windowWidth = useSelector(selectWidth);

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 11) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  return (
    <div
      style={
        window.innerWidth <= 500
          ? { display: 'block', marginLeft: '0px', overflow: 'scroll' }
          : window.innerWidth <= 800
          ? { display: 'block', marginLeft: '20px', overflow: 'scroll' }
          : {
              border: '1px solid #b5b3b3',
              borderTop: '0px solid #b5b3b3',
            }
      }
      className="max-sm:p-2 t:p-5 h-full flex max-sm:block max-sm:overflow-y-scroll t:overflow-y-scroll d:overflow-y-auto"
    >
      <div className="flex w-full pb-4" ref={formRef}>
        <div className="w-full ">
          <div className="flex">
            <GeniustextInput
              title={'Mobile'}
              type={'mobile'}
              autofocus={true}
              width={windowWidth < 768 ? '160px' : '150px'}
              value={customerInfo.mobile}
              onChange={(t) => {
                if (t.target.value.length < 51) {
                  setCustomerInfo({
                    ...customerInfo,
                    mobile: t.target.value,
                  });

                  const isOldCustomer = customerlist.filter((c) => {
                    if (t.target.value) {
                      if (c.mobile === t.target.value) {
                        return true;
                      }
                      if (c.mobile_2 === t.target.value) {
                        return true;
                      }
                      if (c.mobile_3 === t.target.value) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });

                  if (isOldCustomer[0]) {
                    setMobielAlert('Mobile Already registered');
                  } else {
                    if (t.target.value.length >= 10) {
                      setMobielAlert('');
                    } else {
                      setMobielAlert('Invalid Mobile');
                    }
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Mobile Number',
                      msg: 'Please enter a valid  mobile number.',
                    })
                  );
                }
              }}
              search={async () => {
                dispatch(setCustomerMasterHelpModStatus(true));

                // const data = await getCustomerMasterAPI(
                //   masterPrefix,
                //   token,
                //   dispatch,
                //   '',
                //   ''
                // );
                // if (data.data.response[0]) {
                //   setCustomerMasterListByCode(data.data.response);
                // }
              }}
            />
            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 2'}
                type={'mobile'}
                // error={'mobile'}
                width={windowWidth < 768 ? '160px' : '150px'}
                value={customerInfo.mobile_2}
                onChange={(t) => {
                  if (t.target.value.length < 51) {
                    setCustomerInfo({
                      ...customerInfo,
                      mobile_2: t.target.value,
                    });
                    const isOldCustomer = customerlist.filter((c) => {
                      if (t.target.value) {
                        if (c.mobile === t.target.value) {
                          return true;
                        }
                        if (c.mobile_2 === t.target.value) {
                          return true;
                        }
                        if (c.mobile_3 === t.target.value) {
                          return true;
                        }
                        return false;
                      }
                      return false;
                    });
                    if (isOldCustomer[0]) {
                      setMobielAlert('Mobile Already registered');
                    } else {
                      setMobielAlert('');
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Mobile Number',
                        msg: 'Please enter a valid  mobile number.',
                      })
                    );
                  }
                }}
              />
            </div>

            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 3'}
                type={'mobile'}
                error={mobileAlert}
                width={windowWidth < 768 ? '160px' : '150px'}
                value={customerInfo.mobile_3}
                onChange={(t) => {
                  if (t.target.value.length < 51) {
                    setCustomerInfo({
                      ...customerInfo,
                      mobile_3: t.target.value,
                    });
                    const isOldCustomer = customerlist.filter((c) => {
                      if (t.target.value) {
                        if (c.mobile === t.target.value) {
                          return true;
                        }
                        if (c.mobile_2 === t.target.value) {
                          return true;
                        }
                        if (c.mobile_3 === t.target.value) {
                          return true;
                        }
                        return false;
                      }
                      return false;
                    });

                    if (isOldCustomer[0]) {
                      setMobielAlert('Mobile Already registered');
                    } else {
                      setMobielAlert('');
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Mobile Number',
                        msg: 'Please enter a valid  mobile number.',
                      })
                    );
                  }
                }}
              />
            </div>
          </div>

          <div>
            <GeniustextInput
              title={'Customer Name'}
              type={'text'}
              width={windowWidth < 768 ? '160px' : '466px'}
              value={customerInfo.CusName}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  CusName: toProperCase(t.target.value),
                });
              }}
            />
          </div>

          <div className="flex">
            <GeniustextInput
              title={'Address'}
              width={
                windowWidth < 768
                  ? '155px'
                  : window.innerWidth <= 1024
                  ? '660px'
                  : '720px'
              }
              type={'text'}
              value={customerInfo.Address}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  Address: t.target.value,
                });
              }}
            />
          </div>

          <div className="flex">
            <GeniustextInput
              title={'Country'}
              country={true}
              option={countries}
              width={
                windowWidth < 768
                  ? '160px'
                  : window.innerWidth <= 1024
                  ? '320px'
                  : '350px'
              }
              value={toProperCase(customerInfo.Country)}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  Country: t.target.value,
                });
              }}
            />

            <div className="ml-2 tab:ml-5">
              <GeniustextInput
                title={'State'}
                country={true}
                option={stateList}
                width={
                  windowWidth < 768
                    ? '160px'
                    : window.innerWidth <= 1024
                    ? '320px'
                    : '350px'
                }
                value={toProperCase(customerInfo.State)}
                onChange={(t) => {
                  setCustomerInfo({
                    ...customerInfo,
                    State: t.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex">
            <GeniustextInput
              title={'City'}
              country={true}
              width={
                windowWidth < 768
                  ? '160px'
                  : window.innerWidth <= 1024
                  ? '320px'
                  : '350px'
              }
              option={cityList}
              value={toProperCase(customerInfo.City)}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  City: t.target.value,
                });
              }}
            />
            <div className="ml-2 tab:ml-5">
              <GeniustextInput
                title={'Pin Code'}
                type={'number'}
                width={
                  windowWidth < 768
                    ? '160px'
                    : window.innerWidth <= 1024
                    ? '320px'
                    : '350px'
                }
                value={customerInfo.PinCode}
                onChange={(t) => {
                  setCustomerInfo({
                    ...customerInfo,
                    PinCode: t.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex">
            <GeniustextInput
              title={'Email'}
              type={'text'}
              error={'email'}
              width={
                windowWidth < 768
                  ? '160px'
                  : window.innerWidth <= 1024
                  ? '320px'
                  : '350px'
              }
              value={customerInfo.Email}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  Email: t.target.value,
                });
              }}
            />
            <div className="ml-2 tab:ml-5">
              <GeniustextInput
                title={'Phone'}
                type={'number'}
                width={
                  windowWidth < 768
                    ? '160px'
                    : window.innerWidth <= 1024
                    ? '320px'
                    : '350px'
                }
                value={customerInfo.Phone}
                onChange={(t) => {
                  if (t.target.value.length < 16) {
                    setCustomerInfo({
                      ...customerInfo,
                      Phone: t.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="flex">
            <GeniustextInput
              title={'Credit Limit'}
              type={'text'}
              width={
                windowWidth < 768
                  ? '160px'
                  : window.innerWidth <= 1024
                  ? '320px'
                  : '350px'
              }
              value={customerInfo.CredLimit}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  CredLimit: t.target.value,
                });
              }}
            />
            <div className="ml-2 tab:ml-5">
              <GeniustextInput
                title={'Status'}
                width={
                  windowWidth < 768
                    ? '160px'
                    : window.innerWidth <= 1024
                    ? '320px'
                    : '350px'
                }
                option={[
                  {
                    name: 'Select Status',
                    value: '',
                  },
                  {
                    name: 'ACTIVE',
                    value: 'ACTIVE',
                  },
                  {
                    name: 'INACTIVE',
                    value: 'INACTIVE',
                  },
                ]}
                value={Status}
                onChange={(t) => {
                  setStatus(t.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <GeniustextInput
              title={'Discount'}
              type={'number'}
              width={
                windowWidth < 768
                  ? '160px'
                  : window.innerWidth <= 1024
                  ? '320px'
                  : '350px'
              }
              value={customerInfo.Disq}
              onChange={(t) => {
                setCustomerInfo({
                  ...customerInfo,
                  Disq: t.target.value,
                });
              }}
            />
            <div className="ml-5">
              {customerInfo.code ? (
                <GeniustextInput
                  title={'Prefix'}
                  readOnly={true}
                  type={'text'}
                  width={
                    windowWidth < 768
                      ? '160px'
                      : window.innerWidth <= 1024
                      ? '320px'
                      : '350px'
                  }
                  value={customerInfo.prefix}
                />
              ) : (
                <GeniustextInput
                  title={'Prefix'}
                  option={prefixList.map((e) => {
                    return {
                      name: e,
                      value: e,
                    };
                  })}
                  width={
                    windowWidth < 768
                      ? '160px'
                      : window.innerWidth <= 1024
                      ? '320px'
                      : '350px'
                  }
                  value={customerInfo.prefix}
                  onChange={(t) => {
                    setCustomerInfo({
                      ...customerInfo,
                      prefix: t.target.value,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grow h-full max-sm:mt-2 l:flex  ">
          <div className="flex flex-col justify-between h-full  ">
            <div>
              <div
                style={{
                  border: '1px solid #b9bbbd',
                  position: 'relative',
                  width: profilePicWidth,
                  height: profilePicWidth,
                  minWidth: minProfileicWidth,
                  minHeight: minProfileicWidth,
                }}
                className="rounded-sm p-3 flex justify-center items-center"
              >
                {/* <i className="bi bi-person text-[40px]"></i> */}
                <ProfilePicFrame
                  firabseUrl={firabseUrl}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  webcamRef={webcamRef}
                  webcamStatus={webcamStatus}
                  capturedImage={capturedImage}
                  setCapturedImage={setCapturedImage}
                  setWebcamStatus={setWebcamStatus}
                  path={path}
                  setFirebaseUrl={setFirebaseUrl}
                />
              </div>

              <div className="flex items-center mt-2   ">
                <div
                  style={{
                    width: profilePicWidth,
                    minWidth: minProfileicWidth,
                  }}
                  className="flex justify-between "
                >
                  <div className="flex w-[100%] items-center mr-1">
                    <ImageUpload
                      setCapturedImage={setCapturedImage}
                      setSelectedFile={setSelectedFile}
                      webcamRef={webcamRef}
                      webcamStatus={webcamStatus}
                      setWebcamStatus={setWebcamStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerMasterhelp
          onRowClick={(params) => {
            setCustomerInfo({
              mobile: params.row.mobile,
              CusName: params.row.customer_name,
              CusStatus: params.row.status,
              Email: params.row.email,
              Phone: params.row.phone,
              Address: params.row.address,
              City: params.row.city,
              State: params.row.state,
              Country: params.row.country,
              PinCode: params.row.pin_code,
              CredLimit: params.row.credit_limit,
              Disq: '',
              code: params.row.code,
              prefix: params.row.prefix,
            });
            setCompanyInfo({
              compName: params.row.company_name,
              Address: params.row.company_address,
              City: params.row.company_city,
              State: params.row.company_state,
              Country: params.row.company_country,
              Pincode: params.row.company_pincode,
              PanNo: params.row.regd_no1,
              Gst: params.row.regd_no2,
              Regd: params.row.regd_no3,
              mobile: params.row.mobile,
            });
            setId(params.row.code);
            const mobile = params.row.mobile;
            const b = `seam/images/${prefix + mobile}`;
            DownloadFile(b).then((e) => {
              if (!e.includes('FirebaseError')) {
                setFirebaseUrl(e);
                setPath(e);
              } else {
                setFirebaseUrl('');
                setSelectedFile('');
              }
            });
            dispatch(setCustomerMasterHelpModStatus(false));
            // }
          }}
        />
      </div>
    </div>
  );
};

export default CustomerInfo;
