import React from 'react';

import Modal from '@mui/material/Modal';

import { Close } from '@mui/icons-material';
import Attach4Img from './attach4Img';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',

  overflow: 'hidden',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '85%',
  padding: '2px',
  borderRadius: '8px',

  overflow: 'hidden',
};

export default function ModAttach4Image({
  attachImageModStatus,
  setAttachImageModStatus,
  selectedFile,
  webcamStatus,
  setWebcamStatus,
  setSelectedFile,
  capturedImage,
  setCapturedImage,
  capturedImage2,
  setCapturedImage2,
  webcamStatus2,
  setWebcamStatus2,
  selectedFile2,
  setSelectedFile2,
  capturedImage3,
  setCapturedImage3,
  webcamStatus3,
  setWebcamStatus3,
  selectedFile3,
  setSelectedFile3,
  capturedImage4,
  setCapturedImage4,
  webcamStatus4,
  setWebcamStatus4,
  selectedFile4,
  setSelectedFile4,
  bookingCustomerDetails,
  setBookingCustomerDetails,
}) {
  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={attachImageModStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={' overflow-hidden  '}
        >
          <div className="w-full flex flex-col  h-full    overflow: 'hidden',">
            <div
              style={{
                fontSize: '14px',
                border: '1px solid black',
                // borderBottom: '0px',
                overflow: 'hidden',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                borderBottom: '1px solid black',
              }}
              className="font-semibold bg-[#f5f0e8] font-arial p-3 flex justify-between"
            >
              <div>Attach Image</div>

              <div>
                {' '}
                <Close
                  color="warning"
                  onClick={() => setAttachImageModStatus(false)}
                />
              </div>
            </div>
            <Attach4Img
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              capturedImage={capturedImage}
              setCapturedImage={setCapturedImage}
              capturedImage2={capturedImage2}
              setCapturedImage2={setCapturedImage2}
              webcamStatus2={webcamStatus2}
              setWebcamStatus2={setWebcamStatus2}
              selectedFile2={selectedFile2}
              setSelectedFile2={setSelectedFile2}
              capturedImage3={capturedImage3}
              setCapturedImage3={setCapturedImage3}
              webcamStatus3={webcamStatus3}
              setWebcamStatus3={setWebcamStatus3}
              selectedFile3={selectedFile3}
              setSelectedFile3={setSelectedFile3}
              capturedImage4={capturedImage4}
              setCapturedImage4={setCapturedImage4}
              webcamStatus4={webcamStatus4}
              setWebcamStatus4={setWebcamStatus4}
              selectedFile4={selectedFile4}
              setSelectedFile4={setSelectedFile4}
              webcamStatus={webcamStatus}
              setWebcamStatus={setWebcamStatus}
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
