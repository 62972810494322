import { useDispatch, useSelector } from 'react-redux';
import { FormatNumber2 } from '../tools';
import {
  selectBillSettlement,
  selectBillingAdvanceAmount,
  selectBillingCartList,
  selectBillingDiscountP,
  selectModifyBillingCartlist,
  setBillSettlement,
  setBillingAdvanceAmount,
  setBillingCartList,
  setModifyBillcartList,
} from '../../Redux/billing';

import ModItemMasterHelp from '../help/itemMasterHelp';
import { useState } from 'react';

import {
  selectBookingCustomerDetails,
  selectCustomerPrefix,
  selectHistoryOrderDetails,
  selectModyingBookingID,
} from '../../Redux/booking';
import uniqid from 'uniqid';
import { setAlertWithTitle } from '../../Redux/modal';
import { Button } from '@mui/material';
import CreateBillingCartWithTax from '../functions/createBillingCartWithTax';
import { useEffect } from 'react';
const width = (window.innerWidth * 1) / 100;

export const OrderHistryFooter = (props) => {
  const { columns } = props;

  return (
    <div style={{ width: '100%' }} className="  bg-[#e6e6e3] flex ">
      <div className=" bg-white flex w-full ">
        {' '}
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className=" text-black  flex justify-end  items-center "
              style={item.flex ? { flex: item.flex } : { width: item.width }}
            >
              {header === 'Date' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderRight: '0px solid #b5b3b3',
                    borderBottomLeftRadius: '3px',
                  }}
                  className="w-full text-white p-2"
                >
                  {' s '}
                </div>
              ) : null}
              {header === 'ITEM' ? (
                <div
                  style={{
                    border: '1px dotted black',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  {' Total Qnty.'}
                </div>
              ) : null}
              {header === 'QNTY' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full  p-2 text-gray-500"
                >
                  22
                </div>
              ) : null}
              {header === 'RATE' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderRightWidth: '0px',
                    borderLeftWidth: '0px',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  Total Amount Rs.
                </div>
              ) : null}
              {header === 'AMOUNT' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderBottomRightRadius: '3px',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  10,500.00
                </div>
              ) : null}
            </div>
          );
        })}{' '}
      </div>
    </div>
  );
};

export const BillingCardFooter = (props) => {
  const { rows, columns, setVoucherModStatus, mrcNumber } = props;
  const customerPrefix = useSelector(selectCustomerPrefix);
  const [itemMasterStatus, setItemmasterStatus] = useState(false);
  const billingCartList = useSelector(selectBillingCartList);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  const modifyBillingCart = useSelector(selectModifyBillingCartlist);
  const totalQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.QNTY),
    0
  );

  const totalAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );

  const customerDetails = useSelector(selectBookingCustomerDetails);
  const dispatch = useDispatch();

  const TotalCuttingTax1Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX1_AMOUNT),
    0
  );

  const TotalCuttingTax2Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX2_AMOUNT),
    0
  );

  const TotalCuttingTax3Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX3_AMOUNT),
    0
  );

  const TotalSewingTax1Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX1_AMOUNT),
    0
  );

  const TotalSewingTax2Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX2_AMOUNT),
    0
  );

  const TotalSewingTax3Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX3_AMOUNT),
    0
  );

  const billingAdavanceAmount = useSelector(selectBillingAdvanceAmount);

  const netAmount = Math.round(
    totalAmount -
      billingAdavanceAmount +
      TotalCuttingTax1Amount +
      TotalCuttingTax2Amount +
      TotalCuttingTax3Amount +
      TotalSewingTax1Amount +
      TotalSewingTax2Amount +
      TotalSewingTax3Amount
  );
  const modfiyingId = useSelector(selectModyingBookingID);
  const billCartList = useSelector(selectBillingCartList);

  const onItemMasterRowClick = async (i) => {
    if (customerDetails?.customer_name) {
      if (billCartList[0]?.BOOKING_ORDER_NO) {
        const qnty = 1;
        const remarks = '';

        const data = CreateBillingCartWithTax(
          i.row,
          customerDetails,
          qnty,
          billCartList[0]?.BOOKING_ORDER_NO,
          remarks,
          mrcNumber,
          customerPrefix
        );

        const id = uniqid() + 'fabric';

        if (billingCartList[0]) {
          dispatch(
            setBillingCartList([
              ...billingCartList,
              {
                ...data,
                ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
              },
            ])
          );
          setItemmasterStatus(false);
        } else {
          dispatch(
            setBillingCartList([
              {
                ...data,
                ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
              },
            ])
          );
          setItemmasterStatus(false);
        }
      } else {
        if (modifyBillingCart[0]?.BOOKING_ORDER_NO) {
          const qnty = 1;
          const remarks = '';
          const id = uniqid();
          const data = CreateBillingCartWithTax(
            i.row,
            customerDetails,
            qnty,
            modifyBillingCart[0]?.BOOKING_ORDER_NO,
            remarks,
            mrcNumber,
            customerPrefix
          );
          if (modifyBillingCart[0]) {
            dispatch(
              setModifyBillcartList([
                ...modifyBillingCart,
                {
                  ...data,
                  ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
                  INVNO: historyOrderDetails[0].INVNO,
                },
              ])
            );

            setItemmasterStatus(false);
          } else {
            dispatch(
              setModifyBillcartList([
                {
                  ...data,
                  ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
                  INVNO: historyOrderDetails[0].INVNO,
                },
              ])
            );
            setItemmasterStatus(false);
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Oops!',
              msg: `You cannot generate a bill without Measurements `,
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Customer Mobile!',
          msg: 'Please select Customer Mobile to continue.',
        })
      );
    }
  };
  const billSettelmentFields = useSelector(selectBillSettlement);

  const billingDiscountP = useSelector(selectBillingDiscountP);

  useEffect(() => {
    if (billingAdavanceAmount) {
      const total =
        totalAmount +
        TotalCuttingTax1Amount +
        TotalCuttingTax2Amount +
        TotalCuttingTax3Amount +
        TotalSewingTax1Amount +
        TotalSewingTax2Amount +
        TotalSewingTax3Amount;
      dispatch(
        setBillSettlement({
          ...billSettelmentFields,
          btcAmount: Math.round(Number(total)) - billingAdavanceAmount,
          cashAmount: '',
          cardAmount: '',
          epaymentAmount: '',
        })
      );
      console.log(Math.round(Number(total)) - billingAdavanceAmount);
    }
  }, [billingAdavanceAmount]);
  return (
    <div className="w-full mt-2">
      <div style={{ width: '100%' }} className="flex">
        <div className=" flex w-full  ">
          {' '}
          {columns.map((item, index) => {
            const header = item.field;
            return (
              <div
                key={index}
                className="  text-gray-700 flex justify-end  items-end "
                style={
                  item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
                }
              >
                {header === 'BOOKING_ORDER_NO' ? (
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                    }}
                    className=" text-orange  h-full "
                  >
                    <div className=" h-full bg-red  w-full">
                      <Button
                        // ref={updateRef}
                        onClick={() => {
                          setItemmasterStatus(true);
                        }}
                        variant="contained"
                        style={{
                          textTransform: 'none',
                          letterSpacing: '1px',
                          backgroundColor: '#f8e4dd',
                          width: '100%',
                          borderRadius: '0px',
                          border: '1px solid black',
                          borderRight: '0px solid black',
                          borderBottom: '0px solid black',
                          color: '#383535',
                          height: '100% ',
                          padding: '0px',
                          outline: 'none',
                          boxShadow: '0px 0px 0px  #cfccca',
                        }}
                      >
                        <div className="  font-sans"> + Add Item </div>
                      </Button>
                    </div>
                  </div>
                ) : null}

                {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

                {header === 'QNTY' ? (
                  <div
                    style={{
                      border: '1px solid black',
                      borderRight: '0px solid black',
                      borderLeft: '1px solid black',
                      borderBottom: '0px dashed black',
                      width: columns[index].width,
                    }}
                    className="   h-full p-1 flex justify-center "
                  >
                    {totalQnty}
                  </div>
                ) : null}
                {header === 'RATE' ? (
                  <div
                    style={{
                      borderTop: '1px solid black',
                      borderRight: '0px solid #b5b3b3',
                      borderBottom: '0px dashed black',
                      borderLeft: '1px solid black',
                      width: columns[index].width,
                    }}
                    className=" p-1 h-full  text-end text-gray-600 pr-3"
                  >
                    Total Rs.
                  </div>
                ) : null}
                {header === 'AMOUNT' ? (
                  <div
                    style={{
                      border: '1px solid black',
                      borderRight: '1px solid black',
                      borderTop: '1px solid black',
                      borderBottom: '0px solid black',
                      flex: 1,
                    }}
                    className=" p-1 font-semibold text-end l:pr-3"
                  >
                    {FormatNumber2(totalAmount)}
                  </div>
                ) : null}
              </div>
            );
          })}{' '}
        </div>
      </div>

      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderTop: '1px solid black',
                    borderBottom: '0px dotted black',
                    borderLeft: '1px solid black',
                    borderRight: '0px solid black',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className=" text-black h-full "
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px dotted white',
                      }}
                      className="w-full flex justify-start  p-1 pl-3"
                    >
                      <div className="rounded-sm overflow-hidden  w-full ">
                        <input
                          type="text"
                          className="w-full text-blue"
                          value={billSettelmentFields.Remarks}
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                Remarks: e.target.value,
                              })
                            );
                          }}
                          placeholder="Remarks"
                          style={{
                            outline: 'none',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                    borderLeft: '1px solid black',
                    borderTop: '1px dashed black',
                  }}
                  className=" h-full flex justify-end items-center "
                >
                  <div className="px-3 text-gray-700 p-1 ">Advance Amt.</div>
                </div>
              ) : null}

              {header === 'AMOUNT' ? (
                <div
                  onClick={() => {
                    setVoucherModStatus(true);
                  }}
                  style={{
                    borderLeft: '1px solid black',
                    borderTop: '1px solid black ',
                    borderBottom: '0px solid #b5b3b3',
                    borderRight: '1px solid black',
                    // borderBottomRightRadius: '3px',
                    flex: 1,
                  }}
                  className=" p-1 font-semibold text-end h-full bg-gray-100 pr-3"
                >
                  <input
                    type="number"
                    value={billingAdavanceAmount}
                    readOnly
                    className="text-right bg-gray-100 w-full "
                    style={{
                      outline: 'none',
                      alignSelf: 'end',
                    }}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    border: '1px solid #383535',
                    borderTop: '1px solid #383535',
                    borderLeft: '1px solid #383535',
                    borderRight: '0px solid #383535',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className=" text-[#383535] rounded-bl-sm"
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px solid #383535',
                      }}
                      className="w-[60%] flex justify-start  p-1 pl-3"
                    >
                      Balance Due
                    </div>

                    <div className="flex p-1 grow justify-end font-semibold pr-3"></div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                  }}
                  className=" h-full items-center flex justify-end "
                >
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                      border: '1px solid #383535',
                      borderRight: '0px solid #383535',
                      borderLeft: '1px solid #383535',
                    }}
                    className="pl-4 h-full flex items-center justify-end pr-3 font-semibold   "
                  >
                    Net Amount{' '}
                  </div>
                </div>
              ) : null}

              {header === 'AMOUNT' ? (
                <div
                  style={{
                    border: '1px solid #383535',
                    borderTop: '1px solid #383535  ',
                    borderRight: '1px solid #383535',

                    flex: 1,
                  }}
                  className=" p-1  rounded-br-sm font-semibold text-end l:pr-3"
                >
                  {FormatNumber2(netAmount)}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <ModItemMasterHelp
        status={itemMasterStatus}
        setStatus={setItemmasterStatus}
        onItemMasterRowClick={onItemMasterRowClick}
      />
    </div>
  );
};

export const BillSummaryFooter = (props) => {
  const { rows, columns } = props;

  const TotalCredit = rows.reduce(
    (total, row) => Number(total) + Number(row.TOTAL),
    0
  );
  const TotalNetAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.GTOTAL),
    0
  );
  return (
    <div
      className=" flex bg-[#e6e6e3]   mt-3  "
      style={{
        display: 'flex',

        alignItems: 'center',
      }}
    >
      {columns.map((p) => {
        return (
          <div
            key={p.field}
            className="h-full"
            style={
              p.flex
                ? {
                    flex: p.flex,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',

                    flexDirection: 'row',
                    justifyContent: 'center',
                  }
                : {
                    width: p.width,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',

                    flexDirection: 'row',

                    alignItems: 'center',
                  }
            }
          >
            <div className="text-sm p-2">
              {p.field === 'ADDRESS' ? 'Total' : null}
              {p.field === 'TOTAL' ? FormatNumber2(TotalCredit) : null}
              {p.field === 'GTOTAL'
                ? TotalNetAmount
                  ? FormatNumber2(TotalNetAmount)
                  : null
                : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
