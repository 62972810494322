import React from 'react';
import '../utility/styles/print.css';
import moment from 'moment';
import BillSummaryTable from '../utility/component/billSummaryTable';

const PrintBillSummary = React.forwardRef((props, ref) => {
  const { data, columns, firstDate, SecondDate, bookingCustomerDetails } =
    props;

  const companyName = localStorage.getItem('company');

  return (
    <div ref={ref}>
      <div
        className="px-7"
        id="print-header"
        style={{
          width: '96%',
          border: '1px solid black',
          marginLeft: '2%',
          position: 'relative',
          height: 'auto',
          display: 'block',
          visibility: 'hidden',
        }}
      >
        <div style={{ width: '100%' }}>
          <p
            className="flex justify-center items-center font-semibold pt-2"
            style={{ fontSize: '22px' }}
          >
            {companyName}
          </p>
        </div>

        <div
          style={{ width: '100%' }}
          className="flex justify-center items-center"
        >
          <div className="font-semibold mx-1">From</div> :{' '}
          <div className="mx-1">{moment(firstDate).format('DD-MM-YYYY')}</div>
          <div className="font-semibold mx-1">To</div> :{' '}
          <div className="mx-1">{moment(SecondDate).format('DD-MM-YYYY')}</div>
        </div>

        <div
          style={{ width: '100%' }}
          className="flex justify-center items-center font-semibold pb-2"
        >
          {bookingCustomerDetails.customer_name
            ? bookingCustomerDetails.customer_name +
              '  ' +
              '-' +
              ' ' +
              bookingCustomerDetails.mobile
            : null}
        </div>
      </div>

      <div
        id="print-component"
        className="p-6 "
        style={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          marginTop: '10%',
        }}
      >
        <BillSummaryTable data={data} columns={columns} />
      </div>
    </div>
  );
});

export default PrintBillSummary;
