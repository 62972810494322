import ModSaveVoucher from '../utility/component/modals/voucherSaveMod';
import { useState } from 'react';
import { Button, Grow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Updation = () => {
  const [voucherModStatus, setVoucherModStatus] = useState(false);
  const navigate = useNavigate();
  const list = [
    {
      name: 'Reciept Voucher',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        setVoucherModStatus(true);
      },
    },
    // {
    //   name: 'Billing',
    //   path: '',
    //   userType: 'MANAGER,USER,STORE',
    //   onclick: () => {
    //     navigate('/billing');
    //   },
    // },
  ];

  return (
    <div className="h-full p-4">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {list[0] &&
          list.map((e, i) => {
            return (
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
              >
                <Button
                  key={i}
                  onClick={() => {
                    e.onclick();
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e8e2db] py-4 w-full text-brown">
                    {e.name}
                  </div>
                </Button>
              </Grow>
            );
          })}
      </div>
      <ModSaveVoucher
        status={voucherModStatus}
        setStatus={setVoucherModStatus}
        type={'menu'}
      />
    </div>
  );
};

export default Updation;
