import moment from 'moment';

import uniqueId from 'uniqid';

const CreateBillingCartWithTax = (
  itemDetails,
  customerDetails,
  qntity,
  bookingid,
  remarks,
  mrcNumber,
  Prefix
) => {
  const amount = qntity * itemDetails.rate;
  const cuttingTax1Amount = (amount * itemDetails.cuting_tax1_rate) / 100;
  const cuttingTax2Amount = (amount * itemDetails.cuting_tax2_rate) / 100;
  const cuttingTax3Amount = (amount * itemDetails.cuting_tax3_rate) / 100;

  const SewingTax1Amount = (amount * itemDetails.sewing_tax1_rate) / 100;
  const SewingTax2Amount = (amount * itemDetails.sewing_tax2_rate) / 100;
  const SewingTax3Amount = (amount * itemDetails.sewing_tax3_rate) / 100;

  const id = uniqueId();
  const data = {
    INVNO: '',
    BOOKING_ORDER_NO: bookingid,
    DATE: moment().format('YYYY-MM-DD HH:mm:ss'),

    // itemDetails
    ICODE: itemDetails.icode,
    ITEM_NAME: itemDetails.item_name,
    REGIONAL_INAME: itemDetails.regional_iname,
    SECTION_NAME: itemDetails.section_name,
    UPPER_LOWER: itemDetails.upper_lower,
    QNTY: qntity,
    RATE: itemDetails.rate,
    AMOUNT: itemDetails.rate * qntity,
    SEWING_HSN_CODE: itemDetails.sewing_hsn_code,
    SEWING_TAX1_RATE: itemDetails.sewing_tax1_rate,
    SEWING_TAX2_RATE: itemDetails.sewing_tax2_rate,
    SEWING_TAX3_RATE: itemDetails.sewing_tax3_rate,
    CUTTING_HSN_CODE: itemDetails.cutting_hsn_code,
    CUTING_TAX1_RATE: itemDetails.cuting_tax1_rate,
    CUTING_TAX2_RATE: itemDetails.cuting_tax2_rate,
    CUTING_TAX3_RATE: itemDetails.cuting_tax3_rate,
    SEWING_TAX1_AMOUNT: SewingTax1Amount,
    SEWING_TAX2_AMOUNT: SewingTax2Amount,
    SEWING_TAX3_AMOUNT: SewingTax3Amount,
    CUTING_TAX1_AMOUNT: cuttingTax1Amount,
    CUTING_TAX2_AMOUNT: cuttingTax2Amount,
    CUTING_TAX3_AMOUNT: cuttingTax3Amount,
    DISCOUNT_RATE: '0',
    DISCOUNT_AMOUNT: '0',
    GTOTAL: '0',
    MRC_NO: mrcNumber,
    PREFIX: Prefix,
    CUSTOMER_NAME: customerDetails.customer_name,
    FAMILY_MEM_NAME: customerDetails?.familyMemberName
      ? customerDetails.familyMemberName
      : '',
    REMARKS: remarks,
    MOBILE: customerDetails.mobile,
    PHONE: customerDetails.phone,
    ADDRESS: customerDetails.address,
    CITY: customerDetails.city,
    STATE: customerDetails.state,
    COUNTRY: customerDetails.country,
    COMPANY_NAME: customerDetails.company_name,
    DELIVERED_YN: 'NO',
    PICKUP_DATE: '',

    CUSTOMER_TYPE: 'GOOD',
    LATITUDE: '212233',
    LONGITITUDE: '43433',
    ID: id + '-' + itemDetails.upper_lower + '-' + itemDetails.icode,
  };

  return data;
};

export default CreateBillingCartWithTax;
