import { Fade, Modal } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectModUserListStatus,
  setModUserListStatus,
} from '../utility/Redux/modal';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import ModalBody from '../utility/component/styles/modalBody';
import UserListModal from '../masters/userListModal';

const ModUserList = ({ selectedData }) => {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectModUserListStatus);

  const handleClose = () => {
    dispatch(setModUserListStatus(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth <= 768 ? '95%' : 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '2px solid black',
  };

  return (
    <div>
      <Modal
        open={ModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={ModalStatus}>
          <div style={style} className={'rounded-lg overflow-hidden '}>
            <div className="w-full flex flex-col bg-white h-full ">
              <ModalHeaderStyle header={`User List`} onClose={handleClose} />

              <ModalBody>
                <UserListModal selectedData={selectedData} />
              </ModalBody>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModUserList;
