import moment from 'moment';
import { FormatNumber2 } from '../../utility/component/tools';
const width = window.innerWidth / 100;
export const CustomerWiseSummaryColumns = () => {
  const columns = [
    {
      field: 'BILL_NO',
      accessor: 'BILL_NO',
      type: 'string',
      headerAlign: 'center',

      // width: width < 768 ? 100 : 100,
      width: width * 8,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Bill No.
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      headerAlign: 'center',

      // width: width < 768 ? 100 : 120,
      width: width * 8,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {moment(params.value).format('DD-MM-YYYY')}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Date
        </div>
      ),
    },
    {
      field: 'PAY_MODE',
      accessor: 'PAY_MODE',
      type: 'string',
      // width: width < 768 ? 100 : width < 1400 ? 180 : 130,
      width: width * 8,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Pay Mode
        </div>
      ),
    },
    {
      field: 'NAME',
      accessor: 'NAME',
      type: 'string',
      headerAlign: 'center',

      // width: width < 768 ? 100 : 200,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Name
        </div>
      ),
    },
    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      headerAlign: 'center',

      // width: width < 768 ? 100 : 200,
      width: width * 8,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Mobile
        </div>
      ),
    },

    {
      field: 'TOTAL',
      accessor: 'TOTAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 120,
      width: width * 8,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },

    {
      field: 'DISQ_AMT',
      accessor: 'DISQ_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 120,
      width: width * 8,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Discount
        </div>
      ),
    },

    {
      field: 'TAX1_AMT',
      accessor: 'TAX1_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 120,
      width: width * 7,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST Amount
        </div>
      ),
    },

    {
      field: 'TAX2_AMT',
      accessor: 'TAX2_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 120,
      width: width * 7,
      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST Amount
        </div>
      ),
    },

    {
      field: 'TAX3_AMT',
      accessor: 'TAX3_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 120,
      width: width * 7,
      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST Amount
        </div>
      ),
    },
    {
      field: 'ROUND_OFF',
      accessor: 'ROUND_OFF',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 150,
      width: width * 7,
      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Round Off
        </div>
      ),
    },

    {
      field: 'GTOTAL',
      accessor: 'GTOTAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      // width: width < 768 ? 100 : 150,
      width: width * 8,
      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  return columns;
};
