import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import { BillingCardFooter } from '../utility/component/datagrid.js/footer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBillingCartList,
  selectModifyBillingCartlist,
  setBillingCartList,
  setModifyBillcartList,
} from '../utility/Redux/billing';
import { FormatNumber2 } from '../utility/component/tools';
import ModBillDiscount from '../utility/component/modals/billDiscount';
import { useState } from 'react';
import ModalQntyEdit from '../utility/component/modals/updateQnty';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import AddToCart from './addToCart';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { selectToken } from '../utility/Redux/security';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import UpdateProductionFirebase from '../utility/component/updateProductionFirebase';
import { Del_BillingCardFooter } from './footer';

const BillCart = ({
  setVoucherModStatus,

  bookingCustomerDetails,
  mrcNumber,
  isDelivery,
  state,
  orderID,
  qrBillList,
  setQrBillList,
  setBookingCustomerDetails,
  employee,
  customerPrefixState,
  billSettelmentDetails,
}) => {
  const billingCartList = useSelector(selectBillingCartList);
  const [bill_delevery_cart, setBill_delevery_cart] = useState([]);
  const [modQntyEditStatus, setModalQntyEditStatus] = useState(false);
  const [QntyEditID, setQntyEditID] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const company = useSelector(selectCompanyAddress);
  const prefix = useSelector(selectPrefix);
  const navigate = useNavigate();
  const [isDeliveredApiRun, setIsDeliveredApiRun] = useState(false);
  const [isMakeBillApiRun, setIsMakeBillApiRun] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    mobile: '',
    name: '',
  });
  const [modBillDiscountStatus, setModalBillDiscountStatus] = useState(false);
  const StyleColums = [
    {
      field: 'BOOKING_ORDER_NO',
      type: 'string',
      align: 'center',
      width: 115,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'BID'} </div>,
    },

    {
      field: 'ITEM_NAME',
      type: 'string',
      width: 250,
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="text-white"> {'Item Name'} </div>
      ),
    },

    {
      field: 'QNTY',
      type: 'string',
      width: 115,
      align: 'right',
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Qnty'} </div>,
    },

    {
      field: 'select',
      type: 'string',
      width: 180,
      align: 'right',
      renderCell: (params) => {
        const isCheckedRow = bill_delevery_cart.filter(
          (o) => o.ID === params.row.ID
        );
        const isDelChecked = isCheckedRow[0].delivery;
        const isBillChecked = isCheckedRow[0].bill;
        return (
          <div className="flex justify-between w-full">
            <div className="flex flex-1">
              <input
                checked={isDelChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBill_delevery_cart((m) => {
                      const data = m.map((o) => {
                        if (params.row.ID === o.ID) {
                          return {
                            ...o,
                            delivery: e.target.checked,
                          };
                        } else {
                          return o;
                        }
                      });

                      return data;
                    });
                  } else {
                    setBill_delevery_cart((m) => {
                      const data = m.map((o) => {
                        if (params.row.ID === o.ID) {
                          return {
                            ...o,
                            delivery: e.target.checked,
                            bill: e.target.checked,
                          };
                        } else {
                          return o;
                        }
                      });

                      return data;
                    });
                  }
                }}
                type="checkbox"
              />
              <div className="ml-2">Deliver</div>
            </div>
          </div>
        );
      },
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Select'} </div>,
    },
  ];
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const onCellClick = (row) => {
    if (row.field === 'QNTY') {
      if (row.row.SECTION_NAME === 'Fabric') {
        setQntyEditID(row.id);
        setModalQntyEditStatus(true);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Oops!',
            msg: 'You can change only Fabric Quantity.',
          })
        );
      }
    }
  };

  const modifyBillingList = useSelector(selectModifyBillingCartlist);
  const masterPrefix = useSelector(selectTableMasterPrefix);
  useEffect(() => {
    const saveBill = bill_delevery_cart.filter((o) => o.bill === true);
    dispatch(setBillingCartList(saveBill));
  }, [bill_delevery_cart]);
  function setDeliveryStatusAPI(bookingNo, icode, status) {
    const data = {
      table_prefix: prefix,
      BOOKING_ORDER_NO: bookingNo,
      ICODE: icode,
      DELIVERED_YN: status,
      DELIVERED_DATE: moment().format('YYYY-MM-DD'),
    };
    axios
      .post(`/api/booking/deliverItem`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        navigate('/');

        if (!response.data.error) {
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 126',
              msg: response.data.error,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'Error 125',
            msg: 'Something went wrong while Updating Delivery status',
          })
        );
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  }
  const saveStage = (o, details) => {
    dispatch(setSpinnerLoading('Saving'));
    const data = {
      table_prefix: prefix,
      prod_cart: [details],
    };
    axios
      .post(`/api/production/saveForProduction`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response.data.error) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data saved successfully',
            })
          );
          UpdateProductionFirebase(masterPrefix);

          // setDeliveryStatusAPI(o, details.ICODE, 'YES');
          // if (defaultProfile.SEND_WHATSAPP === 'Y') {
          //   setAlertTwoButtonModal({
          //     title: 'Success',
          //     msg: 'Data Saved do you want to send Whastsapp Message also ?',

          //     status: true,
          //     setStatus: '',
          //     button1: 'Yes',
          //     button2: 'No',
          //     button1Click: () => {
          //       setAlertTwoButtonModal((o) => {
          //         return {
          //           ...o,
          //           status: false,
          //         };
          //       });
          //       if (defaultProfile.SEND_WHATSAPP === 'Y') {
          //         getMessageWallet(company.email, token).then((o) => {
          //           if (o > 0) {
          //             sendDelMsgAPI(
          //               bookingCustomerDetails.customer_name,
          //               bookingCustomerDetails.mobile,
          //               '',
          //               '03:00 pm',
          //               company.companyName,
          //               company.mobile,
          //               company.email
          //             );
          //           } else {
          //             dispatch(
          //               setAlertWithTitle({
          //                 title: 'Alert',
          //                 msg: low_Bal_alert,
          //               })
          //             );
          //             navigate('/');
          //           }
          //         });
          //       } else {
          //         navigate('/');
          //       }
          //     },
          //     button2Click: () => {
          //       setAlertTwoButtonModal((o) => {
          //         return {
          //           ...o,
          //           status: false,
          //         };
          //       });
          //     },
          //   });
          // }
        } else {
          navigate('/');
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(setSpinnerLoading(false));
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (bookingCustomerDetails?.mobile) {
      setCustomerDetails({
        mobile: bookingCustomerDetails.mobile,
        name: bookingCustomerDetails.customer_name,
      });
    }
  }, [bookingCustomerDetails]);

  return (
    <div className="w-full flex flex-col h-full  ">
      <div className="flex flex-col grow">
        <div
          style={{
            border: '1px solid #e8e2db',
            borderBottom: '1px solid #e8e2db',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
          className="flex-col h-full   rounded-sm overflow-hidden"
        >
          <DataGrid
            sx={{
              border: '0px solid #e8e2db',
              fontSize: 14,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
            }}
            onCellDoubleClick={onCellClick}
            rowHeight={33}
            headerHeight={0}
            columns={StyleColums}
            rows={bill_delevery_cart}
            hideFooter
            getRowId={(i) => i.ID}
            scrollbarSize={0}
            rowsPerPageOptions={[100]}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            editMode="cell"
            //   onCellEditCommit={editCell}
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={StyleColums} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />

          {/* <div className="w-full"></div> */}
        </div>
        <div>
          <Del_BillingCardFooter
            setModalBillDiscountStatus={setModalBillDiscountStatus}
            rows={modifyBillingList[0] ? modifyBillingList : billingCartList}
            columns={StyleColums}
            setVoucherModStatus={setVoucherModStatus}
            bookingCustomerDetails={bookingCustomerDetails}
            mrcNumber={mrcNumber}
            isDelivery={isDelivery}
            state={state}
            customerPrefixState={customerPrefixState}
            orderID={orderID}
            saveStage={saveStage}
            isMakeBillApiRun={isMakeBillApiRun}
            isDeliveredApiRun={isDeliveredApiRun}
            setAlertTwoButtonModal={setAlertTwoButtonModal}
            alertTwoButtonModal={alertTwoButtonModal}
            employee={employee}
            setDeliveryStatusAPI={setDeliveryStatusAPI}
            qrBillList={qrBillList}
            bill_delevery_cart={bill_delevery_cart}
            setBill_delevery_cart={setBill_delevery_cart}
            billSettelmentDetails={billSettelmentDetails}
          />
        </div>
      </div>
      <ModBillDiscount
        status={modBillDiscountStatus}
        setStatus={setModalBillDiscountStatus}
      />
      <ModalQntyEdit
        status={modQntyEditStatus}
        setStatus={setModalQntyEditStatus}
        editID={QntyEditID}
        mrcNumber={mrcNumber}
      />
      <AddToCart
        bookingCustomerDetails={bookingCustomerDetails}
        mrcNumber={mrcNumber}
        isDelivery={isDelivery}
        state={state}
        qrBillList={qrBillList}
        orderID={orderID}
        customerPrefixState={customerPrefixState}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
        alertTwoButtonModal={alertTwoButtonModal}
        setBookingCustomerDetails={setBookingCustomerDetails}
        setBill_delevery_cart={setBill_delevery_cart}
        bill_delevery_cart={bill_delevery_cart}
        billSettelmentDetails={billSettelmentDetails}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default BillCart;
