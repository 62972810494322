import React from 'react';
import text from '../../images/Tailoring_Text.svg';
import lcon from '../../images/tailiringLogo.svg';
const TailoringxLogo = () => {
  return (
    <div className=" flex flex-col justify-center items-center">
      <img
        src={lcon}
        style={{
          width: '100px',
          height: 'auto',
        }}
      />
      <img
        src={text}
        style={{
          width: '170px',
          height: 'auto',
        }}
        className="mt-[12px]"
      />
    </div>
  );
};

export default TailoringxLogo;
