import React from 'react';
import Profile4Img from '../styles/profilePic';
import ProfilePic2 from '../styles/profilePic2';
import { useState } from 'react';
import ImageUpload from '../CMcustomerPic';
import { useSelector } from 'react-redux';
import { selectHistoryOrderDetails } from '../../Redux/booking';
import { useEffect } from 'react';
import { DownloadFile } from '../functions/firebase';
import { selectPrefix } from '../../Redux/profile';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;
const profilePicWidths = window.innerWidth <= 1024 ? widths * 80 : widths * 80;

const Attach4Img = ({
  selectedFile,
  setSelectedFile,
  capturedImage,
  setCapturedImage,
  capturedImage2,
  setCapturedImage2,
  webcamStatus2,
  webcamStatus,
  setWebcamStatus,
  setWebcamStatus2,
  selectedFile2,
  setSelectedFile2,
  capturedImage3,
  setCapturedImage3,
  webcamStatus3,
  setWebcamStatus3,
  selectedFile3,
  setSelectedFile3,
  capturedImage4,
  setCapturedImage4,
  webcamStatus4,
  setWebcamStatus4,
  selectedFile4,
  setSelectedFile4,
  bookingCustomerDetails,
  setBookingCustomerDetails,
}) => {
  const webcamRef = React.useRef(null);
  const prefix = useSelector(selectPrefix);
  const webcamRef2 = React.useRef(null);
  const webcamRef3 = React.useRef(null);
  const webcamRef4 = React.useRef(null);
  const [profilePicWidth, setProfilePicWidth] = useState(profilePicWidths);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);

  const [firebaseUrl, setFirebasurl] = useState('');
  const [firebaseUrl2, setFirebasurl2] = useState('');
  const [firebaseUrl3, setFirebasurl3] = useState('');
  const [firebaseUrl4, setFirebasurl4] = useState('');
  useEffect(() => {
    if (historyOrderDetails[0]) {
      const path1 =
        'seam/booking/' +
        prefix +
        historyOrderDetails[0].BOOKING_ORDER_NO +
        'left';
      const path2 =
        'seam/booking/' +
        prefix +
        historyOrderDetails[0].BOOKING_ORDER_NO +
        'right';
      const path3 =
        'seam/booking/' +
        prefix +
        historyOrderDetails[0].BOOKING_ORDER_NO +
        'front';
      const path4 =
        'seam/booking/' +
        prefix +
        historyOrderDetails[0].BOOKING_ORDER_NO +
        'back';

      DownloadFile(path1).then((e) => {
        if (!e.includes('FirebaseError')) {
          setFirebasurl(e);
        }
      });

      DownloadFile(path2).then((e) => {
        if (!e.includes('FirebaseError')) {
          setFirebasurl2(e);
        }
      });

      DownloadFile(path3).then((e) => {
        if (!e.includes('FirebaseError')) {
          setFirebasurl3(e);
        }
      });

      DownloadFile(path4).then((e) => {
        if (!e.includes('FirebaseError')) {
          setFirebasurl4(e);
        }
      });
    }
  }, [historyOrderDetails]);

  return (
    <div className="h-full w-full p-4 ">
      <div className="w-full   flex ">
        <div>
          <div
            style={{
              border: '1px dotted #E790A3',
            }}
            className="w-full flex rounded-md p-2  mb-2 justify-center"
          >
            Left
          </div>
          <div
            style={{
              height: window.innerWidth * 0.12,
              width: window.innerWidth * 0.12,
            }}
          >
            <Profile4Img
              customerFirebasePic={''}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              webcamStatus={webcamStatus}
              setWebcamStatus={setWebcamStatus}
              profilePicWidth={profilePicWidth}
              webcamRef={webcamRef}
              capturedImage={capturedImage}
              setCapturedImage={setCapturedImage}
              firebaseUrl={firebaseUrl}
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
            />
          </div>

          <div className="mt-2">
            <ImageUpload
              setCapturedImage={setCapturedImage}
              setSelectedFile={setSelectedFile}
              webcamRef={webcamRef}
              webcamStatus={webcamStatus}
              setWebcamStatus={setWebcamStatus}
            />
          </div>
        </div>
        <div className="   ml-4">
          <div
            style={{
              border: '1px dotted #E790A3',
            }}
            className="w-full flex  rounded-md p-2 mb-2 justify-center"
          >
            Right
          </div>
          <div
            style={{
              width: window.innerWidth * 0.12,
              height: window.innerWidth * 0.12,
            }}
          >
            <Profile4Img
              customerFirebasePic={''}
              selectedFile={selectedFile2}
              setSelectedFile={setSelectedFile2}
              webcamStatus={webcamStatus2}
              setWebcamStatus={setWebcamStatus2}
              profilePicWidth={profilePicWidth}
              webcamRef={webcamRef2}
              capturedImage={capturedImage2}
              setCapturedImage={setCapturedImage2}
              firebaseUrl={firebaseUrl2}
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
            />
          </div>

          <div className="mt-2">
            <ImageUpload
              setCapturedImage={setCapturedImage2}
              setSelectedFile={setSelectedFile2}
              webcamRef={webcamRef2}
              webcamStatus={webcamStatus2}
              setWebcamStatus={setWebcamStatus2}
            />
          </div>
        </div>
        <div className="   ml-4">
          <div
            style={{
              border: '1px dotted #E790A3',
            }}
            className="w-full  flex rounded-md p-2 mb-2 justify-center"
          >
            Front
          </div>
          <div
            style={{
              height: window.innerWidth * 0.12,
              width: window.innerWidth * 0.12,
            }}
          >
            <ProfilePic2
              customerFirebasePic={''}
              selectedFile={selectedFile3}
              setSelectedFile={setSelectedFile3}
              webcamStatus={webcamStatus3}
              setWebcamStatus={setWebcamStatus3}
              profilePicWidth={profilePicWidth}
              webcamRef={webcamRef3}
              capturedImage={capturedImage3}
              setCapturedImage={setCapturedImage}
              firebaseUrl={firebaseUrl3}
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
            />
          </div>

          <div className="mt-2">
            <ImageUpload
              setCapturedImage={setCapturedImage3}
              setSelectedFile={setSelectedFile3}
              webcamRef={webcamRef3}
              webcamStatus={webcamStatus3}
              setWebcamStatus={setWebcamStatus3}
            />
          </div>
        </div>
        <div className=" flex   ml-4">
          <div className="h-full w-full">
            <div
              style={{
                border: '1px dotted #E790A3',
              }}
              className="w-full  flex rounded-md p-2 mb-2 justify-center"
            >
              Back
            </div>
            <div
              style={{
                height: window.innerWidth * 0.12,
                width: window.innerWidth * 0.12,
              }}
            >
              <ProfilePic2
                customerFirebasePic={''}
                selectedFile={selectedFile4}
                setSelectedFile={setSelectedFile4}
                webcamStatus={webcamStatus4}
                setWebcamStatus={setWebcamStatus4}
                profilePicWidth={profilePicWidth}
                webcamRef={webcamRef4}
                capturedImage={capturedImage4}
                setCapturedImage={setCapturedImage4}
                firebaseUrl={firebaseUrl4}
                bookingCustomerDetails={bookingCustomerDetails}
                setBookingCustomerDetails={setBookingCustomerDetails}
              />
            </div>

            <div className="mt-2">
              <ImageUpload
                setCapturedImage={setCapturedImage4}
                setSelectedFile={setSelectedFile4}
                webcamRef={webcamRef4}
                webcamStatus={webcamStatus4}
                setWebcamStatus={setWebcamStatus4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attach4Img;
