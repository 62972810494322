import React, { useEffect, useState } from 'react';
import profile from '../utility/images/Tailoring_Text.svg';
import { HandleIndexedDB } from '../utility/func/func';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefultProfileSetting } from '../utility/Redux/profile';
import moment from 'moment';

import CustomeTwoButtonAlert from '../utility/component/modals/twoButtonAlert';
import { setCustomeTwoButtomAlert } from '../utility/Redux/modal';
// Shared Tailwind CSS classes
const shadowClasses = 'shadow-lg';
const bgWhiteClasses = 'bg-white';
const p4Classes = 'p-4';

const flex1Classes = 'flex-1';

const roundedClasses = 'rounded-[20px]';

const UserProfile = () => {
  const [userData, setUserData] = useState('');
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    const databaseName = 'userDetails';
    const storeName = 'data';

    HandleIndexedDB({
      databaseName,
      storeName,
      callback: (data) => {
        if (data[0]) {
          setUserData(data[0]);
          const endDate = moment(data[0].end_date).diff(
            moment(moment().format('YYYY-MM-DD')),
            'days'
          );
          setEndDate(endDate);
        }
      },
    });
  }, []);
  const defaultSettings = useSelector(selectDefultProfileSetting);
  const dispatch = useDispatch();
  return (
    <div className={`t:flex p-6 w-full h-full`}>
      <div
        className={`  ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} t:w-fit`}
      >
        <div className="border-b mb-4">
          <img src={profile} alt="User Profile" className={'mb-4 w-fit'} />
        </div>
        <h2 className={`text-[19px] font-semibold }`}>Profile</h2>
        <div className={'text-[16px] font-segoe mt-3'}>
          {userData?.user_name}
        </div>
        <div className={''}>{userData?.user_mobile}</div>
        <div className={''}>{userData?.user_email}</div>

        <button
          onClick={() => {
            dispatch(setCustomeTwoButtomAlert(true));
          }}
          className="mt-10 bg-yellow-400 w-full py-1 px-4 rounded-sm shadow-md"
        >
          Logout
        </button>
      </div>
      <div className="flex-1 t:mt-0 mt-3  t:ml-6">
        <div
          className={`${flex1Classes} ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} `}
        >
          <div className={``}>
            <div className={'text-[18px] font-semibold'}>Permissions</div>

            <div className="mt-3">
              <div className="flex items-center">
                <div className="  w-[300px] m">Can send whatsapp to users</div>:
                <div
                  className={`ml-3 ${
                    defaultSettings?.SEND_WHATSAPP === 'Y'
                      ? 'text-green'
                      : 'text-red'
                  }  font-semibold`}
                >
                  {defaultSettings?.SEND_WHATSAPP === 'Y' ? 'Yes' : 'No'}
                </div>
              </div>

              <div className="flex items-center">
                <div className="  w-[300px] m">Can send sms to users</div>:
                <div
                  className={`ml-3 ${
                    defaultSettings?.SEND_SMS === 'Y'
                      ? 'text-green'
                      : 'text-red'
                  }  font-semibold`}
                >
                  {defaultSettings?.SEND_SMS === 'Y' ? 'Yes' : 'No'}
                </div>
              </div>

              <div className="flex items-center">
                <div className="  w-[300px] m">Can modify bill</div>:
                <div
                  className={`ml-3 ${
                    defaultSettings?.BILL_EDITING === 'YES'
                      ? 'text-green'
                      : 'text-red'
                  }  font-semibold`}
                >
                  {defaultSettings?.BILL_EDITING === 'YES' ? 'Yes' : 'No'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${flex1Classes} mt-6 ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} `}
        >
          <div className={``}>
            <div className={'text-[18px] font-semibold'}>Other Details</div>

            <div className="mt-3">
              <div className="t:flex items-center">
                The license will expire on{' '}
                <label className="ml-1 text-red font-semibold">
                  {moment(userData?.end_date).format('DD MMM , YYYY')}
                </label>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end ">
          <p className="mt-3  text-gray-600">
            For any issue create a ticket{' '}
            <a
              href="https://geniusoffice.freshdesk.com/support/tickets/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
      <CustomeTwoButtonAlert />
    </div>
  );
};

export default UserProfile;
