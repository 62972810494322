import React, { useRef } from 'react';
import ProfilePicFrame from '../utility/component/styles/profilepicFrame';
import { SaveButton } from '../utility/component/SNAA_buttons';
import { Country, State, City } from 'country-state-city';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectWidth } from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import GeniustextInput from '../utility/component/mastertext';
import UploadImageButtons from '../utility/component/styles/uploadImageButtons';
import { setModCompanyHelpStatus } from '../utility/Redux/modal';
import CompanyMasterHelp from '../help/companyMasterHelp';

const CompanyInfo = ({
  UpdateCompanyDetailsAPI,
  selectedFile,
  setSelectedFile,
  webcamStatus,
  setWebcamStatus,
  webcamRef,
  capturedImage,
  setCapturedImage,
  companyInfo,
  handleDrop,
  firebaseUrl,
  setCompanyInfo,
}) => {
  const countries = Country.getAllCountries();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const currencies = [
    { code: 'inr', symbol: '₹' },
    { code: 'usd', symbol: '$' },
    { code: 'eur', symbol: '€' },
    { code: 'gbp', symbol: '£' },
    { code: 'jpy', symbol: '¥' },
    // Indian Rupee
  ];
  useEffect(() => {
    if (companyInfo.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === companyInfo.country
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [companyInfo.country]);

  useEffect(() => {
    if (companyInfo.state && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === companyInfo.state
      );
      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [companyInfo.state, stateList]);

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission

          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 9) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            if (index === 4) {
              inputFields[6].focus(); // Set focus on the 13th field for the 10th field
            } else {
              inputFields[nextIndex].focus(); // Set focus on the next field
            }
          }
        }
      });
    });
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);
  const headerRef = useRef(null);
  const windowSize = useSelector(selectWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      ref={formRef}
      className="w-full flex  justify-center  bg-gray-50 h-full  p-3"
    >
      <div className="text-sm  rounded-sm  h-full">
        <div ref={headerRef} className="bg-white ">
          <ModalHeaderStyle
            header={'Company Master'}
            onClose={() => navigate('/settings')}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="      flex flex-col  "
        >
          <div className=" justify-center p-3  bg-white  flex  ">
            <div>
              <div className="flex  ">
                <div>
                  <div className="flex justify-between ">
                    <GeniustextInput
                      type={'text'}
                      title={'Company Name'}
                      width={windowSize > 764 ? '280px' : '140px'}
                      error={false}
                      value={companyInfo.company_name}
                      onChange={(t) => {
                        setCompanyInfo({
                          ...companyInfo,
                          company_name: t.target.value,
                        });
                      }}
                      search={() => {
                        dispatch(setModCompanyHelpStatus(true));
                      }}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between ">
                      <GeniustextInput
                        width={windowSize > 764 ? '280px' : '140px'}
                        type={'text'}
                        title={'Email'}
                        value={companyInfo.user_email}
                        error={'email'}
                        onChange={(t) => {
                          console.log(t.target.value);
                        }}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between ">
                      <GeniustextInput
                        width={windowSize > 764 ? '280px' : '140px'}
                        type={'number'}
                        title={'Mobile'}
                        value={companyInfo.company_mobile}
                        error={'mobile'}
                        onChange={(t) => {
                          setCompanyInfo({
                            ...companyInfo,
                            company_mobile: t.target.value,
                          });
                        }}
                        maxLength={100}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {' '}
                  <div className="w-[140px] h-[140px] ml-3 ">
                    <ProfilePicFrame
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      webcamRef={webcamRef}
                      webcamStatus={webcamStatus}
                      capturedImage={capturedImage}
                      setCapturedImage={setCapturedImage}
                      setWebcamStatus={setWebcamStatus}
                      customerFirebasePic={firebaseUrl}
                    />
                  </div>
                  <div className="flex mt-2 w-[140px] ml-3 ">
                    <div className="flex w-[100%] items-center ">
                      <UploadImageButtons
                        title={'Company Logo'}
                        setSelectedFile={setSelectedFile}
                        webcamStatus={webcamStatus}
                        setWebcamStatus={setWebcamStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={'text'}
                    title={' Website'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.company_website}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        company_website: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>

                <div className="flex justify-between ml-3 ">
                  <GeniustextInput
                    type={'text'}
                    title={'Address'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.address}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        address: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>

              <div className="flex">
                <div className="flex justify-between ">
                  <GeniustextInput
                    title={'Country'}
                    country={true}
                    option={countries}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.country}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        country: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    title={'State'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    country={true}
                    option={stateList}
                    value={companyInfo.state}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        state: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex justify-between ">
                  <GeniustextInput
                    country={true}
                    option={cityList}
                    title={'City'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.city}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        city: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3 ">
                  <GeniustextInput
                    type={'text'}
                    title={'Pin Code'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.pin_code}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        pin_code: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="h-[1px] flex  w-full bg-[#d2d2d7]"></div>

              <div className="flex mt-3">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={'text'}
                    title={'Currency Name'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.currency_name}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        currency_name: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={'text'}
                    title={'Currency Symbol'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.currency_symbol}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        currency_symbol: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex ">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={'text'}
                    title={'Regd No. -1'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.regd_no1}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no1: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={'text'}
                    title={'Regd No. -2'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.regd_no2}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no2: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex ">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={'text'}
                    title={'Regd No. -3'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.regd_no3}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no3: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={'text'}
                    title={'Disputes'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.disputes}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        disputes: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end bg-white py-3 rounded-b-sm  px-3"
          >
            <SaveButton onClick={UpdateCompanyDetailsAPI} />
          </div>

          <CompanyMasterHelp
            onRowClick={(p) => {
              setCompanyInfo({
                ...setCompanyInfo,
                user_email: p.row.user_email,
                user_password: '',
                user_name: p.row.user_name,
                user_type: '',
                user_status: '',
                company_email: p.row.company_email,
                company_name: p.row.company_name,
                company_mobile: p.row.company_mobile,
                company_website: p.row.company_website,
                company_logo: '',
                address: p.row.address,
                city: p.row.city,
                state: p.row.state,
                country: p.row.country,
                pin_code: p.row.pin_code,
                currency_name: p.row.currency_name,
                currency_symbol: p.row.currency_symbol,
                regd_no1: p.row.regd_no1,
                regd_no2: p.row.regd_no2,
                regd_no3: p.row.regd_no3,
                disputes: p.row.disputes,
              });
              dispatch(setModCompanyHelpStatus(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
