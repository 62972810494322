import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BillCart from './billCart';
import { selectModifyBillingCartlist } from '../utility/Redux/billing';
import ModSaveVoucher from '../utility/component/modals/voucherSaveMod';
import { selectCustomerPrefix } from '../utility/Redux/booking';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;
const DelBilling = ({
  SetTabScreen,
  setMrcNumber,
  setBookingDate,
  bookingCustomerDetails,
  setBookingCustomerDetails,
  setCustomerHistory,
  mrcNumber,
  orderID,
  state,
  isDelivery,
  qrBillList,
  setQrBillList,
  employee,
  customerPrefixState,
  billSettelmentDetails,
}) => {
  // ...........................Use State...................................................
  const [rowHeaderWidth, setRowHeaderWidth] = useState('200px');
  const [voucherModStatus, setVoucherModStatus] = useState(false);

  // .......................................Redux.............................................................

  // .......................................Use Effect...........................................................
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // // .................................... function ...........................................................

  // const handleResize = () => {
  //   setRowHeaderWidth(
  //     window.innerWidth <= 1280
  //       ? ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 250
  //       : ((window.innerWidth * window.innerHeight) / 100) * 0.0001 * 200
  //   );
  // };

  const { mobile, customer_name, company_name, regd_no1, email } =
    bookingCustomerDetails;

  const bookingPrefix = useSelector(selectCustomerPrefix);
  const bookingPrefxState = customerPrefixState
    ? customerPrefixState
    : bookingPrefix;
  return (
    <div className="w-full h-full flex flex-col bg-white text-md text-gray-800">
      <div
        style={{
          width: '662px',
        }}
        className="h-fit flex  p-1 "
      >
        {/* ...............................................customer info.................................................................................. */}
        <div className=" h-full w-full">
          <div className="flex  ">
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderBottom: '0px solid #b5b3b3',

                width: rowHeaderWidth,
              }}
              className=" flex rounded-tl-sm text-orange  justify-Start p-1 l:p-1 d:p-2 pl-3 items-center"
            >
              Customer Name
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderBottom: '0px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-tr-sm pl-3 items-center text-red font-semibold flex-1 pt-1 pb-1"
            >
              {customer_name}
            </div>
          </div>

          <div className="flex  ">
            <div
              //   onClick={() => {
              //     dispatch(setCustomerMasterHelpModStatus(true));
              //   }}
              style={{
                border: '1px solid #b5b3b3',
                borderTop: '1px dotted #b5b3b3',
                width: rowHeaderWidth,
              }}
              className=" flex text-orange rounded-bl-sm  justify-Start p-1 l:p-1 d:p-2 pl-3 items-center"
            >
              Customer Mobile
              <i
                style={{
                  fontSize: '11px',
                }}
                className="bi text-red font-semibold ml-2  mr-1 items-center bi-search flex "
              ></i>
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex  pl-3 rounded-br-sm justify-between text-red font-semibold  flex-1 items-center "
            >
              <div
                style={{
                  borderRight: '1px dotted #b5b3b3',
                }}
                className={' h-full flex items-center w-1/3 '}
              >
                {mobile}
              </div>
              <div className="border-l flex-1 flex pl-3">
                {bookingPrefxState + '-' + mrcNumber}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col grow  mt-2  ">
        <div className="flex h-full ">
          <div className="flex flex-col  b">
            <BillCart
              bookingCustomerDetails={bookingCustomerDetails}
              setVoucherModStatus={setVoucherModStatus}
              mrcNumber={mrcNumber}
              isDelivery={isDelivery}
              state={state}
              qrBillList={qrBillList}
              setQrBillList={setQrBillList}
              orderID={orderID}
              employee={employee}
              billSettelmentDetails={billSettelmentDetails}
              customerPrefixState={bookingPrefxState}
              setBookingCustomerDetails={setBookingCustomerDetails}
            />
          </div>
        </div>
      </div>

      <ModSaveVoucher
        bookingCustomerDetails={bookingCustomerDetails}
        status={voucherModStatus}
        setStatus={setVoucherModStatus}
        type={'advance'}
      />
    </div>
  );
};

export default DelBilling;
