import React, { useRef } from 'react';
import ImageUpload from '../utility/component/functions/uploadphoto';
import ProfilePicFrame from '../utility/component/styles/profilepicFrame';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  Button,
  Collapse,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';

import {
  setAlertWithTitle,
  setCustomerMasterHelpModStatus,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
} from '../utility/Redux/modal';

import {
  selectCustomerFirebasePic,
  selectCustomerPrefix,
  selectModyingBookingID,
  selectRemarks,
  setBookingAttachImage,
  setBookingModifiedStatus,
  setCustomerFirebasePic,
  setCustomerPrefix,
  setEditType,
  setHistoryOrderDetails,
  setLowerDate,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setLowerQnty,
  setModifyingBookingId,
  setModifyingBookingList,
  setRemarks,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
  setUpperDate,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
  setUpperQnty,
} from '../utility/Redux/booking';
import axios from 'axios';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { useFocus } from '../utility/hooks/hooks';
import {
  setAdvanceVoucherDetails,
  setBillSettlement,
  setBillingAdvanceAmount,
  setBillingCartList,
  setModifyBillcartList,
} from '../utility/Redux/billing';
import { DownloadFile } from '../utility/component/functions/firebase';
import { toProperCase } from '../utility/component/format';
import CustomerMasterhelp from '../help/customermasterHelp';
import MrcHelp from '../help/modMrc';

import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { getCustomerListByNameOrMobile } from './list';
import { useLocation, useNavigate } from 'react-router-dom';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;
const profilePicWidths = window.innerWidth <= 1024 ? widths * 80 : widths * 80;

const CustomerInfo = ({
  SetTabScreen,
  customerInfo,
  mergehistory,
  mrcNumber,
  setMrcNumber,
  isMrcDuplicate,
  setBookingDate,
  mobileRef,
  mobileRef2,
  mobileRef3,
  setMobileFocus,
  setMobileFocus2,
  setMobileFocus3,
  setSelectedFile,
  setpath,
  path,
  selectedFile,
  capturedImage,
  setCapturedImage,
  editMobileEnabled,
  customerPrifixList,
  setOpenYear,
  openYear,
  bookingCustomerDetails,
  setBookingCustomerDetails,
  setIsMrcFound,
  history,
  setCustomerHistory,
}) => {
  // useState........................................................................................................................

  const [nameRef, setNameFocus] = useFocus();
  const [profilePicWidth, setProfilePicWidth] = useState(profilePicWidths);
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [familyRef, setFamilyFocus] = useFocus();
  const [mrcRef, setMrcFocus] = useFocus();
  const [emailRef, setEmailFocus] = useFocus();
  const [mrcStatus, setMrcStatus] = useState(false);
  const [addressRef, setAddressFocus] = useFocus();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [remarksRef, setRemarksFocus] = useFocus();
  const customerPrefix = useSelector(selectCustomerPrefix);
  const webcamRef = React.useRef(null);
  const countries = Country.getAllCountries();

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  // handle Size ....................................................................................................................
  const bookingPrefix = localStorage.getItem('bookingPrefix');
  const minProfileicWidth =
    window.innerWidth < 768
      ? '120px'
      : window.innerWidth <= 1024
      ? '120px'
      : '110px';

  useEffect(() => {
    if (bookingPrefix) {
      dispatch(setCustomerPrefix(bookingPrefix));
    } else {
      dispatch(setCustomerPrefix(customerPrifixList[1]));
    }
  }, [customerPrifixList]);

  // redux..............................................................................................................................
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const remarks = useSelector(selectRemarks);
  const ModifiedBookingId = useSelector(selectModyingBookingID);

  const customerFirebasePic = useSelector(selectCustomerFirebasePic);

  const getCustomerHistory = (mrc, mobile, name, pr) => {
    if (bookingCustomerDetails?.code || mrcNumber || mrc || mobile) {
      setLoading(true);
      setStatelist([]);
      setCitylist([]);
      const data = {
        table_prefix: prefix,
        MRC_NO: mrc ? mrc : '',
        CUSTOMER_NAME: !mrc
          ? name
            ? name
            : bookingCustomerDetails?.customer_name
            ? bookingCustomerDetails.customer_name
            : ''
          : '',
        PREFIX: pr ? pr : customerPrefix,

        MOBILE: !mrc ? (mobile ? mobile : bookingCustomerDetails.mobile) : '',
      };
      axios
        .post(`/api/booking/bookingHistory`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          if (!response.data.error) {
            if (response.data.response[0]) {
              dispatch(setBookingModifiedStatus(false));
              setCustomerHistory(response.data.response);
              setOpenYear(
                Number(moment(response.data.response[0].DATE).format('YYYY'))
              );
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const undoAction = (
    isMrcIgnore,
    isCustomerIgnore,
    isCustomerHistoryIgnore
  ) => {
    return new Promise((resolve, reject) => {
      try {
        setIsMrcFound(false);
        setAdvanceVoucherDetails('');
        const dispatchPromises = [
          dispatch(setUpperMeasuremnt([])),
          dispatch(setLowerMeasuremnt([])),
          dispatch(setUpperMeasuremntList([])),
          dispatch(setLowerMeasuremntList([])),
          dispatch(setModifyBillcartList([])),
          setBookingDate(
            moment(localStorage.getItem('bookingDate')).format('YYYY-MM-DD')
          ),
          dispatch(setBillingCartList([])),
          !isCustomerHistoryIgnore && setCustomerHistory([]),
          dispatch(
            setRemarks({
              upper: '',
              lower: '',
            })
          ),
          dispatch(
            setUpperDate({
              try: '',
              del: moment().add(8, 'days').format('YYYY-MM-DD'),
              cutting: '',
            })
          ),
          dispatch(
            setLowerDate({
              try: '',
              del: moment().add(8, 'days').format('YYYY-MM-DD'),
              cutting: '',
            })
          ),
          dispatch(setCustomerFirebasePic('')),
          dispatch(setBookingAttachImage('')),
          dispatch(setSelectedBooingLowerItemDetails('')),
          dispatch(setSelctedBookingUpperitemDetails('')),
          dispatch(setModifyingBookingId('')),
          dispatch(setHistoryOrderDetails('')),
          dispatch(setUpperQnty(1)),
          dispatch(setLowerQnty(1)),
        ];

        Promise.all(dispatchPromises)
          .then(() => {
            if (!isCustomerIgnore) {
              setBookingCustomerDetails({
                code: '',
                customer_name: '',
                mobile: '',
                mobile_2: '',
                mobile_3: '',
                phone: '',
                email: '',
                address: '',
                city: '',
                state: '',
                country: '',
                pin_code: '',
                credit_limit: 0,
                company_name: '',
                company_phone: '',
                company_email: '',
                company_address: '',
                company_city: '',
                company_state: '',
                company_country: '',
                company_pincode: '',
                regd_no1: '',
                regd_no2: '',
                regd_no3: '',
                status: '',
                remarks: '',
                prefix: '',
                mrc_no: '',
                old_mobile: '',
              });
            }

            if (!isMrcIgnore) {
              setMrcNumber('');
              setMrcFocus();
            }
          })
          .then(() => resolve())
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/booking') {
      undoAction();
    }
  }, [location]);

  const uniqueYears = [...new Set(history.map((item) => item.YEAR))];
  const h = history.filter(
    (item) =>
      moment(item.DATE).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')
  );
  const h2 = history.filter(
    (item) =>
      moment(item.DATE).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
  );

  const uYears =
    h[0] && h2[0]
      ? [moment().format('YYYY-MM-DD'), ...uniqueYears]
      : h2[0] && !h[0]
      ? [moment().format('YYYY-MM-DD')]
      : h[0]
      ? [...uniqueYears]
      : [];

  const masterPrefix = useSelector(selectTableMasterPrefix);

  // load Customer Histery order details through booking id on click history order
  const loadCustomerOrderAPI = (bookingID) => {
    dispatch(setModifyBillcartList([]));
    dispatch(setBillingCartList([]));
    dispatch(setUpperMeasuremnt([]));
    dispatch(setLowerMeasuremnt([]));
    dispatch(setUpperMeasuremntList([]));
    dispatch(setLowerMeasuremntList([]));
    dispatch(setSpinnerLoading(`Opening booking ID : ${bookingID}`));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setUpperQnty(1));
    dispatch(setLowerQnty(1));
    dispatch(setEditType(''));

    dispatch(setBookingAttachImage(''));
    dispatch(
      setRemarks({
        upper: '',
        lower: '',
      })
    );

    dispatch(
      setBillSettlement({
        cashAmount: '',
        btcAmount: '',
        epaymentAmount: '',
        epaymentType: '',
        cardAmount: '',
        cardNumber: '',
        Remarks: '',
        clothAmount: '',
      })
    );

    dispatch(setBookingModifiedStatus(false));
    axios
      .post(
        `/api/booking/loadCustomerOrder`,
        {
          table_prefix: prefix,
          BOOKING_ORDER_NO: bookingID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          const orderData = response.data.response;

          dispatch(setCustomerPrefix(orderData[0]?.PREFIX));
          setBookingDate(orderData[0]?.DATE);

          setMrcNumber(orderData[0]?.MRC_NO);
          dispatch(setModifyingBookingList(response.data.response));

          // filter upper list from load customer order
          const ordersList = [];
          const upperList = orderData.filter((i) => i.UPPER_LOWER === 'UPPER');

          // filter lower lsit load customer order
          const LowerList = orderData.filter((i) => i.UPPER_LOWER === 'LOWER');

          // make 2 list with 1 upper and 1 lower
          if (upperList[0]) {
            ordersList.push(upperList[0]);
            dispatch(setUpperQnty(upperList[0].QNTY));
          }
          if (LowerList[0]) {
            ordersList.push(LowerList[0]);
            dispatch(setLowerQnty(LowerList[0].QNTY));
          }

          // now map the order details because may be one history have two type records like upper and lowwer
          ordersList.map((customerOrderDetails, index) => {
            // now get orderItem details to set setSelectedBooingItemDetails so that in measuremnt screen upper or lower item will select
            loadBillFromOrder(ordersList, index, orderData, bookingID);
          });
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 109',
              msg: 'Something went wrong while loading customer order details',
            })
          );
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(setSpinnerLoading(false));

        console.log(error);
      });
  };

  // select measuremnt buttton
  const selectMeauremtItemButtons = (
    customerOrderDetails,
    index,
    allparamtersList,
    inv,
    noBill
  ) => {
    const data = {
      table_prefix_master: masterPrefix,
      SECTION_NAME: '',
      ICODE: '',
      ITEM_NAME: customerOrderDetails[index].ITEM_NAME,
    };
    dispatch(setSpinnerLoading('Loading Items'));
    // get itemDetails to set setSelectedBooingItemDetails so that in measuremnt screen upper or lower button will be selected
    axios
      .post(`/api/masters/listItemMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response?.data?.error) {
          const itemDetails = response.data.response[0];
          if (itemDetails.upper_lower === 'UPPER') {
            dispatch(setSelctedBookingUpperitemDetails(itemDetails));
          } else {
            dispatch(setSelectedBooingLowerItemDetails(itemDetails));
          }

          // now turn to fill parameters details in upper or lower datagrid in meaurement screen
          if (index === 1) {
            timer(
              customerOrderDetails,
              itemDetails,
              index,
              allparamtersList,
              inv,
              noBill
            );
          } else {
            listMeasurementMasterApi(
              customerOrderDetails,
              itemDetails,
              index,
              allparamtersList,
              inv,
              noBill
            );
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // make a measurment list and map it and fill paramters details in upper or lower datagrid in meaurement

  const listMeasurementMasterApi = (
    orderDetails,
    itemDetails,
    index,
    allparamtersList,
    inv,
    noBill
  ) => {
    axios
      .post(
        `/api/masters/listMeasurementMaster`,
        {
          table_prefix_master: masterPrefix,
          UPPER_LOWER: orderDetails[index].UPPER_LOWER,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          // make a empty list measurment of selected lower or upper
          let defaultEmptyMeasurment = response.data.response;

          // make a list of availval parameter of selected lower or upper
          const parameterList = allparamtersList.filter(
            (i) => i.UPPER_LOWER === orderDetails[index].UPPER_LOWER
          );

          // map empty measurment list to make a list of  parameter for lower or upper datagrid
          const parameter = await defaultEmptyMeasurment.map((i, ind) => {
            const pIndex = parameterList.findIndex(
              (listItem) => listItem.PARAMETER === i.PARAMETER
            );

            if (pIndex !== -1) {
              const row = {
                ...i,
                size: parameterList[pIndex].SIZE,
              };
              return row;
            } else {
              return {
                ...i,
                size: '',
              };
            }
          });

          if (orderDetails[index].UPPER_LOWER === 'UPPER') {
            dispatch(setUpperMeasuremnt(parameter));

            // if upper is availval only in measuremnt
            if (!orderDetails[1]) {
              dispatch(
                setRemarks({
                  ...remarks,
                  upper: orderDetails[index].REMARKS,
                })
              );
            } else {
              // if upper and lower is availval
              const upperindex = orderDetails.findIndex(
                (item) => item.UPPER_LOWER === 'UPPER'
              );
              const lowerindex = orderDetails.findIndex(
                (item) => item.UPPER_LOWER === 'LOWER'
              );
              dispatch(
                setRemarks({
                  lower: orderDetails[lowerindex].REMARKS,
                  upper: orderDetails[upperindex].REMARKS,
                })
              );
            }
            dispatch(
              setUpperDate({
                try: orderDetails[index].TRY_DATE,
                del: orderDetails[index].DEL_DATE,
                cutting: orderDetails[index].CUTTING_DATE,
              })
            );

            SetTabScreen(2);
          } else {
            dispatch(setLowerMeasuremnt(parameter));

            if (!orderDetails[1]) {
              dispatch(
                setRemarks({
                  ...remarks,
                  lower: orderDetails[index].REMARKS,
                })
              );
            }

            dispatch(
              setLowerDate({
                try: orderDetails[index].TRY_DATE,
                del: orderDetails[index].DEL_DATE,
                cutting: orderDetails[index].CUTTING_DATE,
              })
            );

            SetTabScreen(2);
          }
          if (noBill) {
            dispatch(setloading(false));
          }
          // if upper and lower are availval then make bill only one time so make this function for that
          if (orderDetails.length === 2 && index === 1 && !noBill) {
            loadBill(inv);
            dispatch(setloading(false));
            SetTabScreen(2);
          } else {
            if (orderDetails.length === 1 && !noBill) {
              loadBill(inv);
              dispatch(setloading(false));
              SetTabScreen(2);
            }
          }
        }
      })
      .catch(function (error) {
        if (error.response?.data?.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      });
  };

  const loadBill = (inv) => {
    dispatch(setSpinnerLoading('Loading Bill'));
    axios
      .post(
        `/api/billing/loadBill`,
        {
          table_prefix: prefix,
          INVNO: inv,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response?.data?.error) {
          const data = response.data.response;
          dispatch(setModifyBillcartList(data));
          loadBillSettlementAPI(inv, response.data.response[0].ADVANCE_AMT);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => dispatch(setSpinnerLoading(false)));
  };

  // set a timer for lower and upper data is availval to ignore errors

  const timer = (
    customerOrderDetails,
    itemDetails,
    index,
    allparamtersList,
    inv,
    noBill
  ) => {
    const timeout = setTimeout(() => {
      listMeasurementMasterApi(
        customerOrderDetails,
        itemDetails,
        index,
        allparamtersList,
        inv,
        noBill
      );
    }, 1000); // 3000 milliseconds = 3 seconds

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  };

  const loadBillSettlementAPI = (inv, advanceAmt) => {
    axios
      .post(
        `/api/billing/loadBillSettlement`,
        {
          table_prefix: prefix,
          INVNO: inv,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          const data = response.data.response[0];
          dispatch(
            setBillSettlement({
              cashAmount: data.cash_amount,
              btcAmount: data.btc_amount,
              epaymentAmount: data.epayment,
              epaymentType: data.epayment_type,
              cardAmount: data.card_amount,
              cardNumber: data.card_no,
              Remarks: '',
              clothAmount: data.cloth_balance,
            })
          );

          if (advanceAmt !== 0) {
            loadVocherAPI(inv);
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 113',
              msg: response.data.error,
            })
          );
        }
      })
      .catch(function (error) {
        if (error.response?.data?.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'Error 114',
            msg: 'Some thing went wrong with loading Bill Settlement',
          })
        );
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const loadBillFromOrder = (ordersList, index, orderData, bookingID) => {
    axios
      .post(
        `/api/booking/loadBillFromOrder`,
        {
          table_prefix: prefix,
          BOOKING_ORDER_NO: bookingID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response?.data?.error) {
          let list = [];
          for (let i = 0; i < ordersList.length; i++) {
            list.push({
              ...ordersList[i],
              INVNO: response.data.response[0]?.INVNO,
            });
          }
          dispatch(setHistoryOrderDetails(list));
          selectMeauremtItemButtons(
            ordersList,
            index,
            orderData,
            response.data.response[0]?.INVNO
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadVocherAPI = (invNo) => {
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: '',
      INVNO: invNo,
    };

    axios
      .post(`/api/vouchers/loadVoucher`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          dispatch(
            setAdvanceVoucherDetails({
              ...response.data.response[0],
              DATE: moment(response.data.response[0].DATE).format('YYYY-MM-DD'),
            })
          );
        }

        dispatch(setBillingAdvanceAmount(response.data.response[0].CREDIT));
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      });
  };
  const isMrcOrderAvailval = async (mrc, pr, customerName, rowMobile) => {
    dispatch(setSpinnerLoading('Loading Mrc'));
    const p = pr;
    const bookingCustomerMobile = bookingCustomerDetails?.mobile
      ? bookingCustomerDetails.mobile
      : '';
    let list = [];

    await axios
      .post(
        '/api/booking/listMRCNo',
        {
          table_prefix: prefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (p.status === 200) {
          list = p.data.response;
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    const isOrder = list.filter((o) => o.MRC_NO === mrc && o.PREFIX === p);
    if (isOrder[0]) {
      const mobile = rowMobile ? rowMobile : isOrder[0].MOBILE;
      // mean mrc is in used
      if (bookingCustomerMobile && bookingCustomerMobile !== mobile) {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'MRC no. already used',
          })
        );
        setIsMrcFound(false);

        setMrcNumber('');
      } else {
        if (bookingCustomerMobile && bookingCustomerMobile === mobile) {
          setIsMrcFound(true);

          dispatch(setSpinnerLoading(false));
        } else {
          // mean mobile not entered and now turn to load customer using mrc
          const MrcIgnore = true;
          undoAction(MrcIgnore).then(() => {
            setMrcNumber(mrc);
            getCustomermasterListPromise(mobile, '', true)
              .then((data) => {
                const customer = customerName
                  ? customerName
                  : isOrder[0].CUSTOMER_NAME;
                const customerDetails = data.filter(
                  (i) => i.customer_name === customer
                );
                if (customerDetails[0]) {
                  setBookingCustomerDetails(customerDetails[0]);
                }
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
            setIsMrcFound(true);

            getCustomerHistory(mrc, '', '', p);
          });
        }
      }
    } else {
      setIsMrcFound(false);
      //mean new mrc number
      setNameFocus();
      dispatch(setSpinnerLoading(false));
    }
  };
  const regex = /^\+?\d+$/;

  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.clientWidth;
      setProfilePicWidth(width);
    }
  }, []);

  const getCustomermasterListPromise = (mobile, name, isIgronreLoadingStop) => {
    dispatch(setSpinnerLoading('Customers Loading'));
    const data = {
      table_prefix: prefix,
      MOBILE: mobile ? mobile : '',
      CODE: '',
      CUSTOMER_NAME: name ? name : '',
      ADDRESS: '',
      CITY: '',
      STATE: '',
      COUNTRY: '',
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/masters/listCustomerMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (!res.data.error) {
            const data = res.data.response;
            resolve(data);
          } else {
            reject(new Error('Error in response data'));
          }
        })
        .catch((e) => {
          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(e);
          reject(e);
        })
        .finally(() => {
          if (!isIgronreLoadingStop) {
            dispatch(setSpinnerLoading(false));
          }
        });
    });
  };

  const mrcCheck = (e) => {
    if (e.target.value) {
      isMrcOrderAvailval(e.target.value, customerPrefix);
    } else {
      setNameFocus();
    }
  };

  const todayData = history[0]
    ? history.filter(
        (i) =>
          moment(i.DATE).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      )
    : [];

  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  useEffect(() => {
    if (bookingCustomerDetails.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === bookingCustomerDetails.country
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [bookingCustomerDetails?.country, bookingCustomerDetails?.customer_name]);

  useEffect(() => {
    if (bookingCustomerDetails?.state && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === bookingCustomerDetails.state
      );
      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [bookingCustomerDetails?.state, stateList]);
  useEffect(() => {
    if (!bookingCustomerDetails?.mobile) {
      setMrcFocus();
    } else {
      setMobileFocus();
    }
  }, []);

  return (
    <div className="h-full   text-sm d:text-md w-full  flex flex-col ">
      <div className=" h-fit flex   w-full   ">
        <div
          style={{
            border: '1px dotted #9e9e9e',
          }}
          className="flex rounded-sm w-full p-4 text-gray-800"
        >
          {/* Customer Details..................................................................................................................... */}

          <div className="flex  flex-1">
            <div className=" flex flex-col items-center">
              <div
                style={{
                  // border: '1px dotted #9e9e9e',
                  position: 'relative',
                  // width: profilePicWidth,
                  width: profilePicWidth,
                  height: profilePicWidth,
                  minWidth: minProfileicWidth,
                  minHeight: minProfileicWidth,
                }}
                className="rounded-sm  flex justify-center items-center mb-3"
              >
                <ProfilePicFrame
                  customerFirebasePic={customerFirebasePic}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  webcamStatus={webcamStatus}
                  setWebcamStatus={setWebcamStatus}
                  profilePicWidth={profilePicWidth}
                  webcamRef={webcamRef}
                  bookingCustomerDetails={bookingCustomerDetails}
                  capturedImage={capturedImage}
                  setCapturedImage={setCapturedImage}
                  path={path}
                />
              </div>
              <div ref={divRef} className="w-full">
                <ImageUpload
                  bookingCustomerDetails={bookingCustomerDetails}
                  setWebcamStatus={setWebcamStatus}
                  webcamStatus={webcamStatus}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  capturedImage={capturedImage}
                  setCapturedImage={setCapturedImage}
                  webcamRef={webcamRef}
                />
              </div>
            </div>

            {/* customer info.....................................customer info..................................................customer info.......................................... */}
            <div className="flex ml-3 grow ">
              <div className="flex flex-col grow w-full  ">
                <div className="flex ">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',

                      overflow: 'hidden',
                    }}
                    // className="flex w-[58%] l:w-60  pl-3 py-2 items-center rounded-l-sm"
                    className="flex w-[144px]  pl-3 py-2 items-center rounded-l-sm"
                  >
                    <FormControl
                      size="small"
                      variant="standard"
                      sx={{
                        // minWidth: window.innerWidth <= 1024 ? 50 : 120,
                        border: '0px solid white',
                        height: '20px',
                        backgroundColor: 'white',
                        '& .MuiInput-underline:before': {
                          borderBottom: 'none', // Hide the bottom line when not focused
                        },
                        '& .MuiInput-underline:hover:before': {
                          borderBottom: 'none', // Hide the bottom line when hovered
                        },
                        '& .MuiInput-underline:after': {
                          borderBottom: 'none', // Hide the bottom line when focused
                        },
                      }}
                    >
                      <Select
                        value={customerPrefix}
                        style={{
                          paddingLeft: '12px',
                          fontSize: '13px',
                          zIndex: 1,
                        }}
                        // onChange={handleChange}
                      >
                        <MenuItem
                          style={{
                            fontSize: '13px',
                          }}
                          value={''}
                        >
                          {/* <em>None</em> */}
                        </MenuItem>
                        {customerPrifixList.map((i, key) => {
                          return (
                            <MenuItem
                              key={key}
                              sx={{
                                fontSize: '13px',
                              }}
                              onClick={() => {
                                dispatch(setCustomerPrefix(i));
                                const isMrcIgnore = true;
                                undoAction(isMrcIgnore);

                                setMrcNumber('');
                              }}
                              value={i}
                            >
                              {i}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px dotted #b5b3b3',
                    }}
                    className="flex rounded-tr-sm pl-2 rounded-br-sm grow "
                  >
                    <div className="flex flex-1 items-center  ">
                      <div className="flex  items-center  ">
                        <i
                          onClick={() => {
                            setMrcStatus(true);
                            undoAction();
                            setMrcNumber('');
                          }}
                          c
                          className="bi text-red hover:cursor-pointer font-semibold  mr-1 bi-search flex "
                        ></i>

                        <div className="text-red ">
                          {mrcNumber && isMrcDuplicate ? (
                            <div className="ml-2"> '[ Dup ]' </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <input
                          value={mrcNumber}
                          type="number"
                          style={{
                            outline: 'none',
                          }}
                          className="flex-1 ml-1"
                          onChange={(e) => {
                            if (!ModifiedBookingId) {
                              setMrcNumber(e.target.value);
                            }
                          }}
                          placeholder="MRC No."
                          onKeyDown={(e) => {
                            if (
                              e.code === 'Enter' ||
                              e.code === 'NumpadEnter'
                            ) {
                              if (mrcRef.current) {
                                mrcRef.current.blur();
                              }
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              if (!ModifiedBookingId) {
                                mrcCheck(e);
                              }
                            } else {
                              setNameFocus();
                            }
                          }}
                          ref={mrcRef}
                          maxLength={100}
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        undoAction();
                      }}
                      className="bg-red flex items-center px-2 rounded-r-sm"
                    >
                      <i className="bi font-semibold  text-white text-[12px]  items-center  bi-x-lg flex "></i>
                    </button>
                  </div>
                </div>

                <div className="flex mt-2  ">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',

                      overflow: 'hidden',
                    }}
                    // className="flex w-[58%] l:w-60  pl-3 py-2 items-center rounded-l-sm"
                    className="flex  w-[144px]   pl-3 py-2 items-center rounded-l-sm"
                  >
                    Name
                  </div>

                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px dotted #b5b3b3',
                    }}
                    className="flex rounded-tr-sm c rounded-br-sm grow "
                  >
                    <div className="flex items-center ml-2 flex-1">
                      <i
                        onClick={async () => {
                          dispatch(setCustomerMasterHelpModStatus(true));
                        }}
                        className="bi text-red hover:cursor-pointer font-semibold   bi-search flex "
                      ></i>
                      <input
                        value={
                          bookingCustomerDetails?.customer_name
                            ? bookingCustomerDetails.customer_name
                            : customerInfo.CusName
                        }
                        ref={nameRef}
                        placeholder={
                          bookingCustomerDetails?.customer_name
                            ? bookingCustomerDetails.customer_name
                            : 'Name'
                        }
                        style={{
                          outline: 'none',
                          width: '100%',
                        }}
                        type="text"
                        onKeyDown={async (e) => {
                          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                            if (!e.target.value) {
                              dispatch(setCustomerMasterHelpModStatus(true));
                            } else {
                              setMobileFocus();
                            }
                          }
                        }}
                        onChange={(t) => {
                          if (t.target.value.length < 101) {
                            setBookingCustomerDetails({
                              ...bookingCustomerDetails,
                              customer_name: toProperCase(t.target.value),
                            });
                          }
                        }}
                        maxLength={100}
                        className="w-[200px] flex ml-2 text-black font-semibold"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex mt-2 ">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',

                      overflow: 'hidden',
                    }}
                    // className="flex w-[58%] l:w-60  pl-3 py-2 items-center rounded-l-sm"
                    className="flex w-[144px]  pl-3 py-2 items-center rounded-l-sm"
                  >
                    Mobile
                  </div>
                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px dotted #b5b3b3',
                    }}
                    className="flex rounded-tr-sm pl-1 rounded-br-sm grow   "
                  >
                    <div className="flex-1 flex items-center ">
                      <input
                        value={bookingCustomerDetails.mobile}
                        ref={mobileRef}
                        readOnly={editMobileEnabled ? false : true}
                        placeholder="Mobile 1"
                        autoFocus={window.innerWidth <= 1380 ? false : true}
                        type="text"
                        onKeyDown={(e) => {
                          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                            if (e.target.value) {
                              mobileRef.current.blur();
                            }
                          }
                        }}
                        style={{
                          outline: 'none',
                        }}
                        className="w-full ml-1  text-red font-[500]"
                        onChange={(e) => {
                          if (e.target.value.length < 51) {
                            setBookingCustomerDetails({
                              ...bookingCustomerDetails,
                              mobile: e.target.value,
                            });
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value && editMobileEnabled) {
                            // checkIsMobileDuplicate(e.target.value);
                            setMobileFocus2();
                          }
                        }}
                      />
                    </div>
                    <div className="pl-1 border-l flex-1 flex items-center ">
                      <input
                        value={bookingCustomerDetails?.mobile_2}
                        readOnly={editMobileEnabled ? false : true}
                        placeholder="Mobile 2"
                        autoFocus={window.innerWidth <= 1380 ? false : true}
                        type="text"
                        style={{
                          outline: 'none',
                        }}
                        className="w-full  text-red ml-1 font-[500]"
                        onChange={(e) => {
                          if (e.target.value.length < 51) {
                            setBookingCustomerDetails({
                              ...bookingCustomerDetails,
                              mobile_2: e.target.value,
                            });
                          }
                        }}
                        ref={mobileRef2}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                            if (e.target.value) {
                              mobileRef2.current.blur();
                            } else {
                              setMobileFocus3();
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value && editMobileEnabled) {
                            // checkIsMobileDuplicate('', e.target.value);
                            setMobileFocus3();
                          }
                        }}
                      />
                    </div>
                    <div className="pl-1 border-l flex-1  flex items-center ">
                      <input
                        value={bookingCustomerDetails?.mobile_3}
                        ref={mobileRef3}
                        placeholder="Mobile 3"
                        autoFocus={window.innerWidth <= 1380 ? false : true}
                        type="text"
                        style={{
                          outline: 'none',
                        }}
                        readOnly={editMobileEnabled ? false : true}
                        className="w-full ml-1  text-red font-[500]"
                        onChange={(e) => {
                          if (e.target.value.length < 51) {
                            setBookingCustomerDetails({
                              ...bookingCustomerDetails,
                              mobile_3: e.target.value,
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                            if (e.target.value) {
                              mobileRef3.current.blur();
                            } else {
                              setFamilyFocus();
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value && editMobileEnabled) {
                            // checkIsMobileDuplicate('', '', e.target.value);
                            setEmailFocus();
                          }
                        }}
                      />
                    </div>
                    {/* <div className="flex-1 flex justify-end">
                      <button
                        onClick={() => {
                          undoAction();
                        }}
                        className="bg-red flex items-center px-2 rounded-r-sm"
                      >
                        <i className="bi font-semibold  text-white text-[12px]  items-center  bi-x-lg flex "></i>
                      </button>
                    </div> */}
                  </div>
                </div>

                <div className="flex mt-2 rounded-sm w-full">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',

                      overflow: 'hidden',
                    }}
                    // className="flex w-[55%] l:w-60  pl-3 py-2 items-center rounded-l-sm"
                    className="flex w-[144px]  pl-3 py-2 items-center rounded-l-sm"
                  >
                    Family Mem. Name
                  </div>
                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px solid #b5b3b3',
                    }}
                    className="flex rounded-tr-sm rounded-br-sm grow items-center "
                  >
                    <input
                      value={
                        bookingCustomerDetails?.family_mem_name
                          ? bookingCustomerDetails.family_mem_name
                          : ''
                      }
                      ref={familyRef}
                      onChange={(t) => {
                        setBookingCustomerDetails({
                          ...bookingCustomerDetails,
                          family_mem_name: toProperCase(t.target.value),
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                          setRemarksFocus();
                        }
                      }}
                      maxLength={50}
                      type="text"
                      className="w-full ml-1 outline-0"
                    />
                  </div>
                </div>

                <div className="flex mt-2 w-full ">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',

                      overflow: 'hidden',
                    }}
                    className="flex w-[144px]  pl-3 py-2 items-center rounded-l-sm"
                  >
                    Remarks
                  </div>
                  <div
                    style={{
                      border: '1px dotted #b5b3b3',
                      borderLeft: '0px dotted #b5b3b3',
                      borderRight: '0px dotted #b5b3b3',
                    }}
                    className="flex rounded-tr-sm  grow rounded-br-sm  "
                  >
                    <input
                      value={
                        bookingCustomerDetails?.customer_remarks
                          ? bookingCustomerDetails.customer_remarks
                          : ''
                      }
                      style={{
                        outline: 'none',
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                          setEmailFocus();
                        }
                      }}
                      ref={remarksRef}
                      onChange={(t) => {
                        setBookingCustomerDetails({
                          ...bookingCustomerDetails,
                          customer_remarks: t.target.value,
                        });
                      }}
                      className="flex-1 ml-1"
                      maxLength={100}
                    />
                    <div className="flex items-center w-[100px] h-full">
                      <div
                        style={{
                          backgroundColor:
                            bookingCustomerDetails?.status === 'Good'
                              ? '#7ecd7f'
                              : bookingCustomerDetails?.status === 'Bad'
                              ? '#e7502c'
                              : bookingCustomerDetails?.status === 'Defaulter'
                              ? '#f0f05d'
                              : '#7ecd7f',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                        }}
                        className="flex pr-2 items-center overflow-hidden justify-end flex-1 h-full "
                        // className="flex pr-2 items-center overflow-hidden justify-end tab:w-20 d:w-36 h-full "
                      >
                        <FormControl
                          size="small"
                          variant="standard"
                          sx={{
                            border: '0px solid white',

                            '& .MuiInput-underline:before': {
                              borderBottom: 'none', // Hide the bottom line when not focused
                            },
                            '& .MuiInput-underline:hover:before': {
                              borderBottom: 'none', // Hide the bottom line when hovered
                            },
                            '& .MuiInput-underline:after': {
                              borderBottom: 'none', // Hide the bottom line when focused
                            },
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <select
                            value={
                              bookingCustomerDetails?.status
                                ? bookingCustomerDetails.status
                                : 'Good'
                            }
                            onChange={(e) => {
                              setBookingCustomerDetails((o) => {
                                return {
                                  ...o,
                                  status: e.target.value,
                                };
                              });
                            }}
                            style={{
                              paddingLeft: '12px',
                              fontSize: '13px',
                              color:
                                bookingCustomerDetails?.status === 'Good'
                                  ? 'black'
                                  : bookingCustomerDetails?.status === 'Bad'
                                  ? 'black'
                                  : 'black',

                              width: '100%',
                              borderTopRightRadius: '7px',
                              borderBottomRightRadius: '7px',
                              backgroundColor: 'transparent',
                              display: 'flex',
                              outline: '0px',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                            // onChange={handleChange}
                          >
                            {['Good', 'Bad', 'Defaulter'].map((i, key) => {
                              return (
                                <option
                                  key={key}
                                  onClick={() => {
                                    setBookingCustomerDetails((o) => {
                                      return {
                                        ...o,
                                        status: i,
                                      };
                                    });
                                  }}
                                  value={i}
                                >
                                  {i}
                                </option>
                              );
                            })}
                          </select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex  ml-3 ">
            <div className="w-full">
              <div className="flex  rounded-sm mb-2 ">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',

                    overflow: 'hidden',
                  }}
                  // className="flex w-[45%] l:w-40  pl-3 py-2 items-center rounded-l-sm"
                  className="flex w-[100px]  pl-3 py-2 items-center rounded-l-sm"
                >
                  Email
                </div>
                <div
                  style={{
                    border: bookingCustomerDetails?.email
                      ? !emailRegex.test(bookingCustomerDetails?.email)
                        ? '1px dotted red'
                        : '1px dotted #b5b3b3'
                      : '1px dotted #b5b3b3',
                    borderLeft: bookingCustomerDetails?.email
                      ? !emailRegex.test(bookingCustomerDetails?.email)
                        ? '0px dotted red'
                        : '0px dotted #b5b3b3'
                      : '0px dotted #b5b3b3',
                  }}
                  className="flex pl-2 rounded-tr-sm rounded-br-sm w-[250px] items-center "
                >
                  <input
                    value={
                      bookingCustomerDetails?.email
                        ? bookingCustomerDetails.email
                        : ''
                    }
                    ref={emailRef}
                    onChange={(t) => {
                      setBookingCustomerDetails({
                        ...bookingCustomerDetails,
                        email: t.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                        setAddressFocus();
                      }
                    }}
                    type="text"
                    className="w-full  outline-0"
                  />
                </div>
              </div>
              <div className="flex mt-2 rounded-sm ">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',

                    overflow: 'hidden',
                  }}
                  // className="flex w-[45%] l:w-40  pl-3 py-2 items-center rounded-l-sm"
                  className="flex w-[100px]  pl-3 py-2 items-center rounded-l-sm"
                >
                  Address
                </div>
                <div
                  style={{
                    border: '1px dotted #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="flex pl-2 rounded-tr-sm rounded-br-sm w-[250px] items-center"
                >
                  <input
                    ref={addressRef}
                    value={
                      bookingCustomerDetails?.address
                        ? bookingCustomerDetails.address
                        : ''
                    }
                    onChange={(t) => {
                      setBookingCustomerDetails({
                        ...bookingCustomerDetails,
                        address: toProperCase(t.target.value),
                      });
                    }}
                    type="text"
                    className="w-full outline-0"
                  />
                </div>
              </div>

              <div className="flex mt-2 rounded-sm ">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',

                    overflow: 'hidden',
                  }}
                  // className="flex w-[56%] l:w-40   pl-3 py-2 items-center rounded-l-sm"
                  className="flex w-[100px]  pl-3 py-2 items-center rounded-l-sm"
                >
                  Country
                </div>
                <div
                  style={{
                    border: '1px dotted #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="flex pl-2 rounded-tr-sm rounded-br-sm w-[250px] items-center "
                >
                  <select
                    style={
                      window.innerWidth <= 500
                        ? {
                            borderLeft: '0px',
                            width: '100%',
                            backgroundColor: 'transparent',
                            fontSize: '13px',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            borderLeft: '0px',
                            width: '56%',
                            backgroundColor: 'transparent',

                            fontSize: '13px',
                          }
                        : {
                            borderLeft: '0px',
                            width: '60%',
                            backgroundColor: 'transparent',

                            fontSize: '14px',
                          }
                    }
                    id="countryDropdown"
                    value={
                      bookingCustomerDetails?.country
                        ? bookingCustomerDetails.country
                        : ''
                    }
                    onChange={(t) => {
                      setBookingCustomerDetails({
                        ...bookingCustomerDetails,
                        country: t.target.value,
                      });
                    }}
                    className="   sm:leading-6 focus:outline-none rounded-none  focus:border-solid focus:bg-gray-100 focus:border-blue-300 "
                  >
                    <option>Select a Country</option>
                    {countries.map((e, index) => (
                      <option key={index} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex mt-2 rounded-sm ">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',

                    overflow: 'hidden',
                  }}
                  // className="flex w-[45%] l:w-40  pl-3 py-2 items-center rounded-l-sm"
                  className="flex w-[100px]  pl-3 py-2 items-center rounded-l-sm"
                >
                  State
                </div>
                <div
                  style={{
                    border: '1px dotted #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="flex pl-2 rounded-tr-sm rounded-br-sm w-[250px] items-center "
                >
                  <select
                    style={
                      window.innerWidth <= 500
                        ? {
                            borderLeft: '0px',
                            width: '60%',
                            backgroundColor: 'transparent',
                            fontSize: '13px',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            borderLeft: '0px',
                            width: '56%',
                            backgroundColor: 'transparent',

                            fontSize: '13px',
                          }
                        : {
                            borderLeft: '0px',
                            width: '60%',
                            backgroundColor: 'transparent',

                            fontSize: '14px',
                          }
                    }
                    id="countryDropdown"
                    value={bookingCustomerDetails.state}
                    onChange={(t) => {
                      setBookingCustomerDetails({
                        ...bookingCustomerDetails,
                        state: t.target.value,
                      });
                    }}
                    className="   sm:leading-6 focus:outline-none rounded-none  focus:border-solid focus:bg-gray-100 focus:border-blue-300 "
                  >
                    <option>Select a State</option>
                    {stateList.map((e, index) => (
                      <option key={index} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex mt-2 rounded-sm ">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',

                    overflow: 'hidden',
                  }}
                  // className="flex w-[45%] l:w-40  pl-3 py-2 items-center rounded-l-sm"
                  className="flex w-[100px] pl-3 py-2 items-center rounded-l-sm"
                >
                  City
                </div>
                <div
                  style={{
                    border: '1px dotted #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="flex pl-2 rounded-tr-sm rounded-br-sm w-[250px] items-center "
                >
                  <select
                    style={
                      window.innerWidth <= 500
                        ? {
                            width: '60%',
                            backgroundColor: 'transparent',
                            fontSize: '13px',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            width: '56%',
                            backgroundColor: 'transparent',
                            fontSize: '13px',
                          }
                        : {
                            width: '60%',
                            backgroundColor: 'transparent',
                            fontSize: '14px',
                          }
                    }
                    className="outline-none"
                    id="countryDropdown"
                    value={bookingCustomerDetails.city}
                    onChange={(t) => {
                      setBookingCustomerDetails({
                        ...bookingCustomerDetails,
                        city: t.target.value,
                      });
                    }}
                  >
                    <option>Select a City</option>
                    {cityList.map((e, index) => (
                      <option key={index} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          border: '1px dotted #9e9e9e',
        }}
        className=" w-full flex flex-col min-h-[35%] h-full  rounded-sm mt-3  "
      >
        <div className="w-full h-full  p-4  ">
          {!loading ? (
            todayData[0] ? (
              <div className="w-full h-full  overflow-y-scroll  pr-3">
                {[...uYears].map((year, index) => {
                  const data = history.filter(
                    (item) =>
                      item.YEAR === year &&
                      moment(item.DATE).format('YYYY-MM-DD') !==
                        moment().format('YYYY-MM-DD')
                  );
                  const uniqu_month = [
                    ...new Set(
                      data.map((item) => moment(item.DATE).format('MMMM'))
                    ),
                  ];
                  // const sortedMonths = uniqu_month.sort((a, b) => {
                  //   return (
                  //     monthOrder[a.toLowerCase()] -
                  //     monthOrder[b.toLowerCase()]
                  //   );
                  // });

                  return (
                    <div key={index}>
                      <div
                        style={{
                          border: '1px solid gray',
                        }}
                        className={`w-fit p-1 mb-2 font-semibold  px-6   ${
                          year === moment().format('YYYY-MM-DD')
                            ? 'bg-[#634258] text-white'
                            : 'text-gray-500 '
                        } rounded-br-3xl `}
                      >
                        {year === moment().format('YYYY-MM-DD') ? (
                          <div className=" w-full h-full pr-3">
                            {' '}
                            Today Bookings{' '}
                          </div>
                        ) : (
                          <div className="flex">
                            {' '}
                            <div> {year} </div>
                            <div
                              style={{
                                fontWeight: 700,
                              }}
                              className="font-bold text-gray-900 pl-2 pr-4"
                            >
                              {' '}
                              {uniqu_month[0]}
                            </div>{' '}
                          </div>
                        )}
                      </div>
                      {year === moment().format('YYYY-MM-DD') ? (
                        <div
                          className="grid gap-[12px] grid-cols-1  sm:grid-cols-2 t:grid-cols-3 l:grid-cols-4
                          d:grid-cols-5 ll:grid-cols-5 pr-2 overflow-hidden  mb-3"
                        >
                          {todayData.map((today, key) => {
                            return (
                              <HistoryBox
                                key={key}
                                dispatch={dispatch}
                                today={today}
                                loadCustomerOrderAPI={loadCustomerOrderAPI}
                                ModifiedBookingId={ModifiedBookingId}
                              />
                            );
                          })}
                        </div>
                      ) : null}

                      {/* <div className="grid gap-[12px]  grid-cols-4  d:grid-cols-6 ll:grid-cols- pr-2 overflow-hidden  "> */}

                      {uniqu_month.map((m, key) => {
                        const monthData = data.filter((item) => {
                          return item.MONTH_NAME === m;
                        });

                        return (
                          <div key={key}>
                            {m !== uniqu_month[0] ? (
                              <div
                                style={{
                                  border: '1px solid gray',
                                }}
                                className="w-fit p-1 mb-2 font-semibold  px-6   rounded-br-3xl text-gray-500"
                              >
                                <div className="flex font-semibold">
                                  {year}
                                  <div
                                    style={{
                                      fontWeight: 700,
                                    }}
                                    className="font-bold text-gray-900 pl-2 pr-4"
                                  >
                                    {' '}
                                    {m}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div
                              className="grid gap-[12px] grid-cols-1  sm:grid-cols-2 t:grid-cols-3 l:grid-cols-4
                          d:grid-cols-5 ll:grid-cols-5 pr-2 overflow-hidden  mb-3"
                            >
                              {monthData.map((item, k) => (
                                <HistoryBox
                                  key={k}
                                  dispatch={dispatch}
                                  today={item}
                                  loadCustomerOrderAPI={loadCustomerOrderAPI}
                                  ModifiedBookingId={ModifiedBookingId}
                                />
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : history[0] ? (
              <div className="w-full h-full  overflow-y-scroll  pr-3">
                {uYears.map((year, index) => {
                  const data = history.filter(
                    (item) =>
                      item.YEAR === year &&
                      moment(item.DATE).format('YYYY-MM-DD') !==
                        moment().format('YYYY-MM-DD')
                  );
                  const uniqu_month = [
                    ...new Set(
                      data.map((item) => moment(item.DATE).format('MMMM'))
                    ),
                  ];

                  return (
                    <div key={index}>
                      <div
                        style={{
                          border: '1px solid gray',
                        }}
                        className="w-[150px] p-1 mb-2 font-semibold  px-6   rounded-br-3xl text-gray-500"
                      >
                        {year + '-' + uniqu_month[0]}
                        <button
                          onClick={() => {
                            setOpenYear((e) =>
                              Number(e) === Number(year) ? '' : Number(year)
                            );
                          }}
                        >
                          <div className="ml-2">
                            <i
                              className={`bi text-orange  bi-chevron-${
                                Number(year) === Number(openYear)
                                  ? 'up'
                                  : 'down'
                              }`}
                            ></i>
                          </div>
                        </button>
                      </div>
                      <Collapse
                        in={openYear && Number(openYear) === year}
                        timeout={'auto'}
                        unmountOnExit
                      >
                        {uniqu_month.map((m, key) => {
                          const monthData = data.filter(
                            (item) => item.MONTH_NAME === m
                          );

                          return (
                            <div key={key}>
                              {m !== uniqu_month[0] ? (
                                <div className="flex">
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                    }}
                                    className="w-[150px] p-1 mb-2 font-semibold flex  px-6  rounded-br-3xl text-gray-500"
                                  >
                                    {year + ' ' + m}
                                  </div>
                                </div>
                              ) : null}{' '}
                              <div
                                className="grid gap-[12px] grid-cols-1  sm:grid-cols-2 t:grid-cols-3 l:grid-cols-4
                          d:grid-cols-5 ll:grid-cols-5 pr-2 overflow-hidden  mb-3"
                              >
                                {monthData.map((item, k) => (
                                  <HistoryBox
                                    key={k}
                                    dispatch={dispatch}
                                    today={item}
                                    loadCustomerOrderAPI={loadCustomerOrderAPI}
                                    ModifiedBookingId={ModifiedBookingId}
                                  />
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full   flex flex-col justify-center items-center h-full ">
                <div className="w-1/12 ll:w-2/12 flex justify-center mb-3">
                  <i className="bi bi-search text-[25px] text-gray-300"></i>
                </div>
                <div className="w-fit text-gray-400">
                  Please select Mobile Number to get history data
                </div>
              </div>
            )
          ) : (
            <SkeletonLoader />
          )}
        </div>
      </div>

      <MrcHelp
        status={mrcStatus}
        onCancel={() => {
          setMrcStatus(false);
        }}
        onRowClick={(p) => {
          setMrcNumber('');

          undoAction().then(() => {
            dispatch(setSpinnerLoading('Loading'));

            setMrcStatus(false);

            dispatch(setCustomerPrefix(p.row.PREFIX));
            isMrcOrderAvailval(
              p.row.MRC_NO,
              p.row.PREFIX,
              p.row.CUSTOMER_NAME,
              p.row.MOBILE
            );
            setMrcNumber(p.row.MRC_NO);
          });
        }}
      />

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />

      <CustomerMasterhelp
        onRowClick={(p) => {
          undoAction(false, false, true).then(() => {
            dispatch(setCustomerMasterHelpModStatus(false));

            setMrcNumber(p.row.mrc_no);

            setBookingCustomerDetails(p.row);
            dispatch(setCustomerPrefix(p.row.prefix));

            const mobile = p.row.mobile;
            const b = `seam/images/${prefix + mobile}`;
            DownloadFile(b)
              .then((e) => {
                if (!e.includes('FirebaseError')) {
                  if (typeof setSelectedFile === 'function') {
                    setSelectedFile('');
                  }
                  if (typeof setCapturedImage === 'function') {
                    setCapturedImage('');
                  }
                  setpath(b);
                  dispatch(setCustomerFirebasePic(e));
                } else {
                  dispatch(setCustomerFirebasePic(''));
                  if (typeof setSelectedFile === 'function') {
                    setSelectedFile('');
                  }
                  if (typeof setCapturedImage === 'function') {
                    setCapturedImage('');
                  }
                }
              })
              .catch(() => {
                console.log('clear customer catch');
                dispatch(setCustomerFirebasePic(''));
                if (typeof setSelectedFile === 'function') {
                  setSelectedFile('');
                }
                if (typeof setCapturedImage === 'function') {
                  setCapturedImage('');
                }
              });

            getCustomerHistory('', p.row.mobile, p.row.customer_name);
          });
        }}
      />
    </div>
  );
};

export default CustomerInfo;

const SkeletonLoader = () => {
  return (
    <div className="w-full h-full overflow-y-hidden">
      {[...Array(5)].map((_, index) => (
        <div key={index} mb={2} className="mb-4">
          <Skeleton
            variant="rectangular"
            height={30}
            width="140px"
            sx={{
              borderBottomRightRadius: '24px',
            }}
          />
          <div className="grid max-sm:grid-cols-1 tab:grid-cols-2 t:grid-cols-3 d:grid-cols-5 gap-2 mt-2">
            {[...Array(Math.floor(Math.random() * 3) + 1)].map(() => {
              return (
                <div>
                  <Skeleton
                    sx={{
                      borderRadius: '10px',
                    }}
                    variant="rectangular"
                    height={50}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const HistoryBox = ({
  key,
  dispatch,
  today,
  loadCustomerOrderAPI,
  ModifiedBookingId,
}) => {
  const dep = today.STAGE_NAME ? today.STAGE_NAME?.toLowerCase() : '';
  const typeOfStage = dep
    ? dep.includes('wa')
      ? washing
      : dep.includes('cu')
      ? cutting
      : dep.includes('emb')
      ? embroidery
      : dep.includes('st')
      ? sttiching
      : dep.includes('bu')
      ? buttonstype
      : dep.includes('pr')
      ? press
      : dep.includes('re')
      ? ready
      : dep.includes('del')
      ? delivery
      : sttiching
    : '';
  return (
    <Button
      key={key}
      variant={'contained'}
      style={{
        textTransform: 'none',
        backgroundColor: 'transparent',
        padding: '0px',
        marginBottom: '8px',
        boxShadow: 'none',
      }}
      onClick={() => {
        dispatch(setModifyingBookingId(today.BOOKING_ORDER_NO));

        loadCustomerOrderAPI(today.BOOKING_ORDER_NO);
      }}
      className=" "
    >
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="w-full "
      >
        <div>
          <div>
            <div
              style={{
                boxShadow: '1px 1px 1px gray',
                borderBottomLeftRadius: today.INVNO ? '4px' : '0px',
                borderBottomRightRadius: today.INVNO ? '4px' : '0px',
                backgroundColor:
                  today.UPPER_LOWER === 'UPPER'
                    ? today.BOOKING_ORDER_NO === ModifiedBookingId
                      ? '#f7e2c8'
                      : '#e8e2db'
                    : today.BOOKING_ORDER_NO === ModifiedBookingId
                    ? '#f5d1c4'
                    : '#f8e4dd',
              }}
              className=" w-full pt-[8px] pl-[8px] pr-[8px] p rounded-t-sm"
            >
              <div className="flex justify-between">
                <div className=" flex">
                  <div className="font-semibold  flex text-gray-800">
                    {today.ITEM_NAME} {' - '}
                    {today.QNTY}
                    <div className="ml-1">#{today.BOOKING_ORDER_NO}</div>
                  </div>
                </div>
                <div className="flex">
                  <img
                    alt=""
                    src={require(`../utility/images/${
                      today.UPPER_LOWER === 'UPPER' ? '2' : '3'
                    }.png`)}
                    style={{
                      width: '22px',
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingBottom: '2px',
                }}
                className="text-sm text-gray-600 flex justify-start  "
              >
                {moment(today.DATE).format('ddd - DD MMM')}{' '}
                {today.FAMILY_MEM_NAME}
              </div>{' '}
              {/* <div className="text-gray-600 text-start">{today.MOBILE}</div> */}
            </div>

            <div
              style={{
                boxShadow: '1px 1px 1px gray',
                borderTopLeftRadius: today.INVNO ? '4px' : '0px',
                borderTopRightRadius: today.INVNO ? '4px' : '0px',
                borderTop: today.INVNO
                  ? '1px dotted gray'
                  : today.UPPER_LOWER === 'UPPER'
                  ? '1px dotted #e8e2db'
                  : '1px dotted #f8e4dd',
                backgroundColor:
                  today.UPPER_LOWER === 'UPPER'
                    ? today.BOOKING_ORDER_NO === ModifiedBookingId
                      ? '#f7e2c8'
                      : '#e8e2db'
                    : today.BOOKING_ORDER_NO === ModifiedBookingId
                    ? '#f5d1c4'
                    : '#f8e4dd',
              }}
              className="rounded-b-sm h-[75px]"
            >
              {' '}
              <div>
                <div>
                  <div className="text-[11px] text-gray-600  flex items-center  mt-[1px] px-2 ">
                    {today.INVNO ? (
                      <div className="">Bill No.:{today.INVNO}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center px-2">
                  <div className="text-gray-600 text-sm italic pr-2">
                    <i className="bi bi-scissors "></i>
                    {today.CUTTING_DATE
                      ? moment(today.CUTTING_DATE).format(' ddd - DD MMM')
                      : ''}
                  </div>
                </div>
                <div className="flex justify-between w-full  items-center px-2">
                  <div className="text-gray-500 text-sm   flex">
                    <div className="w-[70px] text-start">Del. Date</div>:{' '}
                    <div className="ml-1">
                      {moment(today.DEL_DATE).format(' ddd - DD MMM')}
                    </div>
                  </div>
                  <div
                    className={`w-[18px] h-[18px] ${
                      today.DELIVERED_YN === 'YES' ? 'bg-green' : 'bg-red'
                    } flex justify-center items-center  rounded-sm`}
                  >
                    {today.DELIVERED_YN === 'YES' ? (
                      <i className="bi bi-check2 justify-center flex items-center text-white"></i>
                    ) : (
                      <i className="bi bi-x justify-center flex items-center text-white"></i>
                    )}
                  </div>
                </div>
                {/* <div className="flex  items-center px-2">
                  <div className="text-gray-500 text-sm pr-2 flex">
                    <div className="w-[70px]  text-start ">On Stage </div>:
                    <div className="ml-1 ">{today.STAGE_NAME}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Button>
  );
};
