import { useLocation, useNavigate } from 'react-router-dom';
import '../src/App.css';
import React, { useEffect, useState } from 'react';
import AlertModalTwoButton from './utility/component/modals/alertWithTwoButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFullScreen,
  selectUserType,
  setFullScreen,
  setWindowWidth,
} from './utility/Redux/profile';
import { setAlertWithTitle } from './utility/Redux/modal';
import AlertModal from './utility/component/modals/alert';
import Lottie from 'lottie-react';
import geniusLogo from './utility/json/Genius-logo-v1.json';
import AppRoutes from './route';
import '../src/utility/component/functions/firebase';
function App() {
  const tkn = localStorage.getItem('tkn');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();
  const [screenMode, setScreenMode] = useState(window.screen.orientation.type);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();
  const checkUserToken = () => {
    const userToken = localStorage.getItem('user');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };

  const isFullscreen = useSelector(selectFullScreen);

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (window.innerWidth >= 768) {
      if (userAgent.match(/android|webos|ipad|ablet|kindle|playbook|silk/i)) {
        if (screenMode.includes('portrait')) {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'FullScreen',
            msg: 'To use this app with best experience allow to full screen',
            button1: 'ok',

            button1Click: () => {
              const element = document.documentElement;
              if (element.requestFullscreen) {
                element.requestFullscreen();
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    window.screen.orientation.lock('landscape').catch((err) => {
                      console.error('Error locking screen orientation:', err);
                    });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    window.screen.orientation.lock('landscape').catch((err) => {
                      console.error('Error locking screen orientation:', err);
                    });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    window.screen.orientation.lock('landscape').catch((err) => {
                      console.error('Error locking screen orientation:', err);
                    });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              }
            },
          });
        }
      }
    }
  }, [screenMode]);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('orientationchange', function (event) {
        // Handle the screen orientation change here

        if (window.screen.orientation.type.includes('portrait')) {
          dispatch(setFullScreen(!isFullscreen));
          setScreenMode(window.screen.orientation.type);
        }
      });
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, 3500); // Adjust the time as needed
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const url = location.pathname;
    if (url === '/booking' && window.innerWidth <= 500) {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Not supported mobile.',
        })
      );
      navigate('/');
    }
  }, [location]);

  useEffect(() => {
    const handleOrientationChange = () => {
      navigate('/');
    };

    // Add event listener for orientation change when the component mounts
    window.addEventListener('orientationchange', handleOrientationChange);

    // Remove the event listener when the component unmounts to prevent memory leaks
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div>
      {showModal && !tkn ? (
        <div
          className="flex flex-col bg-white justify-center items-center text-lg w-full"
          style={
            window.innerWidth <= 500
              ? { padding: '200px 0px' }
              : window.innerWidth <= 1300
              ? { padding: '130px 0px' }
              : { height: '100vh' }
          }
        >
          <Lottie
            animationData={geniusLogo}
            loop={true}
            style={
              window.innerWidth <= 500
                ? {
                    height: 'auto',
                    width: '100%',
                    maxWidth: '100%', // Set a maximum width for the animation if needed
                  }
                : {
                    height: 'auto',
                    width: '100%',
                    maxWidth: '700px', // Set a maximum width for the animation if needed
                  }
            }
          />
        </div>
      ) : (
        <AppRoutes userType={userType} />
      )}

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <AlertModal />
    </div>
  );
}

export default App;
