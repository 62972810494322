import React, { useState } from 'react';
import Dashboard1 from './dashboard1';
import Dashboard2 from './dashboard2';
import OrderProductionHistory from './orderStatus';
import QRCodeScanner from '../employeee/scanCode';
import { useSelector } from 'react-redux';
import { selectWidth } from '../utility/Redux/profile';
const Dashboard = () => {
  const [dashboardType, setDashboardType] = useState(1);
  const windowWidth = useSelector(selectWidth);
  return (
    <div className=" w-full text-sm h-full">
      <div
        style={{
          width: '100%',
        }}
        className="  bg-white p-2 rounded-sm flex "
      >
        <div
          onClick={() => {
            setDashboardType(1);
          }}
          style={{
            color: dashboardType === 1 ? 'white' : 'gray',
          }}
          className={`flex-1 p-1 py-2 ${
            dashboardType === 1
              ? 'bg-[#0d0d0d] '
              : ' hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer '
          }  flex justify-center items-center rounded-l-sm `}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: dashboardType === 1 ? 600 : 400,
            }}
            className=" h-full flex items-center"
          >
            Dashboard
          </div>
        </div>
        <div
          style={{
            color: dashboardType === 2 ? 'white' : 'gray',
          }}
          onClick={() => {
            setDashboardType(2);
          }}
          className={`flex-1 p-1 py-2 ${
            dashboardType === 2
              ? 'bg-[#0d0d0d] '
              : ' hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer '
          }  flex justify-center items-center  `}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: dashboardType === 2 ? 600 : 400,
            }}
            className="  flex items-center"
          >
            {window.innerWidth > 380 ? 'Production Status' : 'Production'}
          </div>
        </div>

        <div
          style={{
            color: dashboardType === 3 ? 'white' : 'gray',
          }}
          onClick={() => {
            setDashboardType(3);
          }}
          className={`flex-1 p-1 py-2 ${
            dashboardType === 3
              ? 'bg-[#0d0d0d] '
              : ' hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer '
          }  flex justify-center items-center rounded-r-sm `}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: dashboardType === 2 ? 600 : 400,
            }}
            className="  flex items-center"
          >
            Order Status
          </div>
        </div>
      </div>
      {dashboardType === 1 ? (
        <Dashboard1 />
      ) : dashboardType === 2 ? (
        <Dashboard2 />
      ) : dashboardType === 3 ? (
        <OrderProductionHistory setDashboardType={setDashboardType} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Dashboard;
