import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  SelectHsnCodeHelpStatus,
  setHsnCodeHelpStatus,
  setSessionExpired,
} from '../utility/Redux/modal';
import { selectToken } from '../utility/Redux/security';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import axios from 'axios';
import MasterHelp from '../utility/component/modals/masterHelp';

const HsnCodeHelp = ({ onRowClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const dispatch = useDispatch();
  const status = useSelector(SelectHsnCodeHelpStatus);
  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'HSN_CODE',
      renderHeader: (params) => <div className="text-md">HSN Code</div>,
      width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [loading, setloading] = useState(true);

  const masterprefix = useSelector(selectTableMasterPrefix);

  const getData = async () => {
    // try {
    await axios
      .post(
        '/api/masters/listHSNCode',
        {
          table_prefix_master: masterprefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
        setFullList(p.data.response);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const handleClose = () => {
    dispatch(setHsnCodeHelpStatus(false));
  };

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            handleClose();
            setRows([]);
          }}
          title={'HSN Code | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.CODE}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default HsnCodeHelp;
