import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import securityReducer from './security';
import modal from './modal';
import refresh from './refresh';
import profile from './profile';
import booking from './booking';
import billing from './billing';

const sagaMiddleware = createSagaMiddleware();
const Store = configureStore({
  reducer: {
    security: securityReducer,
    modals: modal,
    refresh: refresh,
    profile: profile,
    booking: booking,
    billing: billing,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

export default Store;
