import { createSlice } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
const secretPass = 'kasdaksjfdsaask';

const token = localStorage.getItem('tkn');

const getToken = () => {
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, secretPass);

    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const tk = bytes.toString(CryptoJS.enc.Utf8);
      const tkn = JSON.parse(tk);

      return tkn;
    }
  }

  return null;
};

export const Security = createSlice({
  name: 'security',
  initialState: {
    islogin: false,
    token: getToken(),
    systemAdmin: false,
  },
  reducers: {
    setLogin(state, action) {
      state.islogin = action.payload;
    },

    setSystemAdminModalStatus(state, action) {
      state.systemAdmin = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;

      const data = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload),
        secretPass
      ).toString();
      localStorage.setItem('tkn', data);
    },
  },
});

export default Security.reducer;

export const { setLogin, setToken, setSystemAdminModalStatus } =
  Security.actions;

export const selectLogin = (state) => state.security.islogin;
export const selectToken = (state) => state.security.token;
export const selectSystemAdminModal = (state) => state.security.systemAdmin;
// export const selectheader = (state) => state.security.headerAuth;
