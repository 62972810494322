import React, { useEffect, useRef, useState } from 'react';
import { useFocus } from '../utility/hooks/hooks';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import GeniustextInput from '../utility/component/mastertext';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { toProperCase } from '../utility/component/format';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';

const GeniusMeasurementMaster = () => {
  const [upperRows, setUpperRows] = useState([]);
  const [lowerRows, setLowerRows] = useState([]);

  const [parameter, setParameter] = useState('');
  const [upperLower, setUpperLower] = useState('');
  const [category, setCatagory] = useState('');

  const [id, setId] = useState('');

  const [parameterRef, setParameterError] = useFocus();

  const columnsUpper = [
    {
      field: 'PARAMETER',
      type: 'string',
      renderHeader: (params) => (
        <div className="max-sm:text-sm">Upper Parameters</div>
      ),
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        // const runningCaseValue = params.value.toUpperCase();
        return <div>{params.value}</div>;
      },
    },
  ];

  const columnsLower = [
    {
      field: 'PARAMETER',
      type: 'string',
      renderHeader: (params) => (
        <div className="max-sm:text-sm">Lower Parameters</div>
      ),
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        // const runningCaseValue = params.value.toUpperCase();
        return <div>{params.value}</div>;
      },
    },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [regional, setRegional] = useState('');

  const transliterateText = async (text) => {
    const response = await fetch(
      `https://inputtools.google.com/request?text=${text}&itc=${
        selectedLanguage === 'en'
          ? 'en-t-i0-und'
          : selectedLanguage === 'hi'
          ? 'hi-t-i0-und'
          : selectedLanguage === 'pa'
          ? 'pa-t-i0-und'
          : selectedLanguage === 'es'
          ? 'es-t-i0-und'
          : selectedLanguage === 'ru'
          ? 'ru-t-i0-und'
          : selectedLanguage === 'fr'
          ? 'fr-t-i0-und'
          : selectedLanguage === 'de'
          ? 'de-t-i0-und'
          : selectedLanguage === 'pt'
          ? 'pt-t-i0-und'
          : 'roboto-font'
      }&num=1&cp=0&cs=1&ie=utf-8&oe=utf-8&app=jsapi&uv`
    );
    const data = await response.json();
    if (data && data[1] && data[1][0] && data[1][0][1] && data[1][0][1][0]) {
      const t = data[1][0][1][0];
      setRegional(t);
    }
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const detectLanguage = (regional) => {
    const en = /^[A-Za-z]+$/;
    const hi = /^[\u0900-\u097F]+$/;
    const pa = /^[\u0A00-\u0A7F]+$/;
    const de = /^[A-Za-zäÄöÖüÜß]+$/;
    const es = /^[\u00C0-\u02AF\u1E00-\u1EFF]+$/;
    const fr = /^[A-Za-zÀàÂâÇçÉéÈèÊêËëÎîÏïÔôÙùÛûŸÿ]+$/;
    const ru = /^[А-Яа-яЁё]+$/;
    const pt = /^[A-Za-zÀàÁáÂâÃãÉéÊêÍíÓóÔôÚúÇç]+$/;

    if (en.test(regional)) {
      return 'en';
    } else if (hi.test(regional)) {
      return 'hi';
    } else if (pa.test(regional)) {
      return 'pa';
    } else if (de.test(regional)) {
      return 'de';
    } else if (es.test(regional)) {
      return 'es';
    } else if (fr.test(regional)) {
      return 'fr';
    } else if (ru.test(regional)) {
      return 'ru';
    } else if (pt.test(regional)) {
      return 'pt';
    } else {
      return 'Unknown';
    }
  };

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const getData = async () => {
    await axios
      .post(
        '/api/masters/listMeasurementMaster',
        {
          table_prefix_master: masterprefix,
          UPPER_LOWER: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((p) => {
        const lowerData = p.data.response.filter(
          (item) => item.UPPER_LOWER === 'LOWER'
        );

        if (lowerData.length > 0) {
          setLowerRows(lowerData);
        }
        const upperData = p.data.response.filter(
          (item) => item.UPPER_LOWER === 'UPPER'
        );
        setUpperRows(upperData);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const masterprefix = useSelector(selectTableMasterPrefix);

  const saveMeasurementMasterApi = () => {
    if (upperLower && parameter) {
      const isDupParameter =
        upperLower === 'UPPER'
          ? upperRows.some((row) => row.PARAMETER === toProperCase(parameter))
          : lowerRows.some((row) => row.PARAMETER === toProperCase(parameter));

      if (isDupParameter) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Parameter is already exists. Please choose a different Parameter.',
          })
        );
        return;
      }

      const data = {
        table_prefix_master: masterprefix,
        CODE: id ? id : '',
        PARAMETER: toProperCase(parameter),
        CATEGORY: category ? category : '',
        UPPER_LOWER: upperLower,
        STOP_YN: 'NO',
        REGIONAL_INAME: regional ? regional : '',
      };
      dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/masters/saveMeasurementMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            if (id) {
              dispatch(
                setAlertWithTitle({
                  title: 'Success.',
                  msg: 'Data Updated successfully.',
                  lottie: 'success',
                })
              );
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Success.',
                  msg: 'Data Saved successfully.',
                  lottie: 'success',
                })
              );
            }
            clearState();
            getData();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!parameter) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Fill Parameter.',
            lottie: 'reject',
          })
        );
        setParameterError();
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Select Upper/Lower.',
            lottie: 'reject',
          })
        );
      }
    }
  };

  const clearState = () => {
    setParameter('');
    setId('');
    setRegional('');
  };

  const windowWidth = useSelector(selectWidth);

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 15) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            inputFields[nextIndex].focus(); // Set focus on the next field
          }
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.altKey) && e.key === 's') {
        e.preventDefault();
        saveMeasurementMasterApi();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [parameter, upperLower, regional, category]);

  return (
    <div className="flex-grow  bg-white border-brown px-3" ref={formRef}>
      <div className=" flex mt-4">
        <GeniustextInput
          title={' Parameter'}
          type={'text'}
          width={'300px'}
          value={parameter}
          onChange={(e) => setParameter(toProperCase(e.target.value))}
          refrance={parameterRef}
          autofocus={true}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Upper/Lower'}
            option={[
              {
                name: 'Select Option',
                value: '',
              },
              {
                name: 'Upper',
                value: 'UPPER',
              },
              {
                name: 'Lower',
                value: 'LOWER',
              },
            ]}
            width={'300px'}
            value={upperLower}
            onChange={(e) => {
              setUpperLower(e.target.value);
            }}
          />
        </div>
      </div>

      <div className=" flex">
        <GeniustextInput
          title={'Regional Language'}
          option={[
            {
              name: 'Select Language',
              value: '',
            },
            {
              name: 'English',
              value: 'en',
            },
            {
              name: 'ਪੰਜਾਬੀ',
              value: 'pa',
            },
            {
              name: 'हिंदी',
              value: 'hi',
            },
            {
              name: 'Spanish',
              value: 'es',
            },
            {
              name: 'Russian',
              value: 'ru',
            },
            {
              name: 'French',
              value: 'fr',
            },
            {
              name: 'German',
              value: 'de',
            },
            {
              name: 'Portuguese',
              value: 'pt',
            },
          ]}
          value={selectedLanguage}
          onChange={handleLanguageChange}
          width={'300px'}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Regional Item Name'}
            type={'text'}
            width={'300px'}
            onChange={(e) => {
              transliterateText(e.target.value);
              setRegional(toProperCase(e.target.value));
            }}
            value={regional}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Category'}
          option={[
            {
              name: 'Select Category',
              value: '',
            },
            {
              name: 'Male',
              value: 'MALE',
            },
            {
              name: 'Female',
              value: 'FEMALE',
            },
          ]}
          width={'300px'}
          value={category}
          onChange={(e) => setCatagory(e.target.value)}
        />
      </div>

      <div className="w-full  h-[320px] mt-1 flex">
        <div className="flex-1">
          <GeniusDatagrid
            rowID={(t) => t.CODE}
            onRowClick={(p) => {
              setId(p.row.CODE);
              setParameter(p.row.PARAMETER);
              setUpperLower(p.row.UPPER_LOWER);
              setCatagory(p.row.CATEGORY);
              setRegional(p.row.REGIONAL_INAME);
              const lang = detectLanguage(p.row.REGIONAL_INAME);
              setSelectedLanguage(lang);
            }}
            columns={columnsUpper}
            list={upperRows}
          />
        </div>
        <div className="ml-2 flex-1">
          <GeniusDatagrid
            rowID={(t) => t.CODE}
            onRowClick={(p) => {
              setId(p.row.CODE);
              setParameter(p.row.PARAMETER);
              setUpperLower(p.row.UPPER_LOWER);
              setCatagory(p.row.CATEGORY);
              setRegional(p.row.REGIONAL_INAME);
              const l = detectLanguage(p.row.REGIONAL_INAME);
              setSelectedLanguage(l);
            }}
            columns={columnsLower}
            list={lowerRows}
          />
        </div>
      </div>

      <div className="flex w-full justify-end max-sm:justify-between items-center py-3">
        {id ? (
          <ModifyButton onClick={saveMeasurementMasterApi} />
        ) : (
          <SaveButton onClick={saveMeasurementMasterApi} />
        )}
        <UndoButton onClick={clearState} />
      </div>
    </div>
  );
};

export default GeniusMeasurementMaster;
