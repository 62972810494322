import React from 'react';
import { useTable } from 'react-table';

const BillSummaryTable = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <table
          style={{
            width: '100%',
            height: '500px',
          }}
          {...getTableProps()}
        >
          <thead style={{ height: '45px', width: '100%' }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    style={
                      columns.width
                        ? {
                            width: columns.width,
                            border: '1px solid black',
                            fontSize: '10px',
                            textAlign: 'center',
                          }
                        : {
                            flex: 1,
                            border: '1px solid black',
                          }
                    }
                    {...column.getHeaderProps()}
                  >
                    {column.renderHeader()}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            style={{ width: '100%', overflow: 'hidden', overflowY: 'Scroll' }}
            {...getTableBodyProps()}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  style={{
                    height: '30px',
                    width: '100%',
                    pageBreakInside: 'avoid',
                    pageBreakAfter: 'auto',
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    const cellValue = cell.render('Cell');

                    const isNumericValues =
                      cell.column.id === 'GTOTAL' ||
                      cell.column.id === 'ADVANCE_AMT' ||
                      cell.column.id === 'TOTAL' ||
                      cell.column.id === 'BALANCE' ||
                      cell.column.id === 'CREDIT' ||
                      cell.column.id === 'DEBIT';

                    const isPrice =
                      cell.column.id === 'GTOTAL' ||
                      cell.column.id === 'ADVANCE_AMT' ||
                      cell.column.id === 'TOTAL';

                    const isDate = cell.column.id === 'DATE';

                    if (isDate) {
                      const formattedDate = new Date(
                        cell.value
                      ).toLocaleDateString();
                    }

                    const cellContent =
                      cellValue.props.value === 0 ? '' : cellValue;

                    const rowWidth = cell.column.width;

                    const cellStyle = {
                      border: '1px dotted black',
                      fontSize: '12px',
                      textAlign: isNumericValues ? 'right' : 'left',
                      width: rowWidth,
                    };

                    return (
                      <td
                        className="pl-2 pr-3"
                        style={cellStyle}
                        {...cell.getCellProps()}
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BillSummaryTable;
