import React from 'react';
import {
  AppBar,
  Drawer,
  DrawerHeader,
} from '../../../utility/component/compHome';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Close,
  Logout,
  QuestionMark,
  SupervisedUserCircleRounded,
  VerifiedUserRounded,
} from '@mui/icons-material';
import {
  selectFullScreen,
  selectIsProductionModuleAllwed,
  selectUserName,
  selectUserType,
  setFullScreen,
} from '../../Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomeTwoButtomAlert,
  setCustomerMasterModStatus,
} from '../../../utility/Redux/modal';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TalringLogo from './talringLogo';
import { Person } from 'react-bootstrap-icons';

const buttonClass =
  'bg-primary dark:bg-primary-foreground text-primary-foreground dark:text-primary hover:bg-primary/80 dark:hover:bg-primary/70 px-4 py-2 rounded-lg';
const cardClass =
  'bg-card dark:bg-card-foreground rounded-lg shadow-lg p-8 w-full max-w-md';
const optionsClass =
  ' absolute top-14 right-4 bg-card bg-white rounded-[10px] shadow-lg px-3 py-2';

const AppBarDesign = ({ email }) => {
  const [MobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const [selectedMenu, setSelectedMenu] = React.useState('Dashboard');
  const dispatch = useDispatch();
  const isFullscreen = useSelector(selectFullScreen);
  const [open, setOpen] = React.useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const userName = useSelector(selectUserName);

  const requestFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
      dispatch(setFullScreen(true));
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      dispatch(setFullScreen(true));
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      dispatch(setFullScreen(true));
    }
    // Handle other browser-specific methods if needed
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      dispatch(setFullScreen(false));
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
      dispatch(setFullScreen(false));
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      dispatch(setFullScreen(false));
    }
    // Handle other browser-specific methods if needed
  };
  const isProductionAllowed = useSelector(selectIsProductionModuleAllwed);
  const menuDrawer =
    window.innerWidth <= 500
      ? userType === 'MANAGER'
        ? ['Masters']
        : userType === 'SUPERVISOR'
        ? ['Dashboard', 'Test Production']
        : ['Dashboard', 'Reports']
      : userType === 'MANAGER'
      ? ['Dashboard', 'Masters']
      : userType === 'ADMIN'
      ? ['Dashboard', 'Company Master', 'User Master', 'User List', 'Setting']
      : userType === 'USER'
      ? window.innerWidth <= 800
        ? isProductionAllowed
          ? [
              'Dashboard',
              'Masters',
              'Reports',
              'Customer Ledger',
              'Updation',
              'Order Status',
            ]
          : ['Dashboard', 'Masters', 'Reports', 'Customer Ledger', 'Updation']
        : isProductionAllowed
        ? [
            'Dashboard',
            'Booking',
            'Masters',
            'Reports',

            'Customer Ledger',
            'Updation',
            'Order Status',
          ]
        : [
            'Dashboard',
            'Booking',
            'Masters',
            'Reports',

            'Customer Ledger',
            'Updation',
          ]
      : userType === 'PRODUCTION'
      ? ['Dashboard']
      : userType === 'SUPERVISOR'
      ? ['Dashboard', 'Reports', 'Test Production']
      : [];
  return (
    <div>
      <AppBar
        elevation={2}
        position="fixed"
        open={open}
        style={{ height: 47, justifyItems: 'start' }}
      >
        <Toolbar
          style={{ height: 47, justifyItems: 'start' }}
          className={'t:-mt-[10px] -mt-[6px] bg-[#0d0d0d] flex items-center'}
        >
          <div className="flex items-center  ">
            {window.innerWidth <= 600 ? (
              <MenuIcon
                sx={{ marginRight: 1 }}
                onClick={() => setMobileMenu(!MobileMenu)}
              />
            ) : location.pathname === '/' ? (
              <MenuIcon
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 1,
                  marginLeft: -1,
                  ...(open && { display: 'none' }),
                }}
                onClick={handleDrawerOpen}
              />
            ) : (
              <MenuIcon
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 1,
                  marginLeft: -1,

                  ...(open && { display: 'none' }),
                }}
                onClick={handleDrawerOpen}
              />
            )}
          </div>
          {/* </IconButton> */}
          <div className="   flex grow justify-between items-center w-full h-full mt-2 ">
            <div className="flex">
              <div className="ms:hidden mm:flex">
                {open ? (
                  <div className=" -ml-7 z-80 flex items-center ">
                    <Close
                      sx={{
                        color: 'white',
                      }}
                      fontSize="small"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                ) : null}
              </div>

              <TalringLogo />
            </div>
            <div className="flex  items-center h-full ">
              <button
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={'Full Screen'}
                onClick={!isFullscreen ? requestFullscreen : exitFullscreen}
                className="mr-2 hidden md:flex items-center"
              >
                {' '}
                <i className="bi bi-fullscreen flex items-center text-orange"></i>
              </button>
              <div
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={userType}
                className="text-md hidden   truncate overflow-ellipsis   t:flex items-center"
              >
                {userType}
              </div>
              <div className="px-2">|</div>
              <div
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={userType}
                className="text-md  truncate overflow-ellipsis   flex items-center"
              >
                {userName}
              </div>
              <i
                // onClick={toggleOptions}
                onClick={() => {
                  dispatch(setCustomeTwoButtomAlert(true));
                }}
                className="bi bi-person-circle text-[22px] ml-2 flex items-center text-blue"
              ></i>
            </div>
          </div>
          {showOptions && (
            <div id="profileOptions" className={optionsClass}>
              <button
                className={`block w-full py-1 text-left text-black  mb-2`}
              >
                Profile
              </button>
              <button
                className={`block w-full py-1 text-left text-destructive text-black `}
              >
                Logout
              </button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={`${
          MobileMenu ? ' p:flex absolute  ' : 'ms:hidden p:flex  absolute'
        } `}
        PaperProps={{
          style: {
            width: open ? '220px' : '55px',
          },
        }}
      >
        <div className="bg-[#0d0d0d] ">
          <DrawerHeader className="flex flex-row  justify-start ">
            <div className="flex grow"></div>
            <div className={`py-5 ${!open ? 'hidden' : null}`}>
              <div className="flex flex-col items-center ">
                <img
                  alt=""
                  src={require('../../../utility/images/GOROUND2.png')}
                  className="h-auto w-[140px] "
                />
                <img
                  alt=""
                  src={require('../../../utility/images/GO92X12.png')}
                  className="h-auto w-24 "
                />
                <div
                  style={{ fontSize: 9, marginTop: -1 }}
                  className=" flex justify-center   text-gray-200"
                >
                  Since 1994
                </div>
              </div>
            </div>
            <div className="flex grow"></div>
          </DrawerHeader>
        </div>

        <div className={`${open ? 'bg-[#0d0d0d] ' : '  bg-[#0d0d0d]'} -mt-4 `}>
          <List
            sx={{
              width: '100%',
            }}
          >
            {menuDrawer.map((text, index) => (
              <ListItem
                onClick={() => {
                  setOpen(false);
                  if (text === 'Dashboard') {
                    navigate('/');
                    setSelectedMenu(text);
                  }
                  if (text === 'Voucher Summary') {
                    // dispatch(setVoucherSummaryModStatus(true));
                    navigate('/voucher_summary');
                    setSelectedMenu(text);
                  }
                  if (text === 'Customer Master') {
                    if (window.innerWidth >= 500) {
                      dispatch(setCustomerMasterModStatus(true));
                    }
                  }
                  if (text === 'Booking') {
                    navigate('/booking');
                    setSelectedMenu(text);
                  }
                  if (text === 'Bill Summary') {
                    navigate('/bill_summary');
                    setSelectedMenu(text);
                  }
                  if (text === 'Reports') {
                    navigate('/reports');
                    setSelectedMenu(text);
                  }

                  if (text === 'Customer Ledger') {
                    navigate('/customer_ledger');
                    setSelectedMenu(text);
                  }
                  if (text === 'Fund Dashboard') {
                    navigate('/fund_dashboard');
                    setSelectedMenu(text);
                  }

                  if (text === 'Company Master') {
                    if (window.innerWidth >= 500) {
                      navigate('/company_master');
                      setSelectedMenu(text);
                    }
                  }
                  if (text === 'Test Production') {
                    navigate('/Scan_Qr_Code');
                    setSelectedMenu(text);
                  }
                  if (text === 'Masters') {
                    navigate('/masters');
                    setSelectedMenu(text);
                  }
                  if (text === 'User Master') {
                    if (window.innerWidth >= 500) {
                      navigate('/user_master');
                      setSelectedMenu(text);
                    }
                  }
                  if (text === 'Updation') {
                    if (window.innerWidth >= 500) {
                      navigate('/updation');
                      setSelectedMenu(text);
                    }
                  }
                  if (text === 'User List') {
                    navigate('/user_list');
                    setSelectedMenu(text);
                  }
                  if (text === 'Setting') {
                    navigate('/settings');
                    setSelectedMenu(text);
                  }
                  if (text === 'Order Status') {
                    navigate('/order_status');
                    setSelectedMenu(text);
                  }
                  setMobileMenu(false);
                }}
                key={text}
                disablePadding
              >
                <div
                  className={`${
                    !open && selectedMenu === text
                      ? 'bg-[#1a1919]  '
                      : !open && 'hover:bg-[#2e2d2d]'
                  } w-full  cursor-pointer`}
                  style={
                    selectedMenu === text && open
                      ? {
                          color: '#03a9f4',
                          display: 'flex',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          paddingTop: '1px',

                          flexDirection: 'row',
                          alignItems: 'center',
                        }
                      : {
                          color: '#f2f4f7',
                          paddingLeft: '10px',
                          paddingTop: '1px',

                          paddingRight: '10px',

                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }
                  }
                >
                  <div
                    className={`flex ${open && 'hover:bg-[#2e2d2d]'}  ${
                      selectedMenu === text && open && 'bg-[#222121]'
                    } w-full p-2 pl-2 items-center rounded-sm`}
                  >
                    <div>
                      {text === 'Dashboard' && (
                        <div
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className="w-10  flex justify-start"
                        >
                          <i className="bi  text-[18px] text-blue flex items-center bi-columns-gap"></i>
                        </div>
                      )}
                      {text === 'Customer Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          CM
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}{' '}
                      {text === 'Test Production' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          TP
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}{' '}
                      {text === 'Masters' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          MS
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}
                      {text === 'Updation' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          UP
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}
                      {text === 'Reports' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          RS
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}
                      {text === 'Order Status' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          OS
                          {/* {selectedMenu === text && (
                          <div
                            style={{
                              borderTop: '1px solid yellow',
                            }}
                          />
                        )} */}
                        </div>
                      )}
                      {text === 'Booking' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          BK
                        </div>
                      )}
                      {text === 'Company Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          CM
                        </div>
                      )}
                      {text === 'User Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          UM
                        </div>
                      )}
                      {text === 'User List' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          UL
                        </div>
                      )}
                      {text === 'Setting' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          SS
                        </div>
                      )}
                      {text === 'Voucher Summary' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          VS
                        </div>
                      )}
                      {text === 'Bill Summary' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          BS
                        </div>
                      )}
                      {text === 'Reciept Voucher' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          RV
                        </div>
                      )}
                      {text === 'Customer Ledger' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10 text-blue ${
                            selectedMenu === text &&
                            'underline underline-offset-4'
                          } flex justify-start`}
                        >
                          CL
                        </div>
                      )}
                    </div>

                    <div>{text}</div>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </div>

        <div className="h-full  bg-[#0d0d0d] flex flex-col justify-end pb-0 items-center ">
          <div style={{ height: '0.5px' }} className=" bg-[#a9a9a9]  w-11/12" />

          {userType === 'MANAGER' ? (
            <ListItem
              disablePadding
              style={{
                marginTop: '8px',
              }}
            >
              <ListItemButton
                style={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingRight: '0px',
                  paddingLeft: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/settings');
                  setMobileMenu(false);
                }}
              >
                <ListItemIcon>
                  <i
                    className="bi bi-gear flex text-orange items-center"
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  className="text-[#a9a9a9] text-sm"
                  style={{ marginLeft: -14 }}
                  primaryTypographyProps={{
                    variant: 'subtitle',
                    style: {
                      fontSize: 16,
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          <ListItem disablePadding>
            <a
              href="https://geniusoffice.freshdesk.com/support/tickets/new"
              target="_blank"
              style={{
                textDecoration: 'none',
              }}
              rel="noopener noreferrer"
              className="text-blue-400 underline"
            >
              <ListItemButton style={{ paddingLeft: 12 }}>
                <ListItemIcon>
                  <QuestionMark style={{ fontSize: 20, color: '#03a9f4' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Creat a ticket"
                  className="text-[#a9a9a9] text-sm"
                  style={{ marginLeft: -14 }}
                  primaryTypographyProps={{
                    variant: 'subtitle',
                  }}
                />
              </ListItemButton>
            </a>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                setOpen(false);

                navigate('/user_profile');
              }}
            >
              <ListItemIcon>
                <Person style={{ fontSize: 24, color: '#03a9f4' }} />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                className="text-[#a9a9a9]"
                style={{ marginLeft: -14 }}
                primaryTypographyProps={{
                  variant: 'subtitle',
                  style: { fontSize: 16 },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                dispatch(setCustomeTwoButtomAlert(true));
                setMobileMenu(false);
              }}
            >
              <ListItemIcon
                onClick={() => {
                  dispatch(setCustomeTwoButtomAlert(true));
                  setMobileMenu(false);
                }}
              >
                <Logout style={{ fontSize: 24, color: '#03a9f4' }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                className="text-[#a9a9a9]"
                style={{ marginLeft: -14 }}
                primaryTypographyProps={{
                  variant: 'subtitle',
                  style: { fontSize: 16 },
                }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
};

export default AppBarDesign;
