import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { useFocus } from '../utility/hooks/hooks';
import { toProperCase } from '../utility/component/format';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import axios from 'axios';
import {
  setAlertWithTitle,
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';
import GeniustextInput from '../utility/component/mastertext';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import ItemMasterHelp from '../help/itemMasterHelp';
const GeniusStyleMaster = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [loading, setloading] = useState(true);
  const [rows, setRows] = useState([]);
  const [itemName, setItemName] = useState('');
  const [styleInput, setStyleInput] = useState('');
  const [inputRef, setInputRef] = useFocus();

  const columns = [
    {
      field: 'STYLE_NAME',
      type: 'string',
      renderHeader: (params) => <div>Style</div>,
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
  ];
  // const masterprefix = useSelector(selectTableMasterPrefix);

  const getData = async () => {
    // try {
    //   const response =
    await axios
      .post(
        '/api/masters/listStyleMaster',
        {
          table_prefix_master: masterprefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  const windowWidth = useSelector(selectWidth);

  const masterprefix = useSelector(selectTableMasterPrefix);

  const [id, setId] = useState('');

  const saveStyleMasterApi = () => {
    if (styleInput) {
      const isDuplicateHSN = rows.some((row) => row.STYLE_NAME === styleInput);

      if (!id && isDuplicateHSN) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Style is already exists. Please choose a different style.',
          })
        );
        return;
      }

      const data = {
        table_prefix_master: masterprefix,
        CODE: id ? id : '',
        STYLE_NAME: styleInput,
        ITEM_NAME: itemName,
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));

      axios
        .post(`/api/masters/saveStyleMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            {
              id
                ? dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Updated successfully.',
                      lottie: 'success',
                    })
                  )
                : dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Saved successfully.',
                      lottie: 'success',
                    })
                  );
            }

            getData();
            clearData();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Information Error.',
          msg: 'Please Fill Required Feild',
        })
      );
      setInputRef();
    }
  };

  const handleOpenItemMasterHelp = () => {
    dispatch(setItemMasterHelpStatus(true));
  };

  const clearData = () => {
    setItemName('');
    setStyleInput('');
    setId('');
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 15) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            inputFields[nextIndex].focus(); // Set focus on the next field
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  const handleItemInputChange = (e) => {
    const newValueDept = toProperCase(e.target.value);

    if (newValueDept) {
      dispatch(setItemMasterHelpStatus(true));
    } else {
      dispatch(setItemMasterHelpStatus(false));
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.altKey) && e.key === 's') {
        e.preventDefault();
        saveStyleMasterApi();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [styleInput, itemName]);

  return (
    <div className="flex-grow  bg-white border-brown  px-3" ref={formRef}>
      <div id="input" className="flex mt-3">
        <div>
          <GeniustextInput
            title={'Style'}
            width={windowWidth > 764 ? '600px' : '300px'}
            type={'text'}
            value={styleInput}
            onChange={(e) => {
              setStyleInput(toProperCase(e.target.value));
            }}
            onKeyDown={(e) => {
              if ((e.ctrlKey || e.altKey) && e.key === 's') {
                e.preventDefault();
                saveStyleMasterApi();
              }
            }}
            refrance={inputRef}
            autofocus={true}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Item Name'}
          width={windowWidth > 764 ? '600px' : '300px'}
          type={'text'}
          value={itemName}
          onChange={handleItemInputChange}
          onKeyDown={(e) => {
            if ((e.ctrlKey || e.altKey) && e.key === 's') {
              e.preventDefault();
              saveStyleMasterApi();
            }
          }}
          search={handleOpenItemMasterHelp}
        />
      </div>

      <div className="w-full t:h-[300px] d:h-[400px] sm:h-[300px] h-[300px] mt-1 flex">
        <GeniusDatagrid
          rowID={(t) => t.CODE}
          onRowClick={(p) => {
            setId(p.row.CODE);
            setStyleInput(p.row.STYLE_NAME);
            setItemName(p.row.ITEM_NAME);
          }}
          columns={columns}
          list={rows}
        />
      </div>

      <div className="flex w-full justify-end items-center py-3">
        {id ? (
          <ModifyButton onClick={saveStyleMasterApi} />
        ) : (
          <SaveButton onClick={saveStyleMasterApi} />
        )}
        <UndoButton onClick={clearData} />
      </div>

      <ItemMasterHelp
        onRowClick={(p) => {
          setItemName(p.row.item_name);

          dispatch(setItemMasterHelpStatus(false));
        }}
      />
    </div>
  );
};

export default GeniusStyleMaster;
