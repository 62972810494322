import React, { useEffect, useState } from 'react';
import './wallet.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompany,
  selectCompanyAddress,
  selectDate,
  selectDefultProfileSetting,
  selectIsProductionModuleAllwed,
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import moment from 'moment';
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';
import { Collapse } from '@mui/material';
import { ref, get, onValue, set, Database, child } from 'firebase/database';
import database from '../utility/component/functions/firebase';
const WalletCard = ({ title, type, subtitle, p, color, data, bg1, bg2 }) => {
  const totalCredits = data
    ? type === 'Whatsapp'
      ? data.whatsapp_credits
      : data.sms_credits
    : 0;
  const balance = data
    ? type === 'Whatsapp'
      ? data.whatsapp_balance
      : data.sms_balance
    : 0;

  const usedCredits = totalCredits - balance;
  const percentage = (usedCredits / totalCredits) * 100;
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, ${bg1}, ${bg2})`,
      }}
      className="wallet-card w-[270px] shadow-sm rounded-sm text-black  p-4 flex items-center"
    >
      <div
        className="wallet-progress-circle"
        style={{
          background: `conic-gradient(${
            color ? color : '#f5874f'
          }  ${percentage}%, #e5e7eb 0%)`,
        }}
      >
        <div className="wallet-progress-inner-circle">
          <span className="wallet-progress-text">{percentage}%</span>
        </div>
      </div>
      <div
        style={{
          width: `calc(100% - 100px)`,
        }}
        className="wallet-info ml-4"
      >
        <h3 className="wallet-title">{title}</h3>
        <p className="wallet-subtitle">
          {' '}
          <i className="bi bi-coin text-[14px] mr-1"></i>
          {subtitle}
        </p>
        <div className="wallet-balance-bar mt-2">
          <div
            className="wallet-balance"
            style={{
              width: `${percentage}%`,
              backgroundColor: `${color ? color : '#f5874f'}`,
            }}
          ></div>
        </div>
        <span className="wallet-balance-text">{percentage}% Used</span>
      </div>
    </div>
  );
};

const RecordCard = ({
  amount,
  card,
  title,
  icon,
  open,
  type,
  setOpen,
  detail,
}) => {
  return (
    <div
      onClick={() => {
        setOpen((e) => (e === type ? false : type));
      }}
      className="w-full  "
    >
      <div
        style={{
          backgroundColor: card.bg,
        }}
        className={`wallet-card hover:cursor-pointer min-w-[270px] shadow-sm rounded-sm text-black  p-4 flex items-center`}
      >
        <div className="flex">
          <div
            style={{
              backgroundColor: card.circle,
            }}
            className="h-[50px] text-[20px] w-[50px] pb-1 rounded-[50px] flex justify-center items-center "
          >
            <i className={`${icon} text-black`}></i>
          </div>
          <div className="ml-3">
            <div className="font-bold ">{amount}</div>
            <div className="text-gray-800  flex font-semibold">
              {title}{' '}
              <i
                className={`bi text-black ml-4  bi-chevron-${
                  open === type ? 'up' : 'down'
                }`}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {type === open && (
        <div className="flex justify-center ">
          <div
            style={{
              backgroundColor: card.bg,
            }}
            className=" h-[20px] w-[10px] shadow-sm "
          ></div>
        </div>
      )}
      <div className="t:hidden">{detail}</div>
    </div>
  );
};
const FundDashboard = () => {
  const company = useSelector(selectCompanyAddress);
  const token = useSelector(selectToken);
  const [WalletData, setWalletData] = useState();
  const prefix = useSelector(selectPrefix);
  const [todayBookings, setTodayBookings] = useState(0);
  const [todayBookingsOB, setTodayBookingsOB] = useState([]);
  const [todayDeliverd, setTodayDeliverd] = useState(0);
  const [todayDeliverdOb, setTodaydeliverdOB] = useState([]);
  const [productionData, setProductionData] = useState([]);
  const [totalproduction, setTotalproduction] = useState(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState('production');
  const width = useSelector(selectWidth);
  const date = useSelector(selectDate);
  const getWhatspp = () => {
    dispatch(setSpinnerLoading('Loading...'));
    axios
      .post(
        `/api/userSettings/loadMessageSetup`,
        {
          company_email: company.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setWalletData(res.data.response[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getTodayBooking();
      });
  };
  const getTodayBooking = (m) => {
    axios
      .post(
        `/api/dashBoardRoute/todayBookings`,
        {
          table_prefix: prefix,
          DATE: moment(date).format('YYYY-MM-DD'),
          MOBILE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayBookings(
            response.data.response.reduce(
              (acc, row) => acc + Number(row.QNTY),
              0
            )
          );
          const uniqItem = [
            ...new Set(response.data.response.map((item) => item.ITEM_NAME)),
          ];
          setTodayBookingsOB(
            uniqItem.map((o) => {
              const totalQnty = response.data.response.reduce(
                (acc, row) => acc + Number(o === row.ITEM_NAME ? row.QNTY : 0),
                0
              );

              return {
                ITEM_NAME: o,
                QNTY: totalQnty,
                data: response.data.response.filter((it) => it.ITEM_NAME === o),
              };
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        setTodayBookings(0);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayDeliveredItems();
      });
  };
  const getTodayDeliveredItems = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayDelivered`,
        {
          table_prefix: prefix,
          F_DELIVERED_DATE: moment(date).format('YYYY-MM-DD'),
          T_DELIVERED_DATE: moment(date).format('YYYY-MM-DD'),
          MOBILE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayDeliverd(
            response.data.response.reduce(
              (acc, row) => acc + Number(row.QNTY),
              0
            )
          );
          const uniqItem = [
            ...new Set(response.data.response.map((item) => item.ITEM_NAME)),
          ];
          setTodaydeliverdOB(
            uniqItem.map((o) => {
              const totalQnty = response.data.response.reduce(
                (acc, row) => acc + Number(o === row.ITEM_NAME ? row.QNTY : 0),
                0
              );

              return {
                ITEM_NAME: o,
                QNTY: totalQnty,
                data: response.data.response.filter((it) => it.ITEM_NAME === o),
              };
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const getproductionData = () => {
    axios
      .post(
        '/api/production/productionStatus',
        {
          table_prefix: prefix,
          STAGE_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (p.data.response[0]) {
          const data = p.data.response;
          setProductionData(data);
          setTotalproduction(
            data.reduce((acc, row) => acc + Number(row.NO_OF_ORDERS), 0)
          );
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };
  useEffect(() => {
    getWhatspp();
  }, [date]);

  useEffect(() => {
    getproductionData();
  }, []);

  const cardData = [
    {
      bg: '#e8e2db',
      circle: '#d1cbc5',
      round: 'white',
    },
    {
      bg: '#fbe0d8',
      circle: '#e8cfc8',
      round: 'white',
    },
    {
      bg: '#f7e1c3',
      circle: '#edd2af',
      round: 'white',
    },
    {
      bg: '#f3bc77',
      circle: '#8090A0',
      round: 'white',
    },
  ];
  const masterPrefix = useSelector(selectTableMasterPrefix);
  useEffect(() => {
    const connectedRef = ref(database, `${masterPrefix}`);
    const connectedRef2 = ref(database, `${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          getproductionData();
        }
      });
    });
  }, []);

  const isProductionAllowed = useSelector(selectIsProductionModuleAllwed);
  return (
    <div className="w-full h-full overflow-y-auto  overflow-x-hidden">
      <div className="d:flex h-full ">
        <div className="pl-4 p-3 flex-1 ">
          <div className="w-full">
            <div className="flex mb-3">
              <div className="text-[17px] font-semibold  ">Orders</div>
              <button
                onClick={() => {
                  getproductionData();
                }}
              >
                <i className="bi bi-arrow-clockwise text-orange ml-3 text-[20px]"></i>
              </button>
            </div>
            <div className="grid grid-flow-row w-full  t:grid-flow-col auto-cols-max  gap-4  flex-1">
              <RecordCard
                amount={todayBookings}
                title={'Today Bookings'}
                icon={'bi bi-calendar-minus'}
                type={'booking'}
                open={open}
                card={cardData[0]}
                setOpen={setOpen}
                detail={
                  <InfoCard
                    open={open}
                    type={'booking'}
                    ob={todayBookingsOB}
                    card={cardData[0]}
                  />
                }
              />
              <RecordCard
                amount={todayDeliverd}
                title={'Today Delivered'}
                icon={'bi bi-truck'}
                open={open}
                type={'delivered'}
                card={cardData[1]}
                setOpen={setOpen}
                detail={
                  <InfoCard
                    open={open}
                    type={'delivered'}
                    ob={todayDeliverdOb}
                    card={cardData[1]}
                  />
                }
              />
              {isProductionAllowed && (
                <RecordCard
                  amount={totalproduction}
                  title={'In Production'}
                  icon={'bi bi-shop-window'}
                  open={open}
                  card={cardData[2]}
                  type={'production'}
                  setOpen={setOpen}
                  detail={
                    <InfoCard2
                      open={open}
                      type={'production'}
                      ob={productionData}
                      card={cardData[2]}
                    />
                  }
                />
              )}
            </div>
            <div
              style={{
                width: `calc(100% - 30px)`,
                display: width < 1024 ? 'none' : 'block',
              }}
            >
              <InfoCard
                open={open}
                type={'booking'}
                ob={todayBookingsOB}
                card={cardData[0]}
              />
              {isProductionAllowed && (
                <InfoCard2
                  open={open}
                  type={'production'}
                  ob={productionData}
                  card={cardData[2]}
                />
              )}

              <InfoCard
                open={open}
                type={'delivered'}
                ob={todayDeliverdOb}
                card={cardData[1]}
              />
            </div>
          </div>
        </div>

        <div className=" border-l h-full   p-3">
          <div className="flex mb-3">
            <div className="text-[17px] font-semibold ">Balance</div>
            <button
              onClick={() => {
                getWhatspp();
              }}
            >
              <i className="bi bi-arrow-clockwise text-orange ml-3 text-[20px]"></i>
            </button>
          </div>

          <WalletCard
            subtitle={WalletData ? Number(WalletData.whatsapp_balance) : 0}
            title="WhatsApp Credit"
            color={'#25d366'}
            data={WalletData}
            type={'Whatsapp'}
            bg1={'#d2e0ce'}
            bg2={'#8ced6f'}
            p={
              WalletData
                ? (Number(WalletData.whatsapp_credits) -
                    Number(WalletData.whatsapp_balance) /
                      Number(WalletData.whatsapp_credits)) *
                  100
                : 100
            }
          />
          <div className="mt-4">
            <WalletCard
              subtitle={WalletData ? Number(WalletData.sms_balance) : 0}
              title="SMS Credit"
              data={WalletData}
              type={'SMS'}
              bg1={'#f2e3df'}
              bg2={'#f3bc77'}
              color={'#f3bc77'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundDashboard;

const InfoCard = ({ open, ob, card, type, selected }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (ob[0]) {
      setData(ob[0].data);
    }
  }, [ob]);
  const cardBG = 'white';
  return (
    <Collapse in={open === type} timeout={'auto'} unmountOnExit>
      <div
        style={{
          backgroundColor: card.bg,
          width: '100%',
        }}
        className="t:flex   shadow-sm p-3 rounded-sm overflow-x-auto  "
      >
        {ob.map((o, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setData(o.data);
              }}
              className="flex max-w-[400px] min-w-[120px] mx-1 hover:cursor-pointer  items-center justify-center"
            >
              <div className="flex flex-col  justify-center items-center ">
                <div className="flex flex-col  items-center">
                  <div className="text-[15px]  whitespace-nowrap font-semibold">
                    {o.ITEM_NAME}
                  </div>

                  <div
                    style={{
                      backgroundColor: card.round,
                    }}
                    className={
                      'h-[40px] w-[40px] rounded-[40px]  flex justify-center items-center font-semibold border border-[#241c1d]'
                    }
                  >
                    {' '}
                    {o.QNTY}{' '}
                  </div>
                  {data[0] && data[0].ITEM_NAME === o.data[0].ITEM_NAME && (
                    <div className="flex justify-center">
                      <div
                        style={{
                          backgroundColor: card.bg,
                        }}
                        className="h-[40px] absolute w-[10px]"
                      ></div>
                    </div>
                  )}
                </div>
                {i < ob.length - 1 && (
                  <div className="bg-[#241c1d] t:hidden  h-[30px] w-[1px]  t:h-[1px] t:w-[150px]"></div>
                )}
              </div>
              {i < ob.length - 1 && (
                <div className="bg-[#241c1d] hidden t:flex h-[30px] w-[1px]  t:h-[1px] t:w-[150px]"></div>
              )}
            </div>
          );
        })}
      </div>

      {ob[0] && (
        <div
          style={{
            maxHeight: window.innerHeight - 450,
            overflowY: 'auto',
            backgroundColor: card.bg,
          }}
          className="p-2 mt-6 rounded-sm "
        >
          <div className="grid   grid-cols-1 tab:grid-cols-1 t:grid-cols-3 l:grid-cols-3 ll:grid-cols-4 4k:grid-cols-7 gap-2">
            {data?.map((o, i) => {
              return (
                <div
                  style={{
                    backgroundColor: cardBG,
                  }}
                  key={i}
                  className=" mt-1 rounded-sm p-3 shadow-sm"
                >
                  <div className=" w-full">
                    <div className="font-semibold flex justify-between">
                      <div>
                        {o.ITEM_NAME} {o.UPPER_LOWER === 'UPPER' ? '👕' : ' 👖'}
                        {' - ' + o.QNTY}
                      </div>
                      <div>#{o.BOOKING_ORDER_NO} </div>
                    </div>
                    <div className="mt-1">
                      <div className="flex">
                        <div className="w-[80px] font-semibold">Customer</div> :
                        <div className="ml-2"> {o.CUSTOMER_NAME}</div>
                      </div>

                      <div className="flex">
                        <div className="w-[80px] font-semibold">Mobile</div> :
                        <div className="ml-2"> {o.MOBILE}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Collapse>
  );
};

const InfoCard2 = ({ open, ob, card, type }) => {
  const [data, setData] = useState(ob[0] ? ob[0].STAGE_NAME : '');
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const fetchOrderDetails = async () => {
    const details = {};
    for (const stage of ob) {
      const response = await axios
        .post(
          '/api/production/OrdersInProduction',
          {
            table_prefix: prefix,
            STAGE_NAME: stage.STAGE_NAME,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          return p.data.response;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
      details[stage.STAGE_NAME] = response;
    }

    setData(details);
  };

  useEffect(() => {
    fetchOrderDetails();
    if (ob[0]) {
      setSelected(ob[0].STAGE_NAME);
    }
  }, [ob]);
  const cardBG = 'white';
  return (
    <Collapse in={open === type} timeout={'auto'} unmountOnExit>
      <div
        style={{
          backgroundColor: card.bg,
          width: '100%',
        }}
        className="t:flex  shadow-sm p-3 rounded-sm overflow-x-auto  "
      >
        {ob.map((o, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setSelected(o.STAGE_NAME);
              }}
              className="flex max-w-[400px] min-w-[120px] mx-1 hover:cursor-pointer  items-center justify-center"
            >
              <div className="flex flex-col  justify-center items-center ">
                <div className="flex flex-col  items-center">
                  <div className="text-[15px]  whitespace-nowrap font-semibold">
                    {o.STAGE_NAME}
                  </div>

                  <button
                    style={{
                      backgroundColor:
                        o.STAGE_NAME === selected ? cardBG : card.round,
                    }}
                    className={
                      'h-[40px] w-[40px] rounded-[40px]  flex justify-center items-center font-semibold border border-[#241c1d]'
                    }
                  >
                    {' '}
                    {o.NO_OF_ORDERS}{' '}
                  </button>
                  {selected === o.STAGE_NAME && (
                    <div className="flex justify-center">
                      <div
                        style={{
                          backgroundColor: card.bg,
                        }}
                        className="h-[40px] absolute w-[10px]"
                      ></div>
                    </div>
                  )}
                </div>
                {i < ob.length - 1 && (
                  <div className="bg-[#241c1d] t:hidden  h-[30px] w-[1px]  t:h-[1px] t:w-[150px]"></div>
                )}
              </div>
              {i < ob.length - 1 && (
                <div className="bg-[#241c1d] hidden t:flex h-[30px] w-[1px]  t:h-[1px] t:w-[150px]"></div>
              )}
            </div>
          );
        })}
      </div>
      {ob[0] && (
        <div
          style={{
            maxHeight: window.innerHeight - 450,
            overflowY: 'auto',
            backgroundColor: card.bg,
          }}
          className="p-2 mt-6 rounded-sm "
        >
          <div className="grid   grid-cols-1 tab:grid-cols-1 t:grid-cols-3 l:grid-cols-3 ll:grid-cols-4 4k:grid-cols-7 gap-2">
            {data &&
              selected &&
              data[selected]?.map((o, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      backgroundColor: cardBG,
                    }}
                    className=" mt-1 rounded-sm  p-3 shadow-sm"
                  >
                    <div className=" w-full">
                      <div className="font-semibold flex justify-between">
                        <div>
                          {o.ITEM_NAME}{' '}
                          {o.UPPER_LOWER === 'UPPER' ? '👕' : ' 👖'}
                        </div>
                        <div>
                          #
                          {o.PREFIX + '-' + o.MRC_NO + '-' + o.BOOKING_ORDER_NO}{' '}
                        </div>
                      </div>
                      <div className="mt-1">
                        <div className="flex">
                          <div className="w-[80px] font-semibold">Employee</div>{' '}
                          :
                          <div className="ml-2">
                            {' '}
                            {o.CURRENT_EMPLOYEE} [ {o.CURRENT_EMP_MOBILE} ]
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[80px] font-semibold">Customer</div>{' '}
                          :<div className="ml-2"> {o.CUSTOMER_NAME}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Collapse>
  );
};
