import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectTodayIssueStatus,
  selectWidth,
} from '../utility/Redux/profile';
import { selectCustomerPrefix } from '../utility/Redux/booking';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import {
  selectSpinnerLoading,
  setAlertWithTitle,
  setSessionExpired,
} from '../utility/Redux/modal';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
import {
  Button,
  Chip,
  Collapse,
  Divider,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import { toProperCase } from '../utility/component/format';
import formatMobileNumber from '../utility/func/formatNumber';
import database from '../utility/component/functions/firebase';
import { child, get, onValue, ref } from 'firebase/database';
export const ProductionStatus = ({
  mrcNumber,
  bookingCustomerDetails,
  SetTabScreen,
}) => {
  const prefix = useSelector(selectPrefix);
  const bookingPrefix = useSelector(selectCustomerPrefix);
  const token = useSelector(selectToken);
  const [list, setlist] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uniqueStageName, setUniqueStageName] = useState([]);
  const [orderNumbewr, setorderNumber] = useState('');
  const [searchPlaceHolders, setSearchPlaceHolders] = useState('Mobile');

  const getData = async () => {
    setLoading(true);
    await axios
      .post(
        '/api/production/CustomerOrderStatus',
        {
          table_prefix: prefix,
          MRC_NO: mrcNumber,
          PREFIX: bookingPrefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (!p.data.error) {
          if (!p.data.response[0]) {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Not found any order in production releated selected customer',
              })
            );
            SetTabScreen(1);
          }
          const realData = {};
          const uniqueStage = [
            ...new Set(p.data.response.map((item) => item.STAGE_NAME)),
          ];
          setUniqueStageName(uniqueStage);
          uniqueStage.map((item) => {
            const data = p.data.response.filter((i) => i.STAGE_NAME === item);
            realData[item] = data;
          });
          setlist(realData);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const spinnerLoading = useSelector(selectSpinnerLoading);
  useEffect(() => {
    const connectedRef = ref(database, `${masterPrefix}`);
    const connectedRef2 = ref(database, `${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          getData();
        }
      });
    });
  }, []);

  return (
    <div className="w-full h-full p-6 text-sm  ">
      <div className="md:flex md:justify-between h-full ">
        {loading || spinnerLoading ? (
          <SkeletonLoader />
        ) : (
          <div
            style={{
              height: window.innerWidth < 768 ? `calc(100% - 100px)` : '100%',
            }}
            className="overflow-y-auto  w-[500px] t:w-[600px]"
          >
            {uniqueStageName.map((i, n) => {
              const isOpen = uniqueStageName.includes(i);
              const dep = i.toLowerCase();
              const typeOfStage = dep.includes('wa')
                ? washing
                : dep.includes('cu')
                ? cutting
                : dep.includes('emb')
                ? embroidery
                : dep.includes('st')
                ? sttiching
                : dep.includes('bu')
                ? buttonstype
                : dep.includes('pr')
                ? press
                : dep.includes('re')
                ? ready
                : dep.includes('del')
                ? delivery
                : sttiching;
              return (
                <div
                  key={i}

                  // className={`${!isOpen ? 'mb-2' : ''} `}
                >
                  <div className="flex items-center hover:cursor-pointer">
                    <div className="bg-[#e5f2fd] justify-center flex items-center w-[200px] h-fit pt-1 pr-1 pb-1 pl-3  text-center rounded-[11px]">
                      <div className="flex-1 flex items-center text-start font-semibold text-[15px]">
                        <img src={typeOfStage} className="w-[20px] mt-1" />{' '}
                        <div className="ml-2">{`${i} `}</div>
                      </div>

                      {/* <button className="flex text-center">
                        <i
                          className={`bi px-2 text-[13px] text-black flex items-center ${
                            isOpen ? 'bi-chevron-up' : 'bi-chevron-down'
                          }`}
                        ></i>
                      </button> */}
                    </div>
                  </div>
                  {!isOpen && (
                    <div className="h-full w-[100px] flex justify-center">
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          borderRightWidth: 1, // Change width
                          borderColor: '#0d0d0d', // Change color,
                          height: '10px',
                        }}
                      />
                    </div>
                  )}
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <div className="flex flex-col">
                      {list[i]?.map((e, index) => {
                        if (orderNumbewr) {
                          const isOrder = orderNumbewr.includes('#');
                          const compareto = isOrder
                            ? e.BOOKING_ORDER_NO
                            : e.MOBILE;
                          if (
                            compareto
                              .toString()
                              .includes(
                                orderNumbewr.replace('#', '').toString()
                              )
                          ) {
                            return (
                              <div key={index} className="flex h-[100px]">
                                <div className="h-full w-[100px] flex justify-center">
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                      borderRightWidth: 3, // Change width
                                      borderColor: '#e5f2fd', // Change color,
                                      height: '100%',
                                    }}
                                  >
                                    {' '}
                                    <Chip
                                      label={`#${e.BOOKING_ORDER_NO}`}
                                      size="small"
                                      sx={{
                                        width: '60px',
                                        fontSize: '14px',
                                        borderRadius: '11px',
                                      }}
                                    />
                                  </Divider>
                                </div>
                                <div className="p-2 text-[12px] flex items-center">
                                  <div>
                                    <div className="font-semibold text-[15px]">
                                      {toProperCase(e.ITEM_NAME.toLowerCase())}
                                    </div>
                                    <div className=" text-[14px] flex">
                                      <div>
                                        {bookingCustomerDetails.customer_name}
                                      </div>
                                      <div className="ml-1">
                                        {' - '}{' '}
                                        {formatMobileNumber(
                                          bookingCustomerDetails.mobile
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div key={index} className="flex h-[100px]">
                              <div className="h-full w-[100px] flex justify-center">
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{
                                    borderRightWidth: 3, // Change width
                                    borderColor: '#e5f2fd', // Change color,
                                    height: '100%',
                                  }}
                                >
                                  {' '}
                                  <Chip
                                    label={`#${e.BOOKING_ORDER_NO}`}
                                    size="small"
                                    sx={{
                                      width: '60px',
                                      fontSize: '14px',
                                      borderRadius: '11px',
                                    }}
                                  />
                                </Divider>
                              </div>
                              <div className="p-2 text-[12px]   flex-1  flex items-center">
                                <div className="w-full">
                                  <div className="font-semibold text-[15px] w-full flex items-center">
                                    {toProperCase(e.ITEM_NAME.toLowerCase())}
                                    <div className=" ml-1 -mt-1">
                                      {e.UPPER_LOWER === 'UPPER' ? '👕' : '👖'}
                                    </div>
                                  </div>
                                  <div className=" text-[14px] flex flex-1">
                                    <div>
                                      {bookingCustomerDetails.customer_name}
                                    </div>
                                    <div className="ml-1">
                                      {' - '}{' '}
                                      {formatMobileNumber(
                                        bookingCustomerDetails.mobile
                                      )}
                                    </div>
                                  </div>
                                  <div className=" text-[14px] flex flex-1">
                                    {`( Emp. Name : ${
                                      e.CURRENT_EMPLOYEE
                                    } - ${formatMobileNumber(
                                      e.CURRENT_EMP_MOBILE
                                    )} )`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
const SkeletonLoader = () => {
  return (
    <div className="h-full overflow-y-auto">
      {[...Array(3)].map((_, index) => (
        <div key={index} mb={2}>
          <Skeleton variant="rectangular" height={30} width="140px" />
          {[...Array(Math.floor(Math.random() * 2) + 1)].map(() => {
            return (
              <div className="flex">
                <div className="w-[100px] flex justify-center text-center ">
                  <Skeleton variant="rectangular" height={80} width="3px" />
                </div>
                <div>
                  <div className="mt-2">
                    <Skeleton variant="text" height={20} width="200px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="150px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="100px" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
