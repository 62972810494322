import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  selectFinancialYearDetails,
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { FormatNumber2 } from '../utility/component/tools';
import { CollectionFromToDate } from '../utility/component/collectionFromToDate';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  setBookingAttachImage,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../utility/Redux/booking';
import { setViewBillInvoiceId, setViewBillMod } from '../utility/Redux/billing';
import { useNavigate } from 'react-router-dom';
import PrintBillSummary from '../summary/printBillSummary';
import { PrintButton } from '../utility/component/SNAA_buttons';
import { useReactToPrint } from 'react-to-print';
import { CollectionFromToDateMobile } from '../utility/component/collectionFromToDateMobile';
import { CustomerLedgerFooter } from '../utility/component/datagrid.js/customerLedgerFooter';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import CustomerMasterhelp from '../help/customermasterHelp';
import { getCustomerMasterAPI } from '../booking/list';
const width = window.innerWidth / 100;

const CustomerLedger = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const [mrcNumber, setMrcNumber] = useState('');
  const [customerPrefix, setCustomerPrefix] = useState('');
  const dispatch = useDispatch();

  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));

  const [list, setList] = useState([]);
  const [bookingCustomerDetails, setBookingCustomerDetails] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 100000,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: 'Good',
    remarks: '',
  });
  const financialYearDetails = useSelector(selectFinancialYearDetails);
  const [firstDate, setFirstDate] = useState(
    moment(financialYearDetails.start_date).format('YYYY-MM-DD')
  );
  const columns = [
    {
      field: 'VOUCHER_NO',
      accessor: 'VOUCHER_NO',
      type: 'number',
      width: width * 8,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value > 0) {
          return params.value;
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Voucher'}
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'date',
      width: width * 8,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => moment(params.value).format('DD-MM-YYYY'),
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Date'}
        </div>
      ),
    },
    {
      field: 'INVNO',
      accessor: 'INVNO',
      type: 'number',
      width: width * 8,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value > 0) {
          return params.value;
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Bill No.'}
        </div>
      ),
    },
    {
      field: 'AMOUNT_DESC',
      accessor: 'AMOUNT_DESC',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Description'}
        </div>
      ),
    },
    {
      field: 'DEBIT',
      accessor: 'DEBIT',
      type: 'string',
      width: width * 8.5,
      headerAlign: 'center',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      align: 'right',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Debit'}
        </div>
      ),
    },
    {
      field: 'CREDIT',
      accessor: 'CREDIT',
      type: 'string',
      width: width * 8.5,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Credit'}
        </div>
      ),
    },

    {
      field: 'BALANCE',
      accessor: 'BALANCE',
      type: 'string',
      align: 'right',
      width: width * 8,
      headerAlign: 'center',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Balance'}
        </div>
      ),
    },

    {
      field: 'CARD_NO',
      accessor: 'CARD_NO',
      type: 'string',
      width: width * 12,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Card No.'}
        </div>
      ),
    },

    {
      field: 'EPAYMENT_BANK',
      accessor: 'EPAYMENT_BANK',
      type: 'string',
      width: width * 10,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'ePayment Bank'}
        </div>
      ),
    },
  ];

  const columnsTab = [
    {
      field: 'VOUCHER_NO',
      accessor: 'VOUCHER_NO',
      type: 'number',
      width: width * 10,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value > 0) {
          return params.value;
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Voucher'}
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'number',
      width: width * 14,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => moment(params.value).format('DD-MM-YYYY'),
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Date'}
        </div>
      ),
    },
    {
      field: 'INVNO',
      accessor: 'INVNO',
      type: 'number',
      width: width * 12,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value > 0) {
          return params.value;
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Bill No.'}
        </div>
      ),
    },
    {
      field: 'AMOUNT_DESC',
      accessor: 'AMOUNT_DESC',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Description'}
        </div>
      ),
    },

    {
      field: 'DEBIT',
      accessor: 'DEBIT',
      type: 'string',
      width: width * 12,
      headerAlign: 'center',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      align: 'right',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Debit'}
        </div>
      ),
    },
    {
      field: 'CREDIT',
      accessor: 'CREDIT',
      type: 'string',
      width: width * 12,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Credit'}
        </div>
      ),
    },

    {
      field: 'BALANCE',
      accessor: 'BALANCE',
      type: 'string',
      align: 'right',
      width: width * 12,
      headerAlign: 'center',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {' '}
          {'Balance'}
        </div>
      ),
    },
  ];

  const loadCustomerLedger = () => {
    const data = {
      table_prefix: prefix,
      FDATE: firstDate,
      TDATE: SecondDate,
      MRC_NO: mrcNumber,
      PREFIX: customerPrefix,
    };
    dispatch(setSpinnerLoading('Loading'));

    axios
      .post(`/api/reportRoute/customerLedger`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          let rowList = [];
          let OP_BAL = 0;

          const data = response.data.response;

          for (let index = 0; index < data.length; index++) {
            let db = data[index].DEBIT;
            let cr = data[index].CREDIT;
            let BL = OP_BAL + db - cr;

            OP_BAL = BL;
            rowList.push({
              ...data[index],
              id: index + 1,
              BALANCE: BL,
            });
          }

          setList(rowList);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (!bookingCustomerDetails.mobile) {
      dispatch(setCustomerMasterHelpModStatus(true));
    }
  }, [bookingCustomerDetails, firstDate, SecondDate]);

  const printRef = useRef();
  const navigate = useNavigate();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate('/customer_ledger');
      setSelectedPrintOption('print');
    },
  });
  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    setMrcNumber(params.row.mrc_no);
    setCustomerPrefix(params.row.prefix);
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    setBookingCustomerDetails(params.row);
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  return (
    <div className=" w-full h-full flex flex-col  p-4 overflow-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadCustomerLedger();
            }}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadCustomerLedger();
            }}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
              setList([]);
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}

      <div className="flex flex-col grow mt-4">
        <div className="h-[380px] l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 12,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            onRowDoubleClick={(param) => {
              dispatch(setViewBillMod(true));
              dispatch(setViewBillInvoiceId(param.id));
            }}
            rowHeight={33}
            headerHeight={0}
            columns={window.innerWidth <= 800 ? columnsTab : columns}
            rows={list}
            pagination
            scrollbarSize={0}
            getRowId={(e) => e.id}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => (
                <GeniusHeader3
                  columns={window.innerWidth <= 800 ? columnsTab : columns}
                />
              ),

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />
        </div>

        <CustomerLedgerFooter
          rows={list}
          columns={window.innerWidth <= 800 ? columnsTab : columns}
        />

        <div className="flex justify-end mt-3">
          <PrintButton onClick={handlePrint} />
          {/* <ExitButton
            onClick={() => {
              navigate('/masters');
            }}
          /> */}
        </div>
      </div>

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setCustomerMasterHelpModStatus(false));
        }}
      />

      <div className="hidden">
        {selectedPrintOption === 'print' && windowSize ? (
          <div
            ref={printRef}
            style={{ display: 'block', visibility: 'hidden' }}
          >
            <PrintBillSummary
              data={list}
              columns={columns}
              firstDate={firstDate}
              SecondDate={SecondDate}
              bookingCustomerDetails={bookingCustomerDetails}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerLedger;
