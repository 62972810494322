import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { StyleColums } from './datagridCol';
import { MeasurmentStyleHeader } from '../utility/component/datagrid.js/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomerPrefix,
  selectEditType,
  selectModyingBookingID,
  selectRemarks,
  selectUpperBookingItemDetails,
  selectUpperDate,
  selectUpperMeasurementList,
  selectUpperMeasuremnt,
  selectUpperQnty,
  setEditType,
  setLowerQnty,
  setRemarks,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
  setUpperQnty,
} from '../utility/Redux/booking';

import moment from 'moment';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { getUpperMeasuremntDetails } from './list';
import { setAlertWithTitle } from '../utility/Redux/modal';
import { selectModifyBillingCartlist } from '../utility/Redux/billing';
import CapitalizeProperNouns from '../utility/component/functions/sentenceFormater';
import MeasuremtTags from './tags';
const UpperStyle = ({
  trydate,
  remarks,
  editCellValues,
  setEditCellValues,
  mrcNumber,
  parametrInput,
  setParameterInput,
  selectedRow,

  SetItemTabScreen,
  setStatus,
  setStyleType,
  bookingDate,
  alertTwoButtonModal,
  setAlertTwoButtonModal,
  lowerList,
  bookingCustomerDetails,
  setBookingCustomerDetails,
}) => {
  const upperDate = useSelector(selectUpperDate);

  const Remarks = useSelector(selectRemarks);
  const upperMeasurementDetails = useSelector(selectUpperMeasuremnt);
  const MasterPrifix = useSelector(selectTableMasterPrefix);
  const selectedUpperItemDetails = useSelector(selectUpperBookingItemDetails);

  const token = useSelector(selectToken);
  const measurmentList = useSelector(selectUpperMeasurementList);
  const width = window.innerWidth <= 1024 ? '20px' : '30px';
  const dispatch = useDispatch();
  const upperQnty = useSelector(selectUpperQnty);
  const modifyBillingCartlist = useSelector(selectModifyBillingCartlist);
  const modifiedBookingID = useSelector(selectModyingBookingID);
  const prefix = useSelector(selectPrefix);
  const customerPrefix = useSelector(selectCustomerPrefix);
  const editCell = ({ field, id, value }) => {
    const i = upperMeasurementDetails.findIndex((i) => i.CODE === id);
    const parameter = upperMeasurementDetails[i].PARAMETER;
    const PCODE = upperMeasurementDetails[i].CODE;
    const size = upperMeasurementDetails[i].size;

    const isParameter = measurmentList.some(
      (item) => item.PARAMETER === parameter
    );
    if (!size) {
      let data = upperMeasurementDetails.map((i, index) => {
        if (PCODE === i.CODE) {
          const row = {
            ...upperMeasurementDetails[index],
            size: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
          };

          return row;
        } else {
          if (parameter !== i.PARAMETER) {
            return upperMeasurementDetails[index];
          }
        }
      });

      dispatch(setUpperMeasuremnt(data));
    } else {
      if (size && value) {
        let list = upperMeasurementDetails;
        // let list2 = list.filter((item) => item.CODE !== PCODE);
        let list3 = list.map((item) => {
          if (item.CODE !== PCODE) {
            return item;
          } else {
            let data = {
              ...item,
              size: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
            };
            return data;
          }
        });

        dispatch(setUpperMeasuremnt(list3));
      } else {
        if (size && !value) {
          let list = upperMeasurementDetails;
          // let list2 = list.filter((item) => item.CODE !== PCODE);
          let list3 = list.map((item) => {
            if (item.CODE !== PCODE) {
              return item;
            } else {
              let data = {
                ...item,
                size: value
                  .replace(/\/2/g, '½')
                  .replace(/\/3/g, '¾')
                  .replace(/\/4/g, '¼')
                  .replace(/\//g, '½'),
              };
              return data;
            }
          });

          dispatch(setUpperMeasuremnt(list3));
        }
      }
    }
    if (!isParameter) {
      dispatch(
        setUpperMeasuremntList([
          ...measurmentList,
          {
            BOOKING_ORDER_NO: '',
            INVNO: '0',
            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: mrcNumber,
            ICODE: selectedUpperItemDetails.icode,
            ITEM_NAME: selectedUpperItemDetails.item_name,
            PARAMETER: parameter,
            LOWER_ICODE: selectedUpperItemDetails.lower_icode,

            SIZE: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
            REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
            SECTION_NAME: selectedUpperItemDetails.section_name,
            UPPER_LOWER: selectedUpperItemDetails.upper_lower,
            CODE: bookingCustomerDetails.code
              ? bookingCustomerDetails.code
              : '',
            CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
            MOBILE: bookingCustomerDetails?.mobile
              ? bookingCustomerDetails?.mobile
              : '',
            MOBILE_2: bookingCustomerDetails?.mobile_2
              ? bookingCustomerDetails?.mobile_2
              : '',
            MOBILE_3: bookingCustomerDetails?.mobile_3
              ? bookingCustomerDetails?.mobile_3
              : '',
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            PHONE: bookingCustomerDetails?.company_phone
              ? bookingCustomerDetails?.company_phoneL
              : '',
            ADDRESS: bookingCustomerDetails?.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails?.city
              ? bookingCustomerDetails?.city
              : '',
            STATE: bookingCustomerDetails?.state
              ? bookingCustomerDetails?.state
              : '',
            COUNTRY: bookingCustomerDetails?.country
              ? bookingCustomerDetails?.country
              : '',
            TRY_DATE: trydate ? moment(trydate).format('YYYY-MM-DD') : '',
            DEL_DATE: moment(upperDate?.del).format('YYYY-MM-DD'),
            REMARKS: remarks,
          },
        ])
      );
    } else {
      if (isParameter && value) {
        let list = measurmentList;
        let list2 = list.filter((item) => item.PARAMETER !== parameter);

        dispatch(
          setUpperMeasuremntList([
            ...list2,
            {
              BOOKING_ORDER_NO: '',
              INVNO: '0',
              DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
              MRC_NO: mrcNumber,
              ICODE: selectedUpperItemDetails.icode,
              ITEM_NAME: selectedUpperItemDetails.item_name,
              PARAMETER: parameter,
              LOWER_ICODE: selectedUpperItemDetails.lower_icode,

              SIZE: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
              REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
              SECTION_NAME: selectedUpperItemDetails.section_name,
              UPPER_LOWER: selectedUpperItemDetails.upper_lower,
              CODE: bookingCustomerDetails?.code
                ? bookingCustomerDetails.code
                : '',
              CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
              FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                ? bookingCustomerDetails?.family_mem_name
                : '',
              MOBILE: bookingCustomerDetails?.mobile
                ? bookingCustomerDetails?.mobile
                : '',
              MOBILE_2: bookingCustomerDetails?.mobile_2
                ? bookingCustomerDetails?.mobile_2
                : '',
              MOBILE_3: bookingCustomerDetails?.mobile_3
                ? bookingCustomerDetails?.mobile_3
                : '',
              PHONE: bookingCustomerDetails?.company_phone,
              ADDRESS: bookingCustomerDetails?.address
                ? bookingCustomerDetails.address
                : '',
              CITY: bookingCustomerDetails?.city
                ? bookingCustomerDetails?.city
                : '',
              STATE: bookingCustomerDetails?.state
                ? bookingCustomerDetails?.state
                : '',
              COUNTRY: bookingCustomerDetails?.country,
              TRY_DATE: trydate ? moment(trydate).format('YYYY-MM-DD') : '',
              DEL_DATE: moment(upperDate?.del).format('YYYY-MM-DD'),
              REMARKS: remarks,
            },
          ])
        );
      } else {
        if (isParameter && !value) {
          let list = measurmentList;
          let list2 = list.filter((item) => item.PARAMETER !== parameter);

          dispatch(setUpperMeasuremntList([...list2]));
          // setMeasurmentList([...list]);
        }
      }
    }
  };

  const editCell2 = (field, id, value) => {
    /// make diffrent function for modify

    const i = upperMeasurementDetails.findIndex((i) => i.CODE === id);
    const parameter = upperMeasurementDetails[i].PARAMETER;
    const PCODE = upperMeasurementDetails[i].CODE;
    const size = upperMeasurementDetails[i].size;

    const isParameter = measurmentList.some(
      (item) => item.PARAMETER === parameter
    );

    // if parameter is not present in
    let filterdParameter = upperMeasurementDetails.filter(
      (item) => item.PARAMETER !== parameter
    );

    if (!size) {
      let data = upperMeasurementDetails.map((i, index) => {
        if (PCODE === i.CODE) {
          const row = {
            ...upperMeasurementDetails[index],
            size: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
          };

          return row;
        } else {
          if (parameter !== i.PARAMETER) {
            return upperMeasurementDetails[index];
          }
        }
      });

      dispatch(setUpperMeasuremnt(data));
    } else {
      if (size && value) {
        let list = upperMeasurementDetails;
        // let list2 = list.filter((item) => item.CODE !== PCODE);
        let list3 = list.map((item) => {
          if (item.CODE !== PCODE) {
            return item;
          } else {
            let data = {
              ...item,
              size: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
            };
            return data;
          }
        });

        dispatch(setUpperMeasuremnt(list3));
      } else {
        if (size && !value) {
          let list = upperMeasurementDetails;
          // let list2 = list.filter((item) => item.CODE !== PCODE);
          let list3 = list.map((item) => {
            if (item.CODE !== PCODE) {
              return item;
            } else {
              let data = {
                ...item,
                size: value
                  .replace(/\/2/g, '½')
                  .replace(/\/3/g, '¾')
                  .replace(/\/4/g, '¼')
                  .replace(/\//g, '½'),
              };
              return data;
            }
          });

          dispatch(setUpperMeasuremnt(list3));
        }
      }
    }

    if (!isParameter) {
      dispatch(
        setUpperMeasuremntList([
          ...measurmentList,
          {
            BOOKING_ORDER_NO: '',
            INVNO: '0',
            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: mrcNumber,
            ICODE: selectedUpperItemDetails.icode,
            ITEM_NAME: selectedUpperItemDetails.item_name,
            PARAMETER: parameter,
            SIZE: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
            LOWER_ICODE: selectedUpperItemDetails.lower_icode,

            REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
            SECTION_NAME: selectedUpperItemDetails.section_name,
            UPPER_LOWER: selectedUpperItemDetails.upper_lower,
            CODE: bookingCustomerDetails?.code
              ? bookingCustomerDetails.code
              : '',
            CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
            MOBILE: bookingCustomerDetails?.mobile
              ? bookingCustomerDetails?.mobile
              : '',
            MOBILE_2: bookingCustomerDetails?.mobile_2
              ? bookingCustomerDetails?.mobile_2
              : '',
            MOBILE_3: bookingCustomerDetails?.mobile_3
              ? bookingCustomerDetails?.mobile_3
              : '',
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            PHONE: bookingCustomerDetails?.company_phone
              ? bookingCustomerDetails?.company_phone
              : '',
            ADDRESS: bookingCustomerDetails?.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails?.city
              ? bookingCustomerDetails?.city
              : '',
            STATE: bookingCustomerDetails?.state
              ? bookingCustomerDetails?.state
              : '',
            COUNTRY: bookingCustomerDetails?.country
              ? bookingCustomerDetails?.country
              : '',
            TRY_DATE: trydate ? moment(trydate).format('YYYY-MM-DD') : '',
            DEL_DATE: moment(upperDate?.del).format('YYYY-MM-DD'),
            REMARKS: remarks,
          },
        ])
      );
    } else {
      if (isParameter && value) {
        let list = measurmentList;
        let list2 = list.filter((item) => item.PARAMETER !== parameter);

        dispatch(
          setUpperMeasuremntList([
            ...list2,
            {
              BOOKING_ORDER_NO: '',
              INVNO: '0',
              DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
              MRC_NO: mrcNumber,
              ICODE: selectedUpperItemDetails.icode,
              ITEM_NAME: selectedUpperItemDetails.item_name,
              PARAMETER: parameter,
              LOWER_ICODE: selectedUpperItemDetails.lower_icode,

              SIZE: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
              REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
              SECTION_NAME: selectedUpperItemDetails.section_name,
              UPPER_LOWER: selectedUpperItemDetails.upper_lower,
              CODE: bookingCustomerDetails?.code
                ? bookingCustomerDetails.code
                : '',
              CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
              FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                ? bookingCustomerDetails?.family_mem_name
                : '',
              MOBILE: bookingCustomerDetails?.mobile
                ? bookingCustomerDetails?.mobile
                : '',
              MOBILE_2: bookingCustomerDetails?.mobile_2
                ? bookingCustomerDetails?.mobile_2
                : '',
              MOBILE_3: bookingCustomerDetails?.mobile_3
                ? bookingCustomerDetails?.mobile_3
                : '',
              PHONE: bookingCustomerDetails?.company_phone
                ? bookingCustomerDetails?.company_phone
                : '',
              ADDRESS: bookingCustomerDetails?.address
                ? bookingCustomerDetails.address
                : '',
              CITY: bookingCustomerDetails?.city
                ? bookingCustomerDetails?.city
                : '',
              STATE: bookingCustomerDetails?.state
                ? bookingCustomerDetails?.state
                : '',
              COUNTRY: bookingCustomerDetails?.country
                ? bookingCustomerDetails?.country
                : '',
              TRY_DATE: trydate ? moment(trydate).format('YYYY-MM-DD') : '',
              DEL_DATE: moment(upperDate?.del).format('YYYY-MM-DD'),
              REMARKS: remarks,
            },
          ])
        );
      } else {
        if (isParameter && !value) {
          let list = measurmentList;
          let list2 = list.filter((item) => item.PARAMETER !== parameter);

          dispatch(setUpperMeasuremntList([...list2]));
          // setMeasurmentList([...list]);
        }
      }
    }
  };

  useEffect(() => {
    if (
      selectedUpperItemDetails &&
      !upperMeasurementDetails[0] &&
      !modifiedBookingID
    ) {
      getUpperMeasuremntDetails(
        MasterPrifix,
        token,
        dispatch,
        bookingCustomerDetails,
        prefix,
        mrcNumber,
        selectedUpperItemDetails,
        '',
        trydate,
        upperDate?.del,
        Remarks,
        bookingDate,
        '',
        '',
        '',
        setAlertTwoButtonModal,
        alertTwoButtonModal,
        lowerList,
        customerPrefix
      );
    }
  }, [selectedUpperItemDetails]);

  const clear = () => {
    if (!modifyBillingCartlist[0]) {
      const emptyParametr = upperMeasurementDetails.map((i, index) => {
        const data = {
          ...i,
          size: '',
        };
        return data;
      });

      dispatch(setUpperMeasuremnt([...emptyParametr]));

      dispatch(setUpperMeasuremntList([]));
      dispatch(setUpperQnty(1));
    }
  };

  const editType = useSelector(selectEditType);

  useEffect(() => {
    if (editCellValues.field && editType === 'UPPER') {
      const field = editCellValues.field;
      const id = editCellValues.id;

      const value = parametrInput;

      editCell2(field, id, value);
    }
  }, [parametrInput]);

  return (
    <div className=" h-full flex flex-col w-full ">
      <div className="w-full flex">
        <div
          // variant={'contained'}
          style={{
            border: '1px solid #d1d1d1',
            backgroundColor: '#e8e2db',
            textTransform: 'none',
          }}
          className="w-full   bg-[#e8e2db] p-1 rounded-sm flex"
          onClick={() => {
            setStyleType('UPPER');
            setStatus(true);
          }}
        >
          <i className="bi bi-list text-[18px] text-black"></i>
          <div className="flex flex-1 w-full  justify-center text-gray-700 items-center font-semibold">
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '90%',
              }}
            >
              {selectedUpperItemDetails?.item_name
                ? selectedUpperItemDetails?.item_name
                : 'UPPER'}
            </div>
          </div>
          {selectedUpperItemDetails ? (
            <div className="hidden d:flex">
              <img
                src={require(`../utility/images/1.png`)}
                alt=""
                style={{
                  height: width,
                  width: width,
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="flex justify-between h-full ">
          <div
            style={{
              border: '1px solid #d1d1d1',
              borderRight: '1px solid #d1d1d1',
              borderTop: '1px solid #d1d1d1',
            }}
            className="flex items-center rounded-sm ml-2  "
          >
            <div className="flex  h-full items-center overflow-hidden">
              <div
                className=" px-2 h-full flex items-center "
                style={{
                  borderRight: '1px solid #d1d1d1',
                }}
                onClick={() => {
                  if (upperQnty > 1) {
                    dispatch(setUpperQnty(upperQnty - 1));
                    if (selectedUpperItemDetails?.lower_icode > 0) {
                      dispatch(setLowerQnty(upperQnty - 1));
                    }
                    // setQnty((prev) => Number(prev) - Number(1));
                  }
                }}
              >
                <i className="bi bi-dash"></i>
              </div>
              <input
                type={'number'}
                style={{
                  outline: '0px',
                  textAlign: 'center',
                }}
                maxLength={2}
                value={upperQnty}
                onChange={(e) => {
                  let length = e.target.value.length;

                  if (length < 3) {
                    if (e.target.value >= 1) {
                      dispatch(setUpperQnty(e.target.value));
                      if (selectedUpperItemDetails?.lower_icode > 0) {
                        dispatch(setLowerQnty(e.target.value));
                      }
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Qnty',
                        msg: 'Maximum 2 characters allowed',
                        lottie: 'reject',
                      })
                    );
                  }
                }}
                className=" flex w-8 h-full b"
              />
              <div
                className="hover:cursor-pointer h-full flex items-center  "
                style={{
                  borderLeft: '1px solid #d1d1d1',
                }}
              >
                <div
                  onClick={() => {
                    dispatch(setUpperQnty(Number(upperQnty) + Number(1)));
                    if (selectedUpperItemDetails?.lower_icode > 0) {
                      dispatch(setLowerQnty(Number(upperQnty) + Number(1)));
                    }
                    // setQnty((prev) => Number(prev) + Number(1));
                  }}
                  className=" px-2 flex justify-center items-center"
                >
                  <i className="bi bi-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          border: '1px solid #e8e2db',
          height: `calc(100% - 165px)`,
        }}
        className="flex-col  mt-2 overflow-hidden rounded-sm "
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
            borderBottomLeftRadius: '7px solid gray',
            borderBottomRightRadius: '7px solid gray',
          }}
          rowHeight={33}
          headerHeight={0}
          columns={StyleColums}
          rows={upperMeasurementDetails}
          hideFooter
          onCellClick={(e) => {
            if (e.field === 'size') {
              dispatch(setEditType('UPPER'));

              setEditCellValues({
                field: e.field,
                id: e.id,
                value: e.value,
              });

              setParameterInput(e.value);
              if (window.innerWidth <= 1280) {
                SetItemTabScreen(2);
              }
            }
          }}
          getRowId={(i) => i.CODE}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          selectionModel={selectedRow ? [selectedRow] : ''}
          onSelectionModelChange={(e) => {
            if (selectedRow) {
              const row = upperMeasurementDetails.filter(
                (r) => r.CODE === e[0]
              );

              dispatch(setEditType('UPPER'));
              setEditCellValues({
                field: 'size',
                id: row[0].CODE,
                value: row[0].size,
              });
              setParameterInput(row[0].size);
              if (window.innerWidth <= 1280) {
                SetItemTabScreen(2);
              }
            }
          }}
          editMode="cell"
          onCellEditCommit={editCell}
          disableColumnMenu={true}
          components={{
            Header: () => (
              <MeasurmentStyleHeader clear={clear} columns={StyleColums} />
            ),
            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                UPPER Parameter
              </div>
            ),
          }}
        />
      </div>
      <div
        style={{
          border: '1px solid #e8e2db',
        }}
        className=" w-full  h-[104px] rounded-sm mt-3 flex flex-col "
      >
        <div className=" -mt-[10px] ml-4 w-fit bg-white px-3 whitespace-nowrap  text-md text-gray-600">
          Upper Remarks
        </div>

        <textarea
          type="text"
          style={{
            resize: 'none',
          }}
          onClick={() => {
            dispatch(setEditType('U-REMARKS'));
            setParameterInput(Remarks?.upper);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent the default behavior
              const newValue = Remarks?.upper + '\n'; // Add a new line to the existing value
              dispatch(
                setRemarks({
                  ...remarks,
                  upper: newValue,
                })
              );
            }
          }}
          rows={window.innerWidth > 1600 ? 4 : 1}
          value={Remarks?.upper}
          maxLength={500}
          onChange={(e) => {
            const value = e.target.value;
            const text = value;

            dispatch(
              setRemarks({
                ...Remarks,
                upper: CapitalizeProperNouns(text),
              })
            );
          }}
          className="outline-0 pl-3 pt-2 flex flex-col grow   w-full  text-sm md:text-md rounded-sm overflowY-scroll "
        ></textarea>

        <MeasuremtTags
          onSelect={(e) => {
            dispatch(
              setRemarks({
                ...Remarks,
                upper: Remarks?.upper + '' + e + '',
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default UpperStyle;
