import React from 'react';
import { FormatNumber2 } from '../../utility/component/tools';

const Footer = ({ columns, rows }) => {
  return (
    <div className="flex  w-full overflow-x-scroll">
      <div className="flex">
        {columns.map((e, i) => {
          return (
            <div
              style={{
                width: e.width,
                borderRight: '1px solid #b5b3b3',
                borderTop: '1px solid #b5b3b3',
                borderLeft: i === 0 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',
                borderBottom: '1px solid #b5b3b3',
                borderBottomLeftRadius: i === 0 ? '7px' : '0px',
                borderTopLeftRadius: i === 0 ? '7px' : '0px',
                borderTopRightRadius: i === columns.length - 1 ? '7px' : '0px',
                borderBottomRightRadius:
                  i === columns.length - 1 ? '7px' : '0px',
              }}
              className="p-1 text-right "
            >
              {e.field === 'ADDRESS' && 'Total'}

              {e.field === 'TOTAL' &&
                FormatNumber2(
                  rows.reduce((acc, row) => acc + Number(row.TOTAL), 0)
                )}

              {e.field === 'GTOTAL' &&
                FormatNumber2(
                  rows.reduce((acc, row) => acc + Number(row.GTOTAL), 0)
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
