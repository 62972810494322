import React from 'react';

const GeniusError = ({ error }) => {
  return (
    <div className=" h-[14px] text-[13px] flex justify-end text-red">
      {error}
    </div>
  );
};

export default GeniusError;
