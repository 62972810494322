import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CryptoJS from 'crypto-js';
import {
  selectCompanyAddress,
  selectUserEmail,
} from '../utility/Redux/profile';
import { setSessionExpired, setloading } from '../utility/Redux/modal';
import { selectRemarks } from '../utility/Redux/booking';
import Lottie from 'react-lottie-player';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';

const UserInfoHelp = ({
  setUserDetails,
  setStatus,
  setUserCode,
  setoldPaswrd,
  setConfirmPaswrd,
  setUserHelpEmail,
  setEncryptedPassword,
  setSelectedUserStatus,
  setSelectedUserType,
  companyEmail,
}) => {
  const dispatch = useDispatch();
  const [searchText, SetSearchText] = useState('');
  const [filterdList, setFilteredList] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        type: 'string',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'User Name'}</div>,
      },
      {
        field: 'USER_EMAIL',
        type: 'number',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Email'}</div>,
      },
      {
        field: 'USER_TYPE',
        type: 'number',
        width: 200,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Type'}</div>,
      },
    ],
    []
  );
  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        type: 'string',
        width: 150,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'code'}</div>,
      },
      {
        field: 'USER_EMAIL',
        type: 'number',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'style'}</div>,
      },
    ],
    []
  );

  const companyDetail = useSelector(selectCompanyAddress);
  const userEmail = useSelector(selectUserEmail);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const getUserInfoDetails = () => {
    setloading(true);
    const email = userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    axios
      .post(
        `/api/users/userHelp`,
        {
          company_email: companyEmail ? companyEmail : companyDetail.email,
          user_email: userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getUserInfoDetails();
  }, []);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = list.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.USER_MOBILE.toString().includes(searchText);
        } else {
          return item.USER_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setFilteredList(searchItems);
    } else {
      setFilteredList('');
    }
  }, [searchText]);

  return (
    <div className="h-full w-full flex flex-col ">
      <div className="flex flex-col grow">
        {loading ? (
          <div className="w-full   flex justify-center items-center  ">
            <div className="w-6 h-6">
              <Lottie
                animationData={SpinnerLoading}
                loop={true}
                style={{ fontSize: 15 }}
                color="white"
              />
            </div>
          </div>
        ) : (
          <DataGrid
            hideFooterPagination
            hideFooter
            headerHeight={0}
            getRowId={(param) => param.CODE}
            onRowClick={(params) => {
              setStatus(false);

              setUserDetails({
                user_email: params.row.USER_EMAIL,
                user_password: '',
                user_name: params.row.USER_NAME,
                user_mobile: params.row.USER_MOBILE,
                user_type: params.row.USER_TYPE,
                user_status: params.row.USER_STATUS,
                company_name: params.row.COMPANY_NAME,
                code: params.row.CODE,
              });
              setoldPaswrd(params.row.USER_PASSWORD);
              setUserCode(params.row.CODE);
              setSelectedUserStatus(params.row.USER_STATUS);
              setSelectedUserType(params.row.USER_TYPE);
            }}
            components={{
              Header: () => {
                const headerDetails =
                  window.innerWidth < 770 ? columnsMobile : columns;
                return (
                  <div className="  bg-[#f5f0e8] flex ">
                    {headerDetails?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="  text-black flex justify-center items-center py-2  "
                          style={
                            item.flex
                              ? index < headerDetails.length - 1
                                ? {
                                    flex: item.flex,
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',

                                    // borderTop: '1px solid #4a4948',
                                  }
                                : {
                                    flex: item.flex,

                                    borderBottom: '1px solid black',
                                  }
                              : index < headerDetails.length - 1
                              ? {
                                  width: item.width,

                                  borderRight: '1px solid black',
                                  borderBottom: '1px solid black',
                                  // border: '1px solid black',
                                  // borderTop: '1px solid black',
                                }
                              : {
                                  width: item.width,
                                  borderBottom: '1px solid black',
                                }
                          }
                        >
                          {item.renderHeader()}
                        </div>
                      );
                    })}
                  </div>
                );
              },
            }}
            sx={
              window.innerWidth < 770
                ? {
                    backgroundColor: 'white',

                    fontSize: 9,
                    '& .super-app-theme--header': {
                      backgroundColor: '#f5f0e8',
                      borderRight: `1px solid #4a4948`,
                      display: 'none',
                    },
                    borderLeft: '1px solid black',
                    borderRight: '1px solid black',
                    borderRadius: '0px',
                  }
                : {
                    backgroundColor: 'white',

                    fontSize: 13,
                    borderLeft: '1px solid black',
                    borderRight: '1px solid black',

                    borderRadius: '0px',

                    '& .MuiDataGrid-cell': {
                      borderRight: `1px solid #918f8d`,
                      borderBottom: `1px solid #918f8d`, // Customize border color here
                    },

                    '& .super-app-theme--header': {
                      backgroundColor: '#f5f0e8',
                      borderRight: `1px solid #4a4948`,
                      display: 'none',
                    },
                  }
            }
            rowHeight={33}
            columns={window.innerWidth < 770 ? columnsMobile : columns}
            rows={filterdList[0] ? filterdList : list}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
      </div>

      <div
        style={{
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          borderBottom: '0px solid #4a4948',
          borderTop: '1px solid #4a4948',
          fontSize: '14px',
          backgroundColor: '#f5f0e8',
        }}
        className="flex p-2 "
      >
        <i className="bi bi-search pr-2"></i>
        <input
          type={'text'}
          placeholder="Search"
          value={searchText}
          onChange={(t) => {
            SetSearchText(t.target.value);
          }}
          className="w-full outline-0 bg-[#f5f0e8]"
        />
      </div>
    </div>
  );
};

export default UserInfoHelp;
