import React, { useState } from 'react';
import { Box, Button, Slide } from '@mui/material';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
const FullWidthModal = ({
  status,
  handleClose,
  orderDetails,
  departmentName,
  onSave,
  saveQnty,
  setSaveQnty,
}) => {
  const dep = departmentName.toLowerCase();
  const typeOfStage = dep.includes('wa')
    ? washing
    : dep.includes('cu')
    ? cutting
    : dep.includes('emb')
    ? embroidery
    : dep.includes('st')
    ? sttiching
    : dep.includes('bu')
    ? buttonstype
    : dep.includes('pr')
    ? press
    : dep.includes('re')
    ? ready
    : dep.includes('del')
    ? delivery
    : sttiching;
  return (
    <div className="flex justify-center">
      <Modal
        open={status}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Slide direction="up" in={status} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              height: 'auto',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: window.innerHeight - 60,
            }}
          >
            <div className=" t:min-w-[380px] w-full flex flex-col items-center justify-center">
              <div className="flex justify-between border-b  w-full t:w-auto px-4  border-gray-200 t:min-w-[380px]  pt-2 pb-2 ">
                <div className="text-[18px]  font-semibold flex">
                  Update Stage to {departmentName}
                </div>
                <button onClick={handleClose}>
                  <i className="bi text-red bi-x-square-fill"></i>
                </button>
              </div>
              <div className="mt-4 t:min-w-[380px]  w-full t:w-auto ">
                <div className="flex px-4">
                  <div className="w-[160px]">Booking Order </div>
                  <div className="mr-5">:</div>
                  <div>{orderDetails[0].BOOKING_ORDER_NO}</div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Item Name </div>
                  <div className="mr-5">:</div>

                  <div>
                    {orderDetails[0].ITEM_NAME}
                    {orderDetails[0].UPPER_LOWER === 'UPPER' ? '👕' : ' 👖'}
                  </div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Booking Date </div>
                  <div className="mr-5">:</div>

                  <div>{moment(orderDetails[0].DATE).format('YYYY-MM-DD')}</div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Try Date</div>
                  <div className="mr-5">:</div>

                  <div>
                    {orderDetails[0].TRY_DATE
                      ? moment(orderDetails[0].TRY_DATE).format('YYYY-MM-DD')
                      : ''}
                  </div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Del. Date</div>
                  <div className="mr-5">:</div>

                  <div>
                    {moment(orderDetails[0].DEL_DATE).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Order Count </div>
                  <div className="mr-5">:</div>

                  <div>{orderDetails[0].QNTY}</div>
                </div>
                <div className="flex mt-1 px-4 ">
                  <div className="w-[160px]">Stage </div>
                  <div className="mr-5">:</div>

                  <div className="flex">
                    {departmentName}
                    {<img src={typeOfStage} className="w-6 ml-2" />}
                  </div>
                </div>
                {/* <div className="flex mt-1 px-4 ">
                <div className="w-[160px] ">Qnty</div>
                <div className="mr-5">:</div>
                <select
                  type="select"
                  value={saveQnty}
                  onChange={(e) => setSaveQnty(e.target.value)}
                  className="outline-none text-center w-[60px]"
                >
                  {[...Array(orderDetails[0].QNTY)].map((o, i) => {
                    const index = i + 1;
                    return <option value={index}>{index}</option>;
                  })}
                </select>
              </div> */}
                <div className="flex justify-between mt-4 border-t p-2 ">
                  <Button
                    variant="contained"
                    onClick={() => {
                      onSave(orderDetails[0].BOOKING_ORDER_NO, orderDetails);
                    }}
                    style={{
                      textTransform: 'none',
                      letterSpacing: '1px',
                      backgroundColor: '#c1ea9f',
                    }}
                  >
                    <div className="text-black px-4 ">Update</div>
                  </Button>
                  <Button
                    // ref={updateRef}
                    onClick={handleClose}
                    variant="contained"
                    style={{
                      letterSpacing: '1px',
                      backgroundColor: '#fa8572',
                      textTransform: 'none',
                      marginLeft: '12px',
                    }}
                  >
                    <div className="text-black   ">Cancel </div>
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default FullWidthModal;
