import { Button } from '@mui/material';

const SaveButton = ({ onClick }) => {
  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#c1ea9f',
        }}
      >
        <div className="text-black px-5 py-0.5">Save</div>
      </Button>
    </>
  );
};

const PrintButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#bad4f9',
        marginLeft: '5px',
      }}
    >
      <div className="text-black px-4 py-0.5 ">Print </div>
    </Button>
  );
};

const UndoButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#616161',
        marginLeft: '5px',
        marginTop: '1px',
      }}
    >
      <div className="text-white px-4 py-0.5 ">Undo</div>
    </Button>
  );
};

const ExitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#f1f5f9',
          marginLeft: '5px',
        }}
      >
        <div className="text-black px-3  py-0.5">Exit </div>
      </Button>
    </>
  );
};

const ModifyButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#ebd9dd',
        }}
      >
        <div className="text-black px-3 py-0.5 ">Modify</div>
      </Button>
    </>
  );
};

 const DeleteButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#db3737',
        marginLeft: '5px',
        marginTop: '1px',
      }}
    >
      <div className="text-white px-4 py-0.5 ">Delete</div>
    </Button>
  );
};




export { SaveButton, PrintButton, UndoButton, ModifyButton, ExitButton , DeleteButton};
