import { Fade, Modal } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import ModalBody from '../utility/component/styles/modalBody';
import {
  selectModalMeasurementMasterStatus,
  setModalMeasurementMasterStatus,
} from '../utility/Redux/modal';
import GeniusMeasurementMaster from './geniusMeasurementMaster';

const ModalMeasurementMaster = () => {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectModalMeasurementMasterStatus);
  const handleClose = () => {
    dispatch(setModalMeasurementMasterStatus(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth <= 768 ? '95%' : 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '1px solid black',
  };

  return (
    <div>
      <Modal
        open={ModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={ModalStatus}>
          <div style={style} className={'rounded-lg overflow-hidden '}>
            <div className="w-full flex flex-col bg-white h-full ">
              <ModalHeaderStyle
                header={'Measurement Master'}
                onClose={handleClose}
              />

              <ModalBody>
                <GeniusMeasurementMaster />
              </ModalBody>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalMeasurementMaster;
