import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidth } from '../../Redux/profile';
import GeniustextInput from '../mastertext';
import { setCustomerMasterHelpModStatus } from '../../Redux/modal';
import CustomerMasterhelp from '../../../help/customermasterHelp';

const UpdateCustomer = ({
  modifyCustomerDetails,
  setModifyCustomerDetails,
  customerlist,
  modifyMobileAlert,
  setModifyMobileAlert,
}) => {
  const windowWidth = useSelector(selectWidth);
  const dispatch = useDispatch();

  return (
    <div className="border-[#b5b2b3] h-full border-r border-l border-b p-4">
      <div className="text-red text-center font-semibold text-[16px]">
        WARNING: You are about to update the mobile number of the customer
        record across all years. Proceed with caution! Data, once modified,
        cannot be reverted back.
      </div>
      <div className="font-semibold text-red text-[16px] mt-3 mb-2">
        Current Details
      </div>

      <div className="flex w-full pb-4">
        <div className="w-full ">
          <div className="flex">
            <GeniustextInput
              title={'Mobile'}
              type={'mobile'}
              value={modifyCustomerDetails.OLD_MOBILE}
              readOnly={true}
              search={() => {
                dispatch(setCustomerMasterHelpModStatus(true));
              }}
              autofocus={true}
              width={windowWidth < 768 ? '160px' : '150px'}
            />
            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 2'}
                type={'mobile'}
                value={modifyCustomerDetails.OLD_MOBILE_2}
                readOnly={true}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>

            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 3'}
                type={'mobile'}
                value={modifyCustomerDetails.OLD_MOBILE_3}
                readOnly={true}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>
          </div>

          <div>
            <GeniustextInput
              title={'Customer Name'}
              type={'text'}
              value={modifyCustomerDetails.NAME}
              readOnly={true}
              width={windowWidth < 768 ? '160px' : '466px'}
            />
          </div>

          <div className="flex">
            <GeniustextInput
              title={'Address'}
              value={modifyCustomerDetails.ADDRESS}
              readOnly={true}
              width={
                windowWidth < 768
                  ? '466px'
                  : window.innerWidth <= 1024
                  ? '466px'
                  : '466px'
              }
              type={'text'}
            />
          </div>
          <div className="flex">
            <GeniustextInput
              title={'City'}
              type={'text'}
              value={modifyCustomerDetails.CITY}
              readOnly={true}
              autofocus={true}
              width={windowWidth < 768 ? '160px' : '150px'}
            />
            <div className="ml-2">
              <GeniustextInput
                title={'STtete'}
                type={'text'}
                value={modifyCustomerDetails.STATE}
                readOnly={true}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>

            <div className="ml-2">
              <GeniustextInput
                title={'Country'}
                type={'text'}
                value={modifyCustomerDetails.COUNTRY}
                readOnly={true}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="font-semibold text-red text-[16px] mb-2">New Details</div>

      <div className="flex w-full pb-4">
        <div className="w-full ">
          <div className="flex">
            <GeniustextInput
              title={'Mobile'}
              type={'number'}
              value={modifyCustomerDetails.MOBILE}
              onChange={(e) => {
                if (e.target.value) {
                  const isMobileValid = true;
                  if (!isMobileValid) {
                    setModifyMobileAlert('Please enter a valid mobile');
                  }
                  const isOldCustomer = customerlist.filter((c) => {
                    if (e.target.value) {
                      if (c.mobile === e.target.value) {
                        return true;
                      }
                      if (c.mobile_2 === e.target.value) {
                        return true;
                      }
                      if (c.mobile_3 === e.target.value) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });

                  if (isOldCustomer[0]) {
                    setModifyMobileAlert('Mobile already registerd');
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE: e.target.value,
                      };
                    });
                  } else {
                    if (e.target.value.length >= 10) {
                      setModifyMobileAlert('');
                    } else {
                      setModifyMobileAlert('Invalid Mobile');
                    }
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE: e.target.value,
                      };
                    });
                  }
                } else {
                  setModifyMobileAlert('');
                  setModifyCustomerDetails((o) => {
                    return {
                      ...o,
                      MOBILE: e.target.value,
                    };
                  });
                }
              }}
              autofocus={true}
              width={windowWidth < 768 ? '160px' : '150px'}
            />
            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 2'}
                type={'number'}
                value={modifyCustomerDetails.MOBILE_2}
                onChange={(e) => {
                  const isMobileValid = true;
                  if (!isMobileValid) {
                    setModifyMobileAlert('Please enter a valid mobile');
                  }
                  const isOldCustomer = customerlist.filter((c) => {
                    if (e.target.value) {
                      if (c.mobile === e.target.value) {
                        return true;
                      }
                      if (c.mobile_2 === e.target.value) {
                        return true;
                      }
                      if (c.mobile_3 === e.target.value) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });

                  if (isOldCustomer[0]) {
                    setModifyMobileAlert('Mobile 2 already registerd');
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE_2: e.target.value,
                      };
                    });
                  } else {
                    setModifyMobileAlert('');
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE_2: e.target.value,
                      };
                    });
                  }
                }}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>

            <div className="ml-2">
              <GeniustextInput
                title={'Mobile 3'}
                type={'number'}
                value={modifyCustomerDetails.MOBILE_3}
                error={modifyMobileAlert}
                onChange={(e) => {
                  const isMobileValid = true;
                  if (!isMobileValid) {
                    setModifyMobileAlert('Please enter a valid mobile');
                  }
                  const isOldCustomer = customerlist.filter((c) => {
                    if (e.target.value) {
                      if (c.mobile === e.target.value) {
                        return true;
                      }
                      if (c.mobile_2 === e.target.value) {
                        return true;
                      }
                      if (c.mobile_3 === e.target.value) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });

                  if (isOldCustomer[0]) {
                    setModifyMobileAlert('Mobile 3 already registerd');
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE_3: e.target.value,
                      };
                    });
                  } else {
                    setModifyMobileAlert('');
                    setModifyCustomerDetails((o) => {
                      return {
                        ...o,
                        MOBILE_3: e.target.value,
                      };
                    });
                  }
                }}
                width={windowWidth < 768 ? '160px' : '150px'}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomerMasterhelp
        onRowClick={(p) => {
          dispatch(setCustomerMasterHelpModStatus(false));
          setModifyCustomerDetails((e) => {
            return {
              ...e,
              OLD_MOBILE: p.row.mobile,
              OLD_MOBILE_2: p.row.mobile_2,
              OLD_MOBILE_3: p.row.mobile_3,
              MOBILE: '',
              MOBILE_2: '',
              MOBILE_3: '',
              ADDRESS: p.row.address,
              NAME: p.row.customer_name,
              CITY: p.row.city,
              STATE: p.row.state,
              COUNTRY: p.row.country,
            };
          });
        }}
      />
    </div>
  );
};

export default UpdateCustomer;
