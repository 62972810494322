import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import {
  selectIsProductionModuleAllwed,
  selectPrefix,
  selectTableMasterPrefix,
  selectUserType,
} from '../utility/Redux/profile';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import { CallAPI } from '../utility/func/func';
import GeniustextInput from '../utility/component/mastertext';
import ModUserList from '../help/modUserList';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { SaveButton, UndoButton } from '../utility/component/SNAA_buttons';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import UserHelp from '../help/userHelp';
const ChnageDwefaultSetting = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userhelpStatus, setuserhelpStatus] = useState(false);
  const [defaultSettingEmail, setDefaultSettingEmail] = useState('');
  const [modifyBill, setModifyBill] = useState(false);
  const [sendWhatsapp, setSendWhatsapp] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [userMobile, setUserMobile] = useState('');
  const [userCode, setUserCode] = useState('');
  const navigate = useNavigate();
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const thisUser = useSelector(selectUserType);
  const [usertype, setUserType] = useState('USER');
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const isPrdoctionAllowed = useSelector(selectIsProductionModuleAllwed);
  const saveSystemDefaultSetting = () => {
    dispatch(setSpinnerLoading('Saving'));
    const data = {
      table_prefix_master: masterPrefix,
      CODE: userCode,
      USER_EMAIL: defaultSettingEmail,
      USER_TYPE: usertype,
      SEND_SMS: sendSms ? 'Y' : 'N',
      SEND_WHATSAPP: sendWhatsapp ? 'Y' : 'N',
      BILL_EDITING: modifyBill ? 'YES' : 'NO',
    };
    CallAPI(
      '/api/userSettings/changeUserDefaultSetting',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          dispatch(setSpinnerLoading('Saved Successfully'));
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data updated',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
        back();
      }
    );
  };
  const UserTypeOption =
    thisUser === 'ADMIN'
      ? [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
          {
            name: 'ADMIN',
            value: 'ADMIN',
          },
          {
            name: 'PRODUCTION',
            value: 'PRODUCTION',
          },
          {
            name: 'SUPERVISOR',
            value: 'SUPERVISOR',
          },
        ]
      : isPrdoctionAllowed
      ? [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
          {
            name: 'PRODUCTION',
            value: 'PRODUCTION',
          },
          {
            name: 'SUPERVISOR',
            value: 'SUPERVISOR',
          },
        ]
      : [
          {
            name: 'Select Type',
            value: '',
          },
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
        ];
  const back = async () => {
    setUserType('USER');
    setDefaultSettingEmail('');
    setSendSms(false);
    setSendWhatsapp(false);
    setUserMobile('');
    setModifyBill(false);
  };
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const getUserDefaultSetting = () => {
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      CODE: userCode,
      table_prefix_master: masterPrefix,
    };
    CallAPI(
      '/api/userSettings/userDefaultSetting',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            setUserType(res.data.response[0].USER_TYPE);

            setSendSms(res.data.response[0].SEND_SMS === 'Y' ? true : false);
            setSendWhatsapp(
              res.data.response[0].SEND_WHATSAPP === 'Y' ? true : false
            );
            setModifyBill(
              res.data.response[0].BILL_EDITING === 'YES' ? true : false
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'No data',
                msg: 'No data found',
              })
            );
          }
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  useEffect(() => {
    if (defaultSettingEmail || userMobile) {
      getUserDefaultSetting();
    }
  }, [defaultSettingEmail, userMobile]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const actualUserType = useSelector(selectUserType);
  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7]  overflow-y-scroll  max-sm:p-2">
      <div className="text-sm  rounded-sm bg-white mt-4 h-fit max-sm:w-full ">
        <div className="bg-white">
          <ModalHeaderStyle
            header={'User Default Settings'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>
        <div className="  bg-white border-brown px-5 max-sm:px-2">
          <div className="flex mt-4">
            <div>
              <GeniustextInput
                title={'User Email'}
                width={windowWidth > 764 ? '250px' : '150px'}
                type={'text'}
                search={() => setuserhelpStatus(true)}
                value={defaultSettingEmail}
              />
            </div>
            <div className="ml-3">
              <GeniustextInput
                title={'User Mobile'}
                width={windowWidth > 764 ? '250px' : '150px'}
                type={'number'}
                value={userMobile}
              />
            </div>
          </div>

          <div className="flex ">
            <div>
              <GeniustextInput
                title={'User Type'}
                width={windowWidth > 764 ? '180px' : '150px'}
                option={UserTypeOption}
                value={usertype}
                onChange={(e) => setUserType(e.target.value)}
              />
            </div>
          </div>
          <div className=" w-full max-sm:h-[220px] max-sm:overflow-y-scroll max-sm:rounded-sm border border-[#B5B3B3] l:border-0">
            {thisUser === 'MANAGER' && (
              <div className="flex w-full max-sm:block">
                <div className=" w-1/2 max-sm:w-full">
                  <div className="w-full flex">
                    {' '}
                    <div className="flex items-center ">
                      <GeniustextInput
                        //   width={windowWidth > 764 ? '180px' : '150px'}
                        checkBox={true}
                        hideError={true}
                        backgroundColor={sendWhatsapp ? 'green' : 'red'}
                        checked={sendWhatsapp}
                        color={sendWhatsapp ? 'success' : 'error'}
                        onChange={() => setSendWhatsapp((prev) => !prev)}
                      />
                    </div>
                    <div className="flex items-center">Send Whatsapp</div>
                  </div>
                </div>
                <div className=" w-1/2 max-sm:w-full">
                  <div className="w-full flex">
                    <div className="flex items-center ">
                      <GeniustextInput
                        //   width={windowWidth > 764 ? '180px' : '150px'}
                        checkBox={true}
                        hideError={true}
                        backgroundColor={sendSms ? 'green' : 'red'}
                        checked={sendSms}
                        color={sendSms ? 'success' : 'error'}
                        onChange={() => setSendSms((prev) => !prev)}
                      />
                    </div>
                    <div className="flex items-center ">Send SMS</div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex w-full max-sm:block">
              <div className=" w-1/2 max-sm:w-full">
                <div className="w-full flex">
                  {' '}
                  <div className="flex items-center ">
                    <GeniustextInput
                      //   width={windowWidth > 764 ? '180px' : '150px'}
                      checkBox={true}
                      hideError={true}
                      backgroundColor={modifyBill ? 'green' : 'red'}
                      checked={modifyBill}
                      color={modifyBill ? 'success' : 'error'}
                      onChange={() => setModifyBill((prev) => !prev)}
                    />
                  </div>
                  <div className="flex items-center ">Modify Bill</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end  py-5">
            <SaveButton
              onClick={() => {
                if (userCode) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: 'Save',
                    msg: 'Are you want to save this Data ?',
                    button1: 'Yes',
                    button2: 'No',

                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      saveSystemDefaultSetting();
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Please select user first.',
                    })
                  );
                }
              }}
            />
            <UndoButton onClick={back} />
          </div>

          <UserHelp
            status={userhelpStatus}
            onRowClick={(p) => {
              setuserhelpStatus(false);

              back().then(() => {
                setDefaultSettingEmail(p.row.USER_EMAIL);

                setUserMobile(p.row.USER_MOBILE);
                setUserCode(p.row.CODE);
              });
            }}
            handleClose={() => {
              setuserhelpStatus(false);
            }}
          />
          <AlertModalTwoButton
            details={alertTwoButtonModal}
            setAlertTwoButtonModal={setAlertTwoButtonModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ChnageDwefaultSetting;
