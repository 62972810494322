import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import { BillingCardFooter } from '../utility/component/datagrid.js/footer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBillingCartList,
  selectModifyBillingCartlist,
  setBillingCartList,
  setModifyBillcartList,
} from '../utility/Redux/billing';
import { FormatNumber2 } from '../utility/component/tools';
import ModBillDiscount from '../utility/component/modals/billDiscount';
import { useState } from 'react';
import ModalQntyEdit from '../utility/component/modals/updateQnty';
import { setAlertWithTitle } from '../utility/Redux/modal';

const BillCart = ({
  setVoucherModStatus,

  bookingCustomerDetails,
  setBookingCustomerDetails,
  mrcNumber,
}) => {
  const billingCartList = useSelector(selectBillingCartList);
  const [modQntyEditStatus, setModalQntyEditStatus] = useState(false);
  const [QntyEditID, setQntyEditID] = useState('');
  const dispatch = useDispatch();
  const [modBillDiscountStatus, setModalBillDiscountStatus] = useState(false);
  const StyleColums = [
    {
      field: 'BOOKING_ORDER_NO',
      type: 'string',
      align: 'center',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 6,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'BID'} </div>,
    },

    {
      field: 'ITEM_NAME',
      type: 'string',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 15
          : (window.innerWidth / 100) * 25,
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="text-white"> {'Item Name'} </div>
      ),
    },

    {
      field: 'QNTY',
      type: 'string',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 6,
      align: 'right',
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Qnty'} </div>,
    },

    {
      field: 'RATE',
      type: 'string',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 10
          : (window.innerWidth / 100) * 7,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Rate'} </div>,
    },

    {
      field: 'AMOUNT',
      type: 'string',
      flex: 1,
      align: 'right',
      renderCell: (params) => {
        return (
          <div className="flex items-center pr-4">
            <div>{FormatNumber2(params.value)}</div>
            <div
              onClick={() => {
                if (modifyBillingList[0]) {
                  let fiterdList = modifyBillingList.filter(
                    (item) => item.ID !== params.row.ID
                  );
                  dispatch(setModifyBillcartList(fiterdList));
                } else {
                  let fiterdList = billingCartList.filter(
                    (item) => item.ID !== params.row.ID
                  );
                  dispatch(setBillingCartList(fiterdList));
                }
              }}
              className="flex items-center hover:cursor-pointer"
            >
              <i className="bi flex items-center ml-4 text-red text-[17px] bi-x-circle"></i>
            </div>
          </div>
        );
      },

      editable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Amount'} </div>,
    },
  ];

  const onCellClick = (row) => {
    if (row.field === 'QNTY') {
      if (row.row.SECTION_NAME === 'Fabric') {
        setQntyEditID(row.id);
        setModalQntyEditStatus(true);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Oops!',
            msg: 'You can change only Fabric Quantity.',
          })
        );
      }
    }
  };

  const modifyBillingList = useSelector(selectModifyBillingCartlist);

  return (
    <div className="w-full flex flex-col h-full  ">
      <div className="flex flex-col grow">
        <div
          style={{
            border: '1px solid #e8e2db',
            borderBottom: '1px solid #e8e2db',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
          className="flex-col h-full   rounded-sm overflow-hidden"
        >
          <DataGrid
            sx={{
              border: '0px solid #e8e2db',
              fontSize: 14,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
            }}
            onCellDoubleClick={onCellClick}
            rowHeight={33}
            headerHeight={0}
            columns={StyleColums}
            rows={modifyBillingList[0] ? modifyBillingList : billingCartList}
            hideFooter
            getRowId={(i) => i.ID}
            scrollbarSize={0}
            rowsPerPageOptions={[100]}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            editMode="cell"
            //   onCellEditCommit={editCell}
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={StyleColums} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />

          {/* <div className="w-full"></div> */}
        </div>
        <div>
          <BillingCardFooter
            setModalBillDiscountStatus={setModalBillDiscountStatus}
            rows={modifyBillingList[0] ? modifyBillingList : billingCartList}
            columns={StyleColums}
            setVoucherModStatus={setVoucherModStatus}
            bookingCustomerDetails={bookingCustomerDetails}
            setBookingCustomerDetails={setBookingCustomerDetails}
            mrcNumber={mrcNumber}
          />
        </div>
      </div>
      <ModBillDiscount
        status={modBillDiscountStatus}
        setStatus={setModalBillDiscountStatus}
        bookingCustomerDetails={bookingCustomerDetails}
        setBookingCustomerDetails={setBookingCustomerDetails}
      />
      <ModalQntyEdit
        status={modQntyEditStatus}
        setStatus={setModalQntyEditStatus}
        editID={QntyEditID}
        mrcNumber={mrcNumber}
      />
    </div>
  );
};

export default BillCart;
