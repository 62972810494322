import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { parseISO } from 'date-fns';

import DatePicker from 'react-datepicker';
import { setCustomerMasterHelpModStatus } from '../Redux/modal';
import { OkButton } from './buttons';
const width = window.innerWidth / 100;

export const CollectionFromToDateMobile = ({
  firstDate,
  setFirstDate,
  SecondDate,
  setSecondDate,
  reset,
  bookingCustomerDetails,
  hideCustomer,
  setCustomerInfo,
  onOkClick,
}) => {
  //   const firstDate = useSelector(selectFirstDate);

  const dispatch = useDispatch();

  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState('');
  const userType = 'USER';
  return (
    <div
      style={{ borderRadius: '7px' }}
      className=" block h-full w-full t:hidden"
    >
      <div
        className="w-full"
        style={{
          border: '1px solid #b5b3b3',
          borderTopLeftRadius: '7px',
          borderTopRightRadius: '7px',
        }}
      >
        <div className=" flex flex-col   p-3">
          <div className="flex">
            <div
              style={{ fontSize: 13 }}
              className=" w-[80px] whitespace-nowrap text-[#4B4545] "
            >
              From Date
            </div>{' '}
            :
            <div
              style={{
                borderRadius: '7px',
                border: '1px solid #b5b3b3',
              }}
              className="flex ml-2 text-[#4B4545]  "
            >
              <div
                style={{
                  padding: '2px',
                }}
              >
                <DatePicker
                  onFocus={() => setfirstDateFocus(true)}
                  onClickOutside={() => setfirstDateFocus(false)}
                  onSelect={() => setfirstDateFocus(false)}
                  open={firstdateFocus}
                  className=" bg-transparent text-sm w-32 outline-0  "
                  dateFormat=" d MMM , yy "
                  selected={parseISO(firstDate)}
                  onChange={(date) =>
                    setFirstDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
              </div>

              <div
                className=" items-center flex justify-start px-2 "
                style={{ width: '20%' }}
              >
                <i
                  onClick={() => {
                    setfirstDateFocus(true);
                  }}
                  className="bi bi-calendar2-minus"
                  style={{ fontSize: '14px' }}
                ></i>
              </div>
            </div>
          </div>

          <div className="flex mt-2">
            <div
              style={{ fontSize: 13, borderRadius: '7px' }}
              className=" w-[80px] whitespace-nowrap text-[#4B4545] "
            >
              To Date
            </div>{' '}
            :
            <div
              style={{
                borderRadius: '7px',
                border: '1px solid #b5b3b3',
              }}
              className="flex  ml-2"
            >
              <div
                style={{
                  padding: '2px',
                }}
                className="   text-[#4B4545]  "
              >
                <DatePicker
                  onFocus={() => SetSecondDateFocus(true)}
                  onClickOutside={() => SetSecondDateFocus(false)}
                  onSelect={() => SetSecondDateFocus(false)}
                  open={secondDateFocus}
                  style={{ fontSize: 13 }}
                  className=" bg-transparent w-32 text-sm outline-0   "
                  dateFormat=" d MMM , yy "
                  selected={parseISO(SecondDate)}
                  onChange={(date) =>
                    setSecondDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
              </div>

              <div className=" items-center text-[#4B4545]  flex justify-start px-2 ">
                <i
                  onClick={() => {
                    SetSecondDateFocus(true);
                  }}
                  className="bi bi-calendar2-minus"
                  style={{ fontSize: '14px' }}
                ></i>
              </div>
            </div>
          </div>
          <div>
            <button
              className="bg-green mt-3 text-sm px-4 py-1 rounded-sm shadow-md"
              onClick={onOkClick}
            >
              {' '}
              Ok{' '}
            </button>
          </div>
        </div>
      </div>

      {!hideCustomer && (
        <div
          style={{
            border: '1px solid #b5b3b3',
            borderTop: '0px',
            height: '55px',
          }}
          className="flex w-full rounded-b-sm "
        >
          {!hideCustomer && (
            <div
              className="flex  h-full items-center px-2 "
              style={{ width: '30%' }}
            >
              <div
                onClick={() => dispatch(setCustomerMasterHelpModStatus(true))}
                className="flex hover:cursor-pointer h-full items-center"
              >
                <div>
                  <i
                    className="bi bi-search"
                    style={{ color: '#f44336', fontSize: 14 }}
                  ></i>
                </div>

                <span
                  style={{ fontSize: 12 }}
                  className=" text-[#5c5b5b]  pl-2 max-sm:pl-1 flex font-semibold items-center"
                >
                  Customer
                  <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                    Mobile
                  </span>
                </span>
              </div>
            </div>
          )}

          <div
            className=" ml-7 print:h-full  items-center  "
            style={{
              fontSize: 12,
              color: 'red',
              width: '60%',
            }}
          >
            <div
              className="h-1/2 w-full flex justify-center items-center"
              style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}
            >
              {bookingCustomerDetails.customer_name
                ? bookingCustomerDetails.customer_name
                : null}
            </div>
            <div
              className="h-1/2 w-full flex justify-center items-center"
              style={{
                border: '1px solid #b5b3b3',
                borderTop: '0px',
                borderBottom: '0px',
              }}
            >
              {bookingCustomerDetails.mobile
                ? bookingCustomerDetails.mobile
                : null}
            </div>
            {/* <span className="pl-4 font-semibold ">
            {bookingCustomerDetails.customer_name
              ? bookingCustomerDetails.customer_name +
                '  ' +
                '-' +
                ' ' +
                bookingCustomerDetails.mobile
              : null}
          </span> */}
          </div>

          <div
            className="px-2 flex justify-center items-center print:hidden"
            style={{ width: '10%' }}
          >
            <i
              className="bi bi-backspace-fill"
              style={{ color: '#f2666f', fontSize: 18 }}
              onClick={reset}
            ></i>
          </div>
        </div>
      )}
    </div>
  );
};
