import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import '../employeee/QRCodeScanner.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectDepartmentName,
  selectPrefix,
  selectTableMasterPrefix,
  selectUserEmail,
  selectWidth,
  setDepartmentName,
} from '../utility/Redux/profile';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { useNavigate } from 'react-router-dom';
import FullWidthModal from '../employeee/orderDetailsModal';
import { CallAPI } from '../utility/func/func';
import OrderStatusModal from './modalMobile';
import database from '../utility/component/functions/firebase';
import { child, get, onValue, ref } from 'firebase/database';

const OrderProductionHistory = ({ setDashboardType }) => {
  const [OrderNumber, setOrderNumber] = useState('');
  const [devices, setDevices] = useState('');
  const [alert, setAlert] = useState('');
  const scannerRef = useRef(null);
  const [orderDetails, setOrderDetails] = useState('');
  const lineRef = useRef(null);
  const [productionList, setProductionList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState();
  const dispatch = useDispatch();
  const [cameraList, setCameraList] = useState([]);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const prefix = useSelector(selectPrefix);
  const [fullWidthModalStatus, setFullWidthModalStatus] = useState(false);
  const token = useSelector(selectToken);
  const defaultCamera = localStorage.getItem('defaultCamera');
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const navigate = useNavigate();
  // h

  const animateScannerLine = () => {
    if (lineRef.current) {
      lineRef.current.style.display = 'block';
      // Optionally, you can use a CSS animation or requestAnimationFrame for smoother effect
    }
  };
  //test
  // Convert the HMAC to a string and encode it using Base64

  const checkOrder = (QR_CODE) => {
    const data = {
      table_prefix: prefix,
      QR_CODE: QR_CODE,
    };
    axios
      .post('/api/production/OrderProductionHistory', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        if (p.data.response[0]) {
          setFullWidthModalStatus(true);
          setOrderDetails(p.data.response);
        } else {
          setAlertTwoButtonModal({
            title: `Not Found`,
            msg: `Order Not Found in production`,
            status: true,
            setStatus: '',
            button1: 'Ok',
            button1Click: () => {
              setAlertTwoButtonModal({
                title: '',
                msg: '',
                status: false,
                setStatus: '',
                button1: '',
                button1Click: '',
                button2: '',
                button2Click: '',
              });
              navigate('/');
            },
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  const getProductionList = () => {
    const data = {
      table_prefix: prefix,
    };
    axios
      .post('/api/production/listQr', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        if (!p.data.error) {
          setProductionList(p.data.response);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };
  useEffect(() => {
    if (!devices) {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length > 0) {
            setCameraList(devices);
            if (defaultCamera) {
              const defautCameraAvailvl = devices.filter(
                (o) => o.id === defaultCamera
              );
              if (defautCameraAvailvl[0]) {
                setDevices(defautCameraAvailvl[0].id);
              } else {
                setDevices(devices[0].id);
              }
            } else {
              setDevices(devices[0].id);
            }
            // setDevices(devices[0].id);
          } else {
            console.error('No cameras found.');
          }
        })
        .catch((err) => {
          // const v = '886_UPPER_5_1';
          // const val = v.split('_');
          // checkOrder(v);
          // setOrderNumber(val[0]);
          console.error('Error fetching cameras:', err);
        });
    }
  }, [devices]);
  const windowWidth = useSelector(selectWidth);
  const config =
    windowWidth <= 1280
      ? {
          fps: 10,
          qrbox: { width: 200, height: 200 },
          // videoConstraints: {
          //   facingMode: { exact: 'environment' },
          // },
        }
      : {
          fps: 10,
          qrbox: { width: 200, height: 200 },
        };
  useEffect(() => {
    if (devices) {
      const html5QrCode = new Html5Qrcode('reader');

      html5QrCode
        .start(
          devices,
          config,
          (decodedText, decodedResult) => {
            const v = decodedText;
            const val = v.split('_');
            checkOrder(v);
            setOrderNumber(val[0]);

            animateScannerLine();
            if (decodedResult) {
              html5QrCode
                .stop()
                .then(() => {
                  console.log('Camera stopped successfully.');
                })
                .catch((err) => {
                  console.error('Error stopping the camera:', err);
                });
            }
          },
          (errorMessage) => {
            // console.warn(`QR Code no match: ${errorMessage}`);
          }
        )
        .catch((err) => {
          // console.error('Error starting Html5Qrcode:', err);
        });

      scannerRef.current = html5QrCode;
    }
  }, [devices]);
  useEffect(() => {
    getProductionList();
  }, []);

  useEffect(() => {
    const connectedRef = ref(database, `${masterPrefix}`);
    const connectedRef2 = ref(database, `${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          getProductionList();
        }
      });
    });
  }, []);
  const selectedCameras = devices
    ? cameraList.filter((o) => o.id === devices)
    : [{ id: '' }];
  const selectedCamera = selectedCameras[0].id;
  return (
    <div className="p-4">
      {true ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="flex p-2">
            <div className="font-semibold">Check Order Status</div>
          </div>
          {cameraList.length > 1 && (
            <div className="flex mb-2 ">
              <div className="w-[30px] ">
                <i className="bi bi-camera-fill"></i>
              </div>{' '}
              :
              <select
                onChange={(e) => {
                  localStorage.setItem('defaultCamera', e.target.value);
                  window.location.reload();
                }}
                className="w-[100px] outline-none overflow-hidden ml-2"
                value={selectedCamera}
              >
                <option></option>
                {cameraList.map((o) => {
                  return (
                    <option className="" value={o.id}>
                      {o.label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div
            id="readers"
            style={{
              width: '200px',
              height: '200px',
              position: 'relative',
            }}
          >
            <div className="scanner-line" ref={lineRef}></div>

            <div id="reader"></div>
          </div>
          Order ID : {OrderNumber}
          <div className=" flex flex-1 justify-center mt-10 items-center">
            <div className="flex items-center">
              <div className="flex font-semibold">Search by order :</div>
              <select
                onChange={(o) => {
                  const value = o.target.value;
                  const val = value.split('_');
                  checkOrder(value);
                  setOrderNumber(val[0]);
                }}
                onFocus={(e) => e.target.blur()} // Prevents auto-opening
                className="ml-2  outline-none py-2 px-3 bg-[#ebe8e8]"
              >
                <option value="">select</option>
                {productionList.map((o) => {
                  return <option value={o.QR_CODE}>{o.QR_CODE}</option>;
                })}
              </select>
            </div>
            <button
              onClick={() => {
                getProductionList();
              }}
              className="ml-1"
            >
              {' '}
              <i className="bi text-orange text-[20px] ml-2 bi-arrow-clockwise"></i>{' '}
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      {orderDetails[0] && (
        <OrderStatusModal
          orderDetails={orderDetails}
          status={fullWidthModalStatus}
          checkOrder={checkOrder}
          handleClose={() => {
            setFullWidthModalStatus(false);
            setDevices('');
            setOrderNumber('');
            setOrderDetails('');
          }}
        />
      )}
    </div>
  );
};

export default OrderProductionHistory;
