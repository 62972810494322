import React from 'react';

import Modal from '@mui/material/Modal';
import VoucherHelp from './voucherhelp';
import { BackButton } from '../utility/component/buttons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: '85%',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',
  // Change the shadow color
  overflow: 'hidden',
  // boxShadow: 'none',
};
const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '85%',
  padding: '2px',
  borderRadius: '8px',
  // Change the shadow color
  overflow: 'hidden',
};

export default function ModVoucherHelp({ status, onRowClick, setStatus }) {
  const handleClose = () => setStatus(false);

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={' overflow-hidden  '}
        >
          <div className="w-full flex flex-col  h-full    overflow: 'hidden',">
            <div
              style={{
                fontSize: '14px',
                border: '1px solid black',
                // borderBottom: '0px',
                overflow: 'hidden',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                borderBottom: '1px solid black',
              }}
              className="font-semibold bg-[#f5f0e8] font-arial p-3"
            >
              Voucher | Help
            </div>

            <div className="flex  flex-col grow  bg-white  ">
              <VoucherHelp onRowClick={onRowClick} />
              <div
                style={{
                  borderTop: '1px dotted black',
                  borderLeft: '1px dotted black',
                  borderRight: '1px dotted black',
                  borderBottom: '1px dotted black',
                  borderBottomRightRadius: '6px',
                  borderBottomLeftRadius: '6px',
                }}
                className=" flex justify-end p-2 bg-white"
              >
                <BackButton
                  onClick={() => {
                    setStatus(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
