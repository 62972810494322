import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {
  selectDefultProfileSetting,
  selectTableMasterPrefix,
  selectUserCode,
  setdefaultProfileSetting,
} from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../utility/Redux/modal';

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const defaultProfileSetting = useSelector(selectDefultProfileSetting);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();
  const UserTime = () => {
    if (expires >= date) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  const checkUserToken = () => {
    const userToken = localStorage.getItem('tkn');

    if (userToken !== '') {
      UserTime();
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  const emailencryted = localStorage.getItem('email');
  const secretPass = process.env.REACT_APP_SECRET_KEY;
  const tableMasterPrefix = useSelector(selectTableMasterPrefix);
  const getUserEmail = () => {
    if (emailencryted) {
      const bytes = CryptoJS.AES.decrypt(emailencryted, secretPass);
      if (bytes.sigBytes < 0) {
        localStorage.removeItem('prefix');
        localStorage.removeItem('expiresAt');
      } else {
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        var email = data;

        return email;
      }
    }

    return null;
  };
  const userCode = useSelector(selectUserCode);
  const getDefaultProfileSetting = () => {
    dispatch(setSpinnerLoading('getting User Profile'));
    axios
      .post(
        '/api/userSettings/userDefaultSetting',
        {
          CODE: userCode,
          table_prefix_master: tableMasterPrefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          dispatch(
            setdefaultProfileSetting(
              res.data.response[0]
                ? {
                    ...res.data.response[0],
                  }
                : {
                    SEND_SMS: 'N',
                    SEND_WHATSAPP: 'N',
                    CODE: userCode,
                    BILL_EDITING: 'NO',
                  }
            )
          );
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    checkUserToken();
    if (!defaultProfileSetting.SEND_SMS && userCode) {
      getDefaultProfileSetting();
    } else {
      if (!userCode) {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'User Code not found',
          })
        );
      }
    }
  }, [userCode]);
  return (
    <React.Fragment>
      {/* {props.children} */}

      {isLoggedIn ? props.children : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
