import moment from 'moment';
import { FormatNumber2 } from '../../utility/component/tools';

export const CustomerBalanceReportColoumns = (width) => {
  const columns = [
    {
      field: 'CODE',
      accessor: 'CODE',
      type: 'string',
      headerAlign: 'center',
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: 'super-app-theme--header',

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: 'CUSTOMER_NAME',
      accessor: 'CUSTOMER_NAME',
      type: 'string',
      headerAlign: 'center',

      width: width < 768 ? 100 : 200,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Customer Name
        </div>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      headerAlign: 'center',

      width: width < 768 ? 100 : 200,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Mobile
        </div>
      ),
    },

    {
      field: 'DEBIT',
      accessor: 'DEBIT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Debit
        </div>
      ),
    },

    {
      field: 'CREDIT',
      accessor: 'CREDIT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Credit
        </div>
      ),
    },

    {
      field: 'BALANCE',
      accessor: 'BALANCE',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : '0'}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Balance
        </div>
      ),
    },
  ];
  return columns;
};
