import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { selectPrefix } from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';

import axios from 'axios';
import { FormatNumber2 } from '../utility/component/tools';
import { CollectionFromToDate } from '../utility/component/collectionFromToDate';

import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  setBookingAttachImage,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../utility/Redux/booking';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import { setViewBillInvoiceId, setViewBillMod } from '../utility/Redux/billing';

import { CollectionFromToDateMobile } from '../utility/component/collectionFromToDateMobile';
import { PrintButton } from '../utility/component/SNAA_buttons';
import { useNavigate } from 'react-router-dom';
import PrintBillSummary from './printBillSummary';
import { BillSummaryFooter } from '../utility/component/datagrid.js/billSummaryFooter';
import { useReactToPrint } from 'react-to-print';
import CustomerMasterhelp from '../help/customermasterHelp';
const width = window.innerWidth / 100;

const BillSummery = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const dispatch = useDispatch();
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));

  const [customerInfo, setCustomerInfo] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 100000,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: 'Good',
    remarks: '',
  });
  const [list, setList] = useState([]);

  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    setCustomerInfo(params.row);
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  const columns = [
    {
      field: 'INVNO',
      type: 'number',
      width: width * 4,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      accessor: 'INVNO',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Invoice'}
        </div>
      ),
    },
    {
      field: 'BOOKING_ORDER_NO',
      accessor: 'BOOKING_ORDER_NO',
      type: 'number',
      width: width * 5,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'B.ID'}
        </div>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      width: width * 6,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return '';
        }
      },

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Date'}
        </div>
      ),
    },
    {
      accessor: 'CUSTOMER_NAME',
      field: 'CUSTOMER_NAME',
      type: 'string',
      width: width * 8.5,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Customer Name'}
        </div>
      ),
    },
    {
      accessor: 'FAMILY_MEM_NAME',
      field: 'FAMILY_MEM_NAME',
      type: 'string',
      width: width * 8.5,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Family Member'}
        </div>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Mobile'}
        </div>
      ),
    },

    {
      field: 'ADDRESS',
      accessor: 'ADDRESS',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => (
        <div>
          {params.row.ADDRESS + ',' + params.row.CITY + ',' + params.row.STATE}
        </div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Address'}
        </div>
      ),
    },

    {
      field: 'COMPANY_NAME',
      accessor: 'COMPANY_NAME',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Company Name'}
        </div>
      ),
    },

    {
      field: 'TOTAL',
      accessor: 'TOTAL',
      type: 'string',
      width: width * 8,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div> {params.value ? FormatNumber2(params.value) : null} </div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Total'}
        </div>
      ),
    },
    {
      field: 'ADVANCE_AMT',
      accessor: 'ADVANCE_AMT',
      type: 'string',
      width: width * 8,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div> {params.value ? FormatNumber2(params.value) : null} </div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Advance Amt.'}
        </div>
      ),
    },
    {
      field: 'GTOTAL',
      accessor: 'GTOTAL',
      type: 'string',
      width: width * 8,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div className="pr-4">
          {' '}
          {params.value ? FormatNumber2(params.value) : null}{' '}
        </div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Net Amount'}
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: 'INVNO',
      type: 'number',
      width: width * 14,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex text-center">
          {' '}
          {'Invoice'}
        </div>
      ),
    },
    {
      field: 'BOOKING_ORDER_NO',
      type: 'number',
      width: width * 14,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex text-center">
          {' '}
          {'B.ID'}
        </div>
      ),
    },

    {
      field: 'CUSTOMER_NAME',
      type: 'string',
      width: width * 28,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Customer Name'}
        </div>
      ),
    },

    {
      field: 'MOBILE',
      type: 'string',
      width: width * 22,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Mobile'}</div>,
    },

    {
      field: 'TOTAL',
      type: 'string',
      width: width * 17,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div> {params.value ? FormatNumber2(params.value) : null} </div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Total'}</div>,
    },
  ];

  const loadVocherAPI = (invNo) => {
    const data = {
      table_prefix: prefix,
      FDATE: firstDate,
      TDATE: SecondDate,
      MOBILE: customerInfo.mobile,
    };

    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(`/api/billing/billSummary`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          setList(response.data.response);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const navigate = useNavigate();

  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate('/bill_summary');
      setSelectedPrintOption('print');
    },
  });

  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-y-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            setCustomerInfo={setCustomerInfo}
            reset={() => {
              setCustomerInfo({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={customerInfo}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadVocherAPI();
            }}
            reset={() => {
              setCustomerInfo({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={customerInfo}
          />
        </div>
      )}
      <div className="flex flex-col grow mt-2 max-sm:mt-2 ">
        <div className="h-[360px]  l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 13,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },

              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            onRowDoubleClick={(param) => {
              dispatch(setViewBillMod(true));
              dispatch(setViewBillInvoiceId(param.id));
            }}
            rowHeight={33}
            headerHeight={0}
            columns={window.innerWidth <= 500 ? columnsMobile : columns}
            rows={list}
            pagination
            scrollbarSize={0}
            getRowId={(e) => e.INVNO}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => (
                <GeniusHeader3
                  columns={window.innerWidth <= 500 ? columnsMobile : columns}
                />
              ),

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />

          <BillSummaryFooter
            rows={list}
            columns={window.innerWidth <= 500 ? columnsMobile : columns}
          />
        </div>

        <div className="flex justify-end mt-16">
          <PrintButton onClick={handlePrint} />
        </div>
      </div>
      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setCustomerMasterHelpModStatus(false));
        }}
      />
      <div>
        <div className="hidden">
          {selectedPrintOption === 'print' && windowSize ? (
            <div
              ref={printRef}
              style={{ display: 'block', visibility: 'hidden' }}
            >
              <PrintBillSummary
                data={list}
                columns={columns}
                firstDate={firstDate}
                SecondDate={SecondDate}
                bookingCustomerDetails={customerInfo}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BillSummery;
