import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { selectPrefix } from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';

const RunningOrders = () => {
  const [list, setList] = useState([]);
  const [orderDetails, setOrdersDetails] = useState([]);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post(
        '/api/production/productionStatus',
        {
          table_prefix: prefix,
          STAGE_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (p.data.response[0]) {
          const data = p.data.response;
          setList(data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  }, []);

  const Columns = [
    {
      field: 'DATE',
      type: 'string',
      width: 120,
      renderCell: (params) => (
        <div>{moment(params.value).format('YYYY-MM-DD  ddd')}</div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Date'} </div>,
    },

    {
      field: 'STAGE_NAME',
      type: 'string',
      width: 130,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Stage'} </div>,
    },
    {
      field: 'NO_OF_ORDERS',
      type: 'string',
      width: 50,
      headerClassName: 'super-app-theme--header',
      renderCell: (p) => {
        return <div className="font-semibold">{p.value}</div>;
      },
      renderHeader: (params) => <div className="text-white"> {'Count'} </div>,
    },
    // {
    //   field: 'EMPLOYEE_MOBILE',
    //   type: 'string',
    //   width: 120,
    //   headerClassName: 'super-app-theme--header',
    //   renderHeader: (params) => <div className="text-white"> {'Mobile'} </div>,
    // },
  ];

  const Columns2 = [
    {
      field: 'DATE',
      type: 'string',
      width: 120,

      renderCell: (params) => (
        <div>{moment(params.value).format('YYYY-MM-DD ddd')}</div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Date'} </div>,
    },
    {
      field: 'BOOKING_ORDER_NO',
      type: 'string',
      width: 70,

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Order'} </div>,
    },
    {
      field: 'PREFIX',
      type: 'string',
      width: 50,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Prefix'} </div>,
    },
    {
      field: 'ITEM_NAME',
      type: 'string',
      width: 120,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Item'} </div>,
    },
    {
      field: 'CUSTOMER_NAME',
      type: 'string',
      width: 120,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="text-white"> {'Customer'} </div>
      ),
    },
    {
      field: 'MOBILE',
      type: 'string',
      width: 120,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Mobile'} </div>,
    },

    {
      field: 'MRC_NO',
      type: 'string',
      width: 60,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'MRC'} </div>,
    },
  ];

  const getDetails = (o) => {
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        '/api/production/OrdersInProduction',
        {
          table_prefix: prefix,
          STAGE_NAME: o,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        console.log(p);
        if (p.data.response[0]) {
          const data = p.data.response;
          setOrdersDetails(data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  return (
    <div className="p-3 h-full w-full flex">
      <div
        style={{
          width: Columns.reduce((acc, row) => acc + Number(row.width), 0),
        }}
        className=" h-full"
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
            borderBottomLeftRadius: '7px solid gray',
            borderBottomRightRadius: '7px solid gray',
          }}
          rowHeight={33}
          headerHeight={0}
          columns={Columns}
          rows={list}
          hideFooter
          getRowId={(i) => i.BOOKING_ORDER_NO}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          onRowClick={(p) => {
            getDetails(p.row.STAGE_NAME);
          }}
          disableColumnMenu={true}
          components={{
            Header: () => <GeniusHeader3 columns={Columns} />,
            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                No Result found
              </div>
            ),
          }}
        />
      </div>

      <div
        style={{
          width: Columns2.reduce((acc, row) => acc + Number(row.width), 0),
        }}
        className=" h-full ml-5"
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
            borderBottomLeftRadius: '7px solid gray',
            borderBottomRightRadius: '7px solid gray',
          }}
          rowHeight={33}
          headerHeight={0}
          columns={Columns2}
          rows={orderDetails}
          hideFooter
          getRowId={(i) => i.BOOKING_ORDER_NO}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          disableColumnMenu={true}
          components={{
            Header: () => <GeniusHeader3 columns={Columns2} />,
            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                No Result found
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default RunningOrders;
