import React from 'react';
import './qrCss.css';
import QRCodeGenerator from '../utility/component/qrCodegenerater';
import moment from 'moment';

const OrderWiseQrCode = React.forwardRef((props, ref) => {
  const { list } = props;
  console.log(list);
  return (
    <div id="print-container" ref={ref}>
      {list.map((data, index) => {
        return [...Array(data.qnty)].map(() => {
          const qrValue = data.qrCode;

          // if (Upper_lower === data.UPPER_LOWER) {
          //   qrValue = `${data.qrCode}_${data.UPPER_LOWER}_${data.ICODE}_${
          //     ind + 1
          //   }`;

          //   ind = ind + 1;
          // } else {
          //   Upper_lower = data.UPPER_LOWER;
          //   ind = 1;
          // }
          const customer = data?.CUSTOMER_NAME
            ? data?.CUSTOMER_NAME.length > 12
              ? data?.CUSTOMER_NAME?.split(' ')[0].toUpperCase().slice(0, 12)
              : data?.CUSTOMER_NAME
            : '';
          console.log(data);
          console.log(customer);

          return (
            <div key={index} className="print-page   w-[138px]">
              <div className="qr-slip ">
                <div className="h-[190px]"></div>
                <div className="flex flex-col w-full justify-center border-b pb-[12px] border-black items-center">
                  <div
                    className={` ${'text-[17px]'}font-semibold  text-center`}
                  >
                    {customer}
                  </div>
                  <div className="font-semibold text-[17px] text-center">
                    {data?.PREFIX}-{data?.MRC_NO}
                  </div>
                  <div className="font-semibold text-[17px] text-center">
                    {data?.ITEM_NAME?.toUpperCase().slice(0, 12) || ''}
                  </div>
                  <div className="font-semibold text-[17px] flex justify-center w-full text-center">
                    {moment(data?.DATE).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div className="pt-[16px] border-black ">
                  <QRCodeGenerator value={qrValue} />
                </div>
                <div className="text-[13px] flex justify-center w-full text-center">
                  {qrValue}
                </div>
              </div>
            </div>
          );
        });
      })}
    </div>
  );
});

export default OrderWiseQrCode;
