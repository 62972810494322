import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { SchemaValidateCompany } from './schemaValidate';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import TailringLogo from '../utility/images/tailiringLogo.svg';
import talringX from '../utility/images/Tailoring_Text.svg';
import Gicon from '../utility/images/G_Icon.svg';

import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckVerifyEmailAlert,
  setloading,
} from '../utility/Redux/modal';
import moment from 'moment';
import { Signup_button } from '../utility/component/buttons';
import AlertModal2 from '../utility/component/modals/alertWithTitle';
import AlertModal from '../utility/component/modals/alert';

export const DesktopCompnySignup = () => {
  const [companyBorder, setCompanyBorder] = useState('#6e6d6b');
  const [websiteBordeer, setwebsiteBordeer] = useState('#6e6d6b');

  const [companyNameBorder, setcompanyNameBorder] = useState('#6e6d6b');

  const [compnyMobileBorder, setCompnyMobileBorder] = useState('#6e6d6b');
  const [addressBorder, setaddressBorder] = useState('#6e6d6b');

  const [countryBorder, setcountryBorder] = useState('#6e6d6b');

  const [stateBorder, setStateBorder] = useState('#6e6d6b');

  const [cityBorder, setcityBorder] = useState('#6e6d6b');

  const [pincodeBorder, setpincodeBorder] = useState('#6e6d6b');

  const [userEmailBorder, setuserEmailBorder] = useState('#6e6d6b');
  const [userNameBorder, setuserNameBorder] = useState('#6e6d6b');
  const [userpaswrdBorder, setuserpaswrdBorder] = useState('#6e6d6b');
  const [userConfirmBorder, setuserConfirmBorder] = useState('#6e6d6b');

  const inputLabels = {
    sx: {
      fontSize: '14px',
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [isCompanyExist, setCompnyExist] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [companyEmail, setCmpnyEmail] = useState();
  const [allReadyAddedUser, setAllReadyAddedUser] = useState();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  function handleEnter(event) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);
      if (index !== 18) {
        form.elements[index + 2].focus();
      } else {
        form.elements[index + 2].focus();
      }
      if (index === 22) {
        form.elements[index + 3].focus();
      }

      event.preventDefault();
    }
  }

  const initialValues = {
    company: '',
    group: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    userEmail: '',
    userName: '',
    companyPassword: '',
  };

  const createFinacialYear = (code, token) => {
    const data = {
      company_code: code,
      company_email: values.email.toLowerCase(),
      company_name: values.company,
      year: moment().format('YYYY'),
    };
    axios
      .post(
        `/api/users/createFinancialYear`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log('');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowPassword(false), 1000);
    return () => clearTimeout(timer);
  }, [showPassword]);

  const countries = Country.getAllCountries();

  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaValidateCompany,
    onSubmit: (values) => {
      const text = values.company;
      const words = text.split(' ');

      const result =
        words.reduce((acc, word) => {
          if (word) {
            acc += word[0];
          }
          return acc;
        }, '') + '_';

      if (!isUserExist) {
        const data = {
          user_email: values.userEmail.toLowerCase(),
          user_password: values.password,
          user_name: values.userName,
          user_type: 'MANAGER',
          user_status: 'ACTIVE',
          company_email: values.email.toLowerCase(),
          company_name: values.company,
          company_mobile: values.mobile,
          company_website: values.group,
          company_logo: '',
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          pin_code: values.pincode,
          currency_name: 'rupee',
          currency_symbol: '₹',
          regd_no1: '',
          regd_no2: '',
          regd_no3: '',
          disputes: `All Disputes Subject to ${values.city}`,
          table_prefix: result.toLowerCase(),
          // start_date: moment().format('YYYY-MM-DD'),
          // end_date: moment().add(25, 'days').format('YYYY-MM-DD'),
        };
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        const email = values.userEmail;

        const hmac = CryptoJS.HmacSHA256(email, secretPass);

        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        axios
          .post(
            // `http://localhost:8080/api/users/registerCompany`,
            `/api/users/registerCompany`,

            data,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${hmacString}`,
              },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              // localStorage.setItem('user',JSON.stringify(values))'
              const code = response.data.results.insertId;
              const token = response.data.token;
              createFinacialYear(code, token);
              localStorage.clear('rememberd');
              navigate('/auth/login', {
                state: {
                  signupEmail: values.userEmail,
                },
              });
              dispatch(setCheckVerifyEmailAlert(true));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  });

  const CompanyInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const email = values.email;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    dispatch(setloading(true));
    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: values.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response.data[0]) {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Company allready Registered',
            })
          );
          values.email = '';
          // const companyDetails = response.data[0];
          // values.company = companyDetails.company_name;
          // values.mobile = companyDetails.company_mobile;
          // values.group = companyDetails.group_name;
          // values.city = companyDetails.city;
          // values.state = companyDetails.state;
          // values.address = companyDetails.address;
          // setCompnyExist(true);
          // setCmpnyEmail(values.email);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const userInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = values.userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    dispatch(setloading(true));

    axios
      .post(
        `/api/users/userInfo`,
        //  `http://localhost:8080/api/users/userInfo`,
        {
          user_email: values.userEmail.toLowerCase(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response.data.response[0]) {
          dispatch(setAlertMsg('User exist with same email '));
          dispatch(setALertStatus(true));
          values.userEmail = '';
          setAllReadyAddedUser(values.userEmail);
          setUserExist(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(values.email)) {
      CompanyInfo();
    }
    if (companyEmail !== values.email) {
      setCompnyExist(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (isCompanyExist) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(values.userEmail)) {
        userInfo();
      }
    }
    if (allReadyAddedUser !== values.userEmail) {
      setUserExist(false);
    } else {
      setUserExist(true);
    }
  }, [values.userEmail, values.email]);

  const handleSignup = () => {
    if (!values.email || errors.email) {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please enter company email',
        })
      );

      setCompanyBorder('red');
    } else {
      if (!values.company || errors.company) {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please enter company name',
          })
        );

        setcompanyNameBorder('red');
      } else {
        if (!values.group || errors.group) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Please enter Website',
            })
          );

          setwebsiteBordeer('red');
        } else {
          if (!values.address || errors.address) {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Please enter company address',
              })
            );

            setaddressBorder('red');
          } else {
            if (!values.country || errors.country) {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Please select country',
                })
              );

              setcountryBorder('red');
            } else {
              if (!values.state || errors.state) {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Please select state',
                  })
                );

                setStateBorder('red');
              } else {
                if (!values.city || errors.city) {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Please select city',
                    })
                  );

                  setcityBorder('red');
                } else {
                  if (!values.pincode || errors.pincode) {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Please enter pincode',
                      })
                    );

                    setpincodeBorder('red');
                  } else {
                    if (!values.mobile || errors.mobile) {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Please enter company email',
                        })
                      );
                      setCompnyMobileBorder('red');
                    } else {
                      if (!values.userEmail || errors.userEmail) {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'Please enter user email',
                          })
                        );

                        setuserEmailBorder('red');
                      } else {
                        if (!values.userName || errors.userName) {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Please enter user name',
                            })
                          );

                          setuserNameBorder('red');
                        } else {
                          if (!values.password || errors.password) {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Please enter password',
                              })
                            );

                            setuserpaswrdBorder('red');
                          } else {
                            if (
                              !values.confirmPassword ||
                              errors.confirmPassword
                            ) {
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Alert',
                                  msg: 'Please enter confirm password',
                                })
                              );

                              setuserConfirmBorder('red');
                            } else {
                              handleSubmit();
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (values.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === values.country
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [values.country]);
  useEffect(() => {
    if (values.state) {
      const selectedstate = stateList.filter(
        (state) => state.name === values.state
      );
      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [values.state]);

  useEffect(() => {
    if (values.email && !errors.email) {
      setCompanyBorder('#6e6d6b');
    }
    if (values.company && !errors.company) {
      setcompanyNameBorder('#6e6d6b');
    }

    if (values.group && !errors.group) {
      setwebsiteBordeer('#6e6d6b');
    }
    if (values.mobile && !errors.mobile) {
      setCompnyMobileBorder('#6e6d6b');
    }
    if (values.address && !errors.address) {
      setaddressBorder('#6e6d6b');
    }
    if (values.country && !errors.country) {
      setcountryBorder('#6e6d6b');
    }
    if (values.state && !errors.state) {
      setStateBorder('#6e6d6b');
    }
    if (values.city && !errors.city) {
      setcityBorder('#6e6d6b');
    }
    if (values.pincode && !errors.pincode) {
      setpincodeBorder('#6e6d6b');
    }
    if (values.userEmail && !errors.userEmail) {
      setuserEmailBorder('#6e6d6b');
    }
    if (values.userName && !errors.userName) {
      setuserNameBorder('#6e6d6b');
    }
    if (values.password && !errors.password) {
      setuserpaswrdBorder('#6e6d6b');
    }
    if (values.confirmPassword && errors.confirmPassword) {
      setcountryBorder('#6e6d6b');
    }
  }, [values]);

  return (
    <div className=" items-center flex  w-screen   h-screen  justify-center bg-white ">
      <div className={`  bg-white  rounded-lg   d:w-6/12 t:w-8/12    `}>
        <div>
          <div className="  h-[110%] ">
            <div className="flex   h-full">
              <div className=" flex flex-col  justify-center items-center   h-full w-full ">
                <div
                  style={{
                    height: '100px',
                    width: '100px',
                  }}
                >
                  <img src={TailringLogo} />
                </div>
                <div className="flex  justify-center  ">
                  <div className="  flex justify-center  ">
                    <div>
                      <div className="w-full   flex justify-center">
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            height: 'auto',
                            width: 134,
                            backgroundColor: 'white',
                            borderRadius: 100,
                          }}
                        >
                          <img src={talringX} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-3 font-semibold "> SignUp</div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex w-full  h-full mt-3 ">
            {/* company details */}
            <div className=" flex-1 ">
              <div
                style={{ fontSize: 15, fontWeight: '600' }}
                className="flex justify-center text-black font-sans   "
              >
                Company Details
              </div>

              <div
                // style={{
                //   height:
                //     window.innerWidth <= 1280
                //       ? window.innerHeight * 0.467
                //       : window.innerHeight * 0.315,
                // }}
                className="w-full mt-2 flex flex-col  justify-start  "
              >
                <div className="flex w-full">
                  <div className="w-full  ">
                    <div
                      style={{ height: '32px' }}
                      className="flex  items-center"
                    >
                      <TextField
                        onKeyDown={handleEnter}
                        size="small"
                        InputProps={{
                          sx: {
                            fontSize: '14px',
                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: `0.5px solid  ${companyBorder}`,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                        }}
                        InputLabelProps={inputLabels}
                        type="email"
                        name="email"
                        id="email"
                        label={'Company Email'}
                        onBlur={handleBlur}
                        error={errors.email ? true : false}
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.email}
                      />
                    </div>

                    <div
                      style={{ height: '32px' }}
                      className="flex  w-full items-center mt-4 "
                    >
                      <TextField
                        fullWidth
                        type="company"
                        name="company"
                        id="company"
                        label={'Company Name'}
                        InputProps={{
                          sx: {
                            fontSize: '14px',
                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: `0.5px solid ${companyNameBorder}`,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                        }}
                        InputLabelProps={inputLabels}
                        onKeyDown={handleEnter}
                        error={
                          isCompanyExist
                            ? false
                            : values.company && errors.company
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.company}
                        size={'small'}
                      />
                    </div>

                    <div
                      style={{ height: '32px' }}
                      className="flex mt-4 items-center"
                    >
                      <TextField
                        size={'small'}
                        onKeyDown={handleEnter}
                        type="group"
                        name="group"
                        id="outlined-controlled"
                        label={'Company Website'}
                        InputProps={{
                          sx: {
                            fontSize: '14px',
                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: `1px solid ${websiteBordeer}`,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                        }}
                        InputLabelProps={inputLabels}
                        onBlur={handleBlur}
                        error={
                          isCompanyExist
                            ? false
                            : values.group && errors.group
                            ? true
                            : false
                        }
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.group}
                      />
                    </div>
                    <div className="flex mt-4  items-center">
                      <div className="w-full">
                        <TextField
                          size={'small'}
                          type="number"
                          name="mobile"
                          id="mobile"
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${compnyMobileBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                          onKeyDown={handleEnter}
                          label={'Company Mobile'}
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.mobile && errors.mobile
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.mobile}
                        />
                      </div>
                    </div>

                    <div
                      style={{ height: '32px' }}
                      className="flex mt-4  items-center"
                    >
                      <div className="w-full">
                        <TextField
                          size={'small'}
                          type="address"
                          name="address"
                          id="address"
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${addressBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                          onKeyDown={handleEnter}
                          label={'Address'}
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.address && errors.address
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.address}
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 w-full items-center"
                      >
                        <div className=" w-full  flex items-center rounded-[3px] ">
                          <TextField
                            select
                            InputProps={{
                              sx: {
                                fontSize: '14px',
                                backgroundColor: 'white',
                                // Adjust the font size as per your preference
                                '& .MuiOutlinedInput-notchedOutline': {
                                  // Remove the default outline color
                                  border: `0.5px solid ${countryBorder}`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#03a9f4', // Change to your desired color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                  },
                              },
                            }}
                            InputLabelProps={inputLabels}
                            size="small"
                            id="country"
                            name="country"
                            label="Country"
                            value={values.country}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the height as needed
                                    maxWidth: 150,
                                  },
                                },
                              },
                            }}
                            fullWidth
                            onChange={handleChange}
                          >
                            {countries.map((e, index) => (
                              <MenuItem
                                id="country"
                                name="country"
                                label="Country"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            ))}

                            {/* Add more MenuItem components as needed */}
                          </TextField>
                        </div>
                        <div
                          style={{ height: 32 }}
                          className="flex   w-full ml-3  items-center"
                        >
                          <TextField
                            select
                            InputProps={{
                              sx: {
                                fontSize: '14px',
                                backgroundColor: 'white',
                                // Adjust the font size as per your preference
                                '& .MuiOutlinedInput-notchedOutline': {
                                  // Remove the default outline color
                                  border: `0.5px solid ${stateBorder}`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#03a9f4', // Change to your desired color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                  },
                              },
                            }}
                            InputLabelProps={inputLabels}
                            size="small"
                            id="state"
                            name="state"
                            label="State"
                            value={values.state}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the height as needed
                                    maxWidth: 150,
                                  },
                                },
                              },
                            }}
                            fullWidth
                            onChange={handleChange}
                          >
                            {stateList.map((e, index) => (
                              <MenuItem
                                id="country"
                                name="country"
                                label="Country"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            ))}

                            {/* Add more MenuItem components as needed */}
                          </TextField>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 w-full items-center"
                      >
                        <div className=" w-full">
                          <TextField
                            select
                            InputProps={{
                              sx: {
                                fontSize: '14px',
                                backgroundColor: 'white',
                                // Adjust the font size as per your preference
                                '& .MuiOutlinedInput-notchedOutline': {
                                  // Remove the default outline color
                                  border: `0.5px solid ${cityBorder}`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#03a9f4', // Change to your desired color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                  },
                              },
                            }}
                            InputLabelProps={inputLabels}
                            size="small"
                            id="city"
                            name="city"
                            label="City"
                            value={values.city}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the height as needed
                                    maxWidth: 150,
                                  },
                                },
                              },
                            }}
                            fullWidth
                            onChange={handleChange}
                          >
                            {cityList.map((e, index) => (
                              <MenuItem
                                id="city"
                                name="city"
                                label="City"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            ))}

                            {/* Add more MenuItem components as needed */}
                          </TextField>
                        </div>
                      </div>

                      <div
                        style={{ height: 32 }}
                        className="flex mt-4  w-full  ml-3 items-center"
                      >
                        <TextField
                          size={'small'}
                          type="text"
                          onKeyDown={handleEnter}
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${pincodeBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                          name="pincode"
                          id="pincode"
                          label={'Pin Code'}
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.pincode && errors.pincode
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.pincode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-10 flex justify-center">
              <div
                style={{
                  width: '1px',
                  backgroundColor: '#d4cfd2',
                }}
                className="h-[100%] "
              />
            </div>

            {/* user details */}
            <div className="flex-1  flex-col  ">
              <div className="flex flex-col h-full justify-between ">
                <div>
                  <div
                    style={{ fontSize: 15, fontWeight: '600' }}
                    className="flex justify-center text-black   "
                  >
                    User Details
                  </div>

                  <div className="w-full  mt-2  flex flex-col justify-start   ">
                    <div style={{ height: 32 }} className="w-full h-full ">
                      <TextField
                        size={'small'}
                        InputProps={{
                          sx: {
                            fontSize: '14px',
                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: `0.5px solid ${userEmailBorder}`,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                        }}
                        InputLabelProps={inputLabels}
                        type="email"
                        name="userEmail"
                        onKeyDown={handleEnter}
                        id="userEmail"
                        label={'User Email'}
                        onBlur={handleBlur}
                        error={
                          values.userEmail
                            ? errors.userEmail || isUserExist
                              ? true
                              : false
                            : false
                        }
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.userEmail}
                      />
                    </div>

                    <div className="flex w-full items-center ">
                      <div style={{ height: 32 }} className=" w-full mt-4">
                        <TextField
                          fullWidth
                          type="userName"
                          name="userName"
                          id="userName"
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${userNameBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                          onKeyDown={handleEnter}
                          label={'User Name'}
                          error={
                            values.userName && errors.userName ? true : false
                          }
                          onChange={handleChange}
                          // className={'bg-white'}
                          onSubmit={handleSubmit}
                          value={values.userName}
                          size={'small'}
                        />
                      </div>
                    </div>

                    <div className="flex mt-4 items-center ">
                      <div style={{ height: 32 }} className="w-full flex">
                        <TextField
                          size={'small'}
                          type={showPassword ? 'text' : 'password'}
                          fullWidth
                          error={
                            values.password && errors.password ? true : false
                          }
                          label={'Password'}
                          name="password"
                          onKeyDown={handleEnter}
                          id="password"
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${userpaswrdBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.password}
                        />
                      </div>
                    </div>

                    <div className="flex mt-4 w-full items-center">
                      <div style={{ height: 32 }} className="w-full ">
                        <TextField
                          size={'small'}
                          fullWidth
                          label={'Confirm Password'}
                          error={
                            values.confirmPassword && errors.confirmPassword
                              ? true
                              : false
                          }
                          type={showPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          onKeyDown={handleEnter}
                          id="confirmPassword"
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.confirmPassword}
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              backgroundColor: 'white',
                              // Adjust the font size as per your preference
                              '& .MuiOutlinedInput-notchedOutline': {
                                // Remove the default outline color
                                border: `0.5px solid ${userConfirmBorder}`,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#03a9f4', // Change to your desired color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                                },
                            },
                          }}
                          InputLabelProps={inputLabels}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={handleClickShowPassword}
                        className="text-black mt-2 text-[13px]"
                      >
                        Show Password
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex   ">
                  <div className="w-full   flex justify-center ">
                    <Signup_button onClick={handleSignup} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center text-sm mt-9">
            Already have an Account ?
            <div
              onClick={() => navigate('/auth/login')}
              className="text-orange font-medium pl-2"
            >
              Login
            </div>
          </div>
          <div className="mt-20 text-[13px] w-full justify-center text- text-brown flex">
            A product of{' '}
            <div className="pl-1 flex items-center">
              <img
                src={Gicon}
                style={{
                  width: '14px',
                  height: '14px',
                }}
                alt=""
              />
            </div>
            <label className="font-semibold pl-1 pr-1"> genius office</label>
            2023 All rights reserved.
          </div>
        </form>
        <AlertModal />
        <AlertModal2 />
      </div>
    </div>
  );
};
