import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import AppBarDesign from '../utility/component/styles/homeStyles';
import CustomeTwoButtonAlert from '../utility/component/modals/twoButtonAlert';

import { toProperCase } from '../utility/component/format';
import moment from 'moment';
import AlertModal from '../utility/component/modals/alert';

import CustomerMasterModal from '../utility/component/modals/customerMaster';
import SessionOutModal from '../utility/component/modals/SessionOutModal';
import AlertModal2 from '../utility/component/modals/alertWithTitle';

import OnlyLoading from '../utility/component/modals/loading';
import VoucherMasterMod from '../utility/component/modals/voucherMaster';
import { CalendarMonth } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
  resetProfile,
  selectCompany,
  selectDate,
  selectDepartmentName,
  selectPathHistory,
  selectTableMasterPrefix,
  selectUserType,
  selectWidth,
  setDate,
  setDepartmentName,
  setPathHistory,
  setWindowWidth,
  setlatitudeRedux,
  setlongitudeRedux,
} from '../utility/Redux/profile';
import ReactDatePicker from 'react-datepicker';
import { useState } from 'react';
import { parseISO } from 'date-fns';
import ViewBillModal from '../utility/component/modals/viewBillMod';
import CryptoJS from 'crypto-js';
import { selectToken, setLogin, setToken } from '../utility/Redux/security';
import CompanyMaster from '../masters/companyMaster';
import HsnMaster from '../masters/hsnMaster';
import ModalStyleMaster from '../masters/modStyleMaster';
import ModalMeasurementMaster from '../masters/modMeasuremntMaster';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import { HomeDashboard } from './HomeDashboard';
import AdminDashboard from '../admin/dashboard';
import { HandleIndexedDB, deleteWholeDatabase } from '../utility/func/func';
import { Collapse } from '@mui/material';
import EmployeeDashboard from '../employeee/dashboard';
import {
  setAlertWithTitle,
  setCustomeTwoButtomAlert,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import axios from 'axios';
import SuperVisorDahsboard from '../supervisor/dashboard';
import SuperVisorDahsboard2 from '../supervisor/dashboard2';
import SupervisorDashboard from '../supervisor/dashboard';
import EmployeeDepartmentList from '../help/employeeDepartmentList';
import FundDashboard from './fundDashboard';
import MangerDashoboard from './managerDashboard';
const Home = () => {
  const location = useLocation();
  const [isPathShow, setIsPathShown] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const navigate = useNavigate();
  const checkDateData = useSelector(selectDate);
  const [dateFocus, SetDateFocus] = useState(false);
  const dispatch = useDispatch();
  const emailencryted = localStorage.getItem('email');
  const secretPass = process.env.REACT_APP_SECRET_KEY;
  const secretPass2 = process.env.REACT_APP_AUTH_SECRET_KEY;
  const [departmenthelpStatus, setDepartmentHelpStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [expiredIn, setExpiredIn] = useState('');
  // const history = useHistory();

  const getUserEmail = () => {
    if (emailencryted) {
      const bytes = CryptoJS.AES.decrypt(emailencryted, secretPass);
      if (bytes.sigBytes < 0) {
        localStorage.removeItem('prefix');
        localStorage.removeItem('expiresAt');
      } else {
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        var email = data;
        setEmail(email);
        return email;
      }
    }

    return null;
  };

  const company = useSelector(selectCompany);

  useEffect(() => {
    getUserEmail();
  }, []);

  const userType = useSelector(selectUserType);

  useEffect(() => {
    const preventNavigation = (e) => {
      e.preventDefault();

      if (location.pathname === '/') {
        dispatch(setToken(''));
        dispatch(setLogin(false));
        localStorage.removeItem('stationName');
        localStorage.removeItem('stationId');
        localStorage.removeItem('billDetails');
        localStorage.removeItem('userName');
        localStorage.removeItem('count');
        localStorage.removeItem('tkn');
        localStorage.removeItem('company');
        localStorage.removeItem('histryId');
        localStorage.removeItem('prefix');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('orderdItemList');
        localStorage.removeItem('companyDetails');
        localStorage.removeItem('userType');
        localStorage.removeItem('email');

        dispatch(resetProfile());
        navigate('/auth/login');
        window.location.reload();
      }
      // You can redirect to another page here if needed
    };

    // Disables back button press for certain cases, such as when the user is on the home page
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', preventNavigation);

    return () => {
      window.removeEventListener('popstate', preventNavigation);
    };
  }, [location]);

  const pathHistory = useSelector(selectPathHistory);

  const removePath = (tab) => {
    const filterdPath = pathHistory.filter((data) => data.path !== tab.path);
    dispatch(setPathHistory(filterdPath));
    const databaseName = 'pathHistory';
    const storeName = 'data';
    const dataToSave = filterdPath;
    deleteWholeDatabase('pathHistory');
    if (!pathHistory[1]) {
      setIsPathShown(false);
    }
    HandleIndexedDB({ databaseName, storeName, dataToSave });
  };

  const addpath = (history) => {
    if (
      location.pathname !== '/masters' &&
      location.pathname !== '/reports' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/booking' &&
      location.pathname !== '/settings' &&
      location.pathname !== '/billing'
    ) {
      const isDup = history.filter((o) => o.path === location.pathname);
      if (!isDup[0]) {
        const list = [
          ...history,
          {
            ICODE: Math.random(),
            path: location.pathname,
          },
        ];
        const availvalSpace = Math.round(window.innerWidth / 220);
        const slice = availvalSpace - 1;
        const dataToSave = list.slice(-slice);
        const databaseName = 'pathHistory';
        const storeName = 'data';
        if (!pathHistory[0]) {
          setIsPathShown(true);
        }
        dispatch(setPathHistory(dataToSave));
        deleteWholeDatabase('pathHistory');
        HandleIndexedDB({ databaseName, storeName, dataToSave });
      }
    }
  };

  useEffect(() => {
    if (pathHistory[0]) {
      addpath(pathHistory);
    } else {
      const databaseName = 'pathHistory';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            dispatch(setPathHistory(data));
          }
          addpath(data);
        },
      });
    }
  }, [location.pathname]);
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(setlatitudeRedux(position.coords.latitude));
        dispatch(setlongitudeRedux(position.coords.longitude));
      });
    }
  }, []);
  const windowWidth = useSelector(selectWidth);
  useEffect(() => {
    const handleResize = () => {
      dispatch(setWindowWidth(window.innerWidth));
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
  const departMentName = useSelector(selectDepartmentName);
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);

  const hmac = CryptoJS.HmacSHA256(email, secretPass2);

  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const checkDepartment = () => {
    if (email) {
      const data = {
        user_email: email,
      };

      axios
        .post(`/api/users/userInfo`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        })
        .then((res) => {
          const userDetail = res.data.response[0];
          if (userDetail) {
            const mobile = userDetail.user_mobile;
            if (mobile) {
              dispatch(setSpinnerLoading('Checking employee'));
              const d = {
                table_prefix_master: masterPrefix,
                MOBILE: mobile,
              };
              axios
                .post(`/api/masters/findEmployeeDepartment`, d, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(async function (response) {
                  if (!response.data.error) {
                    if (response.data.response[0]) {
                      setDepartmentList(response.data.response);
                      if (!departMentName) {
                        setDepartmentHelpStatus(true);
                      }
                      // const dep = response.data.response[0].DEPARTMENT_NAME;
                      // dispatch(setDepartmentName(dep));
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Error 112',
                        msg: response.data.error,
                      })
                    );
                  }
                })
                .catch(function (error) {
                  if (error.response.data.name === 'TokenExpiredError') {
                    dispatch(setSessionExpired(true));
                    console.log(error.response);
                  }
                  console.log(error);
                  dispatch(
                    setAlertWithTitle({
                      title: 'ERROR',
                      msg: 'Something went wrong.',
                      lottie: 'reject',
                    })
                  );
                })
                .finally(() => {
                  dispatch(setSpinnerLoading(false));
                });
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  useEffect(() => {
    if (userType === 'PRODUCTION') {
      checkDepartment();
    }
  }, [userType, email]);

  useEffect(() => {
    if (!expiredIn) {
      const databaseName = 'userDetails';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            const endDate = moment(data[0].end_date).diff(
              moment(moment().format('YYYY-MM-DD')),
              'days'
            );
            if (endDate < 11) {
              setExpiredIn(endDate);
            } else {
              if (endDate < 4) {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: `License will expire in ${endDate} days.`,
                  })
                );
              }
            }
          }
        },
      });
    }
  }, []);

  return (
    <Box
      style={{
        paddingLeft: windowWidth >= 450 ? '49px' : '0px',
      }}
      className="flex h-screen w-screen  overflow-hidden"
    >
      <AppBarDesign email={email} />
      <div style={{ paddingTop: 48 }} className="w-screen">
        <div className="w-full h-full flex flex-col">
          <div className="w-full  bg-[#e8e2db] p-2 px-4 flex items-center   justify-between z-100">
            <div className="flex items-center justify-between   ">
              <span
                style={{
                  fontSize: 12,
                }}
                className="text-black font-semibold "
              >
                <div className="flex  items-center t:justify-evenly ml-2  z-40    ">
                  {departMentName && (
                    <div className="mr-1">{`[ ${departMentName}  ]`}</div>
                  )}

                  {location.pathname === '/'
                    ? 'Dashboard'
                    : toProperCase(
                        location.pathname
                          .substring(1)
                          .replace('_', ' ')
                          .replace('_', ' ')
                          .replace('_', ' ')
                      )}

                  {location.pathname === '/' ? (
                    <>
                      {' '}
                      <div className="flex pl-4">
                        <ReactDatePicker
                          readOnly={true}
                          open={
                            location.pathname === '/' ||
                            location.pathname === '/sale_desk_rooms'
                              ? dateFocus
                              : false
                          }
                          onFocus={() => SetDateFocus(true)}
                          onClickOutside={() => SetDateFocus(false)}
                          onSelect={() => SetDateFocus(false)}
                          className=" bg-transparent  text-left  w-28  mr-1  "
                          dateFormat=" d MMMM , yyyy "
                          selected={parseISO(
                            moment(checkDateData).format('YYYY-MM-DD')
                          )}
                          onChange={(date) => {
                            dispatch(
                              setDate(moment(date).format('YYYY-MM-DD'))
                            );
                          }}
                        />
                      </div>
                      <CalendarMonth
                        color="primary"
                        onClick={() => {
                          SetDateFocus(true);
                        }}
                      />
                    </>
                  ) : null}
                  {expiredIn && (
                    <div
                      style={{
                        letterSpacing: '2px',
                      }}
                      className="ml-4 font-semibold text-[#cf4848] "
                    >
                      - License will be expired in {expiredIn} days
                    </div>
                  )}
                </div>
              </span>
            </div>

            <div
              style={{
                fontSize: 13,
                paddingRight: '2px',
              }}
              className="text-[#4B4545] hidden t:flex items-center justify-between "
            >
              <label>
                {/* {companyDetails?.companyName} */}
                {company}
              </label>

              <div
                style={{
                  borderLeft: '1px solid black',
                }}
                className="h-4  mx-2  whitespace-nowrap flex items-center"
              />

              <label className="flex font-semibold text-black">
                {moment().format('dddd DD-MMM-YYYY')}
              </label>
              {!isPathShow && (
                <button
                  onClick={() => {
                    setIsPathShown((e) => !e);
                  }}
                  className=" bg-[#e8e2db]"
                >
                  <i
                    className={`bi px-2  flex items-center ${
                      isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                    }`}
                  ></i>
                </button>
              )}
            </div>
          </div>
          <Collapse in={isPathShow} timeout="auto" unmountOnExit>
            <div className="w-full flex bg-[#e8e2db] justify-between">
              <div className="flex">
                {pathHistory.map((o) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          location.pathname === o.path ? 'white' : '#e8e2db',
                        borderRight:
                          location.pathname === o.path
                            ? '1px solid black'
                            : '1px solid #bfbfbd',
                        borderTop:
                          location.pathname === o.path
                            ? '1px solid black'
                            : '1px solid #bfbfbd',
                      }}
                      className={`min-w-[220px] ${
                        location.pathname === o.path && 'font-semibold'
                      } flex rounded-t-sm   px-2 py-1 hover:cursor-pointer text-sm ${
                        o.path === location.pathname && 'rounded-t-sm'
                      }`}
                    >
                      <div
                        onClick={() => {
                          navigate(o.path);
                        }}
                        className="flex-1 text-[#5f5f5e] "
                      >
                        {o.path === '/'
                          ? 'Home'
                          : toProperCase(
                              o.path.replace('/', '').replace(/_/g, ' ')
                            )}
                      </div>
                      <div
                        onClick={() => {
                          removePath(o);
                        }}
                        className="flex items-center hover:bg-gray-300 rounded-sm p-[3px]"
                      >
                        <i className="bi bi-x flex items-center "></i>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button
                onClick={() => {
                  setIsPathShown((e) => !e);
                }}
                className=" bg-[#e8e2db]"
              >
                <i
                  className={`bi px-2  flex items-center ${
                    isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                  }`}
                ></i>
              </button>
            </div>
          </Collapse>
          {/* {userType} */}
          <div
            style={{
              height: `calc(100% - ${isPathShow ? 28 : 0}px)`,
            }}
            className="flex flex-col  overflow-hidden  w-full  bg-[#fdfbfb]"
          >
            {location.pathname === '/' ? (
              userType === 'USER' ? (
                <HomeDashboard />
              ) : userType === 'ADMIN' ? (
                <AdminDashboard />
              ) : userType === 'MANAGER' ? (
                <MangerDashoboard />
              ) : userType === 'PRODUCTION' ? (
                <EmployeeDashboard />
              ) : (
                <SupervisorDashboard />
              )
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>
      <VoucherMasterMod />
      <CustomeTwoButtonAlert />
      <AlertModal />

      <SessionOutModal />
      <CustomerMasterModal />
      <AlertModal2 />
      <OnlyLoading />
      <ViewBillModal />
      <HsnMaster />
      <ModalMeasurementMaster />
      <ModalStyleMaster />
      <SpinnerLoading />
      {window.innerWidth > 1280 ? (
        <Tooltip
          id="tip"
          style={{
            padding: 3,
            backgroundColor: '#f7dda4',
            color: 'black',
          }}
        />
      ) : null}
      {window.innerWidth > 1280 ? (
        <Tooltip
          id="tip2"
          opacity={100}
          style={{
            padding: 5,
            backgroundColor: '#383535',
            color: 'white',
            paddingLeft: 7,
          }}
        />
      ) : null}

      <EmployeeDepartmentList
        status={departmenthelpStatus}
        list={departmentList}
        onRowClick={(p) => {
          dispatch(setDepartmentName(p.DEPARTMENT_NAME));
          setDepartmentHelpStatus(false);
        }}
        handleClose={() => {
          dispatch(setCustomeTwoButtomAlert(true));
        }}
      />
    </Box>
  );
};

export default Home;
