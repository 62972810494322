import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidth } from '../utility/Redux/profile';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import { setSessionExpired, setloading } from '../utility/Redux/modal';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const UserListModal = ({ selectedData }) => {
  const width = useSelector(selectWidth);
  const dispatch = useDispatch();
  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 200 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_NAME',
      renderHeader: (params) => <div>User Name</div>,
    },
    {
      width:
        width <= 768 ? 150 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_EMAIL',
      renderHeader: (params) => <div>User Email</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 130 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_MOBILE',
      renderHeader: (params) => <div>User Mobile</div>,
    },

    {
      field: 'USER_TYPE',
      renderHeader: (params) => <div>User Type</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'COMPANY_NAME',
      renderHeader: (params) => <div>Company Name</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 180 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const companyEmail = selectedData.email;
  const userEmail = selectedData.userEmail;

  const [userList, setUserList] = useState([]);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const getUserInfoDetails = () => {
    setloading(true);
    const email = selectedData.userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: companyEmail,
      user_email: userEmail,
    };

    axios
      .post(`/api/users/userHelp`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setUserList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getUserInfoDetails();
  }, []);

  return (
    <div className="flex-grow  bg-white border-brown  px-3">
      <div className="flex  mt-2 ">
        <div className=" flex">
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="flex rounded-l-sm py-2 px-2"
          >
            <button>
              <i className="bi bi-search mr-2 text-orange ml-2 flex items-center"></i>
            </button>
            Company Name
          </div>
          <div
            style={{
              border: '1px dotted #b5b3b3',
              borderLeft: '0px dotted #b5b3b3',
            }}
            className="flex rounded-r-sm py-2 grow px-2 font-semibold"
          >
            {selectedData.companyName}
          </div>
        </div>
        <div className="flex ml-1">
          <div className=" flex ">
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2  px-2"
            >
              Email
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm grow py-2  px-2 font-semibold"
            >
              {selectedData.email}
            </div>
          </div>
          <div className="flex ml-1">
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2 w-fit px-4"
            >
              <i className="bi bi-people-fill  text-[#b4b4b1] "></i>
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm py-2 grow px-2 font-semibold"
            >
              {selectedData.tUser}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-2 h-[500px]">
        <GeniusDatagrid
          list={userList}
          rowID={(r) => r.CODE}
          columns={columns}
          showPagination={true}
        />
      </div>
    </div>
  );
};

export default UserListModal;
