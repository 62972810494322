import React, { useState } from 'react';
import { CollectionFromToDateMobile } from '../../utility/component/collectionFromToDateMobile';
import { CollectionFromToDate } from '../../utility/component/collectionFromToDate';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBookingCustomerDetails,
  setBookingAttachImage,
  setBookingCustomerDetails,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../../utility/Redux/booking';
import { DataGrid } from '@mui/x-data-grid';
import Footer from './footer';
import { CustomerBalanceReportColoumns } from './columns';
import { selectWidth } from '../../utility/Redux/profile';
import { GeniusHeader3 } from '../../utility/component/datagrid.js/header';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setModCustomerMasterModifyStatus,
} from '../../utility/Redux/modal';
import CustomerMasterhelp from '../../help/customermasterHelp';
const CustomerBalanceReport = () => {
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const dispatch = useDispatch();
  const width = useSelector(selectWidth);
  const columns = CustomerBalanceReportColoumns(width);
  const bookingCustomerDetails = useSelector(selectBookingCustomerDetails);
  const [list, setList] = useState([]);
  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    dispatch(setBookingCustomerDetails(''));
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    dispatch(setBookingCustomerDetails(params.row));
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };

  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-y-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}

      <div className="flex flex-col grow mt-2 max-sm:mt-2 w-fit">
        <div className="h-[360px]  l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 13,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },

              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            rowHeight={33}
            headerHeight={0}
            columns={columns}
            rows={list}
            pagination
            scrollbarSize={0}
            getRowId={(e) => e.INVNO}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={columns} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />

          <Footer rows={list} columns={columns} />
        </div>
      </div>

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setModCustomerMasterModifyStatus(false));
        }}
      />
    </div>
  );
};

export default CustomerBalanceReport;
