import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Lottie from 'lottie-react';
import sucess from '../../json/success2.json';
import reject from '../../json/rejected.json';
import {
  selectAlertWithTitleModalStatus,
  setAlertwithTitleModalStatus,
  selectAlertWithTitleDetails,
} from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/tailiringLogo.svg';
import { AlertOkButton, OkButton } from '../buttons';
import { Slide } from '@mui/material';

const width = window.innerWidth / 100;
export default function AlertModal2() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(selectAlertWithTitleModalStatus);
  const alertDetails = useSelector(selectAlertWithTitleDetails);

  const handleClose = () => dispatch(setAlertwithTitleModalStatus(false));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        event.preventDefault();

        // 👇️ your logic here
        dispatch(setAlertwithTitleModalStatus(false));
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div>
      <Modal open={alertStatus}>
        <div style={style} className=" w-full min-w-[250px] max-w-[300px]">
          <div className={'h-full w-full bg-white  rounded-sm  p-3'}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                {alertDetails.lottie ? (
                  <div className="w-full flex justify-center">
                    <Lottie
                      animationData={
                        alertDetails.lottie === 'success' ? sucess : reject
                      }
                      loop={false}
                      size={100}
                      style={{ fontSize: 15, width: '45px' }}
                      color="white"
                    />
                  </div>
                ) : (
                  <img
                    src={logo}
                    alt=""
                    style={{
                      width: '50px',
                      height: 'auto',
                    }}
                  />
                )}
              </div>
            </div>

            <div className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              {alertDetails?.title}
            </div>
            <div
              style={{
                fontSize: '13px',
              }}
              className="w-full justify-center text-center text-[#1c1a1a] flex mt-1 "
            >
              {alertDetails?.msg}
            </div>

            <div className="w-full flex justify-center mt-3">
              <AlertOkButton onClick={handleClose} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
