import React, { useRef, useState } from 'react';
import { CollectionFromToDateMobile } from '../../utility/component/collectionFromToDateMobile';
import { CollectionFromToDate } from '../../utility/component/collectionFromToDate';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBookingCustomerDetails,
  setBookingAttachImage,
  setBookingCustomerDetails,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
} from '../../utility/Redux/booking';
import { DataGrid } from '@mui/x-data-grid';
import { CustomerWiseSummaryColumns } from './columns';
import { GeniusHeader3 } from '../../utility/component/datagrid.js/header';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setModCustomerMasterModifyStatus,
} from '../../utility/Redux/modal';
import { CustomerLedgerFooter } from '../../utility/component/datagrid.js/customerLedgerFooter';
import { PrintButton } from '../../utility/component/buttons';
import PrintBillSummary from '../../summary/printBillSummary';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import CustomerMasterhelp from '../../help/customermasterHelp';
const CustomerWiseSummary = () => {
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const dispatch = useDispatch();
  const columns = CustomerWiseSummaryColumns();
  const bookingCustomerDetails = useSelector(selectBookingCustomerDetails);
  const [list, setList] = useState([]);

  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    dispatch(setBookingCustomerDetails(''));
    dispatch(setBookingAttachImage(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    dispatch(setBookingCustomerDetails(params.row));
    dispatch(setCustomerMasterHelpModStatus(false));
    dispatch(setHistoryOrderDetails(''));
  };
  const thermalPrintRef = useRef();
  const navigate = useNavigate();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate('/customer_wise_summary');
      setSelectedPrintOption('print');
    },
  });

  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-y-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            setSecondDate={setSecondDate}
            reset={() => {
              dispatch(
                setBookingCustomerDetails({
                  customer_name: '',
                  mobile: '',
                })
              );
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}

      <div className="flex flex-col grow mt-4">
        <div className="h-[380px] l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 12,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            rowHeight={33}
            headerHeight={0}
            columns={columns}
            rows={list}
            pagination
            scrollbarSize={0}
            getRowId={(e) => e.id}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={columns} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />
        </div>

        <CustomerLedgerFooter rows={list} columns={columns} />

        <div className="flex justify-end mt-3">
          <PrintButton onClick={handlePrint} />
        </div>
      </div>

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setModCustomerMasterModifyStatus(false));
        }}
      />

      <div className="hidden">
        {selectedPrintOption === 'print' && windowSize ? (
          <div
            ref={printRef}
            style={{ display: 'block', visibility: 'hidden' }}
          >
            <PrintBillSummary
              data={list}
              columns={columns}
              firstDate={firstDate}
              SecondDate={SecondDate}
              bookingCustomerDetails={bookingCustomerDetails}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerWiseSummary;
