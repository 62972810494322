import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const Booking = createSlice({
  name: 'booking',
  initialState: {
    upper_lower: 'UPPER',
    upperMeasuremnt: [],
    lowerMeasuremnt: [],
    bookingLowerItemDetails: '',
    bookingUpperItemDetails: '',
    bookingCustomerDetails: {
      code: '',
      customer_name: '',
      mobile: '',
      mobile_2: '',
      mobile_3: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pin_code: '',
      credit_limit: 100000,
      company_name: '',
      company_phone: '',
      company_email: '',
      company_address: '',
      company_city: '',
      company_state: '',
      company_country: '',
      company_pincode: '',
      regd_no1: '',
      regd_no2: '',
      regd_no3: '',
      status: 'Good',
      remarks: '',
    },
    upperMeasuremntList: [],
    lowerMeasuremntList: [],
    itemDetails: [],
    customerHistory: [],
    remarks: {
      lower: '',
      upper: '',
    },
    lowerDate: {
      try: '',
      del: moment().add(5, 'days').format('YYYY-MM-DD'),
      cutting: '',
    },
    upperDate: {
      try: '',
      del: moment().add(5, 'days').format('YYYY-MM-DD'),
      cutting: '',
    },
    lowerQnty: 1,
    upperQnty: 1,
    bookingNo: '',
    modifyingBookingList: [],
    modifyingBookingID: false,
    historOrderDetails: [],
    bookingModified: false,
    editType: '',
    customerFirebasePic: '',
    bookingAttachImage: '',
    customerPrefix: localStorage.getItem('bookingPrefix'),
  },

  reducers: {
    setUpperLower(state, action) {
      state.upper_lower = action.payload;
    },
    setCustomerPrefix(state, action) {
      state.customerPrefix = action.payload;
      localStorage.setItem(
        'bookingPrefix',
        action.payload
          ? action.payload !== 'undefined'
            ? action.payload
            : ''
          : ''
      );
    },
    setCustomerFirebasePic(state, action) {
      state.customerFirebasePic = action.payload;
    },
    setBookingAttachImage(state, action) {
      state.bookingAttachImage = action.payload;
    },
    setEditType(state, action) {
      state.editType = action.payload;
    },
    setBookingModifiedStatus(state, action) {
      state.bookingModified = action.payload;
    },
    setHistoryOrderDetails(state, action) {
      state.historOrderDetails = action.payload;
    },
    setModifyingBookingList(state, action) {
      state.modifyingBookingList = action.payload;
    },
    setModifyingBookingId(state, action) {
      state.modifyingBookingID = action.payload;
    },
    setBookingNumber(state, action) {
      state.bookingNo = action.payload;
    },
    setLowerQnty(state, action) {
      state.lowerQnty = action.payload;
    },
    setUpperQnty(state, action) {
      state.upperQnty = action.payload;
    },
    setLowerDate(state, action) {
      state.lowerDate = action.payload;
    },
    setUpperDate(state, action) {
      state.upperDate = action.payload;
    },
    setRemarks(state, action) {
      state.remarks = action.payload;
    },
    setCustomerHistory(state, action) {
      state.customerHistory = action.payload;
    },
    setItemDetails(state, action) {
      state.itemDetails = action.payload;
    },
    setLowerMeasuremntList(state, action) {
      state.lowerMeasuremntList = action.payload;
    },
    setUpperMeasuremntList(state, action) {
      state.upperMeasuremntList = action.payload;
    },
    setBookingCustomerDetails(state, action) {
      if (action.payload) {
        state.bookingCustomerDetails = action.payload;
      } else {
        state.bookingCustomerDetails = {
          code: '',
          customer_name: '',
          mobile: '',
          mobile_2: '',
          mobile_3: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: '',
          country: '',
          pin_code: '',
          credit_limit: 100000,
          company_name: '',
          company_phone: '',
          company_email: '',
          company_address: '',
          company_city: '',
          company_state: '',
          company_country: '',
          company_pincode: '',
          regd_no1: '',
          regd_no2: '',
          regd_no3: '',
          status: 'Good',
        };
      }
    },
    setSelctedBookingUpperitemDetails(state, action) {
      state.bookingUpperItemDetails = action.payload;
    },
    setSelectedBooingLowerItemDetails(state, action) {
      state.bookingLowerItemDetails = action.payload;
    },

    setSlectedItem(state, action) {
      state.selectedItem = action.payload;
    },
    setUpperMeasuremnt(state, action) {
      state.upperMeasuremnt = action.payload;
    },
    setLowerMeasuremnt(state, action) {
      state.lowerMeasuremnt = action.payload;
    },
  },
});

export default Booking.reducer;

export const {
  setUpperLower,
  setItemDetails,
  setBookingAttachImage,
  setCustomerFirebasePic,
  setEditType,
  setCustomerPrefix,
  setHistoryOrderDetails,
  setModifyingBookingId,
  setUpperDate,
  setModifyingBookingList,
  setUpperQnty,
  setRemarks,
  setUpperMeasuremntList,
  setBookingCustomerDetails,
  setSlectedItem,
  setCustomerHistory,
  setUpperMeasuremnt,
  setLowerMeasuremnt,
  setSelectedBooingLowerItemDetails,
  setSelctedBookingUpperitemDetails,
  setLowerMeasuremntList,
  setBookingModifiedStatus,
  setLowerDate,
  setLowerQnty,
  setBookingNumber,
} = Booking.actions;

export const selectUpperLower = (state) => state.booking.upper_lower;
export const selectUpperMeasuremnt = (state) => state.booking.upperMeasuremnt;
export const selectSelectedItem = (state) => state.booking.selectedItem;
export const selectLowerMeasuremnt = (state) => state.booking.lowerMeasuremnt;

export const selectUpperBookingItemDetails = (state) =>
  state.booking.bookingUpperItemDetails;

export const selectLowerBookingItemDetails = (state) =>
  state.booking.bookingLowerItemDetails;

export const selectBookingCustomerDetails = (state) =>
  state.booking.bookingCustomerDetails;

export const selectUpperMeasurementList = (state) =>
  state.booking.upperMeasuremntList;

export const selectLowerMeasuremntList = (state) =>
  state.booking.lowerMeasuremntList;
export const selectItemDetails = (state) => state.booking.itemDetails;
export const selectCustomerHistory = (state) => state.booking.customerHistory;
export const selectRemarks = (state) => state.booking.remarks;

export const selectLowerDate = (state) => state.booking.lowerDate;
export const selectUpperDate = (state) => state.booking.upperDate;
export const selectUpperQnty = (state) => state.booking.upperQnty;
export const selectLowerQnty = (state) => state.booking.lowerQnty;
export const selectBookingNumber = (state) => state.booking.bookingNo;

export const selectModyingBookingID = (state) =>
  state.booking.modifyingBookingID;

export const selectModifyingBookingList = (state) =>
  state.booking.modifyingBookingList;

export const selectHistoryOrderDetails = (state) =>
  state.booking.historOrderDetails;

export const selectModifiedBookingStatus = (state) =>
  state.booking.bookingModified;

export const selectEditType = (state) => state.booking.editType;
export const selectCustomerFirebasePic = (state) =>
  state.booking.customerFirebasePic;
export const selectBookingAttachImage = (state) =>
  state.booking.bookingAttachImage;
export const selectCustomerPrefix = (state) => state.booking.customerPrefix;
