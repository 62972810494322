import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ProfilePicFrame from '../utility/component/CMprofilepic';
import ImageUpload from '../utility/component/CMcustomerPic';
import { Country, State, City } from 'country-state-city';
import {
  ModifyButton,
  PrintButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';
import { toProperCase } from '../utility/component/format';
import {
  setAlertWithTitle,
  setModWorkerMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useFocus } from '../utility/hooks/hooks';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import GeniustextInput from '../utility/component/mastertext';
import {
  DownloadFile,
  UploadFile,
} from '../utility/component/functions/firebase';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import EmployeeMasterHelp from '../help/employeeMasterHelp';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';

const EmployeMaster = () => {
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState('');
  const [workerName, setWorkerName] = useState('');
  const [Status, setStatus] = useState('YES');
  const [fatherName, setFatherName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [sewingRate, setSewingRate] = useState('');
  const [cuttingRate, setCuttingRate] = useState('');
  const [mobileRef, setMobileFocus] = useFocus();
  const [workerRef, setWorkerFocus] = useFocus();
  const [emailRef, setEmailFocus] = useFocus();
  const [cuttingRef, setCuttingFocus] = useFocus();
  const [sevingRef, setSewingFocused] = useFocus();
  const [idNo, setIdNo] = useState('');
  const [firebaseImg, setFirebaseImg] = useState('');
  const [workerData, setWorkerData] = useState([]);
  const [tabScreen, setTabScreen] = useState(1);
  const width = useSelector(selectWidth);
  const [id, setId] = useState('');
  const windowWidth = useSelector(selectWidth);
  const webcamRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState('');
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [list, setList] = useState([]);

  const [selectedState, setSelectedState] = useState('');
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const countries = Country.getAllCountries();

  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const getData = (sec) => {
    dispatch(setSpinnerLoading('Loading Employee list'));
    const data = {
      table_prefix_master: masterprefix,
      MOBILE: '',
      CODE: '',
    };

    axios
      .post(`/api/masters/listEmployeeMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response?.data?.error) {
          setList(response.data.response);
          return response.data.response;
        }
      })
      .catch(function (error) {
        return null;
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
    return <div>error</div>;
  };
  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 110,
      headerAlign: 'center',
      align: 'left',
      field: 'code',
      renderHeader: (params) => <div className="text-[15px]">Code</div>,
    },
    {
      field: 'employee_name',
      renderHeader: (params) => <div className="text-[15px]">Worker Name</div>,
      width:
        width <= 768 ? 90 : width <= 1024 ? 100 : width <= 1440 ? 150 : 210,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'address',
      renderHeader: (params) => <div className="text-[15px]">Address</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 140 : width <= 1440 ? 140 : 140,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'mobile',
      renderHeader: (params) => <div className="text-[15px]">Mobile</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      if (sCountry[0]) {
        setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);
      if (sState[0]) {
        setCitylist(
          City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
        );
      }
    }
  }, [selectedState, stateList]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const prefix = useSelector(selectPrefix);
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.toLowerCase();
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const masterprefix = useSelector(selectTableMasterPrefix);

  const token = useSelector(selectToken);

  const saveEmployeeMasterApi = () => {
    if (workerName && mobile && cuttingRate && sewingRate && isValidEmail) {
      const isDuplicateMobile = workerData.some((row) => row.mobile === mobile);

      if (isDuplicateMobile && !id) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Mobile Number already exists. Please choose a different number.',
          })
        );
        setMobileFocus();
        return; // Exit the function without saving
      }

      const data = {
        table_prefix_master: masterprefix,
        CODE: id ? id : '',
        EMPLOYEE_NAME: workerName,
        FNAME: fatherName,
        EMAIL: email,
        MOBILE: mobile,
        PHONE: phone,
        ADDRESS: address,
        CITY: city,
        STATE: selectedState,
        COUNTRY: selectedCountry ? selectedCountry : '',
        PIN_CODE: pinCode,
        CUTTING_RATE: cuttingRate,
        SEWING_RATE: sewingRate,
        ID_NO: idNo,
        STOP_YN: Status ? Status : 'NO',
      };
      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/masters/saveEmployeeMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            if (capturedImage || selectedFile) {
              const underscoreIndex = prefix.indexOf('_');
              const result = prefix.slice(0, underscoreIndex) + '_';

              const name = `${result + mobile}`;
              const path = 'seam/employee/';
              const camera = capturedImage
                ? await fetch(capturedImage).then((response) => response.blob())
                : '';
              const file = selectedFile ? selectedFile : camera;
              UploadFile(file, path, name);
            }

            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated .',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved .',
                    lottie: 'success',
                  })
                );
            clearState();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!workerName) {
        setWorkerFocus();
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Worker Name.',
            lottie: 'reject',
          })
        );
      }
      if (!mobile) {
        setMobileFocus();
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Correct Mobile',
            lottie: 'reject',
          })
        );
      }
      if (!cuttingRate) {
        setCuttingFocus();
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Cutting Rate',
            lottie: 'reject',
          })
        );
      }
      if (!sewingRate) {
        setSewingFocused();
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Sewing Rate.',
            lottie: 'reject',
          })
        );
      }
      if (!isValidEmail) {
        setEmailFocus();
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Valid Email.',
            lottie: 'reject',
          })
        );
      }
    }
  };

  const clearState = () => {
    setId('');
    setMobile('');
    setWorkerName('');
    setFatherName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setCity('');
    setSelectedState('');
    setSelectedCountry('');
    setPinCode('');
    setSewingRate('');
    setCuttingRate('');
    setIdNo('');
    setStatus('YES');
    setSelectedFile('');
    setFirebaseImg('');
    setCapturedImage('');
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 14) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.altKey) && e.key === 's') {
        e.preventDefault();
        saveEmployeeMasterApi();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    id,
    mobile,
    workerName,
    sewingRate,
    cuttingRate,
    isValidEmail,
    address,
    city,
    selectedCountry,
    selectedState,
    pinCode,
    idNo,
    Status,
    phone,
    fatherName,
  ]);

  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();
  const headerRef = useRef(null);

  return (
    <div
      ref={formRef}
      className="w-full h-full flex justify-center bg-[#f5f5f7] max-sm:p-2  py-3 overflow-y-scroll"
    >
      <div
        className=" flex flex-col justify-center "
        style={{ borderRadius: '12px' }}
      >
        <div ref={headerRef} className="bg-white ">
          <ModalHeaderStyle
            header={'Employee Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>

        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="flex flex-col"
        >
          <div
            style={{
              width: width < 768 ? '100%' : '100%',
            }}
            className="  bg-white p-2  flex "
          >
            <div
              onClick={() => {
                setTabScreen(1);
              }}
              style={{
                color: tabScreen === 1 ? 'white' : 'gray',
              }}
              className={`flex-1 p-2 ${
                tabScreen === 1
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-l-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 1 ? 600 : 400,
                }}
                className=" h-full flex items-center"
              >
                Master
              </div>
            </div>
            <div
              style={{
                color: tabScreen === 2 ? 'white' : 'gray',
              }}
              onClick={() => {
                setTabScreen(2);
              }}
              className={`flex-1 p-2 ${
                tabScreen === 2
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-r-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 2 ? 600 : 400,
                }}
                className="  flex items-center"
              >
                Employee List
              </div>
            </div>
          </div>

          {tabScreen === 1 && (
            <div className=" h-[600px] overflow-y-scroll justify-center bg-white  p-3">
              <div className="  flex">
                <div>
                  <div>
                    <GeniustextInput
                      title={'Mobile'}
                      autofocus={true}
                      error={'mobile'}
                      search={() => {
                        dispatch(setModWorkerMasterHelpStatus(true));
                      }}
                      type={'number'}
                      refrance={mobileRef}
                      value={mobile}
                      onChange={(t) => {
                        if (t.target.value.length < 16) {
                          setMobile(t.target.value);
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Mobile Number',
                              msg: 'Please enter a valid  mobile number.',
                            })
                          );
                        }
                      }}
                      width={
                        windowWidth <= 768
                          ? '150px'
                          : windowWidth <= 1024
                          ? '304px'
                          : '400px'
                      }
                    />
                  </div>
                  <GeniustextInput
                    title={'Worker Name'}
                    type={'text'}
                    refrance={workerRef}
                    value={workerName}
                    onChange={(e) => {
                      setWorkerName(toProperCase(e.target.value));
                    }}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '400px'
                    }
                  />
                  <GeniustextInput
                    title={'Father Name'}
                    type={'text'}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '400px'
                    }
                    value={fatherName}
                    onChange={(e) => {
                      setFatherName(toProperCase(e.target.value));
                    }}
                  />
                </div>
                {
                  <div className=" ml-3 flex justify-end ">
                    <div className="w-full">
                      <div
                        style={{
                          width: windowWidth <= 768 ? '150px' : '190px',
                          height: windowWidth <= 768 ? '170px' : '190px',
                        }}
                      >
                        <ProfilePicFrame
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          firabseUrl={firebaseImg}
                          webcamRef={webcamRef}
                          webcamStatus={webcamStatus}
                          capturedImage={capturedImage}
                          setCapturedImage={setCapturedImage}
                          setWebcamStatus={setWebcamStatus}
                        />
                      </div>
                      <div
                        className="flex mt-2"
                        style={{
                          width: windowWidth <= 768 ? '150px' : '190px',
                        }}
                      >
                        <div className="flex w-[100%] items-center mr-1">
                          <ImageUpload
                            setCapturedImage={setCapturedImage}
                            setSelectedFile={setSelectedFile}
                            webcamRef={webcamRef}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="flex">
                <GeniustextInput
                  title={'Email'}
                  refrance={emailRef}
                  type={'text'}
                  error={'email'}
                  width={
                    windowWidth <= 768
                      ? '150px'
                      : windowWidth <= 1024
                      ? '304px'
                      : '400px'
                  }
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Phone'}
                    type={'number'}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '200px'
                    }
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex">
                <GeniustextInput
                  title={'Address'}
                  type={'text'}
                  width={
                    windowWidth <= 768
                      ? '150px'
                      : windowWidth <= 1024
                      ? '304px'
                      : '400px'
                  }
                  value={address}
                  onChange={(e) => {
                    setAddress(toProperCase(e.target.value));
                  }}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Pincode'}
                    type={'text'}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '200px'
                    }
                    value={pinCode}
                    onChange={(e) => {
                      setPinCode(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex">
                <GeniustextInput
                  title={'Country'}
                  country={true}
                  option={countries}
                  width={windowWidth <= 768 ? '150px' : '195px'}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'State'}
                    country={true}
                    option={stateList}
                    width={windowWidth <= 768 ? '150px' : '195px'}
                    value={selectedState}
                    onChange={handleStateChange}
                  />
                </div>
                {windowWidth >= 768 && (
                  <div className="ml-2">
                    <GeniustextInput
                      title={'City'}
                      country={true}
                      option={cityList}
                      width={windowWidth <= 768 ? '150px' : '200px'}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
              {windowWidth <= 768 && (
                <div>
                  <GeniustextInput
                    title={'City'}
                    country={true}
                    option={cityList}
                    width={windowWidth < 768 ? '150px' : '200px'}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </div>
              )}

              <div className="flex">
                <GeniustextInput
                  title={'Sewing Rate'}
                  refrance={sevingRef}
                  type={'number'}
                  width={
                    windowWidth <= 768
                      ? '150px'
                      : windowWidth <= 1024
                      ? '304px'
                      : '400px'
                  }
                  value={sewingRate}
                  onChange={(e) => {
                    setSewingRate(e.target.value);
                  }}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Cutting Rate'}
                    refrance={cuttingRef}
                    type={'number'}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '200px'
                    }
                    value={cuttingRate}
                    onChange={(e) => {
                      setCuttingRate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex">
                <GeniustextInput
                  title={'Id No.'}
                  type={'text'}
                  width={
                    windowWidth <= 768
                      ? '150px'
                      : windowWidth <= 1024
                      ? '304px'
                      : '400px'
                  }
                  value={idNo}
                  onChange={(e) => {
                    setIdNo(e.target.value);
                  }}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Active'}
                    width={
                      windowWidth <= 768
                        ? '150px'
                        : windowWidth <= 1024
                        ? '304px'
                        : '200px'
                    }
                    option={[
                      {
                        name: 'NO',
                        value: 'NO',
                      },
                      {
                        name: 'YES',
                        value: 'YES',
                      },
                    ]}
                    value={Status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {tabScreen === 2 && (
            <div className="h-[600px]  bg-white p-4">
              <GeniusDatagrid
                list={list}
                rowID={(o) => o.code}
                columns={columns}
              />
            </div>
          )}

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end max-sm:justify-between bg-white py-3 w-full px-4 "
          >
            {id ? (
              <ModifyButton onClick={saveEmployeeMasterApi} />
            ) : (
              <SaveButton onClick={saveEmployeeMasterApi} />
            )}
            <PrintButton />
            <UndoButton onClick={clearState} />
          </div>
        </div>
      </div>

      <EmployeeMasterHelp
        onRowClick={(p) => {
          setId(p.row.code);
          setMobile(p.row.mobile);
          setWorkerName(p.row.employee_name);
          setFatherName(p.row.fname);
          setEmail(p.row.email);
          setPhone(p.row.phone);
          setAddress(p.row.address);
          setCity(p.row.city);
          setSelectedState(p.row.state);
          setSelectedCountry(toProperCase(p.row.country));
          setPinCode(p.row.pin_code);
          setSewingRate(p.row.sewing_rate);
          setCuttingRate(p.row.cutting_rate);
          setIdNo(p.row.id_no);
          setStatus(p.row.stop_yn);
          dispatch(setModWorkerMasterHelpStatus(false));
          const underscoreIndex = prefix.indexOf('_');
          const result = prefix.slice(0, underscoreIndex) + '_';
          const b = `seam/employee/${result + p.row.mobile}`;
          DownloadFile(b)
            .then((e) => {
              if (!e.includes('FirebaseError')) {
                setFirebaseImg(e);
              } else {
                setFirebaseImg('');
              }
            })
            .catch(() => {
              console.log('clear customer catch');
              setFirebaseImg('');
            });
        }}
      />
    </div>
  );
};

export default EmployeMaster;
