import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import { useState } from 'react';

import axios from 'axios';
import { useEffect } from 'react';
import { useFocus } from '../utility/hooks/hooks';
import {
  setAlertWithTitle,
  setModWorkerMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import GeniustextInput from '../utility/component/mastertext';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/SNAA_buttons';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import { useNavigate } from 'react-router-dom';
import { toProperCase } from '../utility/component/format';
import Departmenthelp from '../help/departmenthelp';
import EmployeeMasterHelp from '../help/employeeMasterHelp';

const EmpDepartMaster = () => {
  const dispatch = useDispatch();
  const [departmentName, setDeaprtmentName] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [employeMobile, setEmployeeMobile] = useState('');
  const [employeeCode, setEmployeeCode] = useState('');
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);
  const [id, setId] = useState();
  const [departmemnthelpStatus, setDepartmentHelpStatus] = useState(false);
  const [secName, setSecName] = useState();
  const [rows, setRows] = useState([]);
  const [secError, setSecError] = useFocus();

  const columns = [
    {
      field: 'CODE',
      type: 'string',
      renderHeader: (params) => <div>Code</div>,
      width: 60,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'DEPARTMENT_NAME',
      type: 'string',
      renderHeader: (params) => <div>Department</div>,
      width: windowWidth > 764 ? 200 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'EMPLOYEE_NAME',
      type: 'string',
      renderHeader: (params) => <div>Employee Name</div>,
      width: windowWidth > 764 ? 200 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'MOBILE',
      type: 'string',
      renderHeader: (params) => <div>Mobile</div>,
      width: windowWidth > 764 ? 130 : 130,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];
  const masterPrefix = useSelector(selectTableMasterPrefix);

  const getData = async () => {
    // dispatch(setSpinnerLoading('Loading'));
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      table_prefix_master: masterPrefix,
      DEPARTMENT_NAME: departmentName,
    };
    await axios
      .post('/api/masters/listEmployeeInDepartment', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        setSecError();
        dispatch(setSpinnerLoading(false));
      });
  };
  const undo = () => {
    setDeaprtmentName('');
    setEmployeeCode('');
    setEmployeeName('');
    setEmployeeMobile('');
    setId('');
  };
  useEffect(() => {
    getData();
  }, [departmentName]);
  const saveSectionMasterApi = () => {
    if (employeeName) {
      const isDup = rows.filter(
        (r) =>
          r.EMPLOYEE_CODE === employeeCode &&
          r.DEPARTMENT_NAME === departmentName
      );

      if (isDup[0] && !id) {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Employee already exist',
          })
        );
      } else {
        const data = {
          table_prefix_master: masterPrefix,
          CODE: id ? id : '',
          EMPLOYEE_CODE: employeeCode,
          EMPLOYEE_NAME: employeeName,
          MOBILE: employeMobile,
          DEPARTMENT_NAME: departmentName,
        };

        id
          ? dispatch(setSpinnerLoading('Updating'))
          : dispatch(setSpinnerLoading('Saving'));
        axios
          .post(`/api/masters/saveEmployeeDepMaster`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async function (response) {
            if (!response.data.error) {
              id
                ? dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Updated successfully.',
                      lottie: 'success',
                    })
                  )
                : dispatch(
                    setAlertWithTitle({
                      title: 'Success.',
                      msg: 'Data Saved successfully.',
                      lottie: 'success',
                    })
                  );
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Error 112',
                  msg: response.data.error,
                })
              );
            }
          })
          .catch(function (error) {
            if (error.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
              console.log(error.response);
            }
            console.log(error);
            dispatch(
              setAlertWithTitle({
                title: 'ERROR',
                msg: 'Something went wrong.',
                lottie: 'reject',
              })
            );
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
            undo();

            getData();
          });
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please enter employee name',
        })
      );
    }
  };
  const navigate = useNavigate();
  return (
    <div className="flex justify-center pt-4">
      <div className=" bg-white  rounded-sm shadow-sm  border-brown ">
        <ModalHeaderStyle
          header={`Employee Department Master`}
          onClose={() => {
            navigate('/masters');
          }}
        />

        <div id="input" className="flex mt-4 px-5 ">
          <div>
            <GeniustextInput
              title={'Department Name'}
              search={() => {
                setDepartmentHelpStatus(true);
              }}
              width={windowWidth > 764 ? '400px' : '300px'}
              type={'text'}
              value={departmentName}
              readOnly={true}
              refrance={secError}
            />
          </div>
        </div>
        <div id="input" className="flex px-5 ">
          <div className="flex">
            <GeniustextInput
              title={'Employee Name'}
              width={
                columns.reduce((acc, row) => acc + Number(row.width), 0) - 180
              }
              type={'text'}
              search={() => {
                if (!id) {
                  if (departmentName) {
                    dispatch(setModWorkerMasterHelpStatus(true));
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Please select department first.',
                      })
                    );
                  }
                }
              }}
              value={employeeName}
              readOnly={true}
              refrance={secError}
            />
            <div id="input" className="flex ml-2 ">
              <div>
                <GeniustextInput
                  title={'Mobile'}
                  width={160}
                  type={'number'}
                  readOnly={true}
                  value={employeMobile}
                  refrance={secError}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="px-5 ">
          <div
            style={{
              width: columns.reduce((acc, row) => acc + Number(row.width), 0),
              height: window.innerHeight - 500,
            }}
            className="  mt-1 flex "
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setDeaprtmentName(p.row.DEPARTMENT_NAME);
                setEmployeeCode(p.row.CODE);
                setEmployeeName(p.row.EMPLOYEE_NAME);
                setEmployeeMobile(p.row.MOBILE);
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div
            style={{
              width: columns.reduce((acc, row) => acc + Number(row.width), 0),
            }}
            className="flex  justify-end items-center py-3"
          >
            {id ? (
              <ModifyButton onClick={saveSectionMasterApi} />
            ) : (
              <SaveButton onClick={saveSectionMasterApi} />
            )}

            <UndoButton
              onClick={() => {
                undo();
              }}
            />
          </div>
          <Departmenthelp
            status={departmemnthelpStatus}
            handleClose={() => {
              setDepartmentHelpStatus(false);
            }}
            onRowClick={(p) => {
              setDepartmentHelpStatus(false);
              setDeaprtmentName(p.row.DEPARTMENT_NAME);
            }}
          />
          <EmployeeMasterHelp
            onRowClick={(p) => {
              // const d = {
              //   table_prefix_master: masterPrefix,
              //   // CODE: p.row.code,
              //   // DEPARTMENT_NAME: departmentName,
              //   MOBILE: p.row.mobile,
              // };
              dispatch(setModWorkerMasterHelpStatus(false));
              setEmployeeName(p.row.employee_name);
              setEmployeeMobile(p.row.mobile);
              setEmployeeCode(p.row.code);
              // dispatch(setSpinnerLoading('Checking employee'));
              // axios
              //   .post(`/api/masters/findEmployeeDepartment`, d, {
              //     headers: {
              //       'Content-Type': 'application/json',
              //       Authorization: `Bearer ${token}`,
              //     },
              //   })
              //   .then(async function (response) {
              //     if (!response.data.error) {
              //       if (response.data.response[0]) {
              //         dispatch(
              //           setAlertWithTitle({
              //             title: 'Alert',
              //             msg: 'Employee already in another department.',
              //           })
              //         );
              //       } else {
              //         setEmployeeName(p.row.employee_name);
              //         setEmployeeMobile(p.row.mobile);
              //         setEmployeeCode(p.row.code);
              //       }
              //     } else {
              //       dispatch(
              //         setAlertWithTitle({
              //           title: 'Error 112',
              //           msg: response.data.error,
              //         })
              //       );
              //     }
              //   })
              //   .catch(function (error) {
              //     if (error.response.data.name === 'TokenExpiredError') {
              //       dispatch(setSessionExpired(true));
              //       console.log(error.response);
              //     }
              //     console.log(error);
              //     dispatch(
              //       setAlertWithTitle({
              //         title: 'ERROR',
              //         msg: 'Something went wrong.',
              //         lottie: 'reject',
              //       })
              //     );
              //   })
              //   .finally(() => {
              //     dispatch(setModWorkerMasterHelpStatus(false));
              //     dispatch(setSpinnerLoading(false));
              //   });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EmpDepartMaster;
