import React from 'react';

const CompanyColumns = (width) => {
  const c = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'code',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 180 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'company_name',
      renderCell: (params) => (
        <div className="font-semibold">{params.value}</div>
      ),
      renderHeader: (params) => <div>Company Name</div>,
    },
    {
      width:
        width <= 768 ? 150 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'company_email',
      renderHeader: (params) => <div>Company Email</div>,
    },

    {
      field: 'company_mobile',
      renderHeader: (params) => <div>Company Mobile</div>,
      width:
        width <= 768 ? 90 : width <= 1024 ? 180 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'address',
      renderHeader: (params) => <div>Address</div>,
      width:
        width <= 768 ? 130 : width <= 1024 ? 100 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'company_website',
      renderHeader: (params) => <div className="flex">Website</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'tusers',
      renderHeader: (params) => <div>Users</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 80 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];
  return c;
};

const UserColumns = (width) => {
  const c = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 200 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_NAME',
      renderHeader: (params) => <div>User Name</div>,
    },
    {
      width:
        width <= 768 ? 150 : width <= 1024 ? 200 : width <= 1440 ? 250 : 250,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_EMAIL',
      renderHeader: (params) => <div>User Email</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 130 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'USER_MOBILE',
      renderHeader: (params) => <div>User Mobile</div>,
    },

    {
      field: 'USER_TYPE',
      renderHeader: (params) => <div>User Type</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'COMPANY_NAME',
      renderHeader: (params) => <div>Company Name</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 180 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];
  return c;
};

export { CompanyColumns, UserColumns };
