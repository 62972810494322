import React, { useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix } from '../utility/Redux/profile';
import {
  selectCustomerMasterHelpModStatus,
  setCustomerMasterHelpModStatus,
  setModCustomerMasterModifyStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { FormatNumber2 } from '../utility/component/tools';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import { CollectionFromToDate } from '../utility/component/collectionFromToDate';
import { CollectionFromToDateMobile } from '../utility/component/collectionFromToDateMobile';
import { PrintButton } from '../utility/component/buttons';
import CustomerMasterhelp from '../help/customermasterHelp';
import PrintBillSummary from './printBillSummary';

const width = window.innerWidth / 100;

const ItemWiseBookings = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const [list, setList] = useState([]);
  const [bookingCustomerDetails, setBookingCustomerDetails] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 100000,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: 'Good',
    remarks: '',
  });

  const customerMasterModalStatus = useSelector(
    selectCustomerMasterHelpModStatus
  );

  const customerDetails = (params) => {
    setBookingCustomerDetails(params.row);
    dispatch(setCustomerMasterHelpModStatus(false));
  };

  const columns = [
    {
      field: 'ICODE',
      accessor: 'ICODE',
      type: 'number',

      width: 70,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Code'}
        </div>
      ),
    },
    {
      field: 'ITEM_NAME',
      accessor: 'ITEM_NAME',
      type: 'string',
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Item Name'}
        </div>
      ),
    },

    {
      field: 'QNTY',
      accessor: 'QNTY',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex justify-center items-center">
          {'Qnty'}
        </div>
      ),
    },
  ];

  const loadVocherAPI = (invNo) => {
    const data = {
      table_prefix: prefix,
      FDATE: firstDate,
      TDATE: SecondDate,
    };

    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(`/api/reportRoute/ItemWiseBookings`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;
          const l = data.map((e, i) => {
            return {
              ...e,
              id: i,
            };
          });
          setList(l);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const printRef = useRef();
  const navigate = useNavigate();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate('/voucher_summary');
      setSelectedPrintOption('print');
    },
  });

  return (
    <div className=" w-full h-full flex flex-col p-3 overflow-auto">
      {window.innerWidth <= 800 ? (
        <div>
          <CollectionFromToDateMobile
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            hideCustomer={true}
            setSecondDate={setSecondDate}
            onOkClick={() => {
              loadVocherAPI();
            }}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      ) : (
        <div>
          <CollectionFromToDate
            setFirstDate={setFirstDate}
            firstDate={firstDate}
            SecondDate={SecondDate}
            hideCustomer={true}
            onOkClick={() => {
              loadVocherAPI();
            }}
            setSecondDate={setSecondDate}
            reset={() => {
              setBookingCustomerDetails({
                customer_name: '',
                mobile: '',
              });
            }}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        </div>
      )}
      <div className=" flex flex-col w-[300px] mt-2 max-sm:mt-2">
        <div className="h-[360px] l:h-[430px] d:h-[600px]">
          <DataGrid
            sx={{
              borderRadius: '7px',
              backgroundColor: 'white',
              border: '0.5px solid #b5b3b3',
              fontSize: 12,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
                display: 'none',
              },
              '& .MuiDataGrid-pagination': {
                display: 'none',
              },
              '& .MuiDataGrid-horizontalScroll': {
                overflowX: 'hidden',
              },
            }}
            rowHeight={33}
            headerHeight={0}
            columns={columns}
            rows={list}
            hideFooter
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
            components={{
              Header: () => <GeniusHeader3 columns={columns} />,

              NoRowsOverlay: () => (
                <div className="w-full h-full flex items-center justify-center text-ggray">
                  No data to display
                </div>
              ),
            }}
          />
        </div>
      </div>

      <div className="flex w-[300px] justify-end mt-4">
        <PrintButton onClick={handlePrint} />
      </div>

      <CustomerMasterhelp
        onRowClick={(params) => {
          if (customerMasterModalStatus) {
            customerDetails(params);
          }
          dispatch(setModCustomerMasterModifyStatus(false));
        }}
      />

      <div className="hidden">
        {selectedPrintOption === 'print' && windowSize ? (
          <div
            ref={printRef}
            style={{ display: 'block', visibility: 'hidden' }}
          >
            <PrintBillSummary
              data={list}
              columns={columns}
              firstDate={firstDate}
              SecondDate={SecondDate}
              bookingCustomerDetails={bookingCustomerDetails}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ItemWiseBookings;
