import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectWidth } from '../utility/Redux/profile';
import { Modal } from '@mui/material';
import { Logout } from '@mui/icons-material';
const modalOverlayClasses =
  'fixed top-0 left-0 w-full h-full flex items-center justify-center bg-zinc-900 bg-opacity-50 z-50';
const modalContentClasses =
  'bg-white dark:bg-zinc-900 rounded-lg p-6 w-full max-w-md';
const buttonClasses = 'px-4 py-2 rounded-md';

const EmployeeDepartmentList = ({ onRowClick, list, status, handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);

  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 300 : width <= 1024 ? 300 : width <= 1440 ? 450 : 400,
      headerAlign: 'center',
      align: 'left',
      field: 'DEPARTMENT_NAME',
      headerName: 'Department',
      renderHeader: (params) => <div className="text-md">Department</div>,
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.DEPARTMENT_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);
  useEffect(() => {
    if (list[0]) {
      setFullList(list);
      setRows(list);
    }
  }, [list]);
  return (
    <>
      <div className="bg-white">
        <Modal
          open={status}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={style}>
            <div className="w-[310px]   ">
              <div className="border-b  p-2  flex items-center  w-full">
                <h2 className="text-2xl font-bold text-gray-800  ">
                  Select Department
                </h2>
              </div>
              <div className="flex flex-col gap-4 p-2 py-3 max-h-[600px] overflow-y-auto">
                {list.map((o) => {
                  return (
                    <button
                      onClick={() => {
                        onRowClick(o);
                      }}
                      className={`bg-[#ffc40c] text-zinc-900 ${buttonClasses}`}
                    >
                      {o.DEPARTMENT_NAME}
                    </button>
                  );
                })}
              </div>
              <div className="p-2">
                <button
                  onClick={handleClose}
                  className="bg-red   w-full text-white px-4 py-2 rounded-md"
                >
                  <Logout
                    style={{ fontSize: 20, color: 'white', marginRight: '7px' }}
                  />
                  Logout
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EmployeeDepartmentList;
