import React, { useState } from 'react';
import FundDashboard from './fundDashboard';
import { HomeDashboard } from './HomeDashboard';
const ManagerDashboard = () => {
  const [dashboardType, setDashboardType] = useState(1);
  return (
    <div className=" w-full text-sm">
      <div className="flex justify-center w-full mt-1">
        <div className="flex">
          <input
            onChange={(e) => {
              setDashboardType(e.target.checked ? 1 : 2);
            }}
            type="radio"
            checked={dashboardType === 1}
          />
          <div className="ml-1"> Overview</div>
        </div>

        <div className="ml-3 flex">
          <input
            onChange={(e) => {
              setDashboardType(e.target.checked ? 2 : 1);
            }}
            type="radio"
            checked={dashboardType === 2}
          />

          <div className="ml-1">Sales & Deliveries</div>
        </div>
      </div>
      {dashboardType === 1 ? <FundDashboard /> : <HomeDashboard />}
    </div>
  );
};

export default ManagerDashboard;
