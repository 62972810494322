import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDepartmentName, selectPrefix } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { setSessionExpired } from '../utility/Redux/modal';
import { DataGrid } from '@mui/x-data-grid';
import { GeniusHeader3 } from '../utility/component/datagrid.js/header';
import moment from 'moment';
import GeniusTableReports from '../utility/component/datagrid.js/tableData';

const PendingOrder = () => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const departmentName = useSelector(selectDepartmentName);
  useEffect(() => {
    if (departmentName) {
      const data = {
        table_prefix: prefix,
        STAGE_NAME: departmentName,
      };
      axios
        .post('/api/production/productionStatus', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((p) => {
          if (p.data.response[0]) {
            const data = p.data.response;
            setList(data);
          }
        })
        .catch((error) => {
          console.log(error.response.data.name);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        });
    }
  }, [departmentName]);
  const Columns = [
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      width: 90,
      renderCell: (params) => (
        <div>{moment(params.value).format('YYYY-MM-DD')}</div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Date'} </div>,
    },

    {
      field: 'BOOKING_ORDER_NO',
      accessor: 'BOOKING_ORDER_NO',
      type: 'string',
      width: window.innerWidth < 768 ? 60 : 120,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="text-white">
          {' '}
          {window.innerWidth < 768 ? 'Order' : 'Booking Order'}{' '}
        </div>
      ),
    },
    {
      field: 'STAGE_NAME',
      accessor: 'STAGE_NAME',
      type: 'string',
      width: 110,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Stage'} </div>,
    },
    {
      field: 'NO_OF_ORDERS',
      accessor: 'NO_OF_ORDERS',
      type: 'string',
      width: 60,
      headerClassName: 'super-app-theme--header',
      renderCell: (p) => {
        return <div className="font-semibold">{p.value}</div>;
      },
      renderHeader: (params) => <div className="text-white"> {'Qnty'} </div>,
    },
  ];

  return (
    <div className="p-3 h-full w-full">
      <div
        style={{
          width: Columns.reduce((acc, row) => acc + Number(row.width), 0),
        }}
        className="h-full "
      >
        <GeniusTableReports
          rowHeight={33}
          headerHeight={0}
          columns={Columns}
          data={list}
          getRowId={(i) => i.BOOKING_ORDER_NO}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          disableColumnMenu={true}
          components={{
            Header: () => <GeniusHeader3 columns={Columns} />,
            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                No Result found
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default PendingOrder;
