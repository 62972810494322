import React, { useEffect, useState } from 'react';
import Billing from './billing';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';
import axios from 'axios';
import { setCustomerPrefix } from '../utility/Redux/booking';
import DelBilling from '../del_billing/billing';
import ModalHeaderStyle from '../utility/component/styles/modalHeaderStyle';
import QRCodeScanner from '../employeee/scanCode';

const BillingDashboard = () => {
  const localDate = localStorage.getItem('bookingDate');
  const [familyMemberName, setFamilyMemberName] = useState('');
  const [tabScreen, SetTabScreen] = useState(1);
  const [mrcNumber, setMrcNumber] = useState('');
  const [bookingPref, setBookingPrefix] = useState('');
  const [billSettelmentDetails, setBillSettlementDetails] = useState([]);
  const [bookingDate, setBookingDate] = useState(
    localDate
      ? moment(localDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  );
  const [employee, setEmployee] = useState();
  const [bookingCustomerDetails, setBookingCustomerDetails] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 0,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: '',
    remarks: 'delivery',
    prefix: '',
    mrc_no: '',
    old_mobile: '',
  });
  const location = useLocation();
  const state = location.state;
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const getCustomermasterListPromise = (mobile, name) => {
    dispatch(setSpinnerLoading('Customers Loading'));
    const data = {
      table_prefix: prefix,
      MOBILE: mobile ? mobile : '',
      CODE: '',
      CUSTOMER_NAME: name ? name : '',
      ADDRESS: '',
      CITY: '',
      STATE: '',
      COUNTRY: '',
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/masters/listCustomerMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (!res.data.error) {
            const data = res.data.response;
            resolve(data);
          } else {
            reject(new Error('Error in response data'));
          }
        })
        .catch((e) => {
          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(e);
          reject(e);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    });
  };

  useEffect(() => {
    if (state?.customer) {
      getCustomermasterListPromise(state?.customer.mobile).then((d) => {
        const customerDetails = d.filter(
          (o) =>
            o.mrc_no === state.customer.mrc &&
            o.mobile === state.customer.mobile &&
            o.customer_name === state.customer.customer_name
        );
        if (customerDetails[0]) {
          setBookingCustomerDetails(customerDetails[0]);
          dispatch(setCustomerPrefix(state.customer.prefix));
        }
      });
    }
  }, [state]);

  useEffect(() => {
    if (!localDate) {
      localStorage.setItem('bookingDate', moment().format('YYYY-MM-DD'));
    }
  }, [localDate]);
  const navigate = useNavigate();
  const [qrBillList, setQrBillList] = useState([]);
  const order = qrBillList[0] ? qrBillList[0].split('_') : [];

  useEffect(() => {
    if (state?.customer?.mrc) {
      setMrcNumber(state?.customer.mrc);
      setBookingPrefix(state?.customer.prefix);
      setEmployee(state.employee);
    } else {
      navigate('/');
    }
  }, [state]);

  return (
    <div className="px-3 p-2 h-full flex justify-center">
      <div className="text-sm  rounded-sm  h-full ">
        <div className="bg-white">
          <ModalHeaderStyle
            header={'Save Bill'}
            onClose={() => {
              navigate('/');
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - 80px)`,
          }}
          className="  flex  p-2 bg-white rounded-b-sm"
        >
          <DelBilling
            orderID={order[0] ? order[0] : state?.orderID}
            familyMemberName={familyMemberName}
            setFamilyMemberName={setFamilyMemberName}
            SetTabScreen={SetTabScreen}
            setMrcNumber={() => {}}
            mrcNumber={mrcNumber ? mrcNumber : state?.customer?.mrc}
            customerPrefixState={
              bookingPref ? bookingPref : state?.customer?.prefix
            }
            employee={employee}
            setBookingDate={setBookingDate}
            state={state}
            qrBillList={qrBillList}
            setQrBillList={setQrBillList}
            isDelivery={true}
            billSettelmentDetails={billSettelmentDetails}
            bookingCustomerDetails={bookingCustomerDetails}
            setBookingCustomerDetails={setBookingCustomerDetails}
          />
          <div
            style={{
              display: 'none',
            }}
          >
            <QRCodeScanner
              setQrBillList={setQrBillList}
              setMrcNumber={setMrcNumber}
              setBookingPrefix={setBookingPrefix}
              mrc={mrcNumber}
              bookingPref={bookingPref}
              setBillSettlementDetails={setBillSettlementDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDashboard;
