import React from 'react';
import { useTable } from 'react-table';

const TableGenius = ({ columns, data }) => {
  // Create an instance of the table
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   useTable({ columns, data });

  const desiredColumnKeys = ['PARAMETER', 'size'];
  const additionalFieldKey = 'REGIONAL_INAME';

  return (
    <div>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                style={{
                  border: '1px solid #b5b3b3',
                  padding: '8px',
                  textAlign: 'left',
                  backgroundColor: '#f2f2f2',
                  fontSize: '16px',
                }}
              >
                {column.accessor.toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {desiredColumnKeys.map((key) => (
                <td
                  key={key}
                  style={{
                    border: '1px solid #b5b3b3',
                    padding: '8px',
                    fontSize: '16px',
                  }}
                >
                  {key === 'PARAMETER' ? (
                    <>
                      <div className="flex">
                        <div>{row[key]}</div>
                        <div className="ml-2">
                          {' '}
                          {row[additionalFieldKey]
                            ? `(${row[additionalFieldKey]})`
                            : ''}
                        </div>
                      </div>
                    </>
                  ) : (
                    row[key]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableGenius;
