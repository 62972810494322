import React from 'react';
import uniqid from 'uniqid';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertWithTitle } from '../../Redux/modal';

const AttachImage = ({
  attachImage,
  bookingAttachImage,
  attachImageFolder,
  setAttachImageModStatus,bookingCustomerDetails
}) => {
  const dispatch = useDispatch();

  //
  return (
    <div className="w-full h-full items-end flex ">
      <Button
        variant="contained"
        style={{
          // border: '1px dashed gray',
          backgroundColor: '#88e0d0',
          textTransform: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontWeight: 500,
          height: '100%',
          padding: '4px',
        }}
        onClick={(p) => {
          if (bookingCustomerDetails?.customer_name && bookingCustomerDetails?.mobile) {
            setAttachImageModStatus(true);
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Customer Mobile!',
                msg: 'Please select Customer Mobile to continue.',
              })
            );
          }
        }}
        className="rounded-sm   flex grow items-center  justify-center"
      >
        <div
          style={{ color: 'black' }}
          className="whitespace-nowrap flex items-center "
        >
          {' '}
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 18 32"

            // xml:space="preserve"
          >
            <path
              fill="black"
              d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,23C5.935,23,1,18.065,1,12
		S5.935,1,12,1s11,4.935,11,11S18.065,23,12,23z"
            />
            <path
              fill="black"
              d="M12,6.5c-3.032,0-5.5,2.467-5.5,5.5s2.468,5.5,5.5,5.5s5.5-2.467,5.5-5.5S15.032,6.5,12,6.5z M12,16.5
		c-2.481,0-4.5-2.019-4.5-4.5S9.519,7.5,12,7.5s4.5,2.019,4.5,4.5S14.481,16.5,12,16.5z"
            />
            <path
              fill="black"
              d="M11,9.455c-0.853,0-1.546,0.693-1.546,1.545c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5
		c0-0.301,0.245-0.545,0.546-0.545c0.276,0,0.5-0.224,0.5-0.5S11.276,9.455,11,9.455z"
            />
            <path
              fill="black"
              d="M22.804,31.237c0.254-0.43,0.261-0.948,0.02-1.385l-2.536-4.594c-0.133-0.242-0.439-0.33-0.679-0.196
		c-0.242,0.133-0.33,0.438-0.196,0.679l2.536,4.594c0.09,0.164,0.047,0.304-0.006,0.393C21.865,30.86,21.687,31,21.418,31H2.582
		c-0.269,0-0.447-0.14-0.524-0.271C2.005,30.64,1.962,30.5,2.052,30.335l2.536-4.594c0.134-0.242,0.046-0.546-0.196-0.679
		c-0.24-0.134-0.546-0.046-0.679,0.196l-2.536,4.594c-0.241,0.437-0.234,0.955,0.02,1.385C1.474,31.708,2.005,32,2.582,32h18.836
		C21.995,32,22.526,31.708,22.804,31.237z"
            />
          </svg>
          <label className="whitespace-nowrap pl-1  ">Attach Image</label>
        </div>
      </Button>

   
      {attachImage ? (
        <div
          onClick={() => {
            dispatch(setAttachImageModStatus(true));
          }}
          className=" w-[40px]  rounded-full"
        >
          <img
            src={URL.createObjectURL(attachImage)}
            style={{
              height: '35px',
              width: '35px',
              marginLeft: '8px',
            }}
          />
        </div>
      ) : attachImageFolder ? (
        <div
          onClick={() => {
            dispatch(setAttachImageModStatus(true));
          }}
          className=" w-[40px]  rounded-full"
        >
          <img
            src={URL.createObjectURL(attachImageFolder)}
            alt={`Selected`}
            style={{
              height: '35px',
              width: '35px',
              marginLeft: '8px',
            }}
          />
        </div>
      ) : bookingAttachImage ? (
        <div
          onClick={() => {
            dispatch(setAttachImageModStatus(true));
          }}
          className=" w-[40px]  rounded-full"
        >
          <img
            src={bookingAttachImage}
            style={{
              height: '35px',
              width: '35px',
              marginLeft: '8px',
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AttachImage;
