import React from 'react';
import './IntersectingDiagonals.css';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import Webcam from 'react-webcam';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerFirebasePic } from '../../Redux/booking';
import { setAlertWithTitle } from '../../Redux/modal';
import { UploadFile, deleteFile } from '../functions/firebase';
import { selectPrefix } from '../../Redux/profile';
const ProfilePicFrame = ({
  customerFirebasePic,
  selectedFile,
  setSelectedFile,
  webcamStatus,
  setWebcamStatus,
  capturedImage,
  setCapturedImage,
  webcamRef,
  path,
  bookingCustomerDetails,
  setpath,
  setFirebaseImg,
}) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const mobile = bookingCustomerDetails?.mobile;

  const handleDrop = (acceptedFiles) => {
    if (mobile) {
      const editedFiles = acceptedFiles.map((file) => {
        const editedName = ` ${uniqid()}-${file.name}`;
        return new File([file], editedName, { type: file.type });
      });

      setSelectedFile(editedFiles[0]);

      const name = `${prefix + mobile}`;
      const path = 'seam/images/';
      UploadFile(editedFiles[0], path, name);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Customer Mobile!',
          msg: 'Please select Customer Mobile to continue.',
        })
      );
    }

    // dispatch(setSelectedPhoto(acceptedFiles[0]));
  };

  const shootImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage(imageSrc);
    const name = `${prefix + mobile}`;
    const path = 'seam/images/';
    const file = await fetch(imageSrc).then((response) => response.blob());
    UploadFile(file, path, name);
    setWebcamStatus(false);
  };
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className="diagonalp-container   rounded-sm"
    >
      {webcamStatus ? (
        <div className="h-full w-full flex flex-col justify-between">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#fa8572',
              zIndex: '50',
              fontSize: '19px',
              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />

          <Webcam
            audio={false}
            ref={webcamRef}
            style={{
              width: '100%', // Set your desired width
              height: 'auto',
            }}
            onUserMediaError={() => {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Camera not found',
                })
              );
              dispatch(setWebcamStatus(false));
              setSelectedFile('');
            }}
            // height={profilePicWidth - 50}
            mirrored={true} // Depending on your preference
          />

          <Button
            // ref={updateRef}
            onClick={() => {
              shootImage();
            }}
            variant="contained"
            size="small"
            style={{
              textTransform: 'none',
              letterSpacing: '1px',
              backgroundColor: '#ebd9dd',
              width: '100%',

              fontWeight: '500',

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="text-black  flex flex-col  ">Save Image </div>
          </Button>
        </div>
      ) : capturedImage ? (
        <div className="h-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '0px',
            }}
          >
            <Close
              onClick={() => {
                setCapturedImage(null);
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#fa8572',
                zIndex: '50',
                fontSize: '19px',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
              }}
            />
          </Button>

          <img
            src={capturedImage}
            alt={`Selected`}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
      ) : selectedFile ? (
        <div className="h-full ">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#fa8572',
              zIndex: '50',
              fontSize: '19px',
              borderLeft: '1px dotted #DDD6D6',
              borderBottom: '1px dotted DDD6D6',
              backgroundColor: 'white',
              borderBottomLeftRadius: '7px',
            }}
          />
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <img
                {...getRootProps()}
                src={URL.createObjectURL(selectedFile)}
                alt={`Selected`}
                style={{
                  width: 'auto',
                  height: '100%',
                }}
              />
            )}
          </Dropzone>
        </div>
      ) : bookingCustomerDetails?.mobile ? (
        customerFirebasePic ? (
          <div className="h-full  ">
            <Button
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
                padding: '0px',
              }}
            >
              <Close
                onClick={() => {
                  if (customerFirebasePic && path) {
                    deleteFile(path);
                  }
                  dispatch(setCustomerFirebasePic(''));
                  setCapturedImage(null);
                }}
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  color: '#fa8572',
                  zIndex: '50',
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '7px',
                  border: '1px dotted gray',
                  fontSize: '19px',
                }}
              />
            </Button>

            <img
              src={customerFirebasePic}
              alt={`Selected`}
              style={{
                width: 'auto',
                height: '100%',
              }}
            />
          </div>
        ) : (
          <>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <div
                    {...getRootProps()}
                    style={{
                      right: 0,
                      left: 0,
                      zIndex: 100,
                      width: 'auto',
                      height: '100%',
                    }}
                    className={
                      'flex justify-center items-center absolute h-full  '
                    }
                  >
                    <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
                  </div>
                  <div className="diagonalp-1 w-full h-full "></div>

                  <div className="diagonalp-2 "></div>
                </>
              )}
            </Dropzone>
          </>
        )
      ) : customerFirebasePic ? (
        <div className="h-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '0px',
            }}
          >
            <Close
              onClick={() => {
                if (customerFirebasePic && path) {
                  deleteFile(path);
                }
                if (setFirebaseImg) {
                  setFirebaseImg('');
                }
                dispatch(setCustomerFirebasePic(''));
                setCapturedImage(null);
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#fa8572',
                zIndex: '50',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
                borderLeft: '1px dotted #DDD6D6',
                borderBottom: '1px dotted #DDD6D6',
                fontSize: '19px',
                borderTopRightRadius: '7px',
                borderBottomLeftRadius: '7px',
              }}
            />
          </Button>

          <img
            src={customerFirebasePic}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : (
        <>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  style={{
                    right: 0,
                    left: 0,
                    zIndex: 100,
                    width: 'auto',
                    height: '100%',
                  }}
                  className={
                    'flex justify-center items-center absolute h-full  '
                  }
                >
                  <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
                </div>
                <div className="diagonalp-1 w-full h-full "></div>

                <div className="diagonalp-2 "></div>
              </>
            )}
          </Dropzone>
        </>
      )}
    </div>
  );
};

export default ProfilePicFrame;
