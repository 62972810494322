import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import {
  selectCompanyAddress,
  selectUserEmail,
  selectWidth,
} from '../utility/Redux/profile';
import { CompanyColumns, UserColumns } from './columns';
import {
  setSessionExpired,
  setSpinnerLoading,
  setloading,
} from '../utility/Redux/modal';
import GeniusDatagrid from '../utility/component/datagrid.js/geniusDatagrid';
import axios from 'axios';

const UserList = () => {
  const companyDetails = useSelector(selectCompanyAddress);
  const userEmail = useSelector(selectUserEmail);
  const windowWidth = useSelector(selectWidth);
  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [tUsers, setTusers] = useState('');
  const columns = CompanyColumns(windowWidth);
  const UColumns = UserColumns(windowWidth);
  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  const getCompanyInfo = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(companyDetails.email, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    await axios
      .post(
        '/api/users/companyHelp',
        {
          company_email: companyDetails.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((p) => {
        setCompanyList(p.data.response);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const getUserInfoDetails = () => {
    setloading(true);
    const email = userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    axios
      .post(
        `/api/users/userHelp`,
        {
          company_email: companyEmail,
          user_email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setUserList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getUserInfoDetails();
  }, []);

  return (
    <div className="h-full w-full text-sm p-2">
      <div
        style={{
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className="flex  "
      >
        <div
          style={{
            width: columns[0].width + columns[1].width,
          }}
          className="flex"
        >
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="flex rounded-l-sm py-2 w-fit px-4"
          >
            <i className="bi bi-briefcase-fill flex items-center  text-brown"></i>{' '}
          </div>
          <div
            style={{
              border: '1px dotted #b5b3b3',
              borderLeft: '0px dotted #b5b3b3',
            }}
            className="flex rounded-r-sm py-2 grow px-2 font-semibold"
          >
            {companyDetails.email}
          </div>
        </div>
        <div className=" flex ml-1 ">
          <div
            style={{
              width:
                columns[2].width +
                columns[3].width -
                8 +
                columns[4].width +
                columns[5].width,
            }}
            className=" flex  w-full"
          >
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2 w-fit px-2"
            >
              User Email
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm py-2 grow px-2 font-semibold"
            >
              {userEmail}
            </div>
          </div>
          <div
            style={{
              width: columns[4].width - 4,
            }}
            className="flex ml-1"
          >
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2 w-fit px-4"
            >
              <i className="bi bi-people-fill  text-[#b4b4b1] "></i>
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm py-2 grow px-2 font-semibold"
            ></div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 'calc(50% - 49px)',
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className="  mt-2 "
      >
        <GeniusDatagrid
          list={companyList}
          rowID={(r) => r.code}
          columns={columns}
          onRowClick={(p) => {
            setCompanyEmail(p.row.company_email);
            setSelectedUserEmail(p.row.user_email);
            setTusers(p.row.tusers);
            setCompanyName(p.row.company_name);
            getUserInfoDetails();
          }}
        />
      </div>

      <div className="flex  mt-2 ">
        <div
          style={{
            width: UColumns[0].width + UColumns[1].width + UColumns[2].width,
          }}
          className=" flex"
        >
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="flex rounded-l-sm py-2 px-2"
          >
            <button>
              <i className="bi bi-search mr-2 text-orange ml-2 flex items-center"></i>
            </button>
            Company Name
          </div>
          <div
            style={{
              border: '1px dotted #b5b3b3',
              borderLeft: '0px dotted #b5b3b3',
            }}
            className="flex rounded-r-sm py-2 grow px-2 font-semibold"
          >
            {companyName}
          </div>
        </div>
        <div className="flex ml-1">
          <div
            style={{
              width:
                UColumns[3].width + UColumns[4].width + UColumns[5].width - 4,
            }}
            className=" flex "
          >
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2  px-2"
            >
              Email
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm grow py-2  px-2 font-semibold"
            >
              {selectedUserEmail}
            </div>
          </div>
          <div
            style={{
              width: UColumns[5].width - 4,
            }}
            className="flex ml-1"
          >
            {' '}
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex rounded-l-sm py-2 w-fit px-4"
            >
              <i className="bi bi-people-fill  text-[#b4b4b1] "></i>
            </div>
            <div
              style={{
                border: '1px dotted #b5b3b3',
                borderLeft: '0px dotted #b5b3b3',
              }}
              className="flex rounded-r-sm py-2 grow px-2 font-semibold"
            >
              {tUsers}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 'calc(50% - 49px)',
          width:
            UColumns[0].width +
            UColumns[1].width +
            UColumns[2].width +
            UColumns[3].width +
            UColumns[4].width +
            UColumns[5].width,
        }}
        className="w-full mt-2"
      >
        <GeniusDatagrid
          list={userList}
          rowID={(r) => r.CODE}
          columns={UColumns}
        />
      </div>
    </div>
  );
};

export default UserList;
